import { GraphQLQuery, GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import {
  Account,
  AccountGroup,
  CreateAccountGroupInput,
  CreateAccountGroupMutation,
  CreateAccountInput,
  CreateAccountMutation,
  DeleteAccountGroupMutation,
  DeleteAccountMutation,
  GetAccountGroupQuery,
  GetAccountQuery,
  ListAccountGroupsQuery,
  ListAccountGroupsQueryVariables,
  ListAccountsQuery,
  ListAccountsQueryVariables,
  OnCreateAccountGroupSubscription,
  OnCreateAccountSubscription,
  OnDeleteAccountGroupSubscription,
  OnDeleteAccountSubscription,
  OnUpdateAccountGroupSubscription,
  OnUpdateAccountSubscription,
  UpdateAccountGroupInput,
  UpdateAccountGroupMutation,
  UpdateAccountInput,
  UpdateAccountMutation,
} from '../../API';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';

export const getAccountGroup = async (id: string) => {
  try {
    const result = await API.graphql<GraphQLQuery<GetAccountGroupQuery>>({
      query: queries.getAccountGroup,
      variables: { id: id },
    });
    if (result.data?.getAccountGroup) {
      return result.data.getAccountGroup as AccountGroup;
    } else {
      return undefined;
    }
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in getAccountGroup: ', error.message ?? '');
    }
  }
};

export const listAccountGroups = async () => {
  const account_groups: AccountGroup[] = [];
  const variables: ListAccountGroupsQueryVariables = {
    limit: 100,
    filter: { _deleted: { attributeExists: false } },
  };
  let nextToken: string | undefined | null = undefined;

  do {
    const result = await API.graphql<GraphQLQuery<ListAccountGroupsQuery>>({
      query: queries.listAccountGroups,
      variables: { ...variables },
    });
    if (!result.data?.listAccountGroups?.items) break;
    const { items, nextToken: nt } = result.data.listAccountGroups;
    account_groups.push(...(compact(items) as AccountGroup[]));
    nextToken = nt;
    variables.nextToken = nt;
  } while (nextToken);
  return account_groups;
};

export const createAccountGroup = async (input: CreateAccountGroupInput) => {
  try {
    const result = await API.graphql<GraphQLQuery<CreateAccountGroupMutation>>({
      query: mutations.createAccountGroup,
      variables: { input: input },
    });
    if (result.data?.createAccountGroup) {
      return result.data.createAccountGroup as AccountGroup;
    } else {
      return undefined;
    }
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in createAccountGroup: ', error.message ?? '');
    }
  }
};

export const updateAccountGroup = async (input: UpdateAccountGroupInput) => {
  try {
    const result = await API.graphql<GraphQLQuery<UpdateAccountGroupMutation>>({
      query: mutations.updateAccountGroup,
      variables: { input: input },
    });
    if (result.data?.updateAccountGroup) {
      return result.data.updateAccountGroup as AccountGroup;
    } else {
      return undefined;
    }
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in updateAccountGroup: ', error.message ?? '');
    }
  }
};

export const deleteAccountGroup = async (id: string, version: number) => {
  try {
    const input = { id: id, _version: version };
    const result = await API.graphql<GraphQLQuery<DeleteAccountGroupMutation>>({
      query: mutations.deleteAccountGroup,
      variables: { input: input },
    });
    if (result.data?.deleteAccountGroup) {
      return result.data.deleteAccountGroup as AccountGroup;
    } else {
      return undefined;
    }
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in deleteAccountGroup: ', error.message ?? '');
    }
  }
};

export const useAccountGroupSubscription = () => {
  const [account_groups, setAccountGroups] = useState<AccountGroup[]>([]);

  const fetchAccountGroups = async () => {
    const account_groups = await listAccountGroups();
    setAccountGroups(
      account_groups.sort((a, b) => a.account_group_code.localeCompare(b.account_group_code))
    );
  };

  useEffect(() => {
    fetchAccountGroups();
  }, []);

  useEffect(() => {
    const createSub = API.graphql<GraphQLSubscription<OnCreateAccountGroupSubscription>>({
      query: subscriptions.onCreateAccountGroup,
    }).subscribe({
      next: () => fetchAccountGroups(),
      error: (error) => console.warn(error),
    });

    const updateSub = API.graphql<GraphQLSubscription<OnUpdateAccountGroupSubscription>>({
      query: subscriptions.onUpdateAccountGroup,
    }).subscribe({
      next: () => fetchAccountGroups(),
      error: (error) => console.warn(error),
    });

    const deleteSub = API.graphql<GraphQLSubscription<OnDeleteAccountGroupSubscription>>({
      query: subscriptions.onDeleteAccountGroup,
    }).subscribe({
      next: () => fetchAccountGroups(),
      error: (error) => console.warn(error),
    });

    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      deleteSub.unsubscribe();
    };
  }, []);
  return { account_groups };
};

export const getAccount = async (id: string) => {
  try {
    const result = await API.graphql<GraphQLQuery<GetAccountQuery>>({
      query: queries.getAccount,
      variables: { id: id },
    });
    if (result.data?.getAccount) {
      return result.data.getAccount as Account;
    } else {
      return undefined;
    }
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in getAccount: ', error.message ?? '');
    }
  }
};

export const listAccounts = async () => {
  const accounts: Account[] = [];
  const variables: ListAccountsQueryVariables = {
    limit: 100,
    filter: { _deleted: { attributeExists: false } },
  };
  let nextToken: string | undefined | null = undefined;

  do {
    const result = await API.graphql<GraphQLQuery<ListAccountsQuery>>({
      query: queries.listAccounts,
      variables: { ...variables },
    });
    if (!result.data?.listAccounts?.items) break;
    const { items, nextToken: nt } = result.data.listAccounts;
    accounts.push(...(compact(items) as Account[]));
    nextToken = nt;
    variables.nextToken = nt;
  } while (nextToken);
  return accounts;
};

export const createAccount = async (input: CreateAccountInput) => {
  try {
    const result = await API.graphql<GraphQLQuery<CreateAccountMutation>>({
      query: mutations.createAccount,
      variables: { input: input },
    });
    if (result.data?.createAccount) {
      return result.data.createAccount as Account;
    } else {
      return undefined;
    }
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in createAccount: ', error.message ?? '');
    }
  }
};

export const updateAccount = async (input: UpdateAccountInput) => {
  try {
    const result = await API.graphql<GraphQLQuery<UpdateAccountMutation>>({
      query: mutations.updateAccount,
      variables: { input: input },
    });
    if (result.data?.updateAccount) {
      return result.data.updateAccount as Account;
    } else {
      return undefined;
    }
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in updateAccount: ', error.message ?? '');
    }
  }
};

export const deleteAccount = async (id: string, version: number) => {
  try {
    const input = { id: id, _version: version };
    const result = await API.graphql<GraphQLQuery<DeleteAccountMutation>>({
      query: mutations.deleteAccount,
      variables: { input: input },
    });
    if (result.data?.deleteAccount) {
      return result.data.deleteAccount as Account;
    } else {
      return undefined;
    }
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in deleteAccount: ', error.message ?? '');
    }
  }
};

export const useAccountSubscription = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);

  const fetchAccounts = async () => {
    const accounts = await listAccounts();
    setAccounts(
      accounts
        .sort((a, b) => a.account_code.localeCompare(b.account_code))
        .sort((a, b) => a.account_code_sr.localeCompare(b.account_code_sr))
    );
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    const createSub = API.graphql<GraphQLSubscription<OnCreateAccountSubscription>>({
      query: subscriptions.onCreateAccount,
    }).subscribe({
      next: () => fetchAccounts(),
      error: (error) => console.warn(error),
    });

    const updateSub = API.graphql<GraphQLSubscription<OnUpdateAccountSubscription>>({
      query: subscriptions.onUpdateAccount,
    }).subscribe({
      next: () => fetchAccounts(),
      error: (error) => console.warn(error),
    });

    const deleteSub = API.graphql<GraphQLSubscription<OnDeleteAccountSubscription>>({
      query: subscriptions.onDeleteAccount,
    }).subscribe({
      next: () => fetchAccounts(),
      error: (error) => console.warn(error),
    });

    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      deleteSub.unsubscribe();
    };
  }, []);
  return { accounts };
};

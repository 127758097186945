import React, { useContext, useEffect, useRef, useState } from 'react';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { Button, Card, Form, Input, InputRef, Popconfirm, Space, Tag, Typography } from 'antd';
import { OperationType, Permission } from '../../models';
import { DataTableType } from '../../models';
import { AuthContext } from '../Users/AuthContext';
import { Account } from '../../API';
import { useGetPermissionInfo } from '../Users/adminHooks';
import {
  createAccount,
  deleteAccount,
  getAccount,
  updateAccount,
  useAccountGroupSubscription,
  useAccountSubscription,
} from './api';
import useLogDrawer from '../../custom_hooks/useLogDrawer';
import dayjs, { Dayjs } from 'dayjs';
import { logActivity } from '../../utilities/logger';
import Table, { ColumnType, TableProps } from 'antd/es/table';
import { CheckOutlined, ProfileOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnsType, FilterValue, TablePaginationConfig } from 'antd/es/table/interface';
import NetworkIndicator from '../../components/NetworkIndicator';
import Highlighter from 'react-highlight-words';

const { Title } = Typography;

const Accounts = () => {
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState<'add' | 'edit' | null>(null);
  const [editingAccount, setEditingAccount] = useState<Account | null>(null);
  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.ACCOUNT);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>(
    {} as TablePaginationConfig
  );

  const searchInput = useRef<InputRef>(null);

  useDocumentTitle('Accounts');

  const { accounts } = useAccountSubscription();
  const { account_groups } = useAccountGroupSubscription();

  const { openDrawer, LogDrawer } = useLogDrawer();

  useEffect(() => {
    if (modalState === 'add') {
      form.resetFields();
    } else if (modalState === 'edit' && editingAccount) {
      const { opening_date, ...rest } = editingAccount;
      form.setFieldsValue({
        ...rest,
        opening_date: opening_date ? dayjs(opening_date) : null,
      });
    }
  }, [modalState, editingAccount, form]);

  const validateAccountCode = async (rule: any, value: string) => {
    if (!value) return;
    if (modalState === 'edit' && editingAccount && value === editingAccount.account_code) return;
    const account = accounts.find((account) => account.account_code === value);
    if (account) {
      return Promise.reject('Account code already exists');
    }
    return Promise.resolve();
  };

  const validateAccountName = async (rule: any, value: string) => {
    if (!value) return;
    if (modalState === 'edit' && editingAccount && value === editingAccount.account_name) return;
    const account = accounts.find((account) => account.account_name === value);
    if (account) {
      return Promise.reject('Account name already exists');
    }
    return Promise.resolve();
  };

  type AccountWithDayJS = Omit<Account, 'opening_date'> & { opening_date: Dayjs };

  const hasEditingAccountChanged = (editingAccount: Account, values: AccountWithDayJS) => {
    const { opening_date, ...rest } = values;
    const accountWithPotentialChanges: Account = {
      ...rest,
      opening_date: opening_date.format('YYYY-MM-DD'),
    };
    const fieldListToCheck = [
      'account_code',
      'account_code_sr',
      'account_name',
      'account_group_id',
      'opening_amount',
      'opening_date',
      'is_active',
      'remark',
    ];
    return fieldListToCheck.some(
      (field) =>
        editingAccount[field as keyof Account] !==
        accountWithPotentialChanges[field as keyof Account]
    );
  };

  const onFormSave = async (values: AccountWithDayJS) => {
    if (!currentUser) return;
    try {
      if (modalState === 'add') {
        if (permission.CREATE === false) return;
        const newAccount = await createAccount({
          ...values,
          opening_date: values.opening_date.format('YYYY-MM-DD'),
        });
        await logActivity({
          user_email: currentUser.email,
          job: null,
          model_type: DataTableType.ACCOUNT,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newAccount || null,
        });
      } else if (modalState === 'edit') {
        if (permission.UPDATE === false) return;
        if (!editingAccount) return;
        const oldAccount = await getAccount(editingAccount.id);
        if (!oldAccount) return;
        if (!hasEditingAccountChanged(oldAccount, values)) {
          setModalState(null);
          return;
        }
        const updatedAccount = await updateAccount({
          ...values,
          id: editingAccount.id,
          opening_date: values.opening_date.format('YYYY-MM-DD'),
          _version: oldAccount._version,
        });
        await logActivity({
          user_email: currentUser.email,
          job: null,
          model_type: DataTableType.ACCOUNT,
          operation_type: OperationType.UPDATE,
          old_data: oldAccount,
          new_data: updatedAccount || null,
        });
      }
    } catch (error) {
      console.log('error saving account', error);
    } finally {
      form.resetFields();
      setModalState(null);
    }
  };

  const onDelete = async (account: Account) => {
    if (!currentUser) return;
    if (permission.DELETE === false) return;
    try {
      const deletedAccount = await deleteAccount(account.id, account._version);
      await logActivity({
        user_email: currentUser.email,
        job: null,
        model_type: DataTableType.ACCOUNT,
        operation_type: OperationType.DELETE,
        old_data: account,
        new_data: deletedAccount || null,
      });
    } catch (error) {
      console.log('error deleting account', error);
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
    localStorage.removeItem('accountsFilters');
  };

  useEffect(() => {
    const filters = localStorage.getItem('accountsFilters');
    const pagination = localStorage.getItem('accountsPagination');
    if (filters) setFilteredInfo(JSON.parse(filters));
    if (pagination) setPaginationInfo(JSON.parse(pagination));
  }, []);

  const handleTableChange: TableProps<Account>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    setPaginationInfo(pagination);
    localStorage.setItem('accountsPagination', JSON.stringify(pagination));
    setFilteredInfo(filters);
    localStorage.setItem('accountsFilters', JSON.stringify(filters));
  };

  const getColumnSearchProps = (dataIndex: keyof Account): ColumnType<Account> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex.split('_').join(' ')}`}
          allowClear
          value={selectedKeys[selectedKeys.length - 1]}
          onChange={(e) => {
            const value = e.target.value;
            // if (value.length === 0) return;
            const prevFilter = (filteredInfo[dataIndex] || []) as string[];
            setSelectedKeys([...new Set([...prevFilter, value])]);
          }}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8 }}
        />
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type='link' onClick={clearFilters} disabled={filteredInfo[dataIndex] === null}>
            Reset
          </Button>
          <Button
            type='primary'
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? 'primary' : undefined }} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.current?.select();
        }, 100);
      }
    },
  });

  const columns: ColumnsType<Account> = [
    {
      title: 'Account Code Sr',
      dataIndex: 'account_code_sr',
      key: 'account_code_sr',
      width: 100,
      filteredValue: filteredInfo.account_code_sr || null,
      filters: accounts
        .map((account) => ({
          text: account.account_code_sr,
          value: account.account_code_sr,
        }))
        .filter(
          (value, index, self) => self.findIndex((item) => item.value === value.value) === index
        )
        .sort((a, b) => a.value.localeCompare(b.value)),
      onFilter: (value, record) => record.account_code_sr.includes(value as string),
    },
    {
      title: 'Account Code',
      dataIndex: 'account_code',
      key: 'account_code',
      width: 100,
      ...getColumnSearchProps('account_code'),
      filteredValue: filteredInfo.account_code || null,
      onFilter: (value, record) => record.account_code.includes(value as string),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={(filteredInfo.account_code as string[]) || []}
          autoEscape
          textToHighlight={text || ''}
        />
      ),
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
      ...getColumnSearchProps('account_name'),
      filteredValue: filteredInfo.account_name || null,
      onFilter: (value, record) =>
        record.account_name.toLowerCase().includes((value as string).toLowerCase()),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={(filteredInfo.account_name as string[]) || []}
          autoEscape
          textToHighlight={text || ''}
        />
      ),
    },
    {
      title: 'Account Group',
      dataIndex: 'account_group_id',
      key: 'account_group_id',
      filteredValue: filteredInfo.account_group_id || null,
      filters: account_groups
        .map((group) => ({
          text: group.account_group_name,
          value: group.id,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      onFilter: (value, record) => record.account_group_id === value,
      render: (accountGroupId: string) => {
        const accountGroup = account_groups.find((group) => group.id === accountGroupId);
        return accountGroup?.account_group_name;
      },
    },
    {
      title: 'Opening Amount',
      dataIndex: 'opening_amount',
      key: 'opening_amount',
      width: 80,
    },
    {
      title: 'Opening Date',
      dataIndex: 'opening_date',
      key: 'opening_date',
      width: 120,
      sorter: (a, b) => dayjs(a.opening_date).unix() - dayjs(b.opening_date).unix(),
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 80,
      filteredValue: filteredInfo.is_active || null,
      filters: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      onFilter: (value, record) =>
        value === 'yes' ? record.is_active === true : !record.is_active,
      render: (text, record) => (record.is_active ? <CheckOutlined /> : ''),
    },
    {
      title: 'Action',
      key: 'action',
      width: 160,
      align: 'center',
      render: (text, account) => (
        <Space>
          <ProfileOutlined
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              openDrawer(undefined, DataTableType.ACCOUNT, account.id, 'model');
            }}
          />
          <Button
            onClick={() => {
              setModalState('edit');
              setEditingAccount(account);
            }}
            type='link'
            size='middle'
            disabled={!permission.UPDATE}
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete Account  ${account.account_name}?`}
            onConfirm={() => onDelete(account)}
            okText='Yes'
            cancelText='No'
          >
            <Button type='link' danger disabled={!permission.DELETE} size='middle'>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Title level={4} style={{ color: 'darkred' }}>
              Accounts
            </Title>
          </Space>
        }
        style={{ width: 'max(calc(100vw - 200px), 1100px)', height: 'fit-content' }}
        extra={
          <Space>
            <NetworkIndicator />
          </Space>
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            marginTop: -15,
          }}
        >
          <div>
            {Object.keys(filteredInfo).map((key) => {
              const values = filteredInfo[key];
              if (values === null || values.length === 0) return null;
              const tabs = values.map((v) => {
                return (
                  <Tag
                    key={key + v}
                    closable
                    color='lime'
                    onClose={() => {
                      setFilteredInfo((prev) => {
                        localStorage.setItem(
                          'accountsFilters',
                          JSON.stringify({
                            ...prev,
                            [key]: prev[key]?.filter((k) => k !== v) || [],
                          })
                        );
                        return {
                          ...prev,
                          [key]: prev[key]?.filter((k) => k !== v) || [],
                        };
                      });
                    }}
                  >
                    {key === 'is_active'
                      ? 'Active'
                      : key === 'account_group_id'
                      ? 'Account group'
                      : (key[0].toUpperCase() + key.slice(1)).split('_').join(' ')}{' '}
                    :
                    {key === 'account_group_id'
                      ? account_groups.find((ag) => ag.id === v)?.account_group_name || ''
                      : v.toString()}
                  </Tag>
                );
              });
              return tabs;
            })}
          </div>
          <Space size='large'>
            <Button
              type='link'
              size='middle'
              onClick={clearFilters}
              hidden={!Object.values(filteredInfo).some((value) => value !== null)}
              style={{ margin: 0, padding: 0 }}
            >
              clear all filters
            </Button>
            {/* Button is disabled if current user does not have permission to create account group */}
            <Button
              type='default'
              onClick={() => setModalState('add')}
              disabled={!permission.CREATE}
            >
              Add Account
            </Button>
          </Space>
        </div>
        <Table
          rowKey={'id'}
          columns={columns}
          dataSource={accounts}
          size='small'
          onChange={handleTableChange}
          pagination={{
            current: paginationInfo.current,
            pageSize: paginationInfo.pageSize,
            showTotal: (total, range) => `${total} of ${accounts.length} items`,
          }}
        />
      </Card>
      {LogDrawer}
    </div>
  );
};

export default Accounts;

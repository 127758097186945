import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  LeftOutlined,
  LockFilled,
  UnlockFilled,
  MenuOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Space,
  Tooltip,
  Typography,
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Row as AntRow,
  Col,
  List,
  Popconfirm,
  Table,
  InputNumber,
} from 'antd';
import React, { useState, useEffect, ReactNode, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NetworkIndicator from '../../components/NetworkIndicator';
import {
  DataTableType,
  INVOICE_TYPE,
  Invoice as InvoiceAPI,
  InvoiceDetail,
  Job,
  OperationType,
} from '../../API';
import { Account } from '../../models';
import {
  createInvoice,
  deleteInvoice,
  getInvoice,
  getJob,
  listCashBook,
  listContainers,
  listIdEds,
  updateInvoice,
  updateJob,
  createInvoiceDetails,
  listInvoiceDetails,
  deleteInvoiceDetails,
  getInvoiceDetail,
  updateInvoiceDetails,
} from '../Jobs/api';
import dayjs from 'dayjs';
import { compact, groupBy } from 'lodash';
import { DataStore } from 'aws-amplify';
import { ContainerType } from '../../models';
import { logActivity } from '../../utilities/logger';
import { ColumnsType } from 'antd/es/table';

import * as ExcelJs from 'exceljs';
import { saveAs } from 'file-saver';

import useLogDrawer from '../../custom_hooks/useLogDrawer';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from '../Users/AuthContext';
import { useGetPermissionInfo } from '../Users/adminHooks';

const { Title, Text, Paragraph } = Typography;

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

export const invoiceTypeMap = {
  [INVOICE_TYPE.IMPORT]: 'IMPORT',
  [INVOICE_TYPE.EXPORT]: 'EXPORT',
  [INVOICE_TYPE.AIR]: 'AIR',
  [INVOICE_TYPE.LCL]: 'LCL',
  [INVOICE_TYPE.IMPORT_SEA]: 'IMPORT (SEA)',
  [INVOICE_TYPE.IMPORT_AIR]: 'IMPORT (AIR)',
  [INVOICE_TYPE.EXPORT_SEA]: 'EXPORT (SEA)',
  [INVOICE_TYPE.EXPORT_AIR]: 'EXPORT (AIR)',
  [INVOICE_TYPE.EXPORT_HNM_SEA]: 'EXPORT H&M (SEA)',
  [INVOICE_TYPE.EXPORT_HNM_AIR]: 'EXPORT H&M (AIR)',
};

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const disabled = attributes['aria-disabled'];

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: disabled ? 'auto' : 'move',
                  color: disabled ? 'lightgray' : 'black',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ListItem = ({ label, content }: { label: ReactNode; content: ReactNode }) => {
  return (
    <List.Item style={{ padding: '10px 0' }}>
      <AntRow style={{ width: '100%', minHeight: 22 }}>
        <Col span={8}>
          <Title level={5} style={{ marginBottom: 0 }}>
            {label}
          </Title>
        </Col>
        <Col span={16} style={{ whiteSpace: 'pre-wrap' }}>
          {content}
        </Col>
      </AntRow>
    </List.Item>
  );
};

const Invoice = () => {
  const { job_id, invoice_id } = useParams<{ job_id: string; invoice_id: string }>();
  const [job, setJob] = useState<Job | undefined>(undefined);
  const [invoice, setInvoice] = useState<InvoiceAPI | undefined>(undefined);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetail[]>([]);
  const [editingInvoiceDetails, setEditingInvoiceDetails] = useState<InvoiceDetail | undefined>(
    undefined
  );
  const [accounts, setAccounts] = useState<Account[]>([]);
  const navigate = useNavigate();
  useDocumentTitle(invoice ? `Invoice | ${job?.job_code}` : 'Invoice');

  const [invoiceForm] = Form.useForm();
  const [invoiceDetailForm] = Form.useForm();
  const [modalState, setModalState] = useState<'add' | 'edit' | null>(null);
  const [invoiceDetailModalState, setInvoiceDetailModalState] = useState<'add' | 'edit' | null>(
    null
  );
  const [reload, setReload] = useState(false);
  const [modalFormSaving, setModalFormSaving] = useState(false);

  // const { user } = useAuthenticator((context) => [context.user]);
  const { currentUser } = useContext(AuthContext);
  const { permission: invoice_permission } = useGetPermissionInfo(
    currentUser,
    DataTableType.INVOICE
  );
  const { permission: invoice_detail_permission } = useGetPermissionInfo(
    currentUser,
    DataTableType.INVOICE_DETAIL
  );

  const { openDrawer, LogDrawer } = useLogDrawer();

  useEffect(() => {
    if (!job_id) return;
    const fetchJob = async (job_id: string) => {
      const job = await getJob(job_id);
      setJob(job);
    };
    const fetchAccounts = async () => {
      const accounts = await DataStore.query(Account);
      setAccounts(accounts);
    };
    fetchJob(job_id);
    fetchAccounts();
  }, [job_id]);

  useEffect(() => {
    if (!invoice_id) return;
    const fetchInvoice = async () => {
      const invoice = await getInvoice(invoice_id);
      setInvoice(invoice);
    };
    fetchInvoice();
  }, [reload, invoice_id]);

  useEffect(() => {
    if (!invoice) return;
    const fetchInvoiceDetails = async () => {
      const invoiceDetails = await listInvoiceDetails(invoice.id);
      // Invoice Details are not sorted in the order of invoice.invoice_detail_ids
      // So we need to sort it manually
      // By this way we can chnage the order of invoice details by changing the order of invoice.invoice_detail_ids
      const sort_order = invoice.invoice_detail_ids;
      invoiceDetails.sort((a, b) => {
        return sort_order.indexOf(a.id) - sort_order.indexOf(b.id);
      });
      setInvoiceDetails(invoiceDetails);
    };
    fetchInvoiceDetails();
  }, [reload, invoice, invoice?.invoice_detail_ids]);

  const reloadInvoice = () => {
    setReload(!reload);
  };

  const openModalForAdd = async () => {
    if (!job) return;
    setModalState('add');
    const id_eds = await listIdEds(job.id);
    const id_ed_groups = groupBy(id_eds, 'id_ed_type');
    const id_ed_and_count = Object.entries(id_ed_groups)
      .map(([id_ed_type, id_ed_list]) => {
        return `${id_ed_type} (${id_ed_list.length})`;
      })
      .join(', ');
    const bl_list = compact(id_eds.map((id_ed) => id_ed.bl_number)).join(', ');
    const containers = await listContainers(job.id);
    const containerTypes = await DataStore.query(ContainerType);
    const container_groups = groupBy(containers, 'container_type_id');
    const container_types_and_counts = Object.entries(container_groups)
      .map(([container_type_id, container_list]) => {
        return `${containerTypes.find((c) => c.id === container_type_id)?.container_type}*${
          container_list.length
        }`;
      })
      .join(', ');
    const container_numbers = containers.map((container) => container.container_number).join(', ');
    const id_ed_numbers = id_eds.map((id_ed) => id_ed.id_ed_number).join(', ');
    invoiceForm.setFieldsValue({
      invoice_date: dayjs(),
      customer_name: job.customer?.customer_name,
      comodity: job.comodity,
      description: job.description,
      remark: job.remark,
      id_or_ed_and_count: id_ed_and_count,
      id_ed_numbers,
      container_types_and_counts,
      container_numbers,
      bl_list,
    });
  };

  const openModalForEdit = async () => {
    if (!job) return;
    if (!invoice) return;
    try {
      const editingInvoice = await getInvoice(invoice.id);
      if (!editingInvoice) throw new Error('Invoice not found');
      const { invoice_date, ...rest } = editingInvoice;
      invoiceForm.setFieldsValue({
        invoice_date: dayjs(invoice_date),
        ...rest,
      });
    } catch (error) {
      console.log({ error });
    }
    setModalState('edit');
  };

  type InvoiceAPICopy = Omit<InvoiceAPI, 'invoice_date'> & { invoice_date: dayjs.Dayjs };

  const hasEditingInvoiceChanged = (editingInvoice: InvoiceAPI, values: InvoiceAPICopy) => {
    const { invoice_date, ...rest } = values;
    const invoiceWithPotentialChanges: InvoiceAPI = {
      ...rest,
      invoice_date: invoice_date.format('YYYY-MM-DD'),
    };
    const fieldListToCheck = [
      'invoice_type',
      'invoice_date',
      'customer_name',
      'comodity',
      'description',
      'remark',
      'id_or_ed_and_count',
      'id_ed_numbers',
      'container_types_and_counts',
      'container_numbers',
      'bl_list',
    ];
    return fieldListToCheck.some(
      // @ts-ignore
      (field) => editingInvoice[field] !== invoiceWithPotentialChanges[field]
    );
  };

  const onSaveInvoice = async (values: any) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      setModalFormSaving(true);
      const { invoice_date, ...rest } = values;

      if (modalState === 'edit') {
        if (!invoice) throw new Error('Invoice not found');
        const oldInvoice = await getInvoice(invoice?.id);
        if (!oldInvoice) throw new Error('Invoice not found');
        if (!hasEditingInvoiceChanged(oldInvoice, values)) {
          setModalState(null);
          return;
        }
        const updatedInvoice = await updateInvoice({
          ...values,
          id: oldInvoice.id,
          _version: oldInvoice._version,
          invoice_date: invoice_date.format('YYYY-MM-DD'),
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.INVOICE,
          operation_type: OperationType.UPDATE,
          old_data: oldInvoice,
          new_data: updatedInvoice,
        });
        reloadInvoice();
      } else if (modalState === 'add') {
        const newInvoice = await createInvoice({
          ...rest,
          invoice_date: invoice_date.format('YYYY-MM-DD'),
          job_code: job.job_code,
          customer_id: job.customer_id,
          invoice_detail_ids: [],
          archived: false,
          job_id,
        });
        await updateJob({
          id: job.id,
          _version: job._version,
          invoice_id: newInvoice.id,
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.INVOICE,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newInvoice,
        });

        const cashBookEntries = await listCashBook(job_id);
        const invoice_detail_ids: string[] = [];
        for (const cashBookEntry of cashBookEntries) {
          const newInvoiceDetail = await createInvoiceDetails({
            invoice_id: newInvoice.id,
            invoice_date: newInvoice.invoice_date,
            description: accounts.find((account) => account.id === cashBookEntry.account_id)
              ?.account_name,
            amount_mmk: cashBookEntry.credit_mmk || 0,
            amount_usd: cashBookEntry.credit_usd || 0,
            debit_mmk: cashBookEntry.debit_mmk || 0,
            debit_usd: cashBookEntry.debit_usd || 0,
            archived: false,
          });
          invoice_detail_ids.push(newInvoiceDetail.id);
          await logActivity({
            user_email: currentUser.email,
            job,
            model_type: DataTableType.INVOICE_DETAIL,
            operation_type: OperationType.CREATE,
            old_data: null,
            new_data: newInvoiceDetail,
          });
        }
        await updateInvoice({
          id: newInvoice.id,
          invoice_date: newInvoice.invoice_date,
          _version: newInvoice._version,
          invoice_detail_ids,
        });

        navigate(`/jobs/${job_id}/invoice/${newInvoice.id}`);
      }
      setModalState(null);
    } catch (error) {
      console.log({ error });
    } finally {
      setModalFormSaving(false);
    }
  };

  const onDeleteInvoice = async () => {
    if (!job_id || !job) return;
    if (!invoice) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      const oldInvoice = await getInvoice(invoice?.id);
      if (!oldInvoice) throw new Error('Invoice not found');
      const oldInvoiceDetails = await listInvoiceDetails(oldInvoice.id);
      // Delete all invoice details
      for (const invoiceDetail of oldInvoiceDetails) {
        const deletedInvoiceDetail = await deleteInvoiceDetails(
          invoiceDetail.id,
          invoiceDetail._version
        );
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.INVOICE_DETAIL,
          operation_type: OperationType.DELETE,
          old_data: deletedInvoiceDetail,
          new_data: null,
        });
      }
      const latestJob = await getJob(job_id);
      await updateJob({
        id: job.id,
        _version: latestJob ? latestJob._version : job._version,
        invoice_id: null,
      });
      const deletedInvoice = await deleteInvoice(invoice.id, oldInvoice._version);
      await logActivity({
        user_email: currentUser.email,
        job,
        model_type: DataTableType.INVOICE,
        operation_type: OperationType.DELETE,
        old_data: deletedInvoice,
        new_data: null,
      });
      navigate(`/jobs/${job_id}`);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (invoiceDetailModalState === 'add') {
      invoiceDetailForm.resetFields();
    }
    if (invoiceDetailModalState === 'edit') {
      invoiceDetailForm.setFieldsValue(editingInvoiceDetails);
    }
  }, [invoiceDetailModalState, editingInvoiceDetails, invoiceDetailForm]);

  const hasEditingInvoiceDetailChanged = (
    editingInvoiceDetail: InvoiceDetail,
    values: InvoiceDetail
  ) => {
    const fieldListToCheck = ['description', 'debit_usd', 'debit_mmk', 'amount_usd', 'amount_mmk'];
    // @ts-ignore
    return fieldListToCheck.some((field) => editingInvoiceDetail[field] !== values[field]);
  };

  const validateMultipleDebitCredit = () => {
    const values = invoiceDetailForm.getFieldsValue() as InvoiceDetail;
    const { debit_usd, debit_mmk, amount_usd, amount_mmk } = values;
    if (debit_usd === 0 && debit_mmk === 0 && amount_usd === 0 && amount_mmk === 0) {
      return Promise.reject('At least one of the debit or credit fields must be non-zero.');
    }
    // check if there are more than one non-zero fields
    const nonZeroFields = [debit_usd, debit_mmk, amount_usd, amount_mmk].filter(
      (value) => value !== 0
    );
    if (nonZeroFields.length > 1) {
      return Promise.reject('Only one of the debit or credit fields can be non-zero.');
    }
    return Promise.resolve();
  };

  const onSaveInvoiceDetail = async (values: InvoiceDetail) => {
    if (!job_id || !job) return;
    if (!invoice) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      if (invoiceDetailModalState === 'add') {
        const newInvoiceDetail = await createInvoiceDetails({
          ...values,
          invoice_id: invoice.id,
          invoice_date: invoice.invoice_date,
          archived: false,
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.INVOICE_DETAIL,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newInvoiceDetail,
        });
        const updatedInvoice = await updateInvoice({
          id: invoice.id,
          _version: invoice._version,
          invoice_detail_ids: [...invoice.invoice_detail_ids, newInvoiceDetail.id],
        });
        setInvoice(updatedInvoice);
      } else if (invoiceDetailModalState === 'edit') {
        if (!editingInvoiceDetails) throw new Error('Invoice Detail not found');
        const latestInvoiceDetail = await getInvoiceDetail(editingInvoiceDetails.id);
        if (!latestInvoiceDetail) throw new Error('Invoice Detail not found');
        if (!hasEditingInvoiceDetailChanged(latestInvoiceDetail, values)) {
          setInvoiceDetailModalState(null);
          return;
        }
        const updatedInvoiceDetail = await updateInvoiceDetails({
          ...values,
          id: latestInvoiceDetail.id,
          _version: latestInvoiceDetail._version,
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.INVOICE_DETAIL,
          operation_type: OperationType.UPDATE,
          old_data: latestInvoiceDetail,
          new_data: updatedInvoiceDetail,
        });
        reloadInvoice();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setInvoiceDetailModalState(null);
    }
  };

  const onDeleteInvoiceDetail = async (invoiceDetail: InvoiceDetail) => {
    if (!job_id || !job) return;
    if (!invoice) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      const deletedInvoiceDetail = await deleteInvoiceDetails(
        invoiceDetail.id,
        invoiceDetail._version
      );
      await logActivity({
        user_email: currentUser.email,
        job,
        model_type: DataTableType.INVOICE_DETAIL,
        operation_type: OperationType.DELETE,
        old_data: deletedInvoiceDetail,
        new_data: null,
      });
      const updatedInvoice = await updateInvoice({
        id: invoice.id,
        _version: invoice._version,
        invoice_detail_ids: invoice.invoice_detail_ids.filter((id) => id !== invoiceDetail.id),
      });
      setInvoice(updatedInvoice);
    } catch (error) {
      console.log({ error });
    }
  };

  const invoiceDetailsColumns: ColumnsType<InvoiceDetail> = [
    {
      key: 'sort',
      width: 50,
    },
    {
      title: 'No.',
      key: 'index',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: 70,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      ellipsis: true,
    },

    {
      title: 'Debit',
      children: [
        {
          title: 'USD',
          dataIndex: 'debit_usd',
          key: 'debit_usd',
          width: 60,
          align: 'right',
          render: (text, record) => {
            return <Text>{record.debit_usd.toLocaleString()}</Text>;
          },
        },
        {
          title: 'MMK',
          dataIndex: 'debit_mmk',
          key: 'debit_mmk',
          width: 100,
          align: 'right',
          render: (text, record) => {
            return <Text>{record.debit_mmk.toLocaleString()}</Text>;
          },
        },
      ],
    },
    {
      title: 'Credit',
      children: [
        {
          title: 'USD',
          dataIndex: 'amount_usd',
          key: 'amount_usd',
          width: 60,
          align: 'right',
          render: (text, record) => {
            return <Text>{record.amount_usd.toLocaleString()}</Text>;
          },
        },
        {
          title: 'MMK',
          dataIndex: 'amount_mmk',
          key: 'amount_mmk',
          width: 100,
          align: 'right',
          render: (text, record) => {
            return <Text>{record.amount_mmk.toLocaleString()}</Text>;
          },
        },
      ],
    },

    {
      title: 'Action',
      key: 'action',
      width: 180,
      align: 'center',
      render: (text, record) => (
        <Space size='middle'>
          <ProfileOutlined
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              if (!job_id) return;
              openDrawer(job_id, DataTableType.INVOICE_DETAIL, record.id, 'model');
            }}
          />
          {/* 
          Disable button when
          1. job is archived
          2. permission to edit invoice detail is not granted 
           */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              setEditingInvoiceDetails(record);
              setInvoiceDetailModalState('edit');
            }}
            disabled={job && job.archived ? true : invoice_detail_permission.UPDATE ? false : true}
          >
            Edit
          </Button>
          {/* 
          Disable button when
          1. job is archieved
          2. permission to delete invoice detail is not granted
          */}
          <Popconfirm
            title='Are you sure you want to delete this invoice detail?'
            onConfirm={() => onDeleteInvoiceDetail(record)}
          >
            <Button
              type='link'
              size='small'
              danger
              disabled={
                job && job.archived ? true : invoice_detail_permission.DELETE ? false : true
              }
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (!invoice) return;
    if (active.id !== over?.id) {
      let sortedInvoiceDetails: InvoiceDetail[] = [];
      // This setInvoiceDetails is the optimisitc update
      // The actual update will be done after setInvoice is called and useEffect is triggered
      setInvoiceDetails((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        sortedInvoiceDetails = arrayMove(previous, activeIndex, overIndex);
        return sortedInvoiceDetails;
      });
      const updatedInvoice = await updateInvoice({
        id: invoice.id,
        _version: invoice?._version,
        invoice_detail_ids: sortedInvoiceDetails.map((invoiceDetail) => invoiceDetail.id),
      });
      // This sorting activity is not logged in activity log
      setInvoice(updatedInvoice);
    }
  };

  const onPrintInvoice = async () => {
    if (!invoice) return;
    if (!invoice.job) return;
    if (!currentUser) return;
    await printInvoice(invoice, invoiceDetails);
    await logActivity({
      user_email: currentUser.email,
      job: invoice.job,
      model_type: DataTableType.INVOICE,
      operation_type: OperationType.PRINT_INVOICE,
      old_data: null,
      new_data: invoice,
    });
    // later to add code for loking job after printing invoice
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size='large' align='start'>
            <Button
              type='primary'
              shape='circle'
              icon={<LeftOutlined />}
              size='middle'
              onClick={() => navigate(`/jobs/${job_id}`)}
            />
            <Title level={4} style={{ color: 'darkred' }}>
              Invoice of Job ({job?.job_code})
            </Title>
          </Space>
        }
        headStyle={{
          backgroundColor: 'white',
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 50,
        }}
        style={{
          width: 'max(calc(100vw - 400px), 1000px)',
          height: 'max(calc(100vh - 180px), 600px)',
          // height: 'fit-content',
          // height: '800',
          // overflow: 'auto',
        }}
        bodyStyle={{
          height: 'calc(100% - 50px)',
          overflow: 'auto',
        }}
        extra={
          <Space>
            <Tooltip
              title={job?.archived ? 'Record is locked.' : 'Record is not locked.'}
              color={job?.archived ? 'red' : 'green'}
            >
              {!job || job?.archived ? (
                <LockFilled style={{ fontSize: '1.5em', color: 'darkred' }} />
              ) : (
                <UnlockFilled style={{ fontSize: '1.5em', color: 'green' }} />
              )}
            </Tooltip>
            <NetworkIndicator />
          </Space>
        }
      >
        {invoice ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 0,
                marginTop: 40,
              }}
            >
              <Space size='large'>
                <Button
                  type='link'
                  onClick={() => {
                    if (!job_id) return;
                    openDrawer(job_id, DataTableType.INVOICE, invoice.id, 'model');
                  }}
                >
                  change log
                </Button>
                <Button type='default' onClick={onPrintInvoice}>
                  Print to Excel
                </Button>
                {/* Disable button when
                  1. job is archived
                  2. permission to edit is not granted
                */}
                <Button
                  type='primary'
                  onClick={openModalForEdit}
                  disabled={job && job.archived ? true : invoice_permission.UPDATE ? false : true}
                >
                  Edit Invoice
                </Button>
                {/* Disable button when 
                  1. job is archived
                  2. permission to delete is not granted  
                */}
                <Popconfirm
                  title={
                    <div>
                      <span>Are you sure you want to delete this invoice?</span>
                      <br />
                      <span>All invoice details will also be deleted.</span>
                      <br />
                      <span>This action cannot be undone.</span>
                    </div>
                  }
                  onConfirm={onDeleteInvoice}
                >
                  <Button
                    type='primary'
                    danger
                    disabled={job && job.archived ? true : invoice_permission.DELETE ? false : true}
                  >
                    Delete Invoice
                  </Button>
                </Popconfirm>
              </Space>
            </div>
            <List style={{ marginBottom: '12px' }}>
              <ListItem label='Invoice Type' content={invoice?.invoice_type} key='invoice_type' />
              <ListItem
                label='Invoice Date'
                content={dayjs(invoice?.invoice_date).format('YYYY-MM-DD')}
                key='invoice_date'
              />
              <ListItem label='Customer' content={invoice?.customer_name} key='customer_name' />
              <ListItem label='Commodity' content={invoice?.comodity} key='comodity' />
              <ListItem label='Description' content={invoice?.description} key='description' />
              <ListItem
                label='ID/ED'
                content={invoice?.id_or_ed_and_count}
                key='id_or_ed_and_count'
              />
              <ListItem
                label='ID/ED Numbers'
                content={invoice?.id_ed_numbers}
                key='id_ed_numbers'
              />
              <ListItem
                label='Container Types'
                content={invoice?.container_types_and_counts}
                key='container_types_and_counts'
              />
              <ListItem
                label='Container Numbers'
                content={invoice?.container_numbers}
                key='container_numbers'
              />
              <ListItem label='BL List' content={invoice?.bl_list} key='bl_list' />
              <ListItem label='Remark' content={invoice?.remark} key='remark' />
            </List>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
              <SortableContext
                items={invoiceDetails.map((invoiceDetail) => invoiceDetail.id)}
                strategy={verticalListSortingStrategy}
                disabled={job ? job.archived : true}
              >
                <Table
                  title={() => (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Title level={5} style={{ color: 'darkblue' }}>
                        Invoice Details
                      </Title>
                      {/* 
                        Disable button when
                        1. job is archived
                        2. permission to add invoice detail is not granted
                      */}
                      <Button
                        onClick={() => setInvoiceDetailModalState('add')}
                        disabled={
                          job && job.archived
                            ? true
                            : invoice_detail_permission.CREATE
                            ? false
                            : true
                        }
                      >
                        Add New Record
                      </Button>
                    </div>
                  )}
                  style={{ paddingLeft: 40, paddingRight: 40, width: 1200 }}
                  size='small'
                  columns={invoiceDetailsColumns}
                  dataSource={invoiceDetails}
                  bordered
                  pagination={false}
                  rowKey={'id'}
                  components={{
                    body: {
                      row: Row,
                    },
                  }}
                  summary={(data) => {
                    const amount_mmk_total = data.reduce(
                      (sum, record) => sum + record.amount_mmk,
                      0
                    );
                    const amount_usd_total = data.reduce(
                      (sum, record) => sum + record.amount_usd,
                      0
                    );
                    const debit_mmk_total = data.reduce((sum, record) => sum + record.debit_mmk, 0);
                    const debit_usd_total = data.reduce((sum, record) => sum + record.debit_usd, 0);
                    return (
                      <>
                        <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
                          <Table.Summary.Cell index={1} colSpan={3} align='center'>
                            <Text strong>Total</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4} align='right'>
                            <Text strong>{debit_usd_total.toLocaleString()}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5} align='right'>
                            <Text strong>{debit_mmk_total.toLocaleString()}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6} align='right'>
                            <Text strong>{amount_usd_total.toLocaleString()}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7} align='right'>
                            <Text strong>{amount_mmk_total.toLocaleString()}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={8} />
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </SortableContext>
            </DndContext>
          </>
        ) : (
          <div
            style={{
              height: 200,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paragraph>
              No Invoice Found. Do you want to create invoice for Job{' '}
              <Text style={{ fontWeight: 'bold' }}>{job?.job_code}</Text>
            </Paragraph>
            <Button type='primary' onClick={openModalForAdd} disabled={job ? job.archived : true}>
              Create Invoice
            </Button>
          </div>
        )}
      </Card>
      <Modal
        title={
          modalState === 'add'
            ? `Add Invoice to Job :  ${job?.job_code}`
            : `Edit Invoice of Job : ${job?.job_code}`
        }
        open={modalState === 'add' || modalState === 'edit'}
        okText={modalState === 'add' ? 'Create Invoice' : 'Save'}
        okButtonProps={{ loading: modalFormSaving }}
        onOk={() => {
          invoiceForm.submit();
        }}
        onCancel={() => setModalState(null)}
        styles={{ body: { paddingTop: 20 } }}
        width={900}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          form={invoiceForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onSaveInvoice}
          style={{ maxWidth: 'none' }}
        >
          <AntRow gutter={24}>
            <Col span={12}>
              <Form.Item
                label='Date'
                name='invoice_date'
                rules={[{ required: true, message: 'Date is required' }]}
              >
                <DatePicker minDate={dayjs().subtract(1, 'year')} maxDate={dayjs()} />
              </Form.Item>
              <Form.Item
                label='Invoice Type'
                name='invoice_type'
                rules={[
                  {
                    required: true,
                    message: 'Invoice Type is required',
                  },
                ]}
              >
                <Select>
                  {Object.values(INVOICE_TYPE)
                    .filter((it) => !['IMPORT', 'EXPORT', 'AIR', 'LCL'].includes(it))
                    .map((type) => (
                      <Select.Option value={type} key={type}>
                        {invoiceTypeMap[type]}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label='Customer'
                name='customer_name'
                rules={[
                  {
                    required: true,
                    message: 'Customer field cannot be blank',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label='Commodity' name='comodity'>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
              </Form.Item>
              <Form.Item label='Description' name='description'>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
              </Form.Item>
              <Form.Item label='Remark' name='remark'>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='ID/ED' name='id_or_ed_and_count'>
                <Input />
              </Form.Item>
              <Form.Item label='ID/ED numbers' name='id_ed_numbers'>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
              </Form.Item>
              <Form.Item label='Container Types' name='container_types_and_counts'>
                <Input />
              </Form.Item>
              <Form.Item label='Container Numbers' name='container_numbers'>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
              </Form.Item>
              <Form.Item label='BL list' name='bl_list'>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
              </Form.Item>
            </Col>
          </AntRow>
        </Form>
      </Modal>
      <Modal
        title={invoiceDetailModalState === 'add' ? 'Add Invoice Detail' : 'Edit Invoice Detail'}
        open={invoiceDetailModalState === 'add' || invoiceDetailModalState === 'edit'}
        okText={invoiceDetailModalState === 'add' ? 'Add' : 'Save'}
        onOk={() => invoiceDetailForm.submit()}
        onCancel={() => setInvoiceDetailModalState(null)}
        styles={{ body: { paddingTop: 20 } }}
        style={{ top: 100 }}
        width={500}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          form={invoiceDetailForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onSaveInvoiceDetail}
          style={{ maxWidth: 'none' }}
        >
          <Form.Item
            label='Description'
            name='description'
            rules={[{ required: true, message: 'Description is required.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Debit (USD)'
            name='debit_usd'
            initialValue={0}
            rules={[
              { required: true, message: 'Debit (USD) is required.' },
              { validator: validateMultipleDebitCredit },
            ]}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={100}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label='Debit (MMK)'
            name='debit_mmk'
            initialValue={0}
            rules={[
              { required: true, message: 'Debit (MMK) is required.' },
              { validator: validateMultipleDebitCredit },
            ]}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={100}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label='Credit (USD)'
            name='amount_usd'
            initialValue={0}
            rules={[
              { required: true, message: 'Credit (USD) is required.' },
              { validator: validateMultipleDebitCredit },
            ]}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={100}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label='Credit (MMK)'
            name='amount_mmk'
            initialValue={0}
            rules={[
              { required: true, message: 'Credit (MMK) is required.' },
              { validator: validateMultipleDebitCredit },
            ]}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={100}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </Form>
      </Modal>
      {LogDrawer}
    </div>
  );
};

export default Invoice;

////////////////////////////////////////// Print Invoice //////////////////////////////////////////

const printInvoice = async (invoice: InvoiceAPI, invoiceDetails: InvoiceDetail[]) => {
  try {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Invoice');
    worksheet.pageSetup.margins = {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0,
      footer: 0,
      // header: 0.3,
      // footer: 0.3,
    };
    worksheet.pageSetup.paperSize = 9;
    worksheet.pageSetup.orientation = 'portrait';
    worksheet.pageSetup.horizontalCentered = true;
    worksheet.pageSetup.verticalCentered = true;

    worksheet.mergeCells('A1:J8');

    const letter_head_file = await fetch('/letter_head/letter_head@3x.png');
    const letter_head_buffer = await letter_head_file.arrayBuffer();

    const letterHead = workbook.addImage({
      buffer: letter_head_buffer,
      extension: 'jpeg',
    });

    worksheet.addImage(letterHead, {
      // @ts-ignore
      tl: { col: 0.25, row: 0 },
      // @ts-ignore
      br: { col: 9.75, row: 8 },
    });

    const regular_row_height = 18;
    const updated_row_height = 22;
    // const regular_column_width = 9;

    worksheet.getRows(10, 19)!.forEach((row) => {
      row.height = updated_row_height;
      row.alignment = { vertical: 'top' };
    });

    worksheet.getCell(10, 1).value = 'Customer';
    worksheet.getCell(10, 3).value = invoice.customer_name;
    worksheet.getCell(11, 1).value = 'Invoice Type';
    worksheet.getCell(11, 3).value = invoice.invoice_type;
    worksheet.getCell(12, 1).value = 'Date';
    worksheet.getCell(12, 3).value = dayjs(invoice.invoice_date).format('YYYY-MM-DD');
    worksheet.getCell(13, 1).value = 'Commodity';
    worksheet.getCell(13, 3).value = invoice.comodity;

    worksheet.getCell(14, 1).value = 'Description';
    worksheet.mergeCells(14, 3, 14, 10);
    const trimmed_description = invoice.description?.replace(/(\r\n|\n|\r){2,}/g, '\n');

    const long_description_lines = trimmed_description
      ? trimmed_description
          .split('\n')
          .reduce(
            (total, line) => (line.length > 70 ? (total += Math.floor(line.length / 70)) : total),
            0
          )
      : 0;

    worksheet.getCell(14, 3).value = trimmed_description;
    worksheet.getRow(14).height = trimmed_description
      ? (trimmed_description.split('\n').length + long_description_lines) * regular_row_height + 4
      : updated_row_height;
    worksheet.getCell(14, 3).alignment = { ...worksheet.getCell(14, 3).alignment, wrapText: true };

    worksheet.getCell(15, 1).value = 'STL Invoice No.';
    worksheet.getCell(15, 3).value = invoice.job?.job_code;

    worksheet.getCell(16, 1).value = invoice.id_or_ed_and_count;
    worksheet.getCell(16, 3).value = invoice.id_ed_numbers;
    worksheet.mergeCells(16, 3, 16, 10);
    worksheet.getCell(16, 3).alignment = { ...worksheet.getCell(16, 3).alignment, wrapText: true };
    const id_ed_per_line = 5;
    const id_ed_count = invoice.id_ed_numbers?.split(',').length || 0;
    const id_ed_row_count = Math.ceil(id_ed_count / id_ed_per_line);
    worksheet.getRow(16).height =
      id_ed_row_count !== 0 ? id_ed_row_count * regular_row_height + 4 : updated_row_height;

    const bl_count = invoice.bl_list ? invoice.bl_list.split(',').length : 0;
    const container_count = invoice.container_numbers
      ? invoice.container_numbers.split(',').length
      : 0;

    worksheet.getCell(17, 1).value = 'AWB';
    worksheet.getCell(17, 3).value = invoice.bl_list;
    worksheet.getCell(17, 3).alignment = { ...worksheet.getCell(17, 3).alignment, wrapText: true };
    worksheet.mergeCells(17, 3, 17, 10);
    const bl_per_line = 20;
    const bl_row_count = Math.ceil(bl_count / bl_per_line);
    worksheet.getRow(17).height =
      bl_row_count !== 0 ? bl_row_count * regular_row_height + 4 : updated_row_height;

    worksheet.getCell(18, 1).value = 'Container Types';
    worksheet.getCell(18, 3).value = invoice.container_types_and_counts;

    worksheet.getCell(19, 1).value = 'Containers';
    worksheet.getCell(19, 3).value = invoice.container_numbers;
    worksheet.getCell(19, 3).alignment = { ...worksheet.getCell(19, 3).alignment, wrapText: true };
    worksheet.mergeCells(19, 3, 19, 10);
    const container_per_line = 4;
    const container_row_count = Math.ceil(container_count / container_per_line);
    worksheet.getRow(19).height =
      container_row_count !== 0 ? container_row_count * regular_row_height + 4 : updated_row_height;
    worksheet.getCell(20, 1).value = 'Remark';
    worksheet.mergeCells(20, 3, 20, 10);
    const trimmed_remark = invoice.remark?.replace(/(\r\n|\n|\r){2,}/g, '\n');
    worksheet.getCell(20, 3).value = trimmed_remark;

    const long_remark_lines = trimmed_remark
      ? trimmed_remark
          .split('\n')
          .reduce(
            (total, line) => (line.length > 70 ? (total += Math.floor(line.length / 70)) : total),
            0
          )
      : 0;

    worksheet.getRow(20).height = trimmed_remark
      ? (trimmed_remark.split('\n').length + long_remark_lines) * regular_row_height + 4
      : updated_row_height;

    worksheet.getCell(20, 3).alignment = { ...worksheet.getCell(20, 3).alignment, wrapText: true };

    // Invoice Details
    const credit_details = invoiceDetails.filter(
      (invoiceDetail) => invoiceDetail.amount_usd !== 0 || invoiceDetail.amount_mmk !== 0
    );
    const total_credit_usd = credit_details.reduce((sum, record) => sum + record.amount_usd, 0);
    const total_credit_mmk = credit_details.reduce((sum, record) => sum + record.amount_mmk, 0);
    const debit_details = invoiceDetails.filter(
      (invoiceDetail) => invoiceDetail.debit_usd !== 0 || invoiceDetail.debit_mmk !== 0
    );
    const total_debit_usd = debit_details.reduce((sum, record) => sum + record.debit_usd, 0);
    const total_debit_mmk = debit_details.reduce((sum, record) => sum + record.debit_mmk, 0);

    const invoice_details_header_row_height = 26;
    const invoice_details_header_row = 22;
    const invoice_details_row_height = 20;

    const credit_table_start_row = invoice_details_header_row + 1;
    const credit_table_end_row = credit_table_start_row + credit_details.length - 1;
    const credit_table_footer_row = credit_table_end_row + 1;

    const invoice_details_header = worksheet.getRow(invoice_details_header_row);
    // worksheet.mergeCells(invoice_details_header_row, 1, invoice_details_header_row, 1);
    worksheet.mergeCells(invoice_details_header_row, 2, invoice_details_header_row, 6);
    worksheet.mergeCells(invoice_details_header_row, 7, invoice_details_header_row, 8);
    worksheet.mergeCells(invoice_details_header_row, 9, invoice_details_header_row, 10);
    invoice_details_header.height = invoice_details_header_row_height;
    invoice_details_header.font = { bold: true };
    invoice_details_header.alignment = { vertical: 'middle', horizontal: 'center' };
    [1, 2, 7, 9].forEach((column) => {
      invoice_details_header.getCell(column).border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
      };
    });

    invoice_details_header.getCell(1).value = 'No.';
    invoice_details_header.getCell(2).value = 'Description';
    invoice_details_header.getCell(7).value = 'Amount (USD)';
    invoice_details_header.getCell(9).value = 'Amount (MMK)';
    // invoice_details_header.eachCell((cell) => {
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: 'FFFFFF00' },
    //   };
    // });
    if (credit_details.length !== 0) {
      const credit_table = worksheet.getRows(credit_table_start_row, credit_details.length);

      credit_table!.forEach((row, index) => {
        const current_row_number = credit_table_start_row + index;
        // worksheet.mergeCells(current_row_number, 1, current_row_number, 1);
        worksheet.mergeCells(current_row_number, 2, current_row_number, 6);
        worksheet.mergeCells(current_row_number, 7, current_row_number, 8);
        worksheet.mergeCells(current_row_number, 9, current_row_number, 10);

        row.height = invoice_details_row_height;
        row.getCell(1).value = index + 1;
        row.getCell(1).alignment = { horizontal: 'right', indent: 2 };
        row.getCell(2).value = credit_details[index].description;
        row.getCell(7).value = credit_details[index].amount_usd;
        row.getCell(7).numFmt = '#,##0';
        row.getCell(7).alignment = { horizontal: 'right', indent: 2 };
        row.getCell(9).value = credit_details[index].amount_mmk;
        row.getCell(9).numFmt = '#,##0';
        row.getCell(9).alignment = { horizontal: 'right', indent: 2 };
      });
    }

    const invoice_details_footer = worksheet.getRow(credit_table_footer_row);
    // worksheet.mergeCells(credit_table_footer_row, 1, credit_table_footer_row, 1);
    worksheet.mergeCells(credit_table_footer_row, 2, credit_table_footer_row, 6);
    worksheet.mergeCells(credit_table_footer_row, 7, credit_table_footer_row, 8);
    worksheet.mergeCells(credit_table_footer_row, 9, credit_table_footer_row, 10);
    invoice_details_footer.height = invoice_details_header_row_height;
    invoice_details_footer.font = { bold: true };
    invoice_details_footer.alignment = { vertical: 'middle', horizontal: 'center' };
    [1, 2, 7, 9].forEach((column) => {
      invoice_details_footer.getCell(column).border = {
        top: { style: 'thin' },
        bottom: { style: debit_details.length === 0 ? 'double' : 'thin' },
      };
    });
    invoice_details_footer.getCell(2).value = 'Total Amount';
    invoice_details_footer.getCell(7).value = total_credit_usd;
    invoice_details_footer.getCell(7).numFmt = '#,##0';
    invoice_details_footer.getCell(9).value = total_credit_mmk;
    invoice_details_footer.getCell(9).numFmt = '#,##0';
    [7, 9].forEach((column) => {
      invoice_details_footer.getCell(column).alignment = {
        ...invoice_details_footer.getCell(column).alignment,
        horizontal: 'right',
        indent: 2,
      };
    });

    if (debit_details.length !== 0) {
      const debit_table_start_row = credit_table_footer_row + 1;
      const debit_table_end_row = debit_table_start_row + debit_details.length - 1;
      const balanced_amount_row = debit_table_end_row + 1;

      const debit_table = worksheet.getRows(debit_table_start_row, debit_details.length);
      debit_table!.forEach((row, index) => {
        const current_row_number = debit_table_start_row + index;
        worksheet.mergeCells(current_row_number, 2, current_row_number, 6);
        worksheet.mergeCells(current_row_number, 7, current_row_number, 8);
        worksheet.mergeCells(current_row_number, 9, current_row_number, 10);

        row.height = invoice_details_row_height;
        // row.getCell(1).value = index + 1;
        // row.getCell(1).alignment = { horizontal: 'right', indent: 2 };
        row.getCell(2).value = debit_details[index].description;
        row.getCell(7).value = debit_details[index].debit_usd;
        row.getCell(7).numFmt = '#,##0';
        row.getCell(7).alignment = { horizontal: 'right', indent: 2 };
        row.getCell(9).value = debit_details[index].debit_mmk;
        row.getCell(9).numFmt = '#,##0';
        row.getCell(9).alignment = { horizontal: 'right', indent: 2 };
      });

      const balanced_amount_table = worksheet.getRow(balanced_amount_row);
      worksheet.mergeCells(balanced_amount_row, 2, balanced_amount_row, 6);
      worksheet.mergeCells(balanced_amount_row, 7, balanced_amount_row, 8);
      worksheet.mergeCells(balanced_amount_row, 9, balanced_amount_row, 10);
      balanced_amount_table.height = invoice_details_header_row_height;
      balanced_amount_table.font = { bold: true };
      balanced_amount_table.alignment = { vertical: 'middle', horizontal: 'center' };
      [1, 2, 7, 9].forEach((column) => {
        balanced_amount_table.getCell(column).border = {
          top: { style: 'thin' },
          bottom: { style: 'double' },
        };
      });
      balanced_amount_table.getCell(2).value = 'Total Balance Amount';
      balanced_amount_table.getCell(7).value = total_credit_usd - total_debit_usd;
      balanced_amount_table.getCell(7).numFmt = '#,##0';
      balanced_amount_table.getCell(9).value = total_credit_mmk - total_debit_mmk;
      balanced_amount_table.getCell(9).numFmt = '#,##0';
      [7, 9].forEach((column) => {
        balanced_amount_table.getCell(column).alignment = {
          ...balanced_amount_table.getCell(column).alignment,
          horizontal: 'right',
          indent: 2,
        };
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer]),
      `Invoice ${invoice.invoice_type} ${invoice.job?.job_code} ${dayjs().format(
        'YYYY-MM-DD h-mm a'
      )}.xlsx`
    );
  } catch (error) {
    console.log(error);
  }
};

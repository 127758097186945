import React, { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify';

export function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    const listener = Hub.listen('datastore', async (hubData) => {
      const { event, data } = hubData.payload;
      if (event === 'networkStatus') {
        setIsOnline(data.active);
      }
    });

    return () => {
      listener();
    };
  }, []);

  return isOnline;
}

import { useEffect } from 'react';

const useDocumentTitle = (title: string, default_title: string | undefined = 'STL Logistics') => {
  useEffect(() => {
    document.title = title + ' | ' + default_title;
    return () => {
      document.title = default_title;
    };
  }, [title]);
};

export default useDocumentTitle;

// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const IdEdType = {
  "ID": "ID",
  "ED": "ED"
};

const RouteType = {
  "SEA": "SEA",
  "AIR": "AIR",
  "SEA_AIR": "SEA_AIR",
  "ROAD": "ROAD"
};

const InvoiceType = {
  "IMPORT": "IMPORT",
  "EXPORT": "EXPORT",
  "AIR": "AIR",
  "LCL": "LCL",
  "IMPORT_SEA": "IMPORT_SEA",
  "IMPORT_AIR": "IMPORT_AIR",
  "EXPORT_SEA": "EXPORT_SEA",
  "EXPORT_AIR": "EXPORT_AIR",
  "EXPORT_HNM_SEA": "EXPORT_HNM_SEA",
  "EXPORT_HNM_AIR": "EXPORT_HNM_AIR"
};

const OperationType = {
  "CREATE": "CREATE",
  "UPDATE": "UPDATE",
  "DELETE": "DELETE",
  "LOCK": "LOCK",
  "UNLOCK": "UNLOCK",
  "PRINT_INVOICE": "PRINT_INVOICE"
};

const DataTableType = {
  "JOB": "JOB",
  "CONTAINER": "CONTAINER",
  "ID_ED": "ID_ED",
  "CASH_BOOK": "CASH_BOOK",
  "CASH_HANDLER": "CASH_HANDLER",
  "INVOICE": "INVOICE",
  "INVOICE_DETAIL": "INVOICE_DETAIL",
  "CUSTOMER": "CUSTOMER",
  "ACCOUNT": "ACCOUNT",
  "ACCOUNT_GROUP": "ACCOUNT_GROUP",
  "USER": "USER"
};

const { Country, AccountGroup, Account, Customer, Job, Invoice, InvoiceDetail, ContainerType, Container, IdEd, CashBook, CashHandler, Log, Permission, PageVisit } = initSchema(schema);

export {
  Country,
  AccountGroup,
  Account,
  Customer,
  Job,
  Invoice,
  InvoiceDetail,
  ContainerType,
  Container,
  IdEd,
  CashBook,
  CashHandler,
  Log,
  Permission,
  PageVisit,
  IdEdType,
  RouteType,
  InvoiceType,
  OperationType,
  DataTableType
};
import {
  Button,
  Card,
  Form,
  Popconfirm,
  Space,
  Typography,
  Table,
  Modal,
  Input,
  Checkbox,
} from 'antd';
import React, { useContext, useEffect } from 'react';
import { AccountGroup, DataTableType, OperationType } from '../../API';
import { useGetPermissionInfo } from '../Users/adminHooks';
import { AuthContext } from '../Users/AuthContext';
import {
  createAccountGroup,
  deleteAccountGroup,
  getAccountGroup,
  updateAccountGroup,
  useAccountGroupSubscription,
} from './api';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import useLogDrawer from '../../custom_hooks/useLogDrawer';
import { logActivity } from '../../utilities/logger';
import { ColumnsType } from 'antd/es/table';
import { CheckOutlined, ProfileOutlined } from '@ant-design/icons';
import NetworkIndicator from '../../components/NetworkIndicator';

const { Title } = Typography;

const AccountGroups = () => {
  const [form] = Form.useForm();
  const [modalState, setModalState] = React.useState<'add' | 'edit' | null>(null);
  const [editingAccountGroup, setEditingAccountGroup] = React.useState<AccountGroup | null>(null);

  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.ACCOUNT_GROUP);

  const { account_groups } = useAccountGroupSubscription();

  useDocumentTitle('Account Groups');

  const { openDrawer, LogDrawer } = useLogDrawer();

  useEffect(() => {
    if (modalState === 'add') {
      form.resetFields();
    }
    if (modalState === 'edit' && editingAccountGroup) {
      form.setFieldsValue(editingAccountGroup);
    }
  }, [modalState, editingAccountGroup, form]);

  const validateAccountGroupCode = async (rule: any, value: string) => {
    if (!value) return;
    if (
      modalState === 'edit' &&
      editingAccountGroup &&
      editingAccountGroup.account_group_code === value
    )
      return;
    const accountGroup = account_groups?.find(
      (account_group) => account_group.account_group_code === value
    );
    if (accountGroup) {
      return Promise.reject('Account Group Code already exists');
    }
    return Promise.resolve();
  };

  const validateAccountGroupName = async (rule: any, value: string) => {
    if (!value) return;
    if (
      modalState === 'edit' &&
      editingAccountGroup &&
      editingAccountGroup.account_group_name === value
    )
      return;
    const accountGroup = account_groups?.find(
      (account_group) => account_group.account_group_name === value
    );
    if (accountGroup) {
      return Promise.reject('Account Group Name already exists');
    }
    return Promise.resolve();
  };

  const hasEditingAccountroupChanged = (
    editingAccountGroup: AccountGroup,
    values: AccountGroup
  ) => {
    const fieldListToCheck = [
      'account_group_code',
      'account_group_name',
      'account_heading_code',
      'account_heading',
      'is_active',
      'remark',
    ];
    return fieldListToCheck.some(
      (key) => editingAccountGroup[key as keyof AccountGroup] !== values[key as keyof AccountGroup]
    );
  };

  const onFormSave = async (values: AccountGroup) => {
    if (!currentUser) return;
    try {
      if (modalState === 'add') {
        const newAccountGroup = await createAccountGroup(values);
        await logActivity({
          user_email: currentUser.email,
          job: null,
          model_type: DataTableType.ACCOUNT_GROUP,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newAccountGroup || null,
        });
      }
      if (modalState === 'edit') {
        if (!editingAccountGroup) return;
        const oldAccountGroup = await getAccountGroup(editingAccountGroup.id);
        if (!oldAccountGroup) return;
        if (!hasEditingAccountroupChanged) {
          setModalState(null);
          return;
        }
        const updatedAccountGroup = await updateAccountGroup({
          ...values,
          id: editingAccountGroup.id,
          _version: oldAccountGroup._version,
        });
        await logActivity({
          user_email: currentUser.email,
          job: null,
          model_type: DataTableType.ACCOUNT_GROUP,
          operation_type: OperationType.UPDATE,
          old_data: oldAccountGroup,
          new_data: updatedAccountGroup || null,
        });
      }
      setModalState(null);
    } catch (error) {
      console.log('error saving account group', error);
    } finally {
      form.resetFields();
      setModalState(null);
    }
  };

  const onDelete = async (accountGroup: AccountGroup) => {
    if (!currentUser) return;
    try {
      const deletedAccountGroup = await deleteAccountGroup(accountGroup.id, accountGroup._version);
      await logActivity({
        user_email: currentUser.email,
        job: null,
        model_type: DataTableType.ACCOUNT_GROUP,
        operation_type: OperationType.DELETE,
        old_data: deletedAccountGroup || null,
        new_data: null,
      });
    } catch (error) {
      console.log('error deleting account group', error);
    }
  };

  const columns: ColumnsType<AccountGroup> = [
    {
      title: 'Account Group Code',
      dataIndex: 'account_group_code',
      key: 'account_group_code',
      sorter: (a, b) => a.account_group_code.localeCompare(b.account_group_code),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Account Group Name',
      dataIndex: 'account_group_name',
      key: 'account_group_name',
      sorter: (a, b) => a.account_group_name.localeCompare(b.account_group_name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Account Heading Code',
      dataIndex: 'account_heading_code',
      key: 'account_heading_code',
    },
    {
      title: 'Account Heading',
      dataIndex: 'account_heading',
      key: 'account_heading',
    },
    {
      title: 'Is Active',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (_, record) => (record.is_active ? <CheckOutlined /> : ''),
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, accountGroup) => (
        <Space>
          <ProfileOutlined
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              openDrawer(undefined, DataTableType.ACCOUNT_GROUP, accountGroup.id, 'model');
            }}
          />
          <Button
            onClick={() => {
              setModalState('edit');
              setEditingAccountGroup(accountGroup);
            }}
            type='link'
            disabled={!permission.UPDATE}
            size='middle'
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete Account Group  ${accountGroup.account_group_name}?`}
            onConfirm={() => onDelete(accountGroup)}
            okText='Yes'
            cancelText='No'
          >
            <Button type='link' danger disabled={!permission.DELETE} size='middle'>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Title level={4} style={{ color: 'darkred' }}>
              Account Groups
            </Title>
          </Space>
        }
        style={{ width: 'max(calc(100vw - 400px), 900px)', height: 'fit-content' }}
        extra={
          <Space>
            <NetworkIndicator />
          </Space>
        }
      >
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5, marginTop: -15 }}
        >
          {/* Button is disabled if current user does not have permission to create account group */}
          <Button type='default' onClick={() => setModalState('add')} disabled={!permission.CREATE}>
            Add Account Group
          </Button>
        </div>
        <Table rowKey={'id'} columns={columns} dataSource={account_groups} size='small' />
      </Card>
      <Modal
        title={
          modalState === 'add'
            ? 'Add New Account Group'
            : `Edit Account Group ${editingAccountGroup?.account_group_name}`
        }
        open={modalState !== null}
        okText='Save'
        onOk={async () => {
          form.submit();
        }}
        onCancel={() => {
          setModalState(null);
        }}
        styles={{ body: { paddingTop: 20 } }}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFormSave}>
          <Form.Item
            label='Account Group Code'
            name='account_group_code'
            rules={[
              { required: true, message: 'Please enter Account Group Code' },
              { validator: validateAccountGroupCode },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Account Group Name'
            name='account_group_name'
            rules={[
              { required: true, message: 'Please enter Account Group Name' },
              { validator: validateAccountGroupName },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Account Heading Code' name='account_heading_code'>
            <Input />
          </Form.Item>
          <Form.Item label='Account Heading' name='account_heading'>
            <Input />
          </Form.Item>
          <Form.Item label='Is Active' name='is_active' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Form.Item label='Remark' name='remark'>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
        </Form>
      </Modal>
      {LogDrawer}
    </div>
  );
};

export default AccountGroups;

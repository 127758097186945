import {
  Button,
  Card,
  Form,
  Space,
  Typography,
  message,
  DatePicker,
  Input,
  Select,
  Modal,
} from 'antd';
import React, { useEffect } from 'react';
import NetworkIndicator from '../../components/NetworkIndicator';
import {
  IdEdByIndexAndNumberQuery,
  IdEdByIndexAndNumberQueryVariables,
  ContainerByIndexAndNumberQuery,
  ContainerByIndexAndNumberQueryVariables,
  Job as JobAPI,
  JobByCountryQueryVariables,
  JobByCustomerQueryVariables,
  JobByDateOfCreationQueryVariables,
  JobByRouteQueryVariables,
  JobsByIndexAndCodeQueryVariables,
  ModelSortDirection,
  ROUTE_TYPE,
} from '../../API';
import dayjs, { Dayjs } from 'dayjs';
import { Account, ContainerType, Country, Customer, RouteType } from '../../models';
import { API, DataStore } from 'aws-amplify';
import { GraphQLResult, GraphQLQuery } from '@aws-amplify/api';
import * as filter_queries from './job_filter_queries';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { CheckOutlined, LockFilled, ProfileOutlined, UnlockFilled } from '@ant-design/icons';
import { compact, uniqBy } from 'lodash';
import { useNavigate } from 'react-router-dom';

import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const { Title } = Typography;
const { RangePicker } = DatePicker;

type JobQueryVariables =
  | JobByDateOfCreationQueryVariables
  | JobsByIndexAndCodeQueryVariables
  | JobByCustomerQueryVariables
  | JobByCountryQueryVariables
  | JobByRouteQueryVariables
  | ContainerByIndexAndNumberQueryVariables
  | IdEdByIndexAndNumberQueryVariables;

type JobQueryName =
  | 'jobByDateOfCreation'
  | 'jobsByIndexAndCode'
  | 'jobByCustomer'
  | 'jobByCountry'
  | 'jobByRoute'
  | 'containerByIndexAndNumber'
  | 'idEdByIndexAndNumber';

const hardLimit = 100;
const softLimit = 100;

const JobFilter = () => {
  const [jobs, setJobs] = React.useState<JobAPI[]>([]);
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [containerTypes, setContainerTypes] = React.useState<ContainerType[]>([]);
  const [accounts, setAccounts] = React.useState<Account[]>([]);
  // filter states
  const [filtersLoaded, setFiltersLoaded] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<[string, string] | null>(null);
  const [job_code, setJobCode] = React.useState<string | undefined>();
  const [customer, setCustomer] = React.useState<string | undefined>();
  const [country, setCountry] = React.useState<string | undefined>();
  const [route, setRoute] = React.useState<string | undefined>();
  const [lock_status, setLockStatus] = React.useState<string | undefined>();
  const [has_invoice, setHasInvoice] = React.useState<'yes' | 'no' | undefined>();
  const [id_ed_number, setIdEdNumber] = React.useState<string | undefined>();
  const [container_number, setContainerNumber] = React.useState<string | undefined>();
  const [paginationInfo, setPaginationInfo] = React.useState<TablePaginationConfig>({} as any);
  // query Functions State
  const [queryName, setQueryName] = React.useState<JobQueryName>('jobByDateOfCreation');
  const [queryVariables, setQueryVariables] = React.useState<JobQueryVariables>({
    limit: 100,
    filter: { and: [{ _deleted: { attributeExists: false } }] },
  } as JobByDateOfCreationQueryVariables);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  // const [reload, setReload] = React.useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();
  // const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const [filterForm] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      const customers = await DataStore.query(Customer);
      setCustomers(customers.sort((a, b) => (a.customer_name > b.customer_name ? 1 : -1)));
    };
    const fetchCountries = async () => {
      const countries = await DataStore.query(Country);
      setCountries(countries.sort((a, b) => (a.country_name > b.country_name ? 1 : -1)));
    };
    const fetchContainerTypes = async () => {
      const container_types = await DataStore.query(ContainerType);
      setContainerTypes(container_types);
    };
    const fetchAccounts = async () => {
      const accounts = await DataStore.query(Account);
      setAccounts(accounts);
    };
    fetchCustomers();
    fetchCountries();
    fetchContainerTypes();
    fetchAccounts();
  }, []);

  useEffect(() => {
    // load filter values from local storage
    const filter_date = localStorage.getItem('job_filter_date');
    if (filter_date) {
      setDate(JSON.parse(filter_date));
    }
    const filter_job_code = localStorage.getItem('job_filter_job_code');
    if (filter_job_code) {
      setJobCode(filter_job_code);
    }
    const filter_customer = localStorage.getItem('job_filter_customer');
    if (filter_customer) {
      setCustomer(filter_customer);
    }
    const filter_country = localStorage.getItem('job_filter_country');
    if (filter_country) {
      setCountry(filter_country);
    }
    const filter_route = localStorage.getItem('job_filter_route');
    if (filter_route) {
      setRoute(filter_route);
    }
    const filter_lock_status = localStorage.getItem('job_filter_lock_status');
    if (filter_lock_status) {
      setLockStatus(filter_lock_status);
    }
    const filter_has_invoice = localStorage.getItem('job_filter_has_invoice');
    if (filter_has_invoice) {
      setHasInvoice(filter_has_invoice as 'yes' | 'no');
    }
    const filter_id_ed_number = localStorage.getItem('job_filter_id_ed_number');
    if (filter_id_ed_number) {
      setIdEdNumber(filter_id_ed_number);
    }
    const filter_container_number = localStorage.getItem('job_filter_container_number');
    if (filter_container_number) {
      setContainerNumber(filter_container_number);
    }
    const pagination_info = localStorage.getItem('job_filter_pagination_info');
    if (pagination_info) {
      setPaginationInfo(JSON.parse(pagination_info));
    }
    // console.log(filter_date);
    const parsed_date = filter_date ? JSON.parse(filter_date) : null;
    filterForm.setFieldsValue({
      date: filter_date ? [dayjs(parsed_date[0]), dayjs(parsed_date[1])] : null,
      job_code: filter_job_code,
      customer: filter_customer,
      country: filter_country,
      route: filter_route,
      lock_status: filter_lock_status,
      has_invoice: filter_has_invoice,
      id_ed_number: filter_id_ed_number,
      container_number: filter_container_number,
    });
    setFiltersLoaded(true);
  }, [filterForm]);

  //////// Filter Functions /////////
  const listJobsByFilterValues = async (
    queryName: JobQueryName,
    variables: JobQueryVariables,
    softLimit: number,
    hardLimit: number,
    forDownload: boolean = false
  ) => {
    const filteredJobs: JobAPI[] = []; // to store the filtered jobs
    let nextToken: string | undefined | null = variables.nextToken || null; // to store the nextToken
    let limit = softLimit; // initial limit
    let count = 0; // to count the number of jobs fetched
    let modified_hard_limit = hardLimit < limit ? limit : hardLimit; // to store the modified hard limit
    const queries_for_fetch = filter_queries; // forDownload ? filter_queries : queries;
    do {
      const result: GraphQLResult<any> = await API.graphql<GraphQLQuery<any>>({
        query: queries_for_fetch[queryName],
        variables: {
          ...variables,
          limit,
          nextToken,
        },
      });
      if (result) {
        if (queryName === 'idEdByIndexAndNumber' || queryName === 'containerByIndexAndNumber') {
          const ideds_or_containers = result.data[queryName]?.items as
            | Exclude<
                Exclude<
                  GraphQLResult<IdEdByIndexAndNumberQuery>['data'],
                  undefined
                >['idEdByIndexAndNumber'],
                null | undefined
              >['items']
            | Exclude<
                Exclude<
                  GraphQLResult<ContainerByIndexAndNumberQuery>['data'],
                  undefined
                >['containerByIndexAndNumber'],
                null | undefined
              >['items'];
          if (ideds_or_containers) {
            ideds_or_containers.forEach((ided_or_c) => {
              if (ided_or_c?.job) filteredJobs.push(ided_or_c.job);
            });
            count += ideds_or_containers.length;
          }
        } else {
          const jobs = result.data[queryName]?.items;
          if (jobs) {
            filteredJobs.push(...jobs);
            count += jobs.length;
          }
        }
        nextToken = result.data[queryName]?.nextToken;
      } else {
        nextToken = null;
      }
    } while (nextToken && count < modified_hard_limit);
    if (!forDownload) {
      setIsLoading(false);
    }
    return { filteredJobs: filteredJobs, nextToken };
  };

  ////// create query variables and set query name
  useEffect(() => {
    const pushIntoVairableFilter = (
      variables: JobQueryVariables,
      filter: {
        [p in
          | 'country'
          | 'route'
          | 'lock_status'
          | 'has_invoice'
          | 'job_code'
          | 'date'
          | 'customer']?: string | [string, string] | null | undefined;
      }
    ) => {
      if (filter.country) {
        variables.filter?.and?.push({ country_id: { eq: filter.country as string } });
      }
      if (filter.route) {
        variables.filter?.and?.push({ route: { eq: filter.route as string as ROUTE_TYPE } });
      }
      if (filter.lock_status) {
        variables.filter?.and?.push({
          archived: { eq: filter.lock_status === 'locked' ? true : false },
        });
      }
      if (filter.has_invoice) {
        console.log(filter.has_invoice);
        variables.filter?.and?.push({
          invoice_id: {
            attributeExists: filter.has_invoice === 'yes' ? true : false,
          },
        });
      }
      if (filter.job_code) {
        variables.filter?.and?.push({ job_code: { beginsWith: filter.job_code as string } });
      }
      if (filter.date) {
        variables.filter?.and?.push({
          date_of_creation: {
            between: filter.date as [string, string],
          },
        });
      }
      if (filter.customer) {
        variables.filter?.and?.push({ customer_id: { eq: filter.customer as string } });
      }
    };
    const buildQueryNameAndVariables = async () => {
      let query_name: JobQueryName = 'jobByDateOfCreation';
      let query_variables: JobQueryVariables = {
        limit: 100,
        filter: { and: [{ _deleted: { attributeExists: false } }] },
      } as JobByDateOfCreationQueryVariables;
      // query name and first level variable settings
      if (id_ed_number) {
        query_name = 'idEdByIndexAndNumber';
        query_variables = {
          ...query_variables,
          id_ed_number: { beginsWith: id_ed_number },
          virtual_index: 0,
        } as IdEdByIndexAndNumberQueryVariables;
      } else if (container_number) {
        query_name = 'containerByIndexAndNumber';
        query_variables = {
          ...query_variables,
          container_number: { beginsWith: container_number },
          virtual_index: 0,
        } as ContainerByIndexAndNumberQueryVariables;
      } else if (job_code) {
        query_name = 'jobsByIndexAndCode';
        query_variables = {
          ...query_variables,
          job_code: { beginsWith: job_code },
          virtual_index: 0,
          // job_code,
        } as JobsByIndexAndCodeQueryVariables;
        pushIntoVairableFilter(query_variables, {
          customer,
          country,
          route,
          lock_status,
          has_invoice,
          date,
        });
      } else if (customer) {
        query_name = 'jobByCustomer';
        query_variables = {
          ...query_variables,
          customer_id: customer,
          date_of_creation: date ? { between: date } : undefined,
          sortDirection: ModelSortDirection.DESC,
        } as JobByCustomerQueryVariables;
        pushIntoVairableFilter(query_variables, { country, route, lock_status, has_invoice });
      } else if (country) {
        query_name = 'jobByCountry';
        query_variables = {
          ...query_variables,
          country_id: country,
          date_of_creation: date ? { between: date } : undefined,
          sortDirection: ModelSortDirection.DESC,
        } as JobByCountryQueryVariables;
        pushIntoVairableFilter(query_variables, { route, lock_status, has_invoice });
      } else if (route) {
        query_name = 'jobByRoute';
        query_variables = {
          ...query_variables,
          route,
          date_of_creation: date ? { between: date } : undefined,
          sortDirection: ModelSortDirection.DESC,
        } as JobByRouteQueryVariables;
        pushIntoVairableFilter(query_variables, { lock_status, has_invoice });
      } else {
        query_name = 'jobByDateOfCreation';
        query_variables = {
          ...query_variables,
          date_of_creation: date ? { between: date } : undefined,
          virtual_index: 0,
          sortDirection: ModelSortDirection.DESC,
        } as JobByDateOfCreationQueryVariables;
        pushIntoVairableFilter(query_variables, { lock_status, has_invoice });
      }
      // console.log(query_name, query_variables);
      setQueryName(query_name);
      setQueryVariables(query_variables);
      ///// first time run the fetch
      setIsLoading(true);
      const { filteredJobs, nextToken } = await listJobsByFilterValues(
        query_name,
        query_variables,
        softLimit,
        hardLimit
      );
      //// first time result replace the jobs
      setJobs(uniqBy(filteredJobs, 'id'));
      setQueryVariables({ ...query_variables, nextToken });
      // console.log(filteredJobs);
    };
    if (filtersLoaded) {
      buildQueryNameAndVariables();
    }
  }, [
    date,
    job_code,
    customer,
    country,
    route,
    lock_status,
    has_invoice,
    id_ed_number,
    container_number,
    filtersLoaded,
  ]);

  const fetchMore = async () => {
    if (!queryVariables.nextToken) return;
    setIsLoading(true);
    const { filteredJobs, nextToken } = await listJobsByFilterValues(
      queryName,
      queryVariables,
      softLimit,
      hardLimit
    );
    /// fetchmore result append to the jobs
    setJobs(uniqBy([...jobs, ...filteredJobs], 'id'));
    // console.log(nextToken);
    setQueryVariables({ ...queryVariables, nextToken });
  };

  const fetchForExport = async () => {
    setIsLoading(true);
    setIsDownloading(true);

    const jobs: JobAPI[] = [];
    let nextToken: string | undefined | null = null;
    let softLimit = 500;
    let hardLimit = 500;
    // console.time('exporting');
    do {
      messageApi.open({
        type: 'loading',
        content: `Downloading job data, ${jobs.length} records downloaded.`,
        key: 'exporting',
      });
      const { filteredJobs, nextToken: next } = await listJobsByFilterValues(
        queryName,
        { ...queryVariables, nextToken },
        softLimit,
        hardLimit,
        true
      );
      jobs.push(...filteredJobs);
      nextToken = next as string | undefined | null;
      // console.timeLog('exporting');
    } while (nextToken);
    messageApi.open({
      type: 'success',
      content: `${jobs.length} job records successfully exported.`,
      duration: 5,
      key: 'exporting',
    });

    await exportToExcel({
      jobs: uniqBy(jobs, 'id'),
      customers,
      countries,
      containerTypes,
      accounts,
    });
    setIsLoading(false);
    setIsDownloading(false);
    // console.timeEnd('exporting');
  };

  /////////////// Filter Settings ///////////////

  type FilterValues = {
    [P in keyof AllValues]: AllValues[P];
  };

  type AllValues = {
    date: [Dayjs, Dayjs] | null;
    job_code: string | undefined;
    customer: string | undefined;
    country: string | undefined;
    route: string | undefined;
    lock_status: string | undefined;
    has_invoice: 'yes' | 'no' | undefined;
    id_ed_number: string | undefined;
    container_number: string | undefined;
  };

  let timeoutId: NodeJS.Timeout | null = null;
  const onFilterChange = async (changedValues: FilterValues, allValues: AllValues) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // console.log(changedValues);
    // if the changedValues is id_ed_number or container_number, set other fields to undefined

    timeoutId = setTimeout(() => {
      const all_values = { ...allValues };
      if (changedValues.id_ed_number || changedValues.container_number) {
        all_values.date = null;
        all_values.customer = undefined;
        all_values.country = undefined;
        all_values.route = undefined;
        all_values.lock_status = undefined;
        all_values.has_invoice = undefined;
        all_values.id_ed_number = changedValues.id_ed_number;
        all_values.container_number = changedValues.container_number;
        all_values.job_code = undefined;
      } else {
        all_values.id_ed_number = undefined;
        all_values.container_number = undefined;
      }

      filterForm.setFieldsValue({
        date: all_values.date ? [all_values.date[0], all_values.date[1]] : null,
        job_code: all_values.job_code,
        customer: all_values.customer,
        country: all_values.country,
        route: all_values.route,
        lock_status: all_values.lock_status,
        has_invoice: all_values.has_invoice,
        id_ed_number: all_values.id_ed_number,
        container_number: all_values.container_number,
      });

      if (all_values.date) {
        setDate([all_values.date[0].format('YYYY-MM-DD'), all_values.date[1].format('YYYY-MM-DD')]);
        localStorage.setItem(
          'job_filter_date',
          JSON.stringify([
            all_values.date[0].format('YYYY-MM-DD'),
            all_values.date[1].format('YYYY-MM-DD'),
          ])
        );
      } else {
        setDate(null);
        localStorage.removeItem('job_filter_date');
      }
      const job_code = all_values.job_code ? all_values.job_code.trim() : undefined;
      if (job_code) {
        setJobCode(job_code);
        localStorage.setItem('job_filter_job_code', job_code);
      } else {
        setJobCode(undefined);
        localStorage.removeItem('job_filter_job_code');
      }
      if (all_values.customer) {
        setCustomer(all_values.customer);
        localStorage.setItem('job_filter_customer', all_values.customer);
      } else {
        setCustomer(undefined);
        localStorage.removeItem('job_filter_customer');
      }
      if (all_values.country) {
        setCountry(all_values.country);
        localStorage.setItem('job_filter_country', all_values.country);
      } else {
        setCountry(undefined);
        localStorage.removeItem('job_filter_country');
      }
      if (all_values.route) {
        setRoute(all_values.route);
        localStorage.setItem('job_filter_route', all_values.route);
      } else {
        setRoute(undefined);
        localStorage.removeItem('job_filter_route');
      }
      if (all_values.lock_status) {
        setLockStatus(all_values.lock_status);
        localStorage.setItem('job_filter_lock_status', all_values.lock_status);
      } else {
        setLockStatus(undefined);
        localStorage.removeItem('job_filter_lock_status');
      }
      if (all_values.has_invoice) {
        setHasInvoice(all_values.has_invoice);
        localStorage.setItem('job_filter_has_invoice', all_values.has_invoice);
      } else {
        setHasInvoice(undefined);
        localStorage.removeItem('job_filter_has_invoice');
      }
      const id_ed_number = all_values.id_ed_number ? all_values.id_ed_number.trim() : undefined;
      if (id_ed_number) {
        setIdEdNumber(id_ed_number);
        localStorage.setItem('job_filter_id_ed_number', id_ed_number);
      } else {
        setIdEdNumber(undefined);
        localStorage.removeItem('job_filter_id_ed_number');
      }
      const container_number = all_values.container_number
        ? all_values.container_number.trim()
        : undefined;
      if (container_number) {
        setContainerNumber(container_number);
        localStorage.setItem('job_filter_container_number', container_number);
      } else {
        setContainerNumber(undefined);
        localStorage.removeItem('job_filter_container_number');
      }
    }, 500);
  };

  const clearFilter = () => {
    filterForm.resetFields();
    setDate(null);
    setJobCode(undefined);
    setCustomer(undefined);
    setCountry(undefined);
    setRoute(undefined);
    setLockStatus(undefined);
    setHasInvoice(undefined);
    setIdEdNumber(undefined);
    setContainerNumber(undefined);
    localStorage.removeItem('job_filter_date');
    localStorage.removeItem('job_filter_job_code');
    localStorage.removeItem('job_filter_customer');
    localStorage.removeItem('job_filter_country');
    localStorage.removeItem('job_filter_route');
    localStorage.removeItem('job_filter_lock_status');
    localStorage.removeItem('job_filter_has_invoice');
    localStorage.removeItem('job_filter_id_ed_number');
    localStorage.removeItem('job_filter_container_number');
  };

  //////////////////////// Modal Content //////////////////

  const createModalContent = (record_type: 'ideds' | 'containers' | 'cashbook', record: JobAPI) => {
    // let title =
    //   record_type === 'ideds' ? 'ID/EDs' : record_type === 'containers' ? 'Containers' : 'CashBook';
    // title += ` | ${record.job_code}`;
    // const container_titles = ['Container No', 'Container Type'];
    type ContainerType = Exclude<Exclude<JobAPI['containers'], undefined | null>['items'][0], null>;
    type IdEdType = Exclude<Exclude<JobAPI['id_eds'], undefined | null>['items'][0], null>;
    type CashBookType = Exclude<
      Exclude<JobAPI['cash_book_entries'], undefined | null>['items'][0],
      null
    >;

    let columns: ColumnsType<ContainerType> | ColumnsType<IdEdType> | ColumnsType<CashBookType> =
      [];
    let datasource: ContainerType[] | IdEdType[] | CashBookType[] = [];
    let more_records: boolean = false;
    const cashbookColumns: ColumnsType<CashBookType> = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Cash Handler',
        dataIndex: 'cash_handler',
        key: 'cash_handler',
      },
      {
        title: 'Voucher No',
        dataIndex: 'voucher_number',
        key: 'voucher_number',
      },
      {
        title: 'Account',
        dataIndex: 'account',
        key: 'account',
        ellipsis: true,
        render: (text, record) => {
          return accounts.find((a) => a.id === record.account_id)?.account_name || '';
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 100,
        ellipsis: true,
      },
      {
        title: 'Debit',
        align: 'center',
        children: [
          {
            title: 'USD',
            dataIndex: 'debit_usd',
            key: 'debit_usd',
            align: 'right',
            render: (text, record) => {
              return text.toLocaleString();
            },
          },
          {
            title: 'MMK',
            dataIndex: 'debit_mmk',
            key: 'debit_mmk',
            align: 'right',
            render: (text, record) => {
              return text.toLocaleString();
            },
          },
        ],
      },
      {
        title: 'Credit',
        align: 'center',
        children: [
          {
            title: 'USD',
            dataIndex: 'credit_usd',
            key: 'credit_usd',
            align: 'right',
            render: (text, record) => {
              return text.toLocaleString();
            },
          },
          {
            title: 'MMK',
            dataIndex: 'credit_mmk',
            key: 'credit_mmk',
            align: 'right',
            render: (text, record) => {
              return text.toLocaleString();
            },
          },
        ],
      },
    ];

    const containerColumns: ColumnsType<ContainerType> = [
      {
        title: 'Container No',
        dataIndex: 'container_number',
        key: 'container_number',
      },
      {
        title: 'Container Type',
        dataIndex: 'container_type_id',
        key: 'container_type_id',
        render: (text, record) => {
          return (
            containerTypes.find((ct) => ct.id === record?.container_type_id)?.container_type || ''
          );
        },
      },
    ];
    const idedColumns: ColumnsType<IdEdType> = [
      {
        title: 'ID/ED',
        dataIndex: 'id_ed_type',
        key: 'id_ed_type',
      },
      {
        title: 'ID/ED No',
        dataIndex: 'id_ed_number',
        key: 'id_ed_number',
      },
      {
        title: 'BL No.',
        dataIndex: 'bl_number',
        key: 'bl_number',
      },
      {
        title: 'Form Required',
        dataIndex: 'form_required',
        key: 'form_required',
        render: (text, record) => {
          return record?.form_required ? <CheckOutlined /> : '';
        },
      },
      {
        title: 'Form No.',
        dataIndex: 'form_number',
        key: 'form_number',
      },
    ];
    const containers = [...compact(record.containers?.items || [])]
      .filter((c) => c._deleted !== true)
      .sort((a, b) => a.container_number.localeCompare(b.container_number));
    const container_next_token = record.containers?.nextToken;
    const ideds = [...compact(record.id_eds?.items || [])]
      .filter((i) => i._deleted !== true)
      .sort((a, b) => a.id_ed_number.localeCompare(b.id_ed_number));
    const ided_next_token = record.id_eds?.nextToken;
    const cash_book_entries = [...compact(record.cash_book_entries?.items || [])]
      .filter((c) => c._deleted !== true)
      .sort((a, b) => b.date.localeCompare(a.date));
    const cash_book_next_token = record.cash_book_entries?.nextToken;
    if (record_type === 'cashbook') {
      columns = cashbookColumns as ColumnsType<CashBookType>;
      datasource = cash_book_entries as CashBookType[];
      more_records = cash_book_next_token ? true : false;
    } else if (record_type === 'containers') {
      columns = containerColumns as ColumnsType<ContainerType>;
      datasource = containers as ContainerType[];
      more_records = container_next_token ? true : false;
    } else {
      columns = idedColumns as ColumnsType<IdEdType>;
      datasource = ideds as IdEdType[];
      more_records = ided_next_token ? true : false;
    }

    return (
      <div>
        <Table
          // @ts-ignore
          columns={columns}
          // @ts-ignore
          dataSource={[...compact(datasource)]}
          rowKey='id'
          size='small'
          style={{ width: 1000 }}
          width={1000}
          pagination={{
            showTotal: (total, range) => (
              <span>
                Total {total} records.{' '}
                {more_records ? (
                  <span style={{ color: 'red' }}>
                    There are more records. Please check at the Job page.
                  </span>
                ) : null}
              </span>
            ),
          }}
        />
      </div>
    );
  };

  /////////////// Table Columns ///////////////

  const columns: ColumnsType<JobAPI> = [
    {
      title: 'Created On',
      dataIndex: 'date_of_creation',
      key: 'date_of_creation',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Job Code',
      dataIndex: 'job_code',
      key: 'job_code',
      width: 160,
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: 'Customer',
      dataIndex: 'customer_id',
      key: 'customer_id',
      // width: 150,
      ellipsis: true,
      render: (text, record) => {
        const customer = customers.find((c) => c.id === record.customer_id);
        return customer ? customer.customer_name : '';
      },
    },
    {
      title: 'Country',
      dataIndex: 'country_id',
      key: 'country_id',
      width: 150,
      ellipsis: true,
      render: (text, record) => {
        const country = countries.find((c) => c.id === record.country_id);
        return country ? country.country_name : '';
      },
    },
    {
      title: 'ID/EDs',
      dataIndex: 'id_ed',
      key: 'id_ed',
      width: 80,
      align: 'center',
      render: (text, record) => {
        const records_length = record.id_eds
          ? compact(record.id_eds.items).filter((i) => i._deleted !== true).length
          : 0;
        const more_records = record.id_eds?.nextToken;
        return (
          <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            {records_length > 0 ? (
              <ProfileOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const modal_content = createModalContent('ideds', record);
                  Modal.info({
                    title: 'ID/EDs | ' + record.job_code,
                    okButtonProps: { hidden: true },
                    closable: true,
                    icon: null,
                    maskClosable: true,
                    content: modal_content,
                    width: 600,
                  });
                }}
              />
            ) : (
              <span />
            )}
            <span>
              {records_length} {more_records ? '+' : ''}
            </span>
          </Space>
        );
      },
    },
    {
      title: 'Containers',
      dataIndex: 'container',
      key: 'container',
      width: 90,
      align: 'right',
      render: (text, record) => {
        const records_length = record.containers
          ? compact(record.containers.items).filter((c) => c._deleted !== true).length
          : 0;
        const more_records = record.containers?.nextToken;
        return (
          <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            {records_length > 0 ? (
              <ProfileOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const modal_content = createModalContent('containers', record);
                  Modal.info({
                    title: 'Containers | ' + record.job_code,
                    okButtonProps: { hidden: true },
                    closable: true,
                    icon: null,
                    maskClosable: true,
                    content: modal_content,
                  });
                }}
              />
            ) : (
              <span />
            )}
            <span>
              {records_length} {more_records ? '+' : ''}
            </span>
          </Space>
        );
      },
    },
    {
      title: 'CashBook',
      dataIndex: 'cashbook',
      key: 'cashbook',
      width: 80,
      align: 'right',
      render: (text, record) => {
        const records_length = record.cash_book_entries
          ? compact(record.cash_book_entries.items).filter((c) => c._deleted !== true).length
          : 0;
        const more_records = record.cash_book_entries?.nextToken;
        return (
          <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            {records_length > 0 ? (
              <ProfileOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const modal_content = createModalContent('cashbook', record);
                  Modal.info({
                    title: 'Cashbook | ' + record.job_code,
                    okButtonProps: { hidden: true },
                    closable: true,
                    icon: null,
                    maskClosable: true,
                    content: modal_content,
                    width: 1000,
                  });
                }}
              />
            ) : (
              <span />
            )}
            <span>
              {records_length} {more_records ? '+' : ''}
            </span>
          </Space>
        );
      },
    },
    {
      title: 'Route',
      dataIndex: 'route',
      key: 'route',
      width: 80,
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice',
      key: 'invoice',
      width: 80,
      align: 'center',
      render: (text, record) => {
        return record.invoice_id ? <CheckOutlined /> : '';
      },
    },
    {
      title: 'Locked',
      dataIndex: 'archived',
      key: 'archived',
      width: 70,
      align: 'center',
      render: (text, record) => {
        return record.archived ? (
          <LockFilled style={{ fontSize: '1.2em', color: 'darkred' }} />
        ) : (
          <UnlockFilled style={{ fontSize: '1.2em', color: 'green' }} />
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 110,
      align: 'center',
      render: (text, record) => {
        return (
          <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
            {/* <ProfileOutlined style={{ cursor: 'pointer' }} onClick={() => console.log('clicked')} /> */}
            <Button type='link' size='middle' onClick={() => navigate(`/jobs/${record.id}`)}>
              go to job
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      {contextHolder}
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Title level={4} style={{ color: 'darkred' }}>
              Job Filter
            </Title>
          </Space>
        }
        style={{ width: '100%', height: 'fit-content', marginTop: -10 }}
        extra={
          <Space>
            <Button type='link' onClick={fetchMore} disabled={queryVariables.nextToken === null}>
              fetch more
            </Button>
            <Button onClick={clearFilter} disabled={isLoading}>
              Clear filter
            </Button>
            {/* <Dropdown menu={{ items: export_items }} placement='bottomRight'> */}
            <Button type='primary' onClick={fetchForExport} disabled={isLoading}>
              Export
            </Button>
            {/* </Dropdown> */}
            <NetworkIndicator />
          </Space>
        }
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Form
            form={filterForm}
            layout='inline'
            onValuesChange={onFilterChange}
            disabled={isDownloading}
          >
            <Form.Item
              label='Created on'
              name='date'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              style={{ width: 315, marginBottom: 10 }}
            >
              <RangePicker allowClear />
            </Form.Item>
            <Form.Item
              label='Job Code'
              name='job_code'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ width: 300, marginBottom: 10 }}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label={<span style={{ color: 'blue' }}>ID/ED No</span>}
              name='id_ed_number'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ width: 300, marginBottom: 10 }}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label={<span style={{ color: 'blue' }}>Container No</span>}
              name='container_number'
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
              style={{ width: 300, marginBottom: 10 }}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label='Customer'
              name='customer'
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              style={{ width: 350, marginBottom: 10 }}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.label || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={customers.map((c) => ({ value: c.id, label: c.customer_name }))}
              />
            </Form.Item>
            <Form.Item
              label='Country'
              name='country'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              style={{ width: 250, marginBottom: 10 }}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.label || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={countries.map((c) => ({ value: c.id, label: c.country_name }))}
              />
            </Form.Item>
            <Form.Item
              label='Route'
              name='route'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ width: 150, marginBottom: 10 }}
            >
              <Select
                allowClear
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.label || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={Object.keys(RouteType).map((c) => ({ value: c, label: c }))}
              />
            </Form.Item>
            <Form.Item
              label='Has Invoice'
              name='has_invoice'
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              style={{ width: 160, marginBottom: 10 }}
            >
              <Select allowClear>
                <Select.Option key='yes' value='yes'>
                  Yes
                </Select.Option>
                <Select.Option key='no' value='no'>
                  No
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label='Locked'
              name='lock_status'
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              style={{ width: 180, marginBottom: 10 }}
            >
              <Select allowClear>
                <Select.Option key='locked' value='locked'>
                  Locked
                </Select.Option>
                <Select.Option key='unlocked' value='unlocked'>
                  Unlocked
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </div>
        <Table
          columns={columns}
          dataSource={jobs}
          size='small'
          scroll={{ x: 1100 }}
          bordered
          rowKey='id'
          loading={isLoading}
          pagination={{
            current: paginationInfo.current,
            pageSize: 10,
            showTotal: (total, range) => `Total ${total} records downloaded.`,
          }}
          onChange={(pagination, filters, sorter, extra) => {
            setPaginationInfo(pagination);
            localStorage.setItem('job_filter_pagination_info', JSON.stringify(pagination));
          }}
        />
      </Card>
      {/* <Modal
        title='Job'
        open={modalOpen}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        // onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        style={{ top: 20, right: 20 }}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal> */}
    </div>
  );
};

export default JobFilter;

const exportToExcel = async ({
  jobs,
  customers,
  countries,
  containerTypes,
  accounts,
}: {
  jobs: JobAPI[];
  customers: Customer[];
  countries: Country[];
  containerTypes: ContainerType[];
  accounts: Account[];
}) => {
  try {
    const customerMap = new Map(customers.map((c) => [c.id, c.customer_name]));
    const countryMap = new Map(countries.map((c) => [c.id, c.country_name]));
    const containerTypeMap = new Map(containerTypes.map((c) => [c.id, c.container_type]));
    const accountMap = new Map(accounts.map((a) => [a.id, a.account_name]));

    const workbook = new ExcelJS.Workbook();
    const jobsSheet = workbook.addWorksheet('Jobs');
    const containersSheet = workbook.addWorksheet('Containers');
    const idedsSheet = workbook.addWorksheet('IDEDs');
    const cashbookSheet = workbook.addWorksheet('CashBook');
    const noteSheet = workbook.addWorksheet('Note');

    jobsSheet.columns = [
      { header: 'Created On', key: 'date_of_creation', width: 20 },
      { header: 'Job Code', key: 'job_code', width: 20 },
      { header: 'Customer', key: 'customer', width: 20 },
      { header: 'Invoice Created', key: 'invoice_created', width: 20 },
      { header: 'Country', key: 'country', width: 20 },
      { header: 'ID/EDs', key: 'id_ed', width: 20 },
      { header: 'Route', key: 'route', width: 20 },
      { header: 'Comodity', key: 'comodity', width: 20 },
      { header: 'Arrival date', key: 'date_of_arrival', width: 20 },
      { header: 'Voyage/Flight', key: 'voyage', width: 20 },
      { header: 'Forwarder', key: 'forwarder', width: 20 },
      { header: 'Liner', key: 'liner', width: 20 },
      { header: 'Description', key: 'description', width: 20 },
      { header: 'Remark', key: 'remark', width: 20 },
      { header: 'Containers', key: 'containers', width: 20 },
      { header: 'Job delivered date', key: 'date_of_delivery', width: 20 },
      { header: 'Active', key: 'is_active', width: 20 },
      { header: 'Locked', key: 'archived', width: 20 },
      { header: 'Cashbook', key: 'cashbook', width: 20 },
    ];

    containersSheet.columns = [
      { header: 'Job Code', key: 'job_code', width: 20 },
      { header: 'Container No', key: 'container_number', width: 20 },
      { header: 'Container Type', key: 'container_type', width: 20 },
    ];

    idedsSheet.columns = [
      { header: 'Job Code', key: 'job_code', width: 20 },
      { header: 'ID/ED', key: 'id_ed_type', width: 20 },
      { header: 'ID/ED No', key: 'id_ed_number', width: 20 },
      { header: 'BL No.', key: 'bl_number', width: 20 },
      { header: 'Form Required', key: 'form_required', width: 20 },
      { header: 'Form No.', key: 'form_number', width: 20 },
      { header: 'Remark', key: 'remark', width: 20 },
    ];

    cashbookSheet.columns = [
      { header: 'Job Code', key: 'job_code', width: 20 },
      { header: 'Date', key: 'date', width: 20 },
      { header: 'Cash Handler', key: 'cash_handler', width: 20 },
      { header: 'Voucher No', key: 'voucher_number', width: 20 },
      { header: 'Account', key: 'account', width: 20 },
      { header: 'Description', key: 'description', width: 20 },
      { header: 'Debit USD', key: 'debit_usd', width: 20 },
      { header: 'Debit MMK', key: 'debit_mmk', width: 20 },
      { header: 'Credit USD', key: 'credit_usd', width: 20 },
      { header: 'Credit MMK', key: 'credit_mmk', width: 20 },
    ];

    jobs.forEach((job) => {
      jobsSheet.addRow({
        date_of_creation: job.date_of_creation,
        job_code: job.job_code,
        customer: customerMap.get(job.customer_id),
        invoice_created: job.invoice ? 'Yes' : 'No',
        country: countryMap.get(job.country_id),
        id_ed: job.id_eds?.items.length || 0,
        route: job.route,
        comodity: job.comodity,
        date_of_arrival: job.date_of_arrival,
        voyage: job.voyage,
        forwarder: job.forwarder,
        liner: job.liner,
        description: job.description,
        remark: job.remark,
        containers: job.containers?.items.length || 0,
        date_of_delivery: job.date_of_delivery,
        is_active: job.is_active ? 'Yes' : 'No',
        archived: job.archived ? 'Yes' : 'No',
        cashbook: job.cash_book_entries?.nextToken
          ? `${job.cash_book_entries?.items.length || 0}${
              job.cash_book_entries?.nextToken ? ' +' : ''
            }`
          : job.cash_book_entries?.items.length || 0,
      });
      job.containers?.items.forEach((container) => {
        containersSheet.addRow({
          job_code: job.job_code,
          container_number: container?.container_number || '',
          container_type: containerTypeMap.get(container?.container_type_id || ''),
        });
      });
      job.id_eds?.items.forEach((ided) => {
        idedsSheet.addRow({
          job_code: job.job_code,
          id_ed_type: ided?.id_ed_type || '',
          id_ed_number: ided?.id_ed_number || '',
          bl_number: ided?.bl_number || '',
          form_required: ided?.form_required ? 'Yes' : 'No',
          form_number: ided?.form_number || '',
          remark: ided?.remark || '',
        });
      });
      job.cash_book_entries?.items.forEach((entry) => {
        cashbookSheet.addRow({
          job_code: job.job_code,
          date: entry?.date || '',
          cash_handler: entry?.cash_handler || '',
          voucher_number: entry?.voucher_number || '',
          account: accountMap.get(entry?.account_id || ''),
          description: entry?.description || '',
          debit_usd: entry?.debit_usd || '',
          debit_mmk: entry?.debit_mmk || '',
          credit_usd: entry?.credit_usd || '',
          credit_mmk: entry?.credit_mmk || '',
        });
      });
    });

    workbook.worksheets.forEach((worksheet) => {
      if (worksheet.name === 'Note') return;
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      });
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];
      worksheet.autoFilter = {
        from: 'A1',
        to: { row: worksheet.lastRow?.number || 1, column: worksheet.lastColumn?.number || 1 },
      };
    });

    noteSheet.getCell('A1').value = 'Note';
    noteSheet.getCell('A2').value =
      'For one job, there can be multiple containers, id/eds and cashbook entries.';
    noteSheet.getCell('A3').value =
      'If the number of contaiers, id/eds or cashbook entries is more than 100,';
    noteSheet.getCell('A4').value = 'this file will not contain all the records.';
    noteSheet.getCell('A5').value = 'Please check the Job page for more details.';
    noteSheet.getCell('A6').value =
      'For cashbook records, you can also check the Cashbook Filter page.';
    noteSheet.getCell('A7').value = 'Thank you';

    // https://github.com/exceljs/exceljs/issues/2041
    // [BUG] Slow generation performance #2041
    const excelGenerator = new ExcelGenerator();
    excelGenerator.finalizeWorkbook(workbook);
    //////////////////////////

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `Jobs Filter Export ${dayjs().format('YYYY-MM-DD h-mm a')}.xlsx`);
  } catch (error) {
    console.log('error exporting jobs', error);
  } finally {
    return Promise.resolve(true);
  }
};

// https://github.com/exceljs/exceljs/issues/2041
// [BUG] Slow generation performance #2041
class ExcelGenerator {
  private internStyle<T extends Partial<ExcelJS.Style>>(
    internedStyles: Map<string, T>,
    style: T,
    type: number
  ) {
    let buf = `${JSON.stringify(style)}${type}`;

    const internedStyle = internedStyles.get(buf);
    if (internedStyle) {
      return internedStyle;
    }

    const newInternedStyle = Object.freeze(Object.assign({}, style));
    internedStyles.set(buf, newInternedStyle);
    return newInternedStyle;
  }

  public finalizeWorkbook(workbook: ExcelJS.Workbook) {
    const internedStyles = new Map<string, Partial<ExcelJS.Style>>();
    workbook.worksheets.forEach((worksheet) => {
      (worksheet as any)._rows.forEach((row: ExcelJS.Row) => {
        (row as any)._cells.forEach((cell: ExcelJS.Cell) => {
          cell.style = this.internStyle(internedStyles, cell.style, cell.type);
        });
      });
    });
  }
}

import { Button } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Styles from './layout.module.css';
import MainNavBar from './main_navbar';
import { Layout as AntLayout } from 'antd';
import { AuthContext } from '../pages/Users/AuthContext';
import { API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { CreatePageVisitMutation, CreatePageVisitMutationVariables } from '../API';
import { createPageVisit } from '../graphql/mutations';

const { Header, Footer, Content } = AntLayout;

const Layout = () => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser) return;
    const paths = [
      'home',
      'users',
      'cashhandlers',
      'accounts',
      'accountgroups',
      'customers',
      'jobs',
      'invoice',
      'ideds',
      'containers',
      'cashbook',
      'about',
    ];
    const actions = ['new', 'edit', 'filters'];
    const pathname = location.pathname;
    const keypath = pathname
      .split('/')
      .slice(1)
      .filter((path) => paths.includes(path));
    const actionpath = pathname
      .split('/')
      .slice(1)
      .filter((path) => actions.includes(path));

    const action = actionpath[0] || 'view';
    const key = keypath.length > 1 ? keypath[1] : keypath[0];
    const variables: CreatePageVisitMutationVariables = {
      input: {
        page: `${key}_${action}`,
        user: currentUser?.email || 'unknown',
        visited_at: new Date().toISOString(),
        type: 'PageVisit',
      },
    };
    API.graphql<GraphQLQuery<CreatePageVisitMutation>>({
      query: createPageVisit,
      variables,
    });
  }, [location, currentUser]);

  return (
    <AntLayout>
      <Header style={{ display: 'flex', alignItems: 'center', height: 60 }}>
        <MainNavBar />
      </Header>
      <Content style={{ height: 'calc(100vh - 60px)' }}>
        <Outlet />
      </Content>
      {/* <Footer style={{ height: 60 }}>STL Myanmar Company Limited</Footer> */}
    </AntLayout>
  );
  // return (
  //   <div className={Styles.layout}>
  //     <header>
  //       <MainNavBar />
  //     </header>
  //     <main>
  //       <div className={Styles.main_left} />
  //       <div className={Styles.main_center}>
  //         <Outlet />
  //       </div>
  //       <div className={Styles.main_right} />
  //     </main>
  //     <footer>Footer</footer>
  //   </div>
  // );
};

export default Layout;

import { DataStore } from 'aws-amplify';
import {
  Log,
  DataTableType,
  OperationType,
  Job,
  Container,
  IdEd,
  CashBook,
  Invoice,
  InvoiceDetail,
  Customer,
  Account,
  AccountGroup,
} from '../API';
import { updateLog } from '../graphql/mutations';
import { createChangeLog } from '../pages/Jobs/api';
import { CashHandler } from '../models';

// job_id
// job_code
// model_type
// model_id
// operation_type
// operation_time
// data
// user

// type LogActivityInputType = {
//   user_email: string;
//   job: Job | null;
//   model_type: DataTableType;
//   operation_type: OperationType;
//   old_data: Job | Container | IdEd | CashBook | Invoice | InvoiceDetail | null;
//   new_data: Job | Container | IdEd | CashBook | Invoice | InvoiceDetail | null;
// };

export const logActivity = async (inputs: {
  user_email: string;
  job: Job | null;
  model_type: DataTableType;
  operation_type: OperationType;
  old_data:
    | Job
    | Container
    | IdEd
    | CashBook
    | CashHandler
    | Invoice
    | InvoiceDetail
    | Customer
    | Account
    | AccountGroup
    | null;
  new_data:
    | Job
    | Container
    | IdEd
    | CashBook
    | CashHandler
    | Invoice
    | InvoiceDetail
    | Customer
    | Account
    | AccountGroup
    | null;
}) => {
  const { user_email, job, model_type, operation_type, old_data, new_data } = inputs;
  try {
    if (!old_data && !new_data) throw new Error('Both old and new data are null');
    // no_job_id and no_job_code are for account_group, account, customers and probably users
    await createChangeLog({
      job_id: job ? job.id : 'no_job_id',
      job_code: job ? job.job_code : 'no_job_code',
      model: model_type,
      model_id: new_data ? new_data.id : old_data!.id,
      operation: operation_type,
      old_data:
        operation_type === OperationType.DELETE || operation_type === OperationType.UPDATE
          ? JSON.stringify(old_data)
          : null,
      new_data: operation_type !== OperationType.DELETE ? JSON.stringify(new_data) : null,
      user: user_email,
      changed_at: new Date().toISOString(),
    });
  } catch (error) {
    console.error(error);
  }
};

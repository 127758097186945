export const listJobs = /* GraphQL */ `
  query ListJobs(
    $id: ID
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        job_code
        customer_id
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const listCashBooks = /* GraphQL */ `
  query ListCashBooks(
    $id: ID
    $filter: ModelCashBookFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCashBooks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        cash_handler
        voucher_number
        job_id
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        remark
        created_by
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const cashBookByDate = /* GraphQL */ `
  query CashBookByDate(
    $virtual_index: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashBookByDate(
      virtual_index: $virtual_index
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        cash_handler
        voucher_number
        job_id
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        remark
        created_by
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const cashBookByJob = /* GraphQL */ `
  query CashBookByJob(
    $job_id: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashBookByJob(
      job_id: $job_id
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        cash_handler
        voucher_number
        job_id
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        remark
        created_by
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const cashBookByVoucherNumber = /* GraphQL */ `
  query CashBookByVoucherNumber(
    $voucher_number: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashBookByVoucherNumber(
      voucher_number: $voucher_number
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        cash_handler
        voucher_number
        job_id
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        remark
        created_by
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const cashBookByAccount = /* GraphQL */ `
  query CashBookByAccount(
    $account_id: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashBookByAccount(
      account_id: $account_id
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        cash_handler
        voucher_number
        job_id
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        remark
        created_by
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const cashBookByCashHandler = /* GraphQL */ `
  query CashBookByCashHandler(
    $cash_handler: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashBookByCashHandler(
      cash_handler: $cash_handler
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        cash_handler
        voucher_number
        job_id
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        remark
        created_by
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const cashBookByCreatedBy = /* GraphQL */ `
  query CashBookByCreatedBy(
    $created_by: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashBookByCreatedBy(
      created_by: $created_by
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        cash_handler
        voucher_number
        job_id
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        remark
        created_by
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

import { API, Auth, DataStore } from 'aws-amplify';
import { DataTableType, OperationType } from '../../API';
import { Permission } from '../../models';
import React from 'react';

export interface UserInterface {
  Username: string;
  Attributes: {
    Name: UserAttributeType;
    Value: string;
  }[];
  UserAttributes: {
    Name: UserAttributeType;
    Value: string;
  }[];
  UserCreateDate: string;
  UserLastModifiedDate: string;
  Enabled: boolean;
  UserStatus: string;
}

export interface GroupInterface {
  GroupName: string;
  UserPoolId: string;
  LastModifiedDate: string;
  CreationDate: string;
  Description?: undefined;
}

export type CustomUserType = {
  username: string;
  email: string;
  email_verified: boolean;
  name: string;
  contact_person: string;
  contact_address: string;
  phone: string;
  date_joined: string;
  description: string;
  cash_handler: boolean;
  staff: boolean;
  agent: boolean;
  active: boolean;
  admin: boolean;
  data_entry: boolean;
  user_status: string;
  permission_level: number;
};

const isAdmin = async (username: string) => {
  const adminGroupMembers = await listUsersInGroup('Admin');
  return adminGroupMembers.find((user) => user.Username === username) !== undefined;
};

const isDataEntry = async (username: string) => {
  const dataEntryGroupMembers = await listUsersInGroup('DataEntry');
  return dataEntryGroupMembers.find((user) => user.Username === username) !== undefined;
};

export const convertUsers = async (users: UserInterface[]): Promise<CustomUserType[]> => {
  const customUsers: CustomUserType[] = [];
  const adminGroupMembers = await listUsersInGroup('Admin');
  const dataEntryGroupMembers = await listUsersInGroup('DataEntry');
  const isAdmin = (username: string) => {
    return adminGroupMembers.find((user) => user.Username === username) !== undefined;
  };
  const isDataEntry = (username: string) => {
    return dataEntryGroupMembers.find((user) => user.Username === username) !== undefined;
  };
  for (const user of users) {
    const convertedUser = firstLevelConvert(user);
    convertedUser.admin = isAdmin(user.Username);
    convertedUser.data_entry = isDataEntry(user.Username);
    if (convertedUser) customUsers.push(convertedUser);
  }
  return customUsers;
};

export const convertUser = async (user: UserInterface): Promise<CustomUserType | undefined> => {
  if (!user) return undefined;
  const attributesName = user.Attributes ? 'Attributes' : 'UserAttributes';
  const convertedUser: CustomUserType = firstLevelConvert(user);
  convertedUser.admin = await isAdmin(user.Username);
  convertedUser.data_entry = await isDataEntry(user.Username);
  return convertedUser;
  // return {
  //   username: user.Username,
  //   email: user[attributesName].find((attr) => attr.Name === 'email')?.Value || '',
  //   email_verified: user[attributesName].find((attr) => attr.Name === 'email_verified')?.Value === 'true',
  //   name: user[attributesName].find((attr) => attr.Name === 'name')?.Value || '',
  //   contact_person: user[attributesName].find((attr) => attr.Name === 'custom:contact_person')?.Value || '',
  //   contact_address: user[attributesName].find((attr) => attr.Name === 'custom:contact_address')?.Value || '',
  //   phone: user[attributesName].find((attr) => attr.Name === 'custom:phone')?.Value || '',
  //   date_joined: user[attributesName].find((attr) => attr.Name === 'custom:date_joined')?.Value || '',
  //   description: user[attributesName].find((attr) => attr.Name === 'custom:description')?.Value || '',
  //   cash_handler: user[attributesName].find((attr) => attr.Name === 'custom:cash_handler')?.Value === '1',
  //   staff: user[attributesName].find((attr) => attr.Name === 'custom:staff')?.Value === '1',
  //   agent: user[attributesName].find((attr) => attr.Name === 'custom:agent')?.Value === '1',
  //   active: user[attributesName].find((attr) => attr.Name === 'custom:active')?.Value === '1',
  //   admin: await isAdmin(user.Username),
  //   data_entry: await isDataEntry(user.Username),
  // };
};

const firstLevelConvert = (user: UserInterface): CustomUserType => {
  const attributesName = user.Attributes ? 'Attributes' : 'UserAttributes';
  return {
    username: user.Username,
    email: user[attributesName].find((attr) => attr.Name === 'email')?.Value || '',
    email_verified:
      user[attributesName].find((attr) => attr.Name === 'email_verified')?.Value === 'true',
    name: user[attributesName].find((attr) => attr.Name === 'name')?.Value || '',
    contact_person:
      user[attributesName].find((attr) => attr.Name === 'custom:contact_person')?.Value || '',
    contact_address:
      user[attributesName].find((attr) => attr.Name === 'custom:contact_address')?.Value || '',
    phone: user[attributesName].find((attr) => attr.Name === 'custom:phone')?.Value || '',
    date_joined:
      user[attributesName].find((attr) => attr.Name === 'custom:date_joined')?.Value || '',
    description:
      user[attributesName].find((attr) => attr.Name === 'custom:description')?.Value || '',
    cash_handler:
      user[attributesName].find((attr) => attr.Name === 'custom:cash_handler')?.Value === '1',
    staff: user[attributesName].find((attr) => attr.Name === 'custom:staff')?.Value === '1',
    agent: user[attributesName].find((attr) => attr.Name === 'custom:agent')?.Value === '1',
    active: user[attributesName].find((attr) => attr.Name === 'custom:active')?.Value === '1',
    permission_level: parseInt(
      user[attributesName].find((attr) => attr.Name === 'custom:permission_level')?.Value || '0'
    ),
    user_status: user.UserStatus,
    admin: false,
    data_entry: false,
  };
};

const listGroups = async (limit?: number) => {
  const groups: GroupInterface[] = [];
  let apiName = 'AdminQueries';
  let path = '/listGroups';
  let nextToken: string | undefined | null = null;
  let options = {
    queryStringParameters: {
      limit: limit,
      token: nextToken as string | undefined | null,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  do {
    const { NextToken, ...rest } = await API.get(apiName, path, options);
    if (!rest.Groups) break;
    groups.push(...rest.Groups);
    nextToken = NextToken;
    options.queryStringParameters.token = nextToken;
  } while (nextToken);
  return groups;
};

const listUsers = async (limit?: number) => {
  const users: UserInterface[] = [];
  let apiName = 'AdminQueries';
  let path = '/listUsers';
  let nextToken: string | undefined | null = null;
  let options = {
    queryStringParameters: {
      limit: limit,
      token: nextToken as string | undefined | null,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  do {
    const { NextToken, ...rest } = await API.get(apiName, path, options);
    if (!rest.Users) break;
    users.push(...rest.Users);
    nextToken = NextToken;
    options.queryStringParameters.token = nextToken;
  } while (nextToken);

  // console.log(users);
  return users;
};

const getUser = async (userName: string) => {
  let apiName = 'AdminQueries';
  let path = '/getUser';
  let options = {
    queryStringParameters: {
      username: userName,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const response = await API.get(apiName, path, options);
  // console.log(response);
  return response;
};

const listGroupsForUser = async (userName: string, limit?: number) => {
  const groups: GroupInterface[] = [];
  let apiName = 'AdminQueries';
  let path = '/listGroupsForUser';
  let nextToken: string | undefined | null = null;
  let options = {
    queryStringParameters: {
      username: userName,
      limit: limit,
      token: nextToken as string | undefined | null,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  do {
    const { NextToken, ...rest } = await API.get(apiName, path, options);
    if (!rest.Groups) break;
    groups.push(...rest.Groups);
    nextToken = NextToken;
    options.queryStringParameters.token = nextToken;
  } while (nextToken);
  // console.log(rest);
  return groups;
};

const listUsersInGroup = async (groupName: string, limit?: number) => {
  const users: UserInterface[] = [];

  let apiName = 'AdminQueries';
  let path = '/listUsersInGroup';
  let nextToken: string | undefined | null = null;
  let options = {
    queryStringParameters: {
      groupname: groupName,
      limit: limit,
      token: nextToken as string | undefined | null,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  do {
    const { NextToken, ...rest } = await API.get(apiName, path, options);
    if (!rest.Users) break;
    users.push(...rest.Users);
    nextToken = NextToken;
    options.queryStringParameters.token = nextToken;
  } while (nextToken);
  // console.log(rest);
  return users;
};

const addUserToGroup = async (userName: string, groupName: string) => {
  let apiName = 'AdminQueries';
  let path = '/addUserToGroup';
  let options = {
    body: {
      username: userName,
      groupname: groupName,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const response = await API.post(apiName, path, options);
  // console.log(response);
  return response;
};

const removeUserFromGroup = async (userName: string, groupName: string) => {
  let apiName = 'AdminQueries';
  let path = '/removeUserFromGroup';
  let options = {
    body: {
      username: userName,
      groupname: groupName,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const response = await API.post(apiName, path, options);
  // console.log(response);
  return response;
};

const createGroup = async (groupName: string) => {
  let apiName = 'AdminQueries';
  let path = '/createGroup';
  let options = {
    body: {
      groupname: groupName,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const response = await API.post(apiName, path, options);
  // console.log(response);
  return response;
};

const deleteGroup = async (groupName: string) => {
  let apiName = 'AdminQueries';
  let path = '/deleteGroup';
  let options = {
    body: {
      groupname: groupName,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const response = await API.post(apiName, path, options);
  // console.log(response);
  return response;
};

const createUser = async (
  email: string,
  Name: string,
  temporaryPassword: string,
  messageAction: 'RESEND' | 'SUPPRESS'
) => {
  try {
    let apiName = 'AdminQueries';
    let path = '/createUser';
    let options = {
      body: {
        email,
        Name,
        TemporaryPassword: temporaryPassword,
        MessageAction: messageAction,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const response = await API.post(apiName, path, options);
    return response;
  } catch (err) {
    throw new Error('Error in creating user.');
  }
};

const deleteUser = async (userName: string) => {
  let apiName = 'AdminQueries';
  let path = '/deleteUser';
  let options = {
    body: {
      username: userName,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const response = await API.post(apiName, path, options);
  // console.log(response);
  return response;
};

export type UserAttributeType =
  | 'name'
  | 'email'
  | 'email_verified'
  | 'custom:active' // 0 | 1
  | 'custom:agent' // 0 | 1
  | 'custom:cash_handler' // 0 | 1
  | 'custom:staff' // 0 | 1
  | 'custom:contact_address' // string 1024
  | 'custom:contact_person' // string 1024
  | 'custom:date_joined' // string 1024
  | 'custom:description' // string 2048
  | 'custom:phone' // string 1024
  | 'custom:permission_level'; // 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export type UserAttributes = { Name: UserAttributeType; Value: string }[];
// type UserAttributes = [
//   { 'custom:active': string },
//   { 'custom:agent': string },
//   { 'custom:cash_handler': string },
//   { 'custom:contact_address': string },
//   { 'custom:contact_person': string },
//   { 'custom:date_joined': string },
//   { 'custom:description': string },
//   { 'custom:phone': string },
//   { 'custom:staff': string }
// ];
const updateUserAttributes = async (userName: string, attributes: UserAttributes) => {
  let apiName = 'AdminQueries';
  let path = '/updateUserAttributes';
  let options = {
    body: {
      username: userName,
      attributes: attributes,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const response = await API.post(apiName, path, options);
  // console.log(response);
  return response;
};

export type PermissionType = {
  [Property in Exclude<OperationType, 'PRINT_INVOICE'>]: boolean;
};

// export const defaultPermission: PermissionType = {
//   CREATE: false,
//   UPDATE: false,
//   DELETE: false,
//   LOCK: false,
//   UNLOCK: false,
// };

const getPermissionInfo = async (user: CustomUserType | undefined, table: DataTableType) => {
  const permission_object = {
    CREATE: false,
    UPDATE: false,
    DELETE: false,
    LOCK: false,
    UNLOCK: false,
  };

  if (!user) return permission_object;
  const permission_levels = await DataStore.query(Permission, (p) => p.model.eq(table));
  const user_permission_level = user.permission_level;
  // console.log(required_permission_levels);
  Object.keys(OperationType).forEach((key) => {
    if (key === 'PRINT_INVOICE') return;
    const required_permission_level =
      permission_levels.find((p) => p.operation === OperationType[key as OperationType])
        ?.required_permission_level || 100;
    // console.log(table);
    // console.log(required_permission_level);
    const permission_granted = user_permission_level >= required_permission_level;
    permission_object[key as Exclude<OperationType, 'PRINT_INVOICE'>] = permission_granted;
  });
  return permission_object;
};

export {
  isAdmin,
  isDataEntry,
  listGroups,
  listUsers,
  getUser,
  listGroupsForUser,
  listUsersInGroup,
  addUserToGroup,
  removeUserFromGroup,
  createGroup,
  deleteGroup,
  createUser,
  deleteUser,
  updateUserAttributes,
  getPermissionInfo,
};

import { GraphQLQuery, GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import {
  CashHandler,
  CreateCashHandlerInput,
  CreateCashHandlerMutation,
  DeleteCashHandlerMutation,
  GetCashHandlerQuery,
  ListCashHandlersQuery,
  ListCashHandlersQueryVariables,
  OnCreateCashHandlerSubscription,
  OnDeleteCashHandlerSubscription,
  OnUpdateCashHandlerSubscription,
  UpdateCashHandlerInput,
  UpdateCashHandlerMutation,
} from '../../API';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';

import { compact } from 'lodash';
import { useEffect, useState } from 'react';

export const getCashHandler = async (id: string) => {
  try {
    const cash_handlers = await API.graphql<GraphQLQuery<GetCashHandlerQuery>>({
      query: queries.getCashHandler,
      variables: { id: id },
    });
    if (cash_handlers.data?.getCashHandler?._deleted) return undefined;
    return cash_handlers.data?.getCashHandler;
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in getCashHandler: ', error.message ?? '');
    }
  }
};

export const listCashHandlers = async () => {
  const cash_handlers: CashHandler[] = [];
  const variables: ListCashHandlersQueryVariables = { limit: 100, filter: { _deleted: { attributeExists: false } } };

  let nextToken: string | undefined | null = null;
  do {
    const result = await API.graphql<GraphQLQuery<ListCashHandlersQuery>>({
      query: queries.listCashHandlers,
      variables,
    });
    if (!result.data?.listCashHandlers?.items) break;
    const { items, nextToken: nt } = result.data.listCashHandlers;
    cash_handlers.push(...compact(items));
    nextToken = nt;
    variables.nextToken = nextToken;
  } while (nextToken);
  return cash_handlers;
};

export const createCashHandler = async (input: CreateCashHandlerInput) => {
  try {
    const result = await API.graphql<GraphQLQuery<CreateCashHandlerMutation>>({
      query: mutations.createCashHandler,
      variables: { input },
    });
    return result.data?.createCashHandler as CashHandler;
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in createCashHandler: ', error.message ?? '');
    }
  }
};

export const updateCashHandler = async (input: UpdateCashHandlerInput) => {
  try {
    const result = await API.graphql<GraphQLQuery<UpdateCashHandlerMutation>>({
      query: mutations.updateCashHandler,
      variables: { input },
    });
    return result.data?.updateCashHandler as CashHandler;
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in updateCashHandler: ', error.message ?? '');
    }
  }
};

export const deleteCashHandler = async (id: string, version: number) => {
  try {
    const input = { id, _version: version };
    const result = await API.graphql<GraphQLQuery<DeleteCashHandlerMutation>>({
      query: mutations.deleteCashHandler,
      variables: { input },
    });
    return result.data?.deleteCashHandler as CashHandler;
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in deleteCashHandler: ', error.message ?? '');
    }
  }
};

export const useCashHandlerSubscription = () => {
  const [cash_handlers, setCashHandlers] = useState<CashHandler[]>([]);

  const fetchCashHandlers = async () => {
    const cash_handlers = await listCashHandlers();
    setCashHandlers(cash_handlers.sort((a, b) => a.short_name.localeCompare(b.short_name)));
  };

  useEffect(() => {
    fetchCashHandlers();
  }, []);

  useEffect(() => {
    const createSub = API.graphql<GraphQLSubscription<OnCreateCashHandlerSubscription>>({
      query: subscriptions.onCreateCashHandler,
    }).subscribe({
      next: () => fetchCashHandlers(),
      error: (error) => console.warn(error),
    });

    const updateSub = API.graphql<GraphQLSubscription<OnUpdateCashHandlerSubscription>>({
      query: subscriptions.onUpdateCashHandler,
    }).subscribe({
      next: () => fetchCashHandlers(),
      error: (error) => console.warn(error),
    });

    const deleteSub = API.graphql<GraphQLSubscription<OnDeleteCashHandlerSubscription>>({
      query: subscriptions.onDeleteCashHandler,
    }).subscribe({
      next: () => fetchCashHandlers(),
      error: (error) => console.warn(error),
    });

    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      deleteSub.unsubscribe();
    };
  }, []);

  return { cash_handlers };
};

import { DataStore, Predicates, SortDirection } from 'aws-amplify';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Customer, Job } from '../../models';
import { Button, Divider, Input, InputRef, Space, Table, Tag, Card, Typography } from 'antd';
import { ColumnType, ColumnsType, TableProps } from 'antd/es/table';
import {
  BorderOutlined,
  CheckOutlined,
  CheckSquareTwoTone,
  FilterTwoTone,
  LockFilled,
  SearchOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { RouteType } from '../../models';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from '../Users/AuthContext';
import { useGetPermissionInfo } from '../Users/adminHooks';
import { DataTableType } from '../../API';
import NetworkIndicator from '../../components/NetworkIndicator';

const { Title } = Typography;

const Jobs = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Job>>({});
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({} as any);

  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.JOB);

  const searchInput = useRef<InputRef>(null);
  const navigate = useNavigate();
  useDocumentTitle('Jobs');

  useEffect(() => {
    const subscription = DataStore.observeQuery(Job, Predicates.ALL, {
      sort: (s) => s.createdAt(SortDirection.DESCENDING),
    }).subscribe((snapshot) => {
      const { items, isSynced } = snapshot;
      if (isSynced) {
        setJobs(items);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = DataStore.observeQuery(Customer).subscribe((snapshot) => {
      const { items, isSynced } = snapshot;
      if (isSynced) {
        setCustomers(items);
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  // const handleSearch = (selectedKeys: string[], dataIndex: keyof LazyJob, close: () => void) => {
  //   console.log(selectedKeys);
  //   const selectedKey = selectedKeys[selectedKeys.length - 1];
  //   setFilteredInfo((prev) => {
  //     if (!selectedKey) return prev;
  //     if (typeof selectedKey === 'string' && selectedKey.trim().length === 0) return prev;
  //     const newFilterArray = prev[dataIndex]
  //       ? // @ts-ignore
  //         [...new Set([...prev[dataIndex], selectedKey.trim()])]
  //       : [selectedKey];
  //     return {
  //       ...prev,
  //       [dataIndex]: newFilterArray,
  //     };
  //   });
  //   close();
  // };
  const getColumnSearchProps = (dataIndex: keyof Job): ColumnType<Job> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex.split('_').join(' ')}`}
          allowClear
          value={selectedKeys[selectedKeys.length - 1]}
          onChange={(e) => {
            const value = e.target.value;
            // if (value.length === 0) return;
            const prevFilter = (filteredInfo[dataIndex] || []) as string[];
            setSelectedKeys([...new Set([...prevFilter, value])]);
          }}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8 }}
        />
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type='primary'
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? 'primary' : undefined }} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.current?.select();
        }, 100);
      }
    },
  });

  const clearFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
    localStorage.removeItem('jobsFilters');
    localStorage.removeItem('jobsSorter');
  };

  useEffect(() => {
    const filters = localStorage.getItem('jobsFilters');
    const sorter = localStorage.getItem('jobsSorter');
    const pagination = localStorage.getItem('jobsPagination');
    if (filters) setFilteredInfo(JSON.parse(filters));
    if (sorter) setSortedInfo(JSON.parse(sorter));
    if (pagination) setPaginationInfo(JSON.parse(pagination));
  }, []);

  const handleTableChange: TableProps<Job>['onChange'] = (pagination, filters, sorter) => {
    // console.log(filters);
    // console.log('Various parameters', pagination, filters, sorter);
    // console.log('Filters:', filters);
    // console.log('Sorter:', sorter);
    // console.log('Pagination:', pagination);
    localStorage.setItem('jobsFilters', JSON.stringify(filters));
    localStorage.setItem('jobsSorter', JSON.stringify(sorter));
    localStorage.setItem('jobsPagination', JSON.stringify(pagination));
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<Job>);
    setPaginationInfo(pagination);
  };

  const columns: ColumnsType<Job> = [
    {
      title: 'Job Code',
      dataIndex: 'job_code',
      key: 'job_code',
      width: 200,
      filteredValue: filteredInfo.job_code || null,
      ...getColumnSearchProps('job_code'),
      onFilter: (value, record) =>
        record.job_code.toLowerCase().includes((value as string).toLowerCase()) || false,
      render: (text) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={(filteredInfo.job_code as string[]) || []}
            autoEscape
            textToHighlight={text}
          />
          <FilterTwoTone
            twoToneColor='lightgray'
            onClick={() => {
              setFilteredInfo((prev) => {
                localStorage.setItem(
                  'jobsFilters',
                  JSON.stringify({
                    ...prev,
                    job_code: prev.job_code ? [...new Set([...prev.job_code, text])] : [text],
                  })
                );
                return {
                  ...prev,
                  job_code: prev.job_code ? [...new Set([...prev.job_code, text])] : [text],
                };
              });
              // localStorage.setItem('jobsFilters', JSON.stringify(filteredInfo));
            }}
          />
        </div>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      showSorterTooltip: false,
      sorter: (a, b) => {
        // @ts-ignore
        const customerA = customers.find((c) => c.id === a.customer_id);
        // @ts-ignore
        const customerB = customers.find((c) => c.id === b.customer_id);
        return customerA?.customer_name.localeCompare(customerB?.customer_name || '') || 0;
      },
      sortOrder: sortedInfo.columnKey === 'customer' ? sortedInfo.order : null,
      filteredValue: filteredInfo.customer || null,
      ...getColumnSearchProps('customer'),
      onFilter: (value, record) => {
        // @ts-ignore
        const customer = customers.find((c) => c.id === record.customer_id);
        return (
          customer?.customer_name.toLowerCase().includes((value as string).toLowerCase()) || false
        );
      },
      render: (_, record) => {
        // @ts-ignore
        const customer = customers.find((c) => c.id === record.customer_id);
        const customerName = customer?.customer_name || '';
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={(filteredInfo.customer as string[]) || []}
              autoEscape
              textToHighlight={customerName}
            />
            <FilterTwoTone
              twoToneColor='lightgray'
              onClick={() => {
                setFilteredInfo((prev) => {
                  localStorage.setItem(
                    'jobsFilters',
                    JSON.stringify({
                      ...prev,
                      customer: prev.customer
                        ? [...new Set([...prev.customer, customerName])]
                        : [customerName],
                    })
                  );
                  return {
                    ...prev,
                    customer: prev.customer
                      ? [...new Set([...prev.customer, customerName])]
                      : [customerName],
                  };
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Route',
      dataIndex: 'route',
      key: 'route',
      width: 120,
      filters: [
        ...Object.keys(RouteType).map((key) => ({ text: key, value: key })),
        { text: 'None', value: 'None' },
      ],
      filteredValue: filteredInfo.route || null,
      onFilter: (value, record) => (value === 'None' ? !record.route : record.route === value),
    },
    {
      title: 'Created On',
      dataIndex: 'date_of_creation',
      key: 'date_of_creation',
      showSorterTooltip: false,
      width: 150,
      sorter: (a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA.getTime() - dateB.getTime();
      },
      sortOrder: sortedInfo.columnKey === 'date_of_creation' ? sortedInfo.order : null,
      filters: [
        { text: 'Today', value: 'today' },
        { text: 'Yesterday', value: 'yesterday' },
        { text: 'This week', value: 'this week' },
        { text: 'Last week', value: 'last week' },
        { text: 'This month', value: 'this month' },
        { text: 'Last month', value: 'last month' },
        { text: 'This quarter', value: 'this quarter' },
        { text: 'Last quarter', value: 'last quarter' },
      ],
      filteredValue: filteredInfo.date_of_creation || null,
      onFilter: (value, record) => {
        const date = new Date(record.date_of_creation);
        const today = new Date();
        switch (value) {
          case 'today':
            return date.toDateString() === today.toDateString();
          case 'yesterday':
            return (
              date.toDateString() ===
              new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).toDateString()
            );
          case 'this week':
            return (
              date >=
              new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
            );
          case 'last week':
            return (
              date >=
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - today.getDay() - 7
                ) &&
              date <=
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - today.getDay() - 1
                )
            );
          case 'this month':
            return (
              date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
            );
          case 'last month':
            return (
              date.getMonth() === today.getMonth() - 1 && date.getFullYear() === today.getFullYear()
            );
          case 'this quarter':
            return (
              date >= new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3), 1) &&
              date <=
                new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3) + 2, 31)
            );
          case 'last quarter':
            return (
              date >=
                new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3) - 3, 1) &&
              date <=
                new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3) - 1, 31)
            );
          default:
            return false;
        }
      },
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 100,
      align: 'center',
      render: (value, record) => (value === true ? <CheckSquareTwoTone /> : <BorderOutlined />),
      filters: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      filteredValue: filteredInfo.is_active || null,
      onFilter: (value, record) =>
        value === 'yes' ? record.is_active === true : !record.is_active,
    },
    {
      title: 'Delivered On',
      dataIndex: 'date_of_delivery',
      key: 'date_of_delivery',
      showSorterTooltip: false,
      width: 150,
      sorter: (a, b) => {
        if (!a.date_of_delivery) return 1;
        if (!b.date_of_delivery) return -1;
        const dateA = new Date(a.date_of_delivery);
        const dateB = new Date(b.date_of_delivery);
        return dateA.getTime() - dateB.getTime();
      },
      sortOrder: sortedInfo.columnKey === 'date_of_delivery' ? sortedInfo.order : null,
      filters: [
        { text: 'Today', value: 'today' },
        { text: 'Yesterday', value: 'yesterday' },
        { text: 'This week', value: 'this week' },
        { text: 'Last week', value: 'last week' },
        { text: 'This month', value: 'this month' },
        { text: 'Last month', value: 'last month' },
        { text: 'This quarter', value: 'this quarter' },
        { text: 'Last quarter', value: 'last quarter' },
      ],
      filteredValue: filteredInfo.date_of_delivery || null,
      onFilter: (value, record) => {
        if (!record.date_of_delivery) return false;
        const date = new Date(record.date_of_delivery);
        const today = new Date();
        switch (value) {
          case 'today':
            return date.toDateString() === today.toDateString();
          case 'yesterday':
            return (
              date.toDateString() ===
              new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).toDateString()
            );
          case 'this week':
            return (
              date >=
              new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
            );
          case 'last week':
            return (
              date >=
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - today.getDay() - 7
                ) &&
              date <=
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - today.getDay() - 1
                )
            );
          case 'this month':
            return (
              date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
            );
          case 'last month':
            return (
              date.getMonth() === today.getMonth() - 1 && date.getFullYear() === today.getFullYear()
            );
          case 'this quarter':
            return (
              date >= new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3), 1) &&
              date <=
                new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3) + 2, 31)
            );
          case 'last quarter':
            return (
              date >=
                new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3) - 3, 1) &&
              date <=
                new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3) - 1, 31)
            );
          default:
            return false;
        }
      },
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
      width: 100,
      align: 'center',
      render: (value) => (value ? <CheckOutlined /> : ''),
      filters: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      filteredValue: filteredInfo.invoice_id || null,
      onFilter: (value, record) =>
        value === 'yes' ? record.invoice_id !== null : !record.invoice_id,
    },
    {
      title: 'Locked',
      dataIndex: 'archived',
      key: 'archived',
      width: 100,
      align: 'center',
      render: (value, record) =>
        value === true ? <LockFilled style={{ color: 'darkred' }} /> : '',
      filters: [
        { text: 'Locked', value: 'yes' },
        { text: 'Unlocked', value: 'no' },
      ],
      filteredValue: filteredInfo.archived || null,
      onFilter: (value, record) => (value === 'yes' ? record.archived === true : !record.archived),
    },
    {
      title: 'Actions',
      width: 100,
      render: (_, record) => {
        return (
          <Button type='link' size='middle' onClick={() => goToJob(record.id)}>
            Open
          </Button>
        );
      },
    },
  ];

  const goToJob = (job_id: string) => {
    navigate(`/jobs/${job_id}`);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Title level={4} style={{ color: 'darkred' }}>
              Jobs
            </Title>
          </Space>
        }
        style={{ width: '100%', height: 'fit-content' }}
        extra={
          <Space>
            <NetworkIndicator />
          </Space>
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            marginTop: -15,
          }}
        >
          <div>
            {Object.keys(filteredInfo).map((key) => {
              const values = filteredInfo[key];
              if (values === null || values.length === 0) return null;
              const tabs = values.map((v) => {
                return (
                  <Tag
                    key={key + v}
                    closable
                    color='lime'
                    onClose={() => {
                      setFilteredInfo((prev) => {
                        localStorage.setItem(
                          'jobsFilters',
                          JSON.stringify({
                            ...prev,
                            [key]: prev[key]?.filter((k) => k !== v) || [],
                          })
                        );
                        return {
                          ...prev,
                          [key]: prev[key]?.filter((k) => k !== v) || [],
                        };
                      });
                    }}
                  >
                    {/* key: {key} value: {v.toString()} */}
                    {key === 'invoice_id'
                      ? 'Invoice'
                      : key === 'is_active'
                      ? 'Active'
                      : key === 'archived'
                      ? 'Locked'
                      : (key[0].toUpperCase() + key.slice(1)).split('_').join(' ')}{' '}
                    : {v.toString()}
                  </Tag>
                );
              });
              return tabs;
            })}
          </div>
          <Space style={{ marginRight: 20 }}>
            <Button
              type='link'
              size='middle'
              hidden={!Object.values(filteredInfo).some((value) => value !== null)}
              style={{ margin: 0, padding: 0 }}
              onClick={clearFilters}
            >
              clear all filters
            </Button>
            <Divider type='vertical' />
            <Button onClick={() => navigate('new')} disabled={!permission.CREATE}>
              Add New Job
            </Button>
          </Space>
        </div>
        <Table
          dataSource={jobs}
          rowKey={'id'}
          columns={columns}
          size='small'
          style={{ paddingLeft: 10, paddingRight: 10 }}
          loading={jobs.length <= 0}
          onChange={handleTableChange}
          pagination={{
            current: paginationInfo.current,
            pageSize: paginationInfo.pageSize || 10,
            showTotal: (total) => `Total ${total.toLocaleString()} job${total > 1 ? 's' : ''}`,
          }}
        />
      </Card>
    </div>
  );
};

export default Jobs;

import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { CustomUserType, PermissionType, getPermissionInfo, listUsersInGroup } from './adminFunctions';
import { DataTableType } from '../../API';

export const useGetPermissionInfo = (user: CustomUserType | undefined, table: DataTableType) => {
  const [permission, setPermission] = useState<PermissionType>({
    CREATE: false,
    UPDATE: false,
    DELETE: false,
    LOCK: false,
    UNLOCK: false,
  });

  useEffect(() => {
    (async () => {
      const permissionInfo = await getPermissionInfo(user, table);
      setPermission(permissionInfo);
    })();
  }, [user, table]);
  return { permission };
};

export const useIsAdmin = (username: string | undefined) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!username) return;

    const checkAdmin = async () => {
      const usersOfAdminGroup = await listUsersInGroup('Admin');

      const is_admin = usersOfAdminGroup.find((user) => user.Username === username) !== undefined;
      setIsAdmin(is_admin);
    };
    checkAdmin();
  }, [username]);

  return { isAdmin };
};

export const useIsDataEntry = (username: string | undefined) => {
  const [isDataEntry, setIsDataEntry] = useState(false);

  useEffect(() => {
    if (!username) return;

    const checkDataEntry = async () => {
      const usersOfDataEntryGroup = await listUsersInGroup('DataEntry');

      const is_data_entry = usersOfDataEntryGroup.find((user) => user.Username === username) !== undefined;
      setIsDataEntry(is_data_entry);
    };
    checkDataEntry();
  }, [username]);

  return { isDataEntry };
};

import React, { ReactNode, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Space,
  Typography,
  DatePicker,
  Popconfirm,
  Select,
} from 'antd';
import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import NetworkIndicator from '../../components/NetworkIndicator';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomUserType,
  UserAttributes,
  UserInterface,
  addUserToGroup,
  convertUser,
  deleteUser,
  getUser,
  removeUserFromGroup,
  updateUserAttributes,
} from './adminFunctions';
import { useGetPermissionInfo, useIsAdmin } from './adminHooks';
import { useAuthenticator } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import { Auth } from 'aws-amplify';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from './AuthContext';
import { DataTableType } from '../../API';
const { Title } = Typography;

const ListItem = ({ label, content }: { label: ReactNode; content: ReactNode }) => {
  return (
    <List.Item style={{ padding: '10px 0' }}>
      <Row style={{ width: '100%', minHeight: 22 }}>
        <Col span={8}>
          <Title level={5} style={{ marginBottom: 0 }}>
            {label}
          </Title>
        </Col>
        <Col span={16} style={{ whiteSpace: 'pre-wrap' }}>
          {content}
        </Col>
      </Row>
    </List.Item>
  );
};

const User = () => {
  const { username } = useParams<{ username: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [user, setUser] = React.useState<CustomUserType | undefined>(undefined);

  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.USER);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [reload, setReload] = React.useState(false);

  useDocumentTitle(user?.name || 'User');

  useEffect(() => {
    if (!username) return;
    const get_user = async () => {
      const user = (await getUser(username)) as UserInterface;
      setUser(await convertUser(user));
      // console.log(user);
    };
    get_user();
  }, [username, reload]);

  const reloadData = () => {
    setReload(!reload);
  };

  const onFormSave = async (values: any) => {
    if (!currentUser?.admin && permission.UPDATE) return;
    if (!user) return;
    try {
      const { date_joined, ...rest } = values;
      const attributes = Object.entries(rest).map(([key, value]) => ({
        Name: key === 'name' ? key : `custom:${key}`,
        Value:
          typeof value === 'boolean'
            ? value
              ? '1'
              : '0'
            : typeof value === 'number'
            ? value.toString()
            : value,
      })) as UserAttributes;
      if (date_joined)
        attributes.push({ Name: 'custom:date_joined', Value: date_joined.format('YYYY-MM-DD') });
      // console.log(attributes);
      await updateUserAttributes(user.username, attributes);
      closeModal();
      reloadData();
    } catch (error) {
      console.log(error);
    }
  };

  const addUserToAdminGroup = async (username: string) => {
    if (!currentUser?.admin && permission.UPDATE) return;
    try {
      await addUserToGroup(username, 'Admin');
      reloadData();
    } catch (error) {
      console.log(error);
    }
  };

  const addUserToDataEntryGroup = async (username: string) => {
    if (!currentUser?.admin && permission.UPDATE) return;
    try {
      await addUserToGroup(username, 'DataEntry');
      reloadData();
    } catch (error) {
      console.log(error);
    }
  };

  const removeUserFromAdminGroup = async (username: string) => {
    if (!currentUser?.admin && permission.UPDATE) return;
    try {
      await removeUserFromGroup(username, 'Admin');
      reloadData();
    } catch (error) {
      console.log(error);
    }
  };

  const removeUserFromDataEntryGroup = async (username: string) => {
    if (!currentUser?.admin && permission.UPDATE) return;
    try {
      await removeUserFromGroup(username, 'DataEntry');
      reloadData();
    } catch (error) {
      console.log(error);
    }
  };

  const delete_user = async (username: string) => {
    try {
      await deleteUser(username);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const openModal = () => {
    if (!user) return;
    const { date_joined, ...rest } = user;
    form.setFieldsValue({ ...rest, date_joined: date_joined ? dayjs(date_joined) : null });
    setModalOpen(true);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size='middle' align='start'>
            <Button
              type='primary'
              shape='circle'
              icon={<LeftOutlined />}
              size='middle'
              onClick={() => navigate(-1)}
            />
            <Title level={4} style={{ color: 'darkred' }}>
              {`User ${user?.name || ''} (${user?.email || ''})`}
            </Title>
          </Space>
        }
        style={{ width: 'max(calc(100vw - 800px), 800px)', height: 'fit-content' }}
        extra={
          <Space>
            <NetworkIndicator />
          </Space>
        }
      >
        <List>
          <ListItem
            key='Email'
            label={<span style={{ color: 'blue', marginBottom: 0 }}>Email</span>}
            content={<span style={{ color: 'blue', marginBottom: 0 }}>{user?.email || ''}</span>}
          />
          <ListItem
            key='Email Verified'
            label={<span style={{ color: 'blue', marginBottom: 0 }}>Email Verified</span>}
            content={
              <span style={{ color: 'blue', marginBottom: 0 }}>
                {user?.email_verified ? <CheckOutlined /> : ''}
              </span>
            }
          />
          <ListItem key='Name' label='Name' content={user?.name || ''} />
          <ListItem
            key='Contact Person'
            label='Contact Person'
            content={user?.contact_person || ''}
          />
          <ListItem
            key='Contact Address'
            label='Contact Address'
            content={<span style={{ whiteSpace: 'pre-wrap' }}>{user?.contact_address || ''}</span>}
          />
          <ListItem
            key='Contact Number (Phone)'
            label='Contact Number (Phone)'
            content={user?.phone || ''}
          />
          <ListItem key='Join Date' label='Join Date' content={user?.date_joined || ''} />
          <ListItem
            key='Description'
            label='Description'
            content={<span style={{ whiteSpace: 'pre-wrap' }}>{user?.description || ''}</span>}
          />
          {/* <ListItem key='Handle Cash' label='Handle Cash' content={user?.cash_handler ? <CheckOutlined /> : ''} /> */}
          {/* <ListItem key='Staff' label='Staff' content={user?.staff ? <CheckOutlined /> : ''} /> */}
          {/* <ListItem key='Agent' label='Agent' content={user?.agent ? <CheckOutlined /> : ''} /> */}
          <ListItem key='Active' label='Active' content={user?.active ? <CheckOutlined /> : ''} />
          <ListItem
            key='permission_level'
            label='Permission Level'
            content={user?.permission_level}
          />
          <ListItem
            key='Admin'
            label='Admin'
            content={
              <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{user?.admin ? <CheckOutlined /> : ''}</span>
                <Popconfirm
                  title={`Are you sure you want to ${
                    user?.admin ? 'remove user from' : 'add user to'
                  } Admin Group?`}
                  onConfirm={() => {
                    if (!user) return;
                    if (user.admin) {
                      removeUserFromAdminGroup(user.username);
                    } else {
                      addUserToAdminGroup(user.username);
                    }
                  }}
                  okText='Yes'
                  cancelText='No'
                  disabled={!(currentUser?.admin && permission.UPDATE)}
                >
                  <Button
                    size='small'
                    disabled={!(currentUser?.admin && permission.UPDATE)}
                    hidden={!(currentUser?.admin && permission.UPDATE)}
                  >
                    {user?.admin ? 'Remove User from Admin Group' : 'Add User to Admin Group'}
                  </Button>
                </Popconfirm>
              </Space>
            }
          />
          <ListItem
            key='Data Entry'
            label='Data Entry'
            content={
              <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{user?.data_entry ? <CheckOutlined /> : ''}</span>
                <Popconfirm
                  title={`Are you sure you want to ${
                    user?.data_entry ? 'remove user from' : 'add user to'
                  } Data Entry Group?`}
                  onConfirm={() => {
                    if (!user) return;
                    if (user.data_entry) {
                      removeUserFromDataEntryGroup(user.username);
                    } else {
                      addUserToDataEntryGroup(user.username);
                    }
                  }}
                  okText='Yes'
                  cancelText='No'
                  disabled={!(currentUser?.admin && permission.UPDATE)}
                >
                  <Button
                    size='small'
                    disabled={!(currentUser?.admin && permission.UPDATE)}
                    hidden={!(currentUser?.admin && permission.UPDATE)}
                  >
                    {user?.data_entry
                      ? 'Remove User from Data Entry Group'
                      : 'Add User to Data Entry Group'}
                  </Button>
                </Popconfirm>
              </Space>
            }
          />
          {currentUser?.admin && permission.UPDATE && permission.DELETE && (
            <ListItem
              key='Actions'
              label='Actions'
              content={
                <Space size='middle'>
                  <Button size='small' onClick={openModal}>
                    Edit User Info
                  </Button>
                  {username && (
                    <Popconfirm
                      title={`Are you sure you want to delete user ${user?.email}?`}
                      onConfirm={async () => {
                        await delete_user(username);
                      }}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button size='small' danger>
                        Delete User
                      </Button>
                    </Popconfirm>
                  )}
                </Space>
              }
            />
          )}
        </List>
      </Card>
      <Modal
        title='Update User'
        open={modalOpen}
        okText='Update'
        onOk={form.submit}
        onCancel={closeModal}
        destroyOnClose={true}
        maskClosable={false}
        forceRender={true}
      >
        <Form form={form} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} onFinish={onFormSave}>
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Contact Person' name='contact_person'>
            <Input />
          </Form.Item>
          <Form.Item label='Contact Address' name='contact_address'>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          <Form.Item label='Contact Number (Phone)' name='phone'>
            <Input />
          </Form.Item>
          <Form.Item label='Join Date' name='date_joined'>
            <DatePicker />
          </Form.Item>
          <Form.Item label='Description' name='description'>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          <Form.Item label='Permission Level' name='permission_level'>
            <Select>
              {[0, 1, 2, 3, 4, 5].map((level) => (
                <Select.Option value={level} key={level}>
                  {level}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item label='Handle Cash' name='cash_handler' valuePropName='checked'>
            <Checkbox />
          </Form.Item> */}
          {/* <Form.Item label='Staff' name='staff' valuePropName='checked'>
            <Checkbox />
          </Form.Item> */}
          {/* <Form.Item label='Agent' name='agent' valuePropName='checked'>
            <Checkbox />
          </Form.Item> */}
          <Form.Item label='Active' name='active' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default User;

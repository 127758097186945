import {
  Button,
  Card,
  Form,
  Input,
  InputRef,
  Popconfirm,
  Space,
  Table,
  Typography,
  Modal,
  DatePicker,
  Select,
  InputNumber,
  Tooltip,
} from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Job,
  CashBook as CashBookItem,
  DataTableType,
  OperationType,
  CashHandler,
} from '../../API';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useCashBookSubscription } from './subscriptions';
import { createCashBook, deleteCashBook, getCashBook, getJob, updateCashBook } from './api';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { logActivity } from '../../utilities/logger';
import { ColumnType } from 'antd/es/table';
import { ColumnsType, FilterValue } from 'antd/es/table/interface';
import {
  LeftOutlined,
  SearchOutlined,
  FilterTwoTone,
  LockFilled,
  UnlockFilled,
  ProfileOutlined,
} from '@ant-design/icons';
import NetworkIndicator from '../../components/NetworkIndicator';
import { Account } from '../../models';
import { DataStore } from 'aws-amplify';
import { CustomUserType, convertUser, convertUsers, listUsers } from '../Users/adminFunctions';
import Highlighter from 'react-highlight-words';
import useLogDrawer from '../../custom_hooks/useLogDrawer';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { listCashHandlers } from '../CashHandler/api';
import { AuthContext } from '../Users/AuthContext';
import { useGetPermissionInfo } from '../Users/adminHooks';

const { Title, Text } = Typography;

export const CashBook = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { job_id } = useParams<{ job_id: string }>();

  const [job, setJob] = useState<Job | undefined | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [modalState, setModalState] = useState<'add' | 'edit' | null>(null);
  const [editingCashbookItem, setEditingCashbookItem] = useState<CashBookItem | undefined>(
    undefined
  );

  const { cashBook: cashBookItems } = useCashBookSubscription(job_id);
  const searchInput = useRef<InputRef>(null);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [filteredCashbookItems, setFilteredCashbookItems] = useState<CashBookItem[]>([]);

  const [cashHandlers, setCashHandlers] = useState<CashHandler[]>([]);

  useDocumentTitle(job ? `Cashbook | ${job.job_code}` : 'Cashbook');
  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.CASH_BOOK);

  const { openDrawer, LogDrawer } = useLogDrawer();

  useEffect(() => {
    const fetchAccounts = async () => {
      const accounts = await DataStore.query(Account);
      setAccounts(
        accounts
          .filter((account) => account.is_active === true)
          .sort((a, b) => a.account_name.localeCompare(b.account_name))
      );
    };
    fetchAccounts();
  }, []);

  useEffect(() => {
    const fetchCashHandlers = async () => {
      const cashHandlers = await listCashHandlers();
      setCashHandlers(cashHandlers.sort((a, b) => a.short_name.localeCompare(b.short_name)));
    };
    fetchCashHandlers();
  }, []);

  useEffect(() => {
    if (!job_id) return;
    const fetchJob = async () => {
      const job = await getJob(job_id);
      setJob(job);
    };
    fetchJob();
  }, [job_id]);

  useEffect(() => {
    if (modalState === 'add') {
      form.resetFields();
    }
    if (modalState === 'edit') {
      const { date, ...rest } = editingCashbookItem!;
      const editingCashbookItemWithDate = {
        ...rest,
        date: dayjs(date),
      };
      form.setFieldsValue(editingCashbookItemWithDate);
    }
  }, [modalState, form, editingCashbookItem]);

  useEffect(() => {
    if (cashBookItems.length > 0) {
      setFilteredCashbookItems(cashBookItems);
    }
  }, [cashBookItems]);

  type CashBookItemCopy = Omit<CashBookItem, 'date'> & { date: Dayjs };

  const hasEditingCashbookItemChanged = (
    editingCashbookItem: CashBookItem,
    values: CashBookItemCopy
  ) => {
    const { date, ...rest } = values;
    const cashBookItemWithPotentialChangedValues: CashBookItem = {
      ...rest,
      date: date.format('YYYY-MM-DD'),
    };
    const fieldListToCheck = [
      'date',
      'cash_handler',
      'voucher_number',
      'account_id',
      'description',
      'debit_usd',
      'credit_usd',
      'debit_mmk',
      'credit_mmk',
    ];
    return fieldListToCheck.some(
      // @ts-ignore
      (field) => editingCashbookItem[field] !== cashBookItemWithPotentialChangedValues[field]
    );
  };

  const validateMultipleDebitCredit = () => {
    const values = form.getFieldsValue() as CashBookItemCopy;
    const { debit_usd, credit_usd, debit_mmk, credit_mmk } = values;
    if (debit_usd === 0 && credit_usd === 0 && debit_mmk === 0 && credit_mmk === 0) {
      return Promise.reject('At least one of the debit or credit fields must be non-zero.');
    }
    // check if there are more than one non-zero fields
    const nonZeroFields = [debit_usd, credit_usd, debit_mmk, credit_mmk].filter(
      (value) => value !== 0
    );
    if (nonZeroFields.length > 1) {
      return Promise.reject('Only one of the debit or credit fields can be non-zero.');
    }
    return Promise.resolve();
  };

  const onFormSave = async (values: CashBookItemCopy) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      if (modalState === 'add') {
        if (permission.CREATE === false) return;
        const newCashbookItem = await createCashBook({
          ...values,
          // voucher number is indexed, it cannot be an empty string
          voucher_number:
            values.voucher_number?.trim().length === 0 ? null : values.voucher_number?.trim(),
          job_creation_date: job.date_of_creation,
          created_by: currentUser.email,
          job_id,
          archived: false,
          date: values.date.format('YYYY-MM-DD'),
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.CASH_BOOK,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newCashbookItem,
        });
        // logActivity(user.attributes.email, job, DataTableType.CASH_BOOK, newCashbookItem, OperationType.CREATE);
        setModalState(null);
      } else if (modalState === 'edit') {
        if (permission.UPDATE === false) return;
        if (!editingCashbookItem) return;
        const latestCashbookItem = await getCashBook(editingCashbookItem.id);
        if (!latestCashbookItem) return;
        if (!hasEditingCashbookItemChanged(latestCashbookItem, values)) {
          setModalState(null);
          return;
        }
        const updatedCashbookItem = await updateCashBook({
          ...values,
          // voucher number is indexed, it cannot be an empty string
          voucher_number:
            values.voucher_number?.trim().length === 0 ? null : values.voucher_number?.trim(),
          job_id: job.id,
          id: latestCashbookItem.id,
          _version: latestCashbookItem._version,
          date: values.date.format('YYYY-MM-DD'),
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.CASH_BOOK,
          operation_type: OperationType.UPDATE,
          old_data: latestCashbookItem,
          new_data: updatedCashbookItem,
        });
        // logActivity(user.attributes.email, job, DataTableType.CASH_BOOK, updatedCashbookItem, OperationType.UPDATE);
        setModalState(null);
      }
    } catch (error) {
      console.log('error saving cashbook', error);
    }
  };

  const onDelete = async (cashbookItem: CashBookItem) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    if (permission.DELETE === false) return;
    try {
      const deletedCashbookItem = await deleteCashBook(cashbookItem.id, cashbookItem._version);
      await logActivity({
        user_email: currentUser.email,
        job,
        model_type: DataTableType.CASH_BOOK,
        operation_type: OperationType.DELETE,
        old_data: deletedCashbookItem,
        new_data: null,
      });
      // logActivity(user.attributes.email, job, DataTableType.CASH_BOOK, deletedCashbookItem, OperationType.DELETE);
    } catch (error) {
      console.log('error deleting cashbook', error);
    }
  };

  const getColumnSearchProps = (dataIndex: keyof CashBookItem): ColumnType<CashBookItem> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex.split('_').join(' ')}`}
          allowClear
          value={selectedKeys[selectedKeys.length - 1]}
          onChange={(e) => {
            const value = e.target.value;
            // if (value.length === 0) return;
            const prevFilter = (filteredInfo[dataIndex] || []) as string[];
            setSelectedKeys([...new Set([...prevFilter, value])]);
          }}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8 }}
        />
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type='link' onClick={clearFilters} disabled={filteredInfo[dataIndex] === null}>
            Reset
          </Button>
          <Button
            type='primary'
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? 'primary' : undefined }} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.current?.select();
        }, 100);
      }
    },
  });

  // const clearFilters = () => {
  //   setFilteredInfo({});
  // };

  const columns: ColumnsType<CashBookItem> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 90,
      filteredValue: filteredInfo.date || null,
      filters: cashBookItems
        .map((cashBookItem) => ({
          text: cashBookItem.date,
          value: cashBookItem.date,
        }))
        .filter(
          (cashBookItem, index, self) =>
            self.findIndex((item) => item.value === cashBookItem.value) === index
        )
        .sort((a, b) => a.text.localeCompare(b.text)),
      onFilter: (value, record) => record.date === value,
    },
    {
      title: 'Cash Handler',
      dataIndex: 'cash_handler',
      key: 'cash_handler',
      width: 100,
      // ellipsis: true,
      filteredValue: filteredInfo.cash_handler || null,
      filters: cashBookItems
        .map((cashBookItem) => {
          const cashhandler = cashBookItem.cash_handler;
          return {
            text: cashhandler,
            value: cashhandler,
          };
        })
        .filter(
          (cashBookItem, index, self) =>
            self.findIndex((item) => item.value === cashBookItem.value) === index
        )
        .sort((a, b) => a.text.localeCompare(b.text)),
      onFilter: (value, record) => record.cash_handler === value,
    },
    {
      title: 'Voucher No.',
      dataIndex: 'voucher_number',
      key: 'voucher_number',
      width: 100,
      // ellipsis: true,
      ...getColumnSearchProps('voucher_number'),
      filteredValue: filteredInfo.voucher_number || null,
      onFilter: (value, record) =>
        record.voucher_number
          ? record.voucher_number.toLowerCase().includes((value as string).toLowerCase())
          : false,
      render: (text) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={(filteredInfo.voucher_number as string[]) || []}
            autoEscape
            textToHighlight={text || ''}
          />
          {text && (
            <FilterTwoTone
              twoToneColor='lightgray'
              onClick={() => {
                setFilteredCashbookItems(
                  filteredCashbookItems.filter((item) => item.voucher_number === text)
                );
                setFilteredInfo((prev) => {
                  return {
                    ...prev,
                    voucher_number: prev.voucher_number
                      ? [...new Set([...prev.voucher_number, text])]
                      : [text],
                  };
                });
                // localStorage.setItem('jobsFilters', JSON.stringify(filteredInfo));
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: 'Account',
      dataIndex: 'account_id',
      key: 'account_id',
      width: 150,
      ellipsis: true,
      render: (account_id: string) => {
        const account = accounts.find((account) => account.id === account_id);
        return account ? account.account_name : '';
      },
      filteredValue: filteredInfo.account_id || null,
      filters: cashBookItems
        .map((cashBookItem) => ({
          text:
            accounts.find((account) => account.id === cashBookItem.account_id)?.account_name || '',
          value: cashBookItem.account_id,
        }))
        .filter(
          (cashBookItem, index, self) =>
            self.findIndex((item) => item.value === cashBookItem.value) === index
        )
        .sort((a, b) => a.text.localeCompare(b.text)),
      onFilter: (value, record) => record.account_id === value,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Debit',
      children: [
        {
          title: 'USD',
          dataIndex: 'debit_usd',
          key: 'debit_usd',
          width: 80,
          align: 'right',
          render: (text) => (text ? text.toLocaleString() : '0'),
        },
        {
          title: 'MMK',
          dataIndex: 'debit_mmk',
          key: 'debit_mmk',
          width: 120,
          align: 'right',
          render: (text) => (text ? text.toLocaleString() : '0'),
        },
      ],
    },
    {
      title: 'Credit',
      children: [
        {
          title: 'USD',
          dataIndex: 'credit_usd',
          key: 'credit_usd',
          align: 'right',
          width: 80,
          render: (text) => (text ? text.toLocaleString() : '0'),
        },
        {
          title: 'MMK',
          dataIndex: 'credit_mmk',
          key: 'credit_mmk',
          align: 'right',
          width: 120,
          render: (text) => (text ? text.toLocaleString() : '0'),
        },
      ],
    },

    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 160,
      render: (_: any, record) => (
        <Space>
          <ProfileOutlined
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              if (!job_id) return;
              openDrawer(job_id, DataTableType.CASH_BOOK, record.id, 'model');
            }}
          />
          {/*
          Button is disabled when
          1. job is archived
          2. permission for editing cashbook is not granted 
           */}
          <Button
            style={{ padding: 4 }}
            onClick={() => {
              setEditingCashbookItem(record);
              setModalState('edit');
            }}
            type='link'
            disabled={job && job.archived ? true : permission.UPDATE ? false : true}
            size='small'
          >
            Edit
          </Button>
          {/*
          Button is disabled when
          1. job is archived
          2. permission for deleting cashbook is not granted 
           */}
          <Popconfirm
            title={`Are you sure you want to delete this record?`}
            onConfirm={() => onDelete(record)}
            okText='Yes'
            cancelText='No'
          >
            <Button
              style={{ padding: 4 }}
              type='link'
              danger
              disabled={job && job.archived ? true : permission.DELETE ? false : true}
              size='small'
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Button
              type='primary'
              shape='circle'
              icon={<LeftOutlined />}
              size='middle'
              onClick={() => navigate(`/jobs/${job_id}`)}
            />
            <Title level={4} style={{ color: 'darkred' }}>
              Cashbook Entries for Job {job?.job_code} by {job?.customer?.customer_name}
            </Title>
          </Space>
        }
        style={{ width: 'max(calc(100vw - 200px), 1200px)', height: 'fit-content' }}
        extra={
          <Space>
            <Tooltip
              title={job?.archived ? 'Record is locked.' : 'Record is not locked.'}
              color={job?.archived ? 'red' : 'green'}
            >
              {!job || job?.archived ? (
                <LockFilled style={{ fontSize: '1.5em', color: 'darkred' }} />
              ) : (
                <UnlockFilled style={{ fontSize: '1.5em', color: 'green' }} />
              )}
            </Tooltip>
            <NetworkIndicator />
          </Space>
        }
      >
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5, marginTop: -15 }}
        >
          <Space size='large'>
            <Button
              type='link'
              size='middle'
              onClick={() => {
                setFilteredInfo({});
                setFilteredCashbookItems(cashBookItems);
              }}
              hidden={!Object.values(filteredInfo).some((value) => value !== null)}
              style={{ margin: 0, padding: 0 }}
            >
              clear all filters
            </Button>
            {/* 
Disable the button when
1. job is locked
2. permission for creating cashbook is not granted
*/}
            <Button
              type='default'
              onClick={() => setModalState('add')}
              disabled={job && job.archived ? true : permission.CREATE ? false : true}
            >
              Add new record
            </Button>
          </Space>
        </div>
        <Table
          columns={columns}
          dataSource={cashBookItems}
          rowKey='id'
          size='small'
          pagination={{
            showTotal: (total, range) => (
              <Space size={'large'}>
                <span>{`Total ${total} records`}</span>
              </Space>
            ),
          }}
          onChange={
            (pagination, filters, sorter, extra) => {
              console.log('here');
              setFilteredInfo(filters);
              setFilteredCashbookItems(extra.currentDataSource);
            }
            // console.log('params', pagination, filters, sorter, extra)
          }
          bordered
          summary={(data) => {
            const debit_usd_page_total = data.reduce((sum, record) => sum + record.debit_usd, 0);
            const debit_mmk_page_total = data.reduce((sum, record) => sum + record.debit_mmk, 0);
            const credit_usd_page_total = data.reduce((sum, record) => sum + record.credit_usd, 0);
            const credit_mmk_page_total = data.reduce((sum, record) => sum + record.credit_mmk, 0);

            const debit_usd_total = filteredCashbookItems.reduce(
              (sum, record) => sum + record.debit_usd,
              0
            );
            const debit_mmk_total = filteredCashbookItems.reduce(
              (sum, record) => sum + record.debit_mmk,
              0
            );
            const credit_usd_total = filteredCashbookItems.reduce(
              (sum, record) => sum + record.credit_usd,
              0
            );
            const credit_mmk_total = filteredCashbookItems.reduce(
              (sum, record) => sum + record.credit_mmk,
              0
            );

            return (
              <>
                <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
                  <Table.Summary.Cell index={1} colSpan={5} align='center'>
                    <Text strong>Page Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align='right'>
                    <Text strong>{debit_usd_page_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align='right'>
                    <Text strong>{debit_mmk_page_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align='right'>
                    <Text strong>{credit_usd_page_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align='right'>
                    <Text strong>{credit_mmk_page_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} />
                </Table.Summary.Row>
                <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
                  <Table.Summary.Cell index={1} colSpan={5} align='center'>
                    <Text strong>
                      Total{' '}
                      {Object.values(filteredInfo).some((value) => value !== null) ? (
                        <Text style={{ color: 'red' }}>(filtered)</Text>
                      ) : (
                        ''
                      )}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align='right'>
                    <Text strong>{debit_usd_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align='right'>
                    <Text strong>{debit_mmk_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align='right'>
                    <Text strong>{credit_usd_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align='right'>
                    <Text strong>{credit_mmk_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} />
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Card>
      <Modal
        title={modalState === 'add' ? 'Add new record' : 'Edit record'}
        open={modalState !== null}
        onOk={() => form.submit()}
        onCancel={() => setModalState(null)}
        okText={modalState === 'add' ? 'Add' : 'Save'}
        cancelText='Cancel'
        styles={{ body: { paddingTop: 20 } }}
        destroyOnClose
        maskClosable={false}
        width={580}
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onFormSave}>
          <Form.Item name='date' label='Date' rules={[{ required: true }]}>
            <DatePicker
              defaultValue={dayjs()}
              minDate={dayjs().subtract(1, 'year')}
              maxDate={dayjs()}
            />
          </Form.Item>
          <Form.Item name='cash_handler' label='Cash Handler' rules={[{ required: true }]}>
            <Select>
              {cashHandlers.map((ch) => (
                <Select.Option key={ch.short_name} value={ch.short_name}>
                  {ch.short_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='voucher_number'
            label='Voucher No.'
            rules={[{ whitespace: true, message: 'Voucher number cannot be white spaces.' }]}
          >
            <Input type='text' />
          </Form.Item>
          <Form.Item name='account_id' label='Account' rules={[{ required: true }]}>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.children ?? '').toString().toLowerCase().includes(input.toLowerCase())
              }
            >
              {accounts.map((account) => (
                <Select.Option key={account.id} value={account.id}>
                  {account.account_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='description' label='Description' rules={[{ required: true }]}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
          </Form.Item>
          <Form.Item
            name='debit_usd'
            label='Debit (USD)'
            rules={[{ required: true }, { validator: validateMultipleDebitCredit }]}
            initialValue={0}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={1}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            name='debit_mmk'
            label='Debit (MMK)'
            rules={[{ required: true }, { validator: validateMultipleDebitCredit }]}
            initialValue={0}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={100}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            name='credit_usd'
            label='Credit (USD)'
            rules={[{ required: true }, { validator: validateMultipleDebitCredit }]}
            initialValue={0}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={1}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            name='credit_mmk'
            label='Credit (MMK)'
            rules={[{ required: true }, { validator: validateMultipleDebitCredit }]}
            initialValue={0}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={100}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </Form>
      </Modal>
      {LogDrawer}
    </div>
  );
};

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCountry = /* GraphQL */ `query GetCountry($id: ID!) {
  getCountry(id: $id) {
    id
    country_id
    country_name
    alpha_2_code
    Jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCountryQueryVariables,
  APITypes.GetCountryQuery
>;
export const listCountries = /* GraphQL */ `query ListCountries(
  $id: ID
  $filter: ModelCountryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCountries(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      country_id
      country_name
      alpha_2_code
      Jobs {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCountriesQueryVariables,
  APITypes.ListCountriesQuery
>;
export const syncCountries = /* GraphQL */ `query SyncCountries(
  $filter: ModelCountryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCountries(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      country_id
      country_name
      alpha_2_code
      Jobs {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCountriesQueryVariables,
  APITypes.SyncCountriesQuery
>;
export const getAccountGroup = /* GraphQL */ `query GetAccountGroup($id: ID!) {
  getAccountGroup(id: $id) {
    id
    account_group_id
    account_group_code
    account_group_name
    account_heading_code
    account_heading
    is_active
    remark
    accounts {
      items {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountGroupQueryVariables,
  APITypes.GetAccountGroupQuery
>;
export const listAccountGroups = /* GraphQL */ `query ListAccountGroups(
  $id: ID
  $filter: ModelAccountGroupFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAccountGroups(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      account_group_id
      account_group_code
      account_group_name
      account_heading_code
      account_heading
      is_active
      remark
      accounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountGroupsQueryVariables,
  APITypes.ListAccountGroupsQuery
>;
export const syncAccountGroups = /* GraphQL */ `query SyncAccountGroups(
  $filter: ModelAccountGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAccountGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      account_group_id
      account_group_code
      account_group_name
      account_heading_code
      account_heading
      is_active
      remark
      accounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAccountGroupsQueryVariables,
  APITypes.SyncAccountGroupsQuery
>;
export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    account_id
    account_code
    account_code_sr
    account_name
    account_group_id
    account_group {
      id
      account_group_id
      account_group_code
      account_group_name
      account_heading_code
      account_heading
      is_active
      remark
      accounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    opening_amount
    opening_date
    is_active
    remark
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $id: ID
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAccounts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      account_id
      account_code
      account_code_sr
      account_name
      account_group_id
      account_group {
        id
        account_group_id
        account_group_code
        account_group_name
        account_heading_code
        account_heading
        is_active
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      opening_amount
      opening_date
      is_active
      remark
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const syncAccounts = /* GraphQL */ `query SyncAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAccounts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      account_id
      account_code
      account_code_sr
      account_name
      account_group_id
      account_group {
        id
        account_group_id
        account_group_code
        account_group_name
        account_heading_code
        account_heading
        is_active
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      opening_amount
      opening_date
      is_active
      remark
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAccountsQueryVariables,
  APITypes.SyncAccountsQuery
>;
export const getCustomer = /* GraphQL */ `query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    id
    customer_id
    customer_code
    customer_name
    opening_amount
    contact_person
    contact_address
    phone
    is_active
    remark
    jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    created_by
    modified_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerQueryVariables,
  APITypes.GetCustomerQuery
>;
export const listCustomers = /* GraphQL */ `query ListCustomers(
  $id: ID
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCustomers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomersQueryVariables,
  APITypes.ListCustomersQuery
>;
export const syncCustomers = /* GraphQL */ `query SyncCustomers(
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCustomers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCustomersQueryVariables,
  APITypes.SyncCustomersQuery
>;
export const getJob = /* GraphQL */ `query GetJob($id: ID!) {
  getJob(id: $id) {
    id
    job_id
    job_code
    date_of_creation
    invoice_id
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    customer_id
    customer {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    country_id
    country {
      id
      country_id
      country_name
      alpha_2_code
      Jobs {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    id_eds {
      items {
        id
        id_ed_id
        id_ed_number
        id_ed_type
        bl_number
        form_required
        form_number
        remark
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    route
    comodity
    date_of_arrival
    voyage
    forwarder
    liner
    description
    remark
    date_of_delivery
    is_active
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetJobQueryVariables, APITypes.GetJobQuery>;
export const listJobs = /* GraphQL */ `query ListJobs(
  $id: ID
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listJobs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListJobsQueryVariables, APITypes.ListJobsQuery>;
export const syncJobs = /* GraphQL */ `query SyncJobs(
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncJobs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncJobsQueryVariables, APITypes.SyncJobsQuery>;
export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_code
    invoice_date
    invoice_type
    customer_id
    customer_name
    comodity
    description
    remark
    id_or_ed_and_count
    id_ed_numbers
    container_types_and_counts
    container_numbers
    bl_list
    invoice_details {
      items {
        id
        invoice_id
        invoice_date
        description
        amount_mmk
        amount_usd
        debit_mmk
        debit_usd
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoice_detail_ids
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $id: ID
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvoices(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const syncInvoices = /* GraphQL */ `query SyncInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInvoices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInvoicesQueryVariables,
  APITypes.SyncInvoicesQuery
>;
export const getInvoiceDetail = /* GraphQL */ `query GetInvoiceDetail($id: ID!) {
  getInvoiceDetail(id: $id) {
    id
    invoice_id
    invoice_date
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    amount_mmk
    amount_usd
    debit_mmk
    debit_usd
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceDetailQueryVariables,
  APITypes.GetInvoiceDetailQuery
>;
export const listInvoiceDetails = /* GraphQL */ `query ListInvoiceDetails(
  $id: ID
  $filter: ModelInvoiceDetailFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvoiceDetails(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      invoice_id
      invoice_date
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      amount_mmk
      amount_usd
      debit_mmk
      debit_usd
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoiceDetailsQueryVariables,
  APITypes.ListInvoiceDetailsQuery
>;
export const syncInvoiceDetails = /* GraphQL */ `query SyncInvoiceDetails(
  $filter: ModelInvoiceDetailFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInvoiceDetails(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      invoice_id
      invoice_date
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      amount_mmk
      amount_usd
      debit_mmk
      debit_usd
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInvoiceDetailsQueryVariables,
  APITypes.SyncInvoiceDetailsQuery
>;
export const getContainerType = /* GraphQL */ `query GetContainerType($id: ID!) {
  getContainerType(id: $id) {
    id
    container_type_id
    container_type
    description
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContainerTypeQueryVariables,
  APITypes.GetContainerTypeQuery
>;
export const listContainerTypes = /* GraphQL */ `query ListContainerTypes(
  $id: ID
  $filter: ModelContainerTypeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listContainerTypes(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      container_type_id
      container_type
      description
      containers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContainerTypesQueryVariables,
  APITypes.ListContainerTypesQuery
>;
export const syncContainerTypes = /* GraphQL */ `query SyncContainerTypes(
  $filter: ModelContainerTypeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncContainerTypes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      container_type_id
      container_type
      description
      containers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncContainerTypesQueryVariables,
  APITypes.SyncContainerTypesQuery
>;
export const getContainer = /* GraphQL */ `query GetContainer($id: ID!) {
  getContainer(id: $id) {
    id
    container_id
    container_number
    container_type_id
    container_type {
      id
      container_type_id
      container_type
      description
      containers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContainerQueryVariables,
  APITypes.GetContainerQuery
>;
export const listContainers = /* GraphQL */ `query ListContainers(
  $id: ID
  $filter: ModelContainerFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listContainers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      container_id
      container_number
      container_type_id
      container_type {
        id
        container_type_id
        container_type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContainersQueryVariables,
  APITypes.ListContainersQuery
>;
export const syncContainers = /* GraphQL */ `query SyncContainers(
  $filter: ModelContainerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncContainers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      container_id
      container_number
      container_type_id
      container_type {
        id
        container_type_id
        container_type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncContainersQueryVariables,
  APITypes.SyncContainersQuery
>;
export const getIdEd = /* GraphQL */ `query GetIdEd($id: ID!) {
  getIdEd(id: $id) {
    id
    id_ed_id
    id_ed_number
    id_ed_type
    bl_number
    form_required
    form_number
    remark
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetIdEdQueryVariables, APITypes.GetIdEdQuery>;
export const listIdEds = /* GraphQL */ `query ListIdEds(
  $id: ID
  $filter: ModelIdEdFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIdEds(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      id_ed_id
      id_ed_number
      id_ed_type
      bl_number
      form_required
      form_number
      remark
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListIdEdsQueryVariables, APITypes.ListIdEdsQuery>;
export const syncIdEds = /* GraphQL */ `query SyncIdEds(
  $filter: ModelIdEdFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncIdEds(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      id_ed_id
      id_ed_number
      id_ed_type
      bl_number
      form_required
      form_number
      remark
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncIdEdsQueryVariables, APITypes.SyncIdEdsQuery>;
export const getCashBook = /* GraphQL */ `query GetCashBook($id: ID!) {
  getCashBook(id: $id) {
    id
    cashbook_id
    date
    cash_handler
    voucher_number
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_creation_date
    account_id
    account {
      id
      account_id
      account_code
      account_code_sr
      account_name
      account_group_id
      account_group {
        id
        account_group_id
        account_group_code
        account_group_name
        account_heading_code
        account_heading
        is_active
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      opening_amount
      opening_date
      is_active
      remark
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    debit_usd
    credit_usd
    debit_mmk
    credit_mmk
    is_deleted
    remark
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCashBookQueryVariables,
  APITypes.GetCashBookQuery
>;
export const listCashBooks = /* GraphQL */ `query ListCashBooks(
  $id: ID
  $filter: ModelCashBookFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCashBooks(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      cashbook_id
      date
      cash_handler
      voucher_number
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_creation_date
      account_id
      account {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      debit_usd
      credit_usd
      debit_mmk
      credit_mmk
      is_deleted
      remark
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashBooksQueryVariables,
  APITypes.ListCashBooksQuery
>;
export const syncCashBooks = /* GraphQL */ `query SyncCashBooks(
  $filter: ModelCashBookFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCashBooks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cashbook_id
      date
      cash_handler
      voucher_number
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_creation_date
      account_id
      account {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      debit_usd
      credit_usd
      debit_mmk
      credit_mmk
      is_deleted
      remark
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCashBooksQueryVariables,
  APITypes.SyncCashBooksQuery
>;
export const getCashHandler = /* GraphQL */ `query GetCashHandler($id: ID!) {
  getCashHandler(id: $id) {
    id
    short_name
    full_name
    email
    phone
    address
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCashHandlerQueryVariables,
  APITypes.GetCashHandlerQuery
>;
export const listCashHandlers = /* GraphQL */ `query ListCashHandlers(
  $id: ID
  $filter: ModelCashHandlerFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCashHandlers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      short_name
      full_name
      email
      phone
      address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashHandlersQueryVariables,
  APITypes.ListCashHandlersQuery
>;
export const syncCashHandlers = /* GraphQL */ `query SyncCashHandlers(
  $filter: ModelCashHandlerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCashHandlers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      short_name
      full_name
      email
      phone
      address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCashHandlersQueryVariables,
  APITypes.SyncCashHandlersQuery
>;
export const getLog = /* GraphQL */ `query GetLog($id: ID!) {
  getLog(id: $id) {
    id
    job_id
    job_code
    model
    model_id
    operation
    old_data
    new_data
    user
    changed_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLogQueryVariables, APITypes.GetLogQuery>;
export const listLogs = /* GraphQL */ `query ListLogs(
  $id: ID
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listLogs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      job_id
      job_code
      model
      model_id
      operation
      old_data
      new_data
      user
      changed_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListLogsQueryVariables, APITypes.ListLogsQuery>;
export const syncLogs = /* GraphQL */ `query SyncLogs(
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      job_id
      job_code
      model
      model_id
      operation
      old_data
      new_data
      user
      changed_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncLogsQueryVariables, APITypes.SyncLogsQuery>;
export const getPermission = /* GraphQL */ `query GetPermission($id: ID!) {
  getPermission(id: $id) {
    id
    model
    operation
    required_permission_level
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPermissionQueryVariables,
  APITypes.GetPermissionQuery
>;
export const listPermissions = /* GraphQL */ `query ListPermissions(
  $id: ID
  $filter: ModelPermissionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPermissions(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      model
      operation
      required_permission_level
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPermissionsQueryVariables,
  APITypes.ListPermissionsQuery
>;
export const syncPermissions = /* GraphQL */ `query SyncPermissions(
  $filter: ModelPermissionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPermissions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      model
      operation
      required_permission_level
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPermissionsQueryVariables,
  APITypes.SyncPermissionsQuery
>;
export const getPageVisit = /* GraphQL */ `query GetPageVisit($id: ID!) {
  getPageVisit(id: $id) {
    id
    page
    user
    visited_at
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPageVisitQueryVariables,
  APITypes.GetPageVisitQuery
>;
export const listPageVisits = /* GraphQL */ `query ListPageVisits(
  $id: ID
  $filter: ModelPageVisitFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPageVisits(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      page
      user
      visited_at
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPageVisitsQueryVariables,
  APITypes.ListPageVisitsQuery
>;
export const syncPageVisits = /* GraphQL */ `query SyncPageVisits(
  $filter: ModelPageVisitFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPageVisits(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      page
      user
      visited_at
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPageVisitsQueryVariables,
  APITypes.SyncPageVisitsQuery
>;
export const customerByCode = /* GraphQL */ `query CustomerByCode(
  $customer_code: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  customerByCode(
    customer_code: $customer_code
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CustomerByCodeQueryVariables,
  APITypes.CustomerByCodeQuery
>;
export const jobByCode = /* GraphQL */ `query JobByCode(
  $job_code: String!
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByCode(
    job_code: $job_code
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.JobByCodeQueryVariables, APITypes.JobByCodeQuery>;
export const jobByInvoice = /* GraphQL */ `query JobByInvoice(
  $invoice_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByInvoice(
    invoice_id: $invoice_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobByInvoiceQueryVariables,
  APITypes.JobByInvoiceQuery
>;
export const jobByCustomer = /* GraphQL */ `query JobByCustomer(
  $customer_id: ID!
  $date_of_creation: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByCustomer(
    customer_id: $customer_id
    date_of_creation: $date_of_creation
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobByCustomerQueryVariables,
  APITypes.JobByCustomerQuery
>;
export const jobByCountry = /* GraphQL */ `query JobByCountry(
  $country_id: ID!
  $date_of_creation: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByCountry(
    country_id: $country_id
    date_of_creation: $date_of_creation
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobByCountryQueryVariables,
  APITypes.JobByCountryQuery
>;
export const jobByRoute = /* GraphQL */ `query JobByRoute(
  $route: ROUTE_TYPE!
  $date_of_creation: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByRoute(
    route: $route
    date_of_creation: $date_of_creation
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobByRouteQueryVariables,
  APITypes.JobByRouteQuery
>;
export const jobByDateOfCreation = /* GraphQL */ `query JobByDateOfCreation(
  $virtual_index: Int!
  $date_of_creation: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByDateOfCreation(
    virtual_index: $virtual_index
    date_of_creation: $date_of_creation
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobByDateOfCreationQueryVariables,
  APITypes.JobByDateOfCreationQuery
>;
export const jobsByIndexAndCode = /* GraphQL */ `query JobsByIndexAndCode(
  $virtual_index: Int!
  $job_code: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobsByIndexAndCode(
    virtual_index: $virtual_index
    job_code: $job_code
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobsByIndexAndCodeQueryVariables,
  APITypes.JobsByIndexAndCodeQuery
>;
export const invoiceByJob = /* GraphQL */ `query InvoiceByJob(
  $job_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByJob(
    job_id: $job_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceByJobQueryVariables,
  APITypes.InvoiceByJobQuery
>;
export const invoiceByType = /* GraphQL */ `query InvoiceByType(
  $invoice_type: INVOICE_TYPE!
  $invoice_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByType(
    invoice_type: $invoice_type
    invoice_date: $invoice_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceByTypeQueryVariables,
  APITypes.InvoiceByTypeQuery
>;
export const invoiceByCustomer = /* GraphQL */ `query InvoiceByCustomer(
  $customer_id: String!
  $invoice_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByCustomer(
    customer_id: $customer_id
    invoice_date: $invoice_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceByCustomerQueryVariables,
  APITypes.InvoiceByCustomerQuery
>;
export const invoiceByInvoiceDate = /* GraphQL */ `query InvoiceByInvoiceDate(
  $virtual_index: Int!
  $invoice_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByInvoiceDate(
    virtual_index: $virtual_index
    invoice_date: $invoice_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceByInvoiceDateQueryVariables,
  APITypes.InvoiceByInvoiceDateQuery
>;
export const invoiceByJobCode = /* GraphQL */ `query InvoiceByJobCode(
  $virtual_index: Int!
  $job_code: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByJobCode(
    virtual_index: $virtual_index
    job_code: $job_code
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceByJobCodeQueryVariables,
  APITypes.InvoiceByJobCodeQuery
>;
export const invoiceDetailByInvoice = /* GraphQL */ `query InvoiceDetailByInvoice(
  $invoice_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceDetailFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceDetailByInvoice(
    invoice_id: $invoice_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      invoice_id
      invoice_date
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      amount_mmk
      amount_usd
      debit_mmk
      debit_usd
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceDetailByInvoiceQueryVariables,
  APITypes.InvoiceDetailByInvoiceQuery
>;
export const invoiceDetailByInvoiceDate = /* GraphQL */ `query InvoiceDetailByInvoiceDate(
  $invoice_date: String!
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceDetailFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceDetailByInvoiceDate(
    invoice_date: $invoice_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      invoice_id
      invoice_date
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      amount_mmk
      amount_usd
      debit_mmk
      debit_usd
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceDetailByInvoiceDateQueryVariables,
  APITypes.InvoiceDetailByInvoiceDateQuery
>;
export const containerByNumber = /* GraphQL */ `query ContainerByNumber(
  $container_number: String!
  $sortDirection: ModelSortDirection
  $filter: ModelContainerFilterInput
  $limit: Int
  $nextToken: String
) {
  containerByNumber(
    container_number: $container_number
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      container_id
      container_number
      container_type_id
      container_type {
        id
        container_type_id
        container_type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContainerByNumberQueryVariables,
  APITypes.ContainerByNumberQuery
>;
export const containerByJob = /* GraphQL */ `query ContainerByJob(
  $job_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContainerFilterInput
  $limit: Int
  $nextToken: String
) {
  containerByJob(
    job_id: $job_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      container_id
      container_number
      container_type_id
      container_type {
        id
        container_type_id
        container_type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContainerByJobQueryVariables,
  APITypes.ContainerByJobQuery
>;
export const containerByIndexAndNumber = /* GraphQL */ `query ContainerByIndexAndNumber(
  $virtual_index: Int!
  $container_number: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContainerFilterInput
  $limit: Int
  $nextToken: String
) {
  containerByIndexAndNumber(
    virtual_index: $virtual_index
    container_number: $container_number
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      container_id
      container_number
      container_type_id
      container_type {
        id
        container_type_id
        container_type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContainerByIndexAndNumberQueryVariables,
  APITypes.ContainerByIndexAndNumberQuery
>;
export const idEdByNumber = /* GraphQL */ `query IdEdByNumber(
  $id_ed_number: String!
  $sortDirection: ModelSortDirection
  $filter: ModelIdEdFilterInput
  $limit: Int
  $nextToken: String
) {
  idEdByNumber(
    id_ed_number: $id_ed_number
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_ed_id
      id_ed_number
      id_ed_type
      bl_number
      form_required
      form_number
      remark
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IdEdByNumberQueryVariables,
  APITypes.IdEdByNumberQuery
>;
export const idEdByJob = /* GraphQL */ `query IdEdByJob(
  $job_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelIdEdFilterInput
  $limit: Int
  $nextToken: String
) {
  idEdByJob(
    job_id: $job_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_ed_id
      id_ed_number
      id_ed_type
      bl_number
      form_required
      form_number
      remark
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.IdEdByJobQueryVariables, APITypes.IdEdByJobQuery>;
export const idEdByIndexAndNumber = /* GraphQL */ `query IdEdByIndexAndNumber(
  $virtual_index: Int!
  $id_ed_number: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIdEdFilterInput
  $limit: Int
  $nextToken: String
) {
  idEdByIndexAndNumber(
    virtual_index: $virtual_index
    id_ed_number: $id_ed_number
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_ed_id
      id_ed_number
      id_ed_type
      bl_number
      form_required
      form_number
      remark
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IdEdByIndexAndNumberQueryVariables,
  APITypes.IdEdByIndexAndNumberQuery
>;
export const cashBookByCashHandler = /* GraphQL */ `query CashBookByCashHandler(
  $cash_handler: String!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashBookFilterInput
  $limit: Int
  $nextToken: String
) {
  cashBookByCashHandler(
    cash_handler: $cash_handler
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cashbook_id
      date
      cash_handler
      voucher_number
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_creation_date
      account_id
      account {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      debit_usd
      credit_usd
      debit_mmk
      credit_mmk
      is_deleted
      remark
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashBookByCashHandlerQueryVariables,
  APITypes.CashBookByCashHandlerQuery
>;
export const cashBookByVoucherNumber = /* GraphQL */ `query CashBookByVoucherNumber(
  $voucher_number: String!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashBookFilterInput
  $limit: Int
  $nextToken: String
) {
  cashBookByVoucherNumber(
    voucher_number: $voucher_number
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cashbook_id
      date
      cash_handler
      voucher_number
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_creation_date
      account_id
      account {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      debit_usd
      credit_usd
      debit_mmk
      credit_mmk
      is_deleted
      remark
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashBookByVoucherNumberQueryVariables,
  APITypes.CashBookByVoucherNumberQuery
>;
export const cashBookByJob = /* GraphQL */ `query CashBookByJob(
  $job_id: ID!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashBookFilterInput
  $limit: Int
  $nextToken: String
) {
  cashBookByJob(
    job_id: $job_id
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cashbook_id
      date
      cash_handler
      voucher_number
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_creation_date
      account_id
      account {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      debit_usd
      credit_usd
      debit_mmk
      credit_mmk
      is_deleted
      remark
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashBookByJobQueryVariables,
  APITypes.CashBookByJobQuery
>;
export const cashBookByAccount = /* GraphQL */ `query CashBookByAccount(
  $account_id: ID!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashBookFilterInput
  $limit: Int
  $nextToken: String
) {
  cashBookByAccount(
    account_id: $account_id
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cashbook_id
      date
      cash_handler
      voucher_number
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_creation_date
      account_id
      account {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      debit_usd
      credit_usd
      debit_mmk
      credit_mmk
      is_deleted
      remark
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashBookByAccountQueryVariables,
  APITypes.CashBookByAccountQuery
>;
export const cashBookByCreatedBy = /* GraphQL */ `query CashBookByCreatedBy(
  $created_by: String!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashBookFilterInput
  $limit: Int
  $nextToken: String
) {
  cashBookByCreatedBy(
    created_by: $created_by
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cashbook_id
      date
      cash_handler
      voucher_number
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_creation_date
      account_id
      account {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      debit_usd
      credit_usd
      debit_mmk
      credit_mmk
      is_deleted
      remark
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashBookByCreatedByQueryVariables,
  APITypes.CashBookByCreatedByQuery
>;
export const cashBookByDate = /* GraphQL */ `query CashBookByDate(
  $virtual_index: String!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashBookFilterInput
  $limit: Int
  $nextToken: String
) {
  cashBookByDate(
    virtual_index: $virtual_index
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cashbook_id
      date
      cash_handler
      voucher_number
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_creation_date
      account_id
      account {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      description
      debit_usd
      credit_usd
      debit_mmk
      credit_mmk
      is_deleted
      remark
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashBookByDateQueryVariables,
  APITypes.CashBookByDateQuery
>;
export const logByJobId = /* GraphQL */ `query LogByJobId(
  $job_id: ID!
  $modelModel_id: ModelLogLogByJobIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logByJobId(
    job_id: $job_id
    modelModel_id: $modelModel_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      model
      model_id
      operation
      old_data
      new_data
      user
      changed_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogByJobIdQueryVariables,
  APITypes.LogByJobIdQuery
>;
export const logByJobCode = /* GraphQL */ `query LogByJobCode(
  $job_code: String!
  $modelModel_id: ModelLogLogByJobCodeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logByJobCode(
    job_code: $job_code
    modelModel_id: $modelModel_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      model
      model_id
      operation
      old_data
      new_data
      user
      changed_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogByJobCodeQueryVariables,
  APITypes.LogByJobCodeQuery
>;
export const logByModel = /* GraphQL */ `query LogByModel(
  $model: DataTableType!
  $changed_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logByModel(
    model: $model
    changed_at: $changed_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      model
      model_id
      operation
      old_data
      new_data
      user
      changed_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogByModelQueryVariables,
  APITypes.LogByModelQuery
>;
export const logByOperation = /* GraphQL */ `query LogByOperation(
  $operation: OperationType!
  $changed_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLogFilterInput
  $limit: Int
  $nextToken: String
) {
  logByOperation(
    operation: $operation
    changed_at: $changed_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      model
      model_id
      operation
      old_data
      new_data
      user
      changed_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LogByOperationQueryVariables,
  APITypes.LogByOperationQuery
>;
export const pageVisitByPage = /* GraphQL */ `query PageVisitByPage(
  $page: String!
  $visited_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPageVisitFilterInput
  $limit: Int
  $nextToken: String
) {
  pageVisitByPage(
    page: $page
    visited_at: $visited_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      page
      user
      visited_at
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PageVisitByPageQueryVariables,
  APITypes.PageVisitByPageQuery
>;
export const pageVisitByUser = /* GraphQL */ `query PageVisitByUser(
  $user: String!
  $visited_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPageVisitFilterInput
  $limit: Int
  $nextToken: String
) {
  pageVisitByUser(
    user: $user
    visited_at: $visited_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      page
      user
      visited_at
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PageVisitByUserQueryVariables,
  APITypes.PageVisitByUserQuery
>;
export const pageVisitByDate = /* GraphQL */ `query PageVisitByDate(
  $type: String!
  $visited_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPageVisitFilterInput
  $limit: Int
  $nextToken: String
) {
  pageVisitByDate(
    type: $type
    visited_at: $visited_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      page
      user
      visited_at
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PageVisitByDateQueryVariables,
  APITypes.PageVisitByDateQuery
>;

import React, { useEffect } from 'react';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { Button, Card, Space, Typography, Table, Form, Input, DatePicker } from 'antd';
import NetworkIndicator from '../../components/NetworkIndicator';
import {
  ModelSortDirection,
  ModelStringKeyConditionInput,
  PageVisit,
  PageVisitByDateQuery,
  PageVisitByDateQueryVariables,
  PageVisitByPageQuery,
  PageVisitByPageQueryVariables,
  PageVisitByUserQuery,
  PageVisitByUserQueryVariables,
} from '../../API';
import { ColumnsType } from 'antd/es/table';
import { Dayjs } from 'dayjs';
import { filter } from 'lodash';
import { API } from 'aws-amplify';
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';

const { Title } = Typography;

type QueryVariables =
  | PageVisitByPageQueryVariables
  | PageVisitByUserQueryVariables
  | PageVisitByDateQueryVariables;

type QueryName = 'pageVisitByPage' | 'pageVisitByUser' | 'pageVisitByDate' | undefined;

const Pagevisits = () => {
  useDocumentTitle('Page Visits');
  const [pageVisits, setPageVisits] = React.useState<PageVisit[]>([]);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [filters, setFilters] = React.useState<FilterValues>({
    page: undefined,
    user: undefined,
    visited_at: null,
  });
  const [queryName, setQueryName] = React.useState<QueryName | undefined>(undefined);
  const [queryVariables, setQueryVariables] = React.useState<Partial<QueryVariables>>({});
  const [form] = Form.useForm();

  type FilterValues = {
    page: string | undefined;
    user: string | undefined;
    visited_at: [Dayjs, Dayjs] | null;
  };

  let timeoutId: NodeJS.Timeout | null = null;

  const listPageVisitsByFilter = async (
    queryName: Exclude<QueryName, undefined>,
    queryVariables: QueryVariables,
    limit: number
  ) => {
    const pageVisits: PageVisit[] = [];
    let nextToken: string | null = queryVariables.nextToken || null;
    const result = await API.graphql<
      GraphQLQuery<PageVisitByPageQuery | PageVisitByUserQuery | PageVisitByDateQuery>
    >({
      query: queryName,
      variables: queryVariables,
    });
    if (result) {
      // let abc = result['data']?.[queryName]?.items;
      // if (data) {
      //   if (items) {
      //     pageVisits.push(...items as PageVisit[]);
      //   }
      //   nextToken = newNextToken;
      // }
    }
  };

  const onFilterChange = async (changedValues: Partial<FilterValues>, allValues: FilterValues) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // console.log('changedValues', changedValues);
    // console.log('allValues', allValues);
    timeoutId = setTimeout(async () => {
      const startDate = allValues.visited_at ? allValues.visited_at[0] : null;
      const endDate = allValues.visited_at ? allValues.visited_at[1] : null;
      const page = allValues.page;
      const user = allValues.user;

      setFilters({
        page: page && page.trim().length ? page.trim() : undefined,
        user: user && user.trim().length ? user.trim() : undefined,
        visited_at: startDate && endDate ? [startDate, endDate] : null,
      });
    }, 500);
  };

  useEffect(() => {
    let queryname: QueryName | undefined = undefined;
    let queryVariables: Partial<QueryVariables> = {
      limit: 100,
      sortDirection: ModelSortDirection.DESC,
      filter: { and: [{ _deleted: { attributeExists: false } }] },
    };

    const dateFilter: ModelStringKeyConditionInput = {};
    if (filters.visited_at) {
      const startDate = filters.visited_at[0].format('YYYY-MM-DD');
      const endDate = filters.visited_at[1].format('YYYY-MM-DD');
      if (startDate === endDate) {
        dateFilter.beginsWith = startDate;
      } else {
        dateFilter.between = [startDate, endDate];
      }
    }

    if (filters.page) {
      queryname = 'pageVisitByPage';

      queryVariables = {
        ...queryVariables,
        page: filters.page,
        visited_at: dateFilter,
      } satisfies PageVisitByPageQueryVariables;
      if (filters.user) {
        queryVariables.filter!.and!.push({ user: { eq: filters.user } });
      }
    } else if (filters.user) {
      queryname = 'pageVisitByUser';
      queryVariables = {
        ...queryVariables,
        user: filters.user,
        visited_at: dateFilter,
      } satisfies PageVisitByUserQueryVariables;
    } else if (filters.visited_at) {
      queryname = 'pageVisitByDate';
      queryVariables = {
        ...queryVariables,
        visited_at: dateFilter,
        type: 'PageVisit',
      } satisfies PageVisitByDateQueryVariables;
    }
    setQueryName(queryname);
    setQueryVariables(queryVariables);
  }, [filters]);

  // page
  // user
  // visited_at
  const columns: ColumnsType<PageVisit> = [
    {
      title: 'Page',
      dataIndex: 'page',
      key: 'page',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Visited At',
      dataIndex: 'visited_at',
      key: 'visited_at',
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Title level={4} style={{ color: 'darkred' }}>
              Page Visits
            </Title>
          </Space>
        }
        style={{ width: '100%', height: 'fit-content', marginTop: -10 }}
        extra={
          <Space>
            <Button
              type='link'
              // onClick={fetchMore}
              // disabled={queryVariables.nextToken === null}
            >
              fetch more
            </Button>
            <Button
            // onClick={clearFilter}
            // disabled={isLoading}
            >
              Clear filter
            </Button>
            <NetworkIndicator />
          </Space>
        }
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Form
            form={form}
            layout='inline'
            onValuesChange={onFilterChange}
            disabled={isDownloading}
          >
            <Form.Item
              label='Page'
              name='page'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              style={{ width: 300, marginBottom: 10 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='User'
              name='user'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              style={{ width: 300, marginBottom: 10 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Visited At'
              name='visited_at'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              style={{ width: 350, marginBottom: 10 }}
            >
              <DatePicker.RangePicker />
            </Form.Item>
          </Form>
        </div>
        <Table
          columns={columns}
          dataSource={pageVisits}
          rowKey='id'
          size='small'
          bordered
          pagination={{
            pageSize: 10,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </Card>
    </div>
  );
};

export default Pagevisits;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { API, DataStore } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { compact } from 'lodash';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, Card, Space, Tooltip, Typography, Table, Input, InputRef, Tag } from 'antd';
import NetworkIndicator from '../../components/NetworkIndicator';
import { ColumnType, ColumnsType, TableProps } from 'antd/es/table';
import { Customer, DataTableType } from '../../models';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { FilterValue, TablePaginationConfig } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from '../Users/AuthContext';
import { useGetPermissionInfo } from '../Users/adminHooks';

const { Title } = Typography;

const Customers = () => {
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const searchInput = useRef<InputRef>(null);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>(
    {} as TablePaginationConfig
  );
  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.CUSTOMER);

  const navigate = useNavigate();

  useDocumentTitle('Customers');

  useEffect(() => {
    const customerSubscription = DataStore.observeQuery(Customer).subscribe((msg) => {
      const { items, isSynced } = msg;
      if (isSynced) {
        setCustomers(items.sort((a, b) => (a.customer_code > b.customer_code ? 1 : -1)));
      }
    });
    return () => {
      customerSubscription.unsubscribe();
    };
  }, []);

  const clearFilters = () => {
    setFilteredInfo({});
    localStorage.removeItem('customersFilters');
  };

  useEffect(() => {
    const filters = localStorage.getItem('customersFilters');
    const pagination = localStorage.getItem('customersPagination');
    if (filters) setFilteredInfo(JSON.parse(filters));
    if (pagination) setPaginationInfo(JSON.parse(pagination));
  }, []);

  const handleTableChange: TableProps<Customer>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    setPaginationInfo(pagination);
    localStorage.setItem('customersPagination', JSON.stringify(pagination));
    setFilteredInfo(filters);
    localStorage.setItem('customersFilters', JSON.stringify(filters));
  };

  const getColumnSearchProps = (dataIndex: keyof Customer): ColumnType<Customer> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex.split('_').join(' ')}`}
          allowClear
          value={selectedKeys[selectedKeys.length - 1]}
          onChange={(e) => {
            const value = e.target.value;
            // if (value.length === 0) return;
            const prevFilter = (filteredInfo[dataIndex] || []) as string[];
            setSelectedKeys([...new Set([...prevFilter, value])]);
          }}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8 }}
        />
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type='link' onClick={clearFilters} disabled={filteredInfo[dataIndex] === null}>
            Reset
          </Button>
          <Button
            type='primary'
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? 'primary' : undefined }} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.current?.select();
        }, 100);
      }
    },
  });

  const columns: ColumnsType<Customer> = [
    {
      title: 'Customer Code',
      dataIndex: 'customer_code',
      key: 'customer_code',
      width: 120,
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      filteredValue: filteredInfo.customer_name || null,
      ...getColumnSearchProps('customer_name'),
      onFilter: (value, record) => {
        return record.customer_name.toLowerCase().includes(value.toString().toLowerCase());
      },
      render: (text, record) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={(filteredInfo.customer_name as string[]) || []}
          autoEscape
          textToHighlight={text}
        />
      ),
    },
    {
      title: 'Contact Person',
      dataIndex: 'contact_person',
      key: 'contact_person',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Contact Address',
      dataIndex: 'contact_address',
      key: 'contact_address',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Contact Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 80,
      filteredValue: filteredInfo.is_active || null,
      filters: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      onFilter: (value, record) =>
        value === 'yes' ? record.is_active === true : !record.is_active,
      render: (text, record) => (record.is_active ? <CheckOutlined /> : ''),
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <Button type='link' size='middle' onClick={() => navigate(record.id)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Title level={4} style={{ color: 'darkred' }}>
              Customers
            </Title>
          </Space>
        }
        style={{ width: 'max(calc(100vw - 400px), 1100px)', height: 'fit-content' }}
        extra={
          <Space>
            <NetworkIndicator />
          </Space>
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            marginTop: -15,
          }}
        >
          <div>
            {Object.keys(filteredInfo).map((key) => {
              const values = filteredInfo[key];
              if (values === null || values.length === 0) return null;
              const tabs = values.map((v) => {
                return (
                  <Tag
                    key={key + v}
                    closable
                    color='lime'
                    onClose={() => {
                      setFilteredInfo((prev) => {
                        localStorage.setItem(
                          'customersFilters',
                          JSON.stringify({
                            ...prev,
                            [key]: prev[key]?.filter((k) => k !== v) || [],
                          })
                        );
                        return {
                          ...prev,
                          [key]: prev[key]?.filter((k) => k !== v) || [],
                        };
                      });
                    }}
                  >
                    {key === 'is_active'
                      ? 'Active'
                      : (key[0].toUpperCase() + key.slice(1)).split('_').join(' ')}{' '}
                    : {v.toString()}
                  </Tag>
                );
              });
              return tabs;
            })}
          </div>
          <Space size='large'>
            <Button
              type='link'
              size='middle'
              onClick={clearFilters}
              hidden={!Object.values(filteredInfo).some((value) => value !== null)}
              style={{ margin: 0, padding: 0 }}
            >
              clear all filters
            </Button>

            <Button
              type='default'
              onClick={() => {
                navigate('new');
              }}
              disabled={!permission.CREATE}
            >
              Add new customer
            </Button>
          </Space>
        </div>
        <Table
          columns={columns}
          dataSource={customers}
          rowKey={'id'}
          size='small'
          onChange={handleTableChange}
          pagination={{
            current: paginationInfo.current,
            pageSize: paginationInfo.pageSize || 10,
            showTotal: (total) => `${total} of ${customers.length} customers`,
          }}
        />
      </Card>
    </div>
  );
};

export default Customers;

import { DataStore } from 'aws-amplify';
import {
  Container,
  Job as JobAPI,
  IdEd,
  CashBook,
  OperationType,
  InvoiceDetail,
  ID_ED_TYPE,
} from '../../API';

import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  List,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Typography,
  Collapse,
  Table,
  Modal,
  Form,
  Select,
  Input,
  Checkbox,
  message,
} from 'antd';
import {
  BorderOutlined,
  CheckOutlined,
  CheckSquareTwoTone,
  // FilterTwoTone,
  LeftOutlined,
  LockFilled,
  MinusOutlined,
  ProfileOutlined,
  // SearchOutlined,
  UnlockFilled,
} from '@ant-design/icons';
import NetworkIndicator from '../../components/NetworkIndicator';
import { logActivity } from '../../utilities/logger';
import { DataTableType } from '../../models';
// import { ColumnType, ColumnsType } from 'antd/es/table';

// import dayjs from 'dayjs';
import { ContainerType } from '../../models';
import {
  createContainer,
  createIdEd,
  deleteContainer,
  deleteIdEd,
  deleteJob,
  getContainer,
  getIdEd,
  getJob,
  listCashBook,
  listContainers,
  listIdEds,
  listInvoiceDetails,
  updateContainer,
  updateIdEd,
  updateJob,
} from './api';
// import { FilterValue } from 'antd/es/table/interface';
// import { CustomUserType, convertUsers, listUsers } from '../Users/adminFunctions';
import { useGetPermissionInfo } from '../Users/adminHooks';
import useLogDrawer from '../../custom_hooks/useLogDrawer';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from '../Users/AuthContext';
import { ColumnsType } from 'antd/es/table';
import { compact } from 'lodash';

const { Title, Text } = Typography;

const ListItem = ({ label, content }: { label: ReactNode; content: ReactNode }) => {
  return (
    <List.Item style={{ padding: '10px 0' }}>
      <Row style={{ width: '100%', minHeight: 22 }}>
        <Col span={8}>
          <Title level={5} style={{ marginBottom: 0 }}>
            {label}
          </Title>
        </Col>
        <Col span={16} style={{ whiteSpace: 'pre-wrap' }}>
          {content}
        </Col>
      </Row>
    </List.Item>
  );
};

const Job = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { job_id } = useParams<{ job_id: string }>();
  const [job, setJob] = useState<JobAPI | undefined>(undefined);
  const [containers, setContainers] = useState<Container[]>([]);
  const [containerTypes, setContainerTypes] = useState<ContainerType[]>([]);
  const [ideds, setIdeds] = useState<IdEd[]>([]);
  const [cashbook, setCashbook] = useState<CashBook[]>([]);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetail[]>([]);

  const [reload, setReload] = React.useState(false);

  const { currentUser } = useContext(AuthContext);

  const [ided_form] = Form.useForm();
  const [idedModalState, setIdedModalState] = useState<'add' | 'edit' | null>(null);
  const [editingIdEd, setEditingIdEd] = useState<IdEd | null>(null);

  const [container_form] = Form.useForm();
  const [containerModalState, setContainerModalState] = useState<'add' | 'edit' | null>(null);
  const [editingContainer, setEditingContainer] = useState<Container | null>(null);

  const { permission: invoice_permission } = useGetPermissionInfo(
    currentUser,
    DataTableType.INVOICE
  );
  const { permission: job_permission } = useGetPermissionInfo(currentUser, DataTableType.JOB);
  const { permission: ided_permission } = useGetPermissionInfo(currentUser, DataTableType.ID_ED);
  const { permission: container_permission } = useGetPermissionInfo(
    currentUser,
    DataTableType.CONTAINER
  );

  const navigate = useNavigate();
  useDocumentTitle(job?.job_code || 'Job');

  const { openDrawer, LogDrawer } = useLogDrawer();

  useEffect(() => {
    if (!job_id) return;
    getJob(job_id).then((job) => {
      setJob(job);
      if (job && job.containers) {
        const undeletedContainers = compact(job.containers.items).filter(
          (c) => c._deleted !== true
        );
        setContainers(undeletedContainers);
      } else {
        setContainers([]);
      }
      if (job && job.id_eds) {
        const undeletedIdEds = compact(job.id_eds.items).filter((ided) => ided._deleted !== true);
        setIdeds(undeletedIdEds);
      } else {
        setIdeds([]);
      }
    });
  }, [job_id, reload]);

  const reloadData = () => {
    setReload(!reload);
  };

  useEffect(() => {
    if (!job_id) return;
    // listContainers(job_id).then((containers) => {
    //   setContainers(containers);
    // });
    // listIdEds(job_id).then((ideds) => {
    //   setIdeds(ideds);
    // });
    listCashBook(job_id).then((cashbook) => {
      setCashbook(cashbook);
    });
  }, [job_id]);

  useEffect(() => {
    if (!job) return;
    const getInvoiceDetails = async () => {
      if (!job.invoice_id) {
        setInvoiceDetails([]);
        return;
      }
      const invoiceDetails = await listInvoiceDetails(job.invoice_id);
      setInvoiceDetails(invoiceDetails);
    };
    getInvoiceDetails();
  }, [job]);

  useEffect(() => {
    if (!job) return;
    const getContainerType = async () => {
      const containerTypes = await DataStore.query(ContainerType);
      setContainerTypes(containerTypes);
    };
    getContainerType();
  }, [job]);

  // if the user is in admin, he/she is allowed to lock/archive the record
  const lockRecord = async (lock: boolean) => {
    if (!job) return;
    if (!currentUser) return;
    if (lock === true && !job_permission.LOCK) return;
    if (lock === false && !job_permission.UNLOCK) return;
    const updatedJob = await updateJob({
      id: job.id,
      archived: lock,
      _version: job._version,
    });
    await logActivity({
      user_email: currentUser.email,
      job,
      model_type: DataTableType.JOB,
      operation_type: lock ? OperationType.LOCK : OperationType.UNLOCK,
      old_data: null,
      new_data: updatedJob,
    });
    reloadData();
  };

  useEffect(() => {
    if (idedModalState === 'add') {
      ided_form.resetFields();
    }
    if (idedModalState === 'edit') {
      ided_form.setFieldsValue(editingIdEd);
    }
  }, [idedModalState, editingIdEd, ided_form]);

  const hasEditingIdEdChanged = (editingIdEd: IdEd, values: IdEd) => {
    const fieldListToCheck = [
      'id_ed_number',
      'id_ed_type',
      'bl_number',
      'form_required',
      'form_number',
      'remark',
    ];
    // @ts-ignore
    return fieldListToCheck.some((field) => editingIdEd[field] !== values[field]);
  };

  const onFormSave = async (values: IdEd) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      if (idedModalState === 'add') {
        const newIdEd = await createIdEd({
          ...values,
          job_id,
          archived: false,
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.ID_ED,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newIdEd,
        });
      } else if (idedModalState === 'edit') {
        if (!editingIdEd) return;
        const latestIdEd = await getIdEd(editingIdEd.id);
        if (!latestIdEd) return;
        if (!hasEditingIdEdChanged(latestIdEd, values)) {
          setIdedModalState(null);
          return;
        }
        const updatedIdEd = await updateIdEd({
          ...values,
          id: latestIdEd.id,
          _version: latestIdEd._version,
        });
        // logActivity(user.attributes.email, job, DataTableType.ID_ED, updatedIdEd, OperationType.UPDATE);
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.ID_ED,
          operation_type: OperationType.UPDATE,
          old_data: latestIdEd,
          new_data: updatedIdEd,
        });
      }
    } catch (error) {
      console.log('error saving id_ed', error);
    } finally {
      reloadData();
      ided_form.resetFields();
      setIdedModalState(null);
    }
  };

  const checkJobHasRelevantData = (job: JobAPI) => {
    let returnMessage = '';
    const containers = job.containers
      ? job.containers.items.filter((c) => !(c?._deleted === true))
      : [];
    const id_eds = job.id_eds ? job.id_eds.items.filter((i) => !(i?._deleted === true)) : [];
    const cash_book_entries = job.cash_book_entries
      ? job.cash_book_entries.items.filter((c) => !(c?._deleted === true))
      : [];
    let hasRelevantData = false;
    if (job.invoice_id) {
      returnMessage = returnMessage + 'Invoice, ';
      hasRelevantData = true;
    }
    if (containers.length > 0) {
      returnMessage = returnMessage + `Container (${containers.length}), `;
      hasRelevantData = true;
    }
    if (id_eds.length > 0) {
      returnMessage = returnMessage + `ID/ED (${id_eds.length}), `;
      hasRelevantData = true;
    }
    if (cash_book_entries.length > 0) {
      returnMessage = returnMessage + `Cashbook (${cash_book_entries.length}), `;
      hasRelevantData = true;
    }
    returnMessage = returnMessage.slice(0, -2);
    return { hasRelevantData, returnMessage };
  };

  // useEffect(() => {
  //   console.log(job);
  // }, [job]);

  const onJobDelete = async () => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      const { hasRelevantData, returnMessage } = checkJobHasRelevantData(job);
      if (hasRelevantData) {
        message.open({
          type: 'error',
          content: (
            <p>
              This job has the following linked data:
              <br />
              <span style={{ color: 'red' }}>{returnMessage}</span>
              <br />
              Please delete them first.
            </p>
          ),
          duration: 4,
        });
        return;
      }
      const deletedJob = await deleteJob(job.id, job._version);
      await logActivity({
        user_email: currentUser.email,
        job,
        model_type: DataTableType.JOB,
        operation_type: OperationType.DELETE,
        old_data: job,
        new_data: null,
      });
    } catch (error) {
      console.log('error deleting job', error);
    }
    messageApi.open({
      type: 'success',
      content: `Job (${job.job_code}) has been deleted.`,
      duration: 2,
    });
    setTimeout(() => {
      navigate('/jobs');
    }, 2000);
  };

  const onIdedDelete = async (idEd: IdEd) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      const deletedIdEd = await deleteIdEd(idEd.id, idEd._version);
      // logActivity(user.attributes.email, job, DataTableType.ID_ED, deletedIdEd, OperationType.DELETE);
      logActivity({
        user_email: currentUser.email,
        job,
        model_type: DataTableType.ID_ED,
        operation_type: OperationType.DELETE,
        old_data: deletedIdEd,
        new_data: null,
      });
    } catch (error) {
      console.log('error deleting id_ed', error);
    } finally {
      reloadData();
    }
  };

  const ided_columns: ColumnsType<IdEd> = [
    {
      title: 'ID/ED',
      dataIndex: 'id_ed_type',
      key: 'id_ed_type',
      width: 80,
    },
    {
      title: 'ID/ED No.',
      dataIndex: 'id_ed_number',
      key: 'id_ed_number',
      ellipsis: true,
    },
    {
      title: 'BL No.',
      dataIndex: 'bl_number',
      key: 'bl_number',
      ellipsis: true,
    },
    {
      title: 'Form Required',
      dataIndex: 'form_required',
      key: 'form_required',
      width: 120,
      render: (form_required: boolean) => (form_required ? <CheckOutlined /> : null),
    },
    {
      title: 'Form No.',
      dataIndex: 'form_number',
      key: 'form_number',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      // width: 150,
      ellipsis: true,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 160,
      render: (_: any, record: IdEd) => (
        <Space>
          <ProfileOutlined
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              if (!job_id) return;
              openDrawer(job_id, DataTableType.ID_ED, record.id, 'model');
            }}
          />
          {/* 
          Button is disabled when
          1. job is archived
          2. user does not have permission to edit ID/ED
          */}
          <Button
            onClick={() => {
              setIdedModalState('edit');
              setEditingIdEd(record);
            }}
            type='link'
            size='small'
            disabled={job && job.archived ? true : ided_permission.UPDATE ? false : true}
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete ID/ED ${record.id_ed_number}?`}
            onConfirm={() => onIdedDelete(record)}
            okText='Yes'
            cancelText='No'
          >
            {/* 
            Button is disabled when
            1. job is archived
            2. user does not have permission to delete ID/ED
            */}
            <Button
              type='link'
              danger
              size='small'
              disabled={job && job.archived ? true : ided_permission.DELETE ? false : true}
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (containerModalState === 'add') {
      container_form.resetFields();
    }
    if (containerModalState === 'edit') {
      container_form.setFieldsValue(editingContainer);
    }
  }, [containerModalState, container_form, editingContainer]);

  const hasEditingContainerChanged = (editingContainer: Container, values: Container) => {
    return (
      editingContainer.container_number !== values.container_number ||
      editingContainer.container_type_id !== values.container_type_id
    );
  };

  const onContainerSave = async (values: Container) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      if (containerModalState === 'add') {
        const newContainer = await createContainer({ ...values, job_id, archived: false });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.CONTAINER,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newContainer,
        });
        // await logActivity(user.attributes.email, job, DataTableType.CONTAINER, newContainer, OperationType.CREATE);

        setContainerModalState(null);
      } else if (containerModalState === 'edit') {
        if (!editingContainer) return;
        const latestContainer = await getContainer(editingContainer.id);
        if (!latestContainer) return;
        if (!hasEditingContainerChanged(latestContainer, values)) {
          setContainerModalState(null);
          return;
        }
        const updatedContainer = await updateContainer({
          ...values,
          id: latestContainer.id,
          _version: latestContainer._version,
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.CONTAINER,
          operation_type: OperationType.UPDATE,
          old_data: latestContainer,
          new_data: updatedContainer,
        });
        // await logActivity(user.attributes.email, job, DataTableType.CONTAINER, updatedContainer, OperationType.UPDATE);
        setContainerModalState(null);
      }
    } catch (error) {
      console.log('error saving container', error);
    } finally {
      reloadData();
      container_form.resetFields();
      setContainerModalState(null);
    }
  };

  const onContainerDelete = async (container: Container) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      const deletedContainer = await deleteContainer(container.id, container._version);
      await logActivity({
        user_email: currentUser.email,
        job,
        model_type: DataTableType.CONTAINER,
        operation_type: OperationType.DELETE,
        old_data: deletedContainer,
        new_data: null,
      });
      // await logActivity(user.attributes.email, job, DataTableType.CONTAINER, deletedContainer, OperationType.DELETE);
    } catch (error) {
      console.log('error deleting container', error);
    } finally {
      reloadData();
    }
  };

  const container_columns: ColumnsType<Container> = [
    {
      title: 'Container Number',
      dataIndex: 'container_number',
      key: 'container_number',
    },
    {
      title: 'Container Type',
      dataIndex: 'container_type_id',
      key: 'container_type_id',
      width: 160,
      render: (container_type_id: string) => {
        const containerType = containerTypes.find((ct) => ct.id === container_type_id);
        return containerType ? containerType.container_type : '';
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 160,
      render: (_: any, record: Container) => (
        <Space>
          <ProfileOutlined
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              if (!job_id) return;
              openDrawer(job_id, DataTableType.CONTAINER, record.id, 'model');
            }}
          />
          {/* 
          Button is disabled when
          1. job is locked
          2. user doesn't have permission to edit container */}
          <Button
            onClick={() => {
              setContainerModalState('edit');
              setEditingContainer(record);
            }}
            type='link'
            disabled={job && job.archived ? true : !container_permission.UPDATE}
            size='small'
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete container ${record.container_number}?`}
            onConfirm={() => onContainerDelete(record)}
            okText='Yes'
            cancelText='No'
          >
            {/* 
          Button is disabled when
          1. job is locked
          2. user doesn't have permission to delete containers */}
            <Button
              type='link'
              danger
              disabled={job && job.archived ? true : !container_permission.DELETE}
              size='small'
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      {contextHolder}
      <Card
        title={
          <Space size='large' align='start'>
            <Button
              type='primary'
              shape='circle'
              icon={<LeftOutlined />}
              size='middle'
              onClick={() => navigate(`/jobs`)}
            />
            <Title level={4} style={{ color: 'darkred' }}>
              Job Details
            </Title>
            {/* <Button type='link' onClick={showChangeLog}> */}
            <Button
              type='link'
              onClick={() => {
                if (!job_id) return;
                openDrawer(job_id, undefined, undefined, 'job');
              }}
            >
              complete change history
            </Button>
          </Space>
        }
        style={{
          width: 'max(calc(100vw - 500px), 1000px)',
          height: 'fit-content',
        }}
        extra={
          <Space>
            <Popconfirm
              title={job?.archived ? 'Unlock this record?' : 'Lock this record?'}
              description={
                job?.archived ? (
                  <div>
                    <div>Are you sure you want to unlock this record?</div>
                    <div>
                      Once unlocked, the job and containers, id/ed, cashbook and invoice of this job
                      can be edited.
                    </div>
                    <div>Level 3 and above can lock the record again.</div>
                  </div>
                ) : (
                  <div>
                    <div>Are you sure you want to lock this record?</div>
                    <div>
                      Once locked, the job and containers, id/ed, cashbook and invoice of this job
                      cannot be edited.
                    </div>
                    <div>Only Admin can unlock the record.</div>
                  </div>
                )
              }
              onConfirm={() => lockRecord(!job?.archived)}
            >
              <Button
                style={{ marginRight: 30 }}
                disabled={
                  job?.archived
                    ? job_permission.UNLOCK
                      ? false
                      : true
                    : job_permission.LOCK
                    ? false
                    : true
                }
              >
                {job?.archived ? 'Unlock' : 'Lock'}
              </Button>
            </Popconfirm>
            <Tooltip
              title={job?.archived ? 'Record is locked.' : 'Record is not locked.'}
              color={job?.archived ? 'red' : 'green'}
            >
              {!job || job?.archived ? (
                <LockFilled style={{ fontSize: '1.5em', color: 'darkred' }} />
              ) : (
                <UnlockFilled style={{ fontSize: '1.5em', color: 'green' }} />
              )}
            </Tooltip>
            {/* <Tooltip
              title={job?.archived ? 'Record is locked.' : 'Record is not locked.'}
              color={job?.archived ? 'red' : 'green'}
            >
              {currentUser?.admin ? (
                <Popconfirm
                  title={job?.archived ? 'Unlock this record?' : 'Lock this record?'}
                  description={
                    job?.archived ? (
                      <div>
                        <div>Are you sure you want to unlock this record?</div>
                        <div>Once unlocked, it can be edited.</div>
                        <div>Only Admin can lock the record again.</div>
                      </div>
                    ) : (
                      <div>
                        <div>Are you sure you want to lock this record?</div>
                        <div>Once locked, it cannot be edited.</div>
                        <div>Only Admin can unlock the record.</div>
                      </div>
                    )
                  }
                  onConfirm={() => lockRecord(!job?.archived)}
                >
                  {job?.archived ? (
                    <LockFilled
                      style={{
                        fontSize: '1.5em',
                        color: 'darkred',
                        cursor: 'pointer',
                      }}
                    />
                  ) : (
                    <UnlockFilled
                      style={{
                        fontSize: '1.5em',
                        color: 'green',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </Popconfirm>
              ) : !job || job?.archived ? (
                <LockFilled style={{ fontSize: '1.5em', color: 'darkred' }} />
              ) : (
                <UnlockFilled style={{ fontSize: '1.5em', color: 'green' }} />
              )}
            </Tooltip> */}
            <NetworkIndicator />
          </Space>
        }
      >
        <List>
          <ListItem
            key={'job_code'}
            label={<span style={{ color: 'blue', marginBottom: 0 }}>Job Code</span>}
            content={
              <Row>
                <Col span={12}>
                  <Title level={5} style={{ color: 'blue', marginBottom: 0 }}>
                    {job?.job_code}
                  </Title>
                </Col>
                <Col
                  span={12}
                  style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 20 }}
                >
                  <Space size='middle'>
                    <Button
                      type='link'
                      onClick={() => {
                        if (!job_id) return;
                        openDrawer(job_id, DataTableType.JOB, job_id, 'model');
                      }}
                    >
                      change history
                    </Button>

                    <Button
                      type='primary'
                      onClick={() => navigate('edit')}
                      disabled={job?.archived ? true : job_permission.UPDATE ? false : true}
                    >
                      Edit Job
                    </Button>
                    {currentUser && currentUser.admin && job_permission.DELETE && (
                      <Popconfirm
                        style={{ width: 200 }}
                        title='Are you sure you want to delete this job?'
                        description={
                          <p style={{ width: 300 }}>
                            If you are sure, first make sure that there is no ided, container,
                            invoice and cashbook data associated to this job.
                          </p>
                        }
                        onConfirm={onJobDelete}
                      >
                        <Button
                          type='primary'
                          danger
                          disabled={job?.archived ? true : job_permission.DELETE ? false : true}
                        >
                          Delete Job
                        </Button>
                      </Popconfirm>
                    )}
                  </Space>
                </Col>
              </Row>
            }
          />
          <ListItem
            key='customer'
            label='Customer'
            // @ts-ignore
            content={job?.customer?.customer_name || ''}
          />
          <ListItem
            key='date_of_creation'
            label='Job Created Date'
            content={job?.date_of_creation}
          />
          <ListItem
            key='invoice'
            label={
              <span>
                Invoice ({' '}
                {job?.invoice_id ? (
                  <CheckOutlined style={{ color: 'green', fontSize: 18, verticalAlign: -5 }} />
                ) : (
                  <sub>
                    <MinusOutlined style={{ color: 'red', fontSize: 18 }} />
                  </sub>
                )}{' '}
                )
              </span>
            }
            content={
              <Row>
                <Col span={10}>{job?.invoice_id ? <>Items: {invoiceDetails.length}</> : null}</Col>
                <Col span={10}>
                  {job?.invoice_id ? (
                    <>
                      Sum:{' '}
                      <Text
                        copyable={{
                          text: invoiceDetails
                            .reduce(
                              (acc, cur) =>
                                (acc = acc + (cur.amount_mmk || 0) - (cur.debit_mmk || 0)),
                              0
                            )
                            .toString(),
                        }}
                      >
                        {invoiceDetails
                          .reduce(
                            (acc, cur) =>
                              (acc = acc + (cur.amount_mmk || 0) - (cur.debit_mmk || 0)),
                            0
                          )
                          .toLocaleString()}{' '}
                        MMK
                      </Text>
                      {' / '}
                      <Text
                        copyable={{
                          text: invoiceDetails
                            .reduce(
                              (acc, cur) =>
                                (acc = acc + (cur.amount_usd || 0) - (cur.debit_usd || 0)),
                              0
                            )
                            .toString(),
                        }}
                      >
                        {invoiceDetails
                          .reduce(
                            (acc, cur) =>
                              (acc = acc + (cur.amount_usd || 0) - (cur.debit_usd || 0)),
                            0
                          )
                          .toLocaleString()}{' '}
                        USD
                      </Text>
                      {/* {invoiceDetails
                        .reduce((acc, cur) => (acc = acc + cur.amount_mmk), 0)
                        .toLocaleString()} */}
                    </>
                  ) : null}
                </Col>
                <Col
                  span={4}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    type='default'
                    style={{ position: 'absolute', top: -5, left: -20 }}
                    onClick={() => navigate(`invoice/${job?.invoice ? job.invoice.id : 'new'}`)}
                    disabled={
                      job?.invoice
                        ? false
                        : invoice_permission.CREATE
                        ? job?.archived
                          ? true
                          : false
                        : true
                    }
                  >
                    {job?.invoice ? 'View Invoice' : 'Create Invoice'}
                  </Button>
                </Col>
              </Row>
            }
          />
          <ListItem
            key='Country'
            label='Country'
            // @ts-ignore
            content={job?.country?.country_name || ''}
          />
          {/* <ListItem
            key='id_ed'
            label='ID / ED'
            content={
              <Row>
                <Col span={20}>Count: {job?.id_eds?.items.length || 0}</Col>
                <Col
                  span={4}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    type='default'
                    style={{ position: 'absolute' }}
                    onClick={() => navigate('ideds')}
                  >
                    View ID/ED
                  </Button>
                </Col>
              </Row>
            }
          /> */}
          <List.Item style={{ padding: 0 }}>
            <Collapse
              collapsible='icon'
              ghost
              activeKey={ideds.length > 0 ? ['1'] : undefined}
              style={{ width: '100%', translate: -16 }}
              items={[
                {
                  key: '1',
                  showArrow: false,
                  label: (
                    <Row style={{ width: '100%', minHeight: 22 }}>
                      <Col span={8}>
                        <Title level={5} style={{ marginBottom: 0 }}>
                          ID / ED
                        </Title>
                      </Col>
                      <Col span={16} style={{ whiteSpace: 'pre-wrap', translate: 10 }}>
                        <Row>
                          <Col span={20}>Count: {ideds.length}</Col>
                          <Col
                            span={4}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              position: 'relative',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              type='default'
                              style={{ position: 'absolute' }}
                              onClick={() => setIdedModalState('add')}
                              disabled={
                                job && job.archived ? true : ided_permission.CREATE ? false : true
                              }
                            >
                              Add ID/ED
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ),
                  children: (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingLeft: '30px',
                      }}
                    >
                      <Table
                        columns={ided_columns}
                        dataSource={ideds}
                        rowKey={'id'}
                        size='small'
                        onRow={() => ({
                          style: { backgroundColor: '#f5f1f5' },
                        })}
                        pagination={
                          (job?.id_eds?.items || []).length > 10 ? { pageSize: 10 } : false
                        }
                      />
                    </div>
                  ),
                },
              ]}
            />
          </List.Item>
          <ListItem key='route' label='Route' content={job?.route} />
          <ListItem
            key='comodity'
            label='Comodity'
            content={<Text copyable={!!job?.comodity}>{job?.comodity}</Text>}
          />
          <ListItem
            key='date_of_arrival'
            label='Date Of Arrival / Date Of Departure'
            content={job?.date_of_arrival}
          />
          <ListItem
            key='voyage'
            label='Voyage / Flight'
            content={<Text copyable={!!job?.voyage}>{job?.voyage}</Text>}
          />
          <ListItem
            key='forwarder'
            label='Forwarder'
            content={<Text copyable={!!job?.forwarder}>{job?.forwarder}</Text>}
          />
          <ListItem
            key='liner'
            label='Liner'
            content={<Text copyable={!!job?.liner}>{job?.liner}</Text>}
          />
          <ListItem
            key='description'
            label='Description'
            content={<Text copyable={!!job?.description}>{job?.description}</Text>}
          />
          {/* <ListItem
            label='Description'
            content={<Paragraph ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}>{job?.description}</Paragraph>}
          /> */}
          <ListItem
            key='remark'
            label='Remark'
            content={<Text copyable={!!job?.remark}>{job?.remark}</Text>}
          />
          {/* <ListItem
            key='containers'
            label='Containers'
            content={
              <Row>
                <Col span={6}>Count: {job?.containers?.items.length || 0}</Col>
                <Col span={10}>
                  {Object.entries(
                    (job?.containers?.items ? compact(job.containers.items) : []).reduce(
                      (acc, curr) => {
                        // @ts-ignore
                        acc[curr.container_type_id] = (acc[curr.container_type_id] || 0) + 1;
                        return acc;
                      },
                      {}
                    )
                  )
                    .map(
                      ([key, value]) =>
                        `${containerTypes.find((ct) => ct.id === key)?.container_type}: ${value}`
                    )
                    .join(', ')}
                </Col>

                <Col
                  span={8}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    type='default'
                    style={{ position: 'absolute' }}
                    onClick={() => navigate('containers')}
                  >
                    View Containers
                  </Button>
                </Col>
              </Row>
            }
          /> */}
          <List.Item style={{ padding: 0 }}>
            <Collapse
              collapsible='icon'
              ghost
              activeKey={containers.length > 0 ? ['1'] : undefined}
              style={{ width: '100%', translate: -16 }}
              items={[
                {
                  key: '1',
                  showArrow: false,
                  label: (
                    <Row style={{ width: '100%', minHeight: 22 }}>
                      <Col span={8}>
                        <Title level={5} style={{ marginBottom: 0 }}>
                          Containers
                        </Title>
                      </Col>
                      <Col span={16} style={{ whiteSpace: 'pre-wrap', translate: 10 }}>
                        <Row>
                          <Col span={6}>Count: {containers.length}</Col>
                          <Col span={10}>
                            {/* Return containerType1: 2 (counttByType), containerType2, 3 */}
                            {Object.entries(
                              containers.reduce((acc, curr) => {
                                // @ts-ignore
                                acc[curr.container_type_id] =
                                  // @ts-ignore
                                  (acc[curr.container_type_id] || 0) + 1;
                                return acc;
                              }, {})
                            )
                              .map(
                                ([key, value]) =>
                                  `${
                                    containerTypes.find((ct) => ct.id === key)?.container_type
                                  }: ${value}`
                              )
                              .join(', ')}
                          </Col>

                          <Col
                            span={8}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              position: 'relative',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              type='default'
                              style={{ position: 'absolute' }}
                              onClick={() => setContainerModalState('add')}
                              disabled={job && job.archived ? true : !container_permission.CREATE}
                            >
                              Add Containers
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ),
                  children: (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingLeft: '30px',
                      }}
                    >
                      <Table
                        columns={container_columns}
                        dataSource={containers}
                        rowKey={'id'}
                        style={{ width: '700px' }}
                        size='small'
                        onRow={() => ({
                          style: { backgroundColor: '#f5f1f5' },
                        })}
                        pagination={
                          (job?.containers?.items || []).length > 10 ? { pageSize: 10 } : false
                        }
                      />
                    </div>
                  ),
                },
              ]}
            />
          </List.Item>
          <ListItem
            key='date_of_delivery'
            label='Job Delivered Date'
            content={job?.date_of_delivery}
          />
          <ListItem
            key='active'
            label='Active'
            content={job?.is_active ? <CheckSquareTwoTone /> : <BorderOutlined />}
          />
          <ListItem
            key='cashbook'
            label='Cashbook'
            content={
              <Row>
                <Col span={6}>Count: {cashbook.length}</Col>
                <Col span={14}>
                  Sum:{' '}
                  <Text
                    copyable={{
                      text: cashbook
                        .reduce(
                          (acc, cur) => (acc = acc + (cur.credit_mmk || 0) - (cur.debit_mmk || 0)),
                          0
                        )
                        .toString(),
                    }}
                  >
                    {cashbook
                      .reduce(
                        (acc, cur) => (acc = acc + (cur.credit_mmk || 0) - (cur.debit_mmk || 0)),
                        0
                      )
                      .toLocaleString()}{' '}
                    MMK
                  </Text>
                  {' / '}
                  <Text
                    copyable={{
                      text: cashbook
                        .reduce(
                          (acc, cur) => (acc = acc + (cur.credit_usd || 0) - (cur.debit_usd || 0)),
                          0
                        )
                        .toString(),
                    }}
                  >
                    {cashbook
                      .reduce(
                        (acc, cur) => (acc = acc + (cur.credit_usd || 0) - (cur.debit_usd || 0)),
                        0
                      )
                      .toLocaleString()}{' '}
                    USD
                  </Text>
                </Col>
                <Col
                  span={4}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    type='default'
                    style={{ position: 'absolute' }}
                    onClick={() => navigate('cashbook')}
                  >
                    View Cashbook
                  </Button>
                </Col>
              </Row>
            }
          />
        </List>
        {/* IDED Model */}
        <Modal
          title={
            idedModalState === 'add'
              ? `Add ID/ED to Job ${job?.job_code}`
              : `Edit ID/ED for Job ${job?.job_code}`
          }
          open={idedModalState !== null}
          okText='Save'
          onOk={async () => {
            ided_form.submit();
          }}
          onCancel={() => {
            setIdedModalState(null);
          }}
          styles={{ body: { paddingTop: 20 } }}
          destroyOnClose={true}
          maskClosable={false}
        >
          <Form
            form={ided_form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFormSave}
          >
            <Form.Item
              label='ID/ED Type'
              name='id_ed_type'
              rules={[{ required: true, message: 'Please select ID/ED Type.' }]}
            >
              <Select>
                {Object.values(ID_ED_TYPE).map((type) => (
                  <Select.Option value={type} key={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='ID/ED Number'
              name='id_ed_number'
              rules={[{ required: true, message: 'ID/ED Number is required.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label='BL Number' name='bl_number'>
              <Input />
            </Form.Item>
            <Form.Item label='Form Required' name='form_required' valuePropName='checked'>
              <Checkbox />
            </Form.Item>
            <Form.Item label='Form Number' name='form_number'>
              <Input />
            </Form.Item>
            <Form.Item label='Remark' name='remark'>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        {/* Container Model */}
        <Modal
          title={
            containerModalState === 'add'
              ? `Add Container for Job ${job?.job_code}`
              : `Edit Container for Job ${job?.job_code}`
          }
          open={containerModalState !== null}
          okText={'Save'}
          onOk={async () => {
            container_form.submit();
          }}
          onCancel={() => {
            setContainerModalState(null);
          }}
          styles={{ body: { paddingTop: 20 } }}
          destroyOnClose={true}
          maskClosable={false}
        >
          <Form
            form={container_form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onContainerSave}
          >
            <Form.Item
              label='Container Number'
              name='container_number'
              rules={[{ required: true, message: 'Container Number is required.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Container Type'
              name='container_type_id'
              rules={[{ required: true, message: 'Container Type is required.' }]}
            >
              <Select>
                {containerTypes
                  .sort((a, b) => a.container_type.localeCompare(b.container_type))
                  .map((ct) => (
                    <Select.Option value={ct.id} key={ct.id}>
                      {ct.container_type}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
      {LogDrawer}
    </div>
  );
};

export default Job;

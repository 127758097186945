import React, { useEffect, useRef, useState } from 'react';
import { DataTableType, Log, OperationType } from '../API';
import { Button, ConfigProvider, Drawer, Input, InputRef, Space, Table, Typography } from 'antd';
import { ColumnType, ColumnsType, FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { FilterTwoTone, SearchOutlined } from '@ant-design/icons';
import { CustomUserType, convertUsers, listUsers } from '../pages/Users/adminFunctions';

const { Title, Text } = Typography;

interface logCompareDataType {
  field: string;
  oldData: string | undefined;
  newData: string | undefined;
  changed: boolean;
}

const isHiddenField = (field_name: string, model_name: DataTableType) => {
  const commonHiddenFields = [
    'id',
    '__typename',
    'archived',
    'created_by',
    'modified_by',
    '_deleted',
    'createdAt',
    'updatedAt',
    'virtual_index',
    '_version',
    '_lastChangedAt',
  ];
  switch (model_name) {
    case 'JOB':
      return [
        ...commonHiddenFields,
        'job_id',
        'invoice',
        'invoice_id',
        'customer',
        'date_of_creation',
        'country',
        'id_eds',
        'containers',
        'cash_book_entries',
      ].includes(field_name);
    case 'CONTAINER':
      return [
        ...commonHiddenFields,
        'container_type',
        'type',
        'job_id',
        'job',
        'container_id',
      ].includes(field_name);
    case 'ID_ED':
      return [...commonHiddenFields, 'container_type', 'job', 'job_id', 'id_ed_id'].includes(
        field_name
      );
    case 'CASH_BOOK':
      return [
        ...commonHiddenFields,
        'account',
        'is_deleted',
        'remark',
        'job',
        'job_id',
        'cashbook_id',
      ].includes(field_name);
    case 'CASH_HANDLER':
      return [...commonHiddenFields].includes(field_name);
    case 'INVOICE':
      return [
        ...commonHiddenFields,
        'invoice_details',
        'invoice_detail_ids',
        'job',
        'job_id',
      ].includes(field_name);
    case 'INVOICE_DETAIL':
      return [
        ...commonHiddenFields,
        'cash_book_entry',
        'serial_number',
        'invoice',
        'invoice_id',
      ].includes(field_name);
    case 'CUSTOMER':
      return [...commonHiddenFields, 'customer_id', 'jobs', 'opening_amount', 'remark'].includes(
        field_name
      );
    case 'ACCOUNT':
      return [...commonHiddenFields, 'account_id', 'cash_book_entries', 'account_group'].includes(
        field_name
      );
    case 'ACCOUNT_GROUP':
      return [...commonHiddenFields, 'accounts', 'account_group_id'].includes(field_name);
  }
};

interface LogDrawerProps {
  logs: Log[];
  show_full_log: boolean;
  open_drawer: boolean;
  close_drawer: () => void;
}

function findDifferences<T extends Record<string, any>>(
  newData: T,
  oldData: T
): Record<string, { newData: any; oldData: any }> {
  let differences: Record<string, { newData: any; oldData: any }> = {};

  for (let key in newData) {
    if (
      Object.prototype.hasOwnProperty.call(newData, key) &&
      Object.prototype.hasOwnProperty.call(oldData, key)
    ) {
      if (newData[key] !== oldData[key]) {
        differences[key] = {
          newData: newData[key],
          oldData: oldData[key],
        };
      }
    }
  }

  return differences;
}

const LogDrawer = ({ logs, show_full_log, open_drawer, close_drawer }: LogDrawerProps) => {
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const searchInput = useRef<InputRef>(null);
  const [allStaff, setAllStaff] = useState<CustomUserType[]>([]);

  // useEffect(() => {
  //   console.log('logger initiated ----------');
  // }, []);

  useEffect(() => {
    const fetchCashers = async () => {
      // console.log('fetching cashers');
      const users = await listUsers();
      const customUsers = await convertUsers(users);
      setAllStaff(customUsers.sort((a, b) => a.email.localeCompare(b.email)));
    };
    fetchCashers();
  }, []);

  const formatFieldName = (field_name: string) => {
    // if field name end with _id, remove _id
    // then remove the _ from the remaining string
    return field_name.replace(/_id$/, '').replace(/_/g, ' ');
  };

  const getRelevantData = (field_name: string, data: any) => {
    const field_data = data[field_name];
    // check if field_data is boolean and if so, return yes or no
    if (typeof field_data === 'boolean') return field_data ? 'Yes' : 'No';
    if (field_name === 'customer_id') return data['customer']?.customer_name;
    if (field_name === 'country_id') return data['country']?.country_name;
    if (field_name === 'container_type_id') return data['container_type']?.container_type;
    if (field_name === 'account_id') return data['account']?.account_name;
    if (field_name === 'cash_handler') {
      const staff = allStaff.find((staff) => staff.email === field_data);
      return staff ? `${staff.name} (${staff.email})` : field_data;
    }
    if (
      ['debit_mmk', 'credit_mmk', 'debit_usd', 'credit_usd', 'amount_mmk', 'amount_usd'].includes(
        field_name
      ) &&
      field_data
    )
      return field_data.toLocaleString();
    return field_data;
  };

  const getColumnSearchProps = (dataIndex: keyof Log): ColumnType<Log> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex.split('_').join(' ')}`}
          allowClear
          value={selectedKeys[selectedKeys.length - 1]}
          onChange={(e) => {
            const value = e.target.value;
            // if (value.length === 0) return;
            const prevFilter = (filteredInfo[dataIndex] || []) as string[];
            setSelectedKeys([...new Set([...prevFilter, value])]);
          }}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8 }}
        />
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <Button type='primary' onClick={() => confirm()} icon={<SearchOutlined />} size='small' style={{ width: 90 }}>
            Search
          </Button> */}
          <Button
            type='primary'
            onClick={() => {
              // copy filterInfo to a new object and remove the dataIndex key
              const newFilteredInfo = { ...filteredInfo };
              delete newFilteredInfo[dataIndex];
              setFilteredInfo(newFilteredInfo);
              // confirm();

              clearFilters!();
              close();
            }}
            size='small'
            style={{ width: 90 }}
          >
            Clear Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? 'primary' : undefined }} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.current?.select();
        }, 100);
      }
    },
  });

  const changeLogColumns: ColumnType<Log>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => dayjs(record.changed_at).format('YYYY-MM-DD ddd'),
      width: 130,
      // create filter based on log.changed_at unique values
      filters: logs
        .map((log) => dayjs(log.changed_at).format('YYYY-MM-DD'))
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      filteredValue: filteredInfo.date || null,
      onFilter: (value, record) => dayjs(record.changed_at).format('YYYY-MM-DD') === value,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (_, record) => dayjs(record.changed_at).format('h:mm a'),
      width: 80,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: 200,
      ellipsis: true,
      // create filter based on log.user (email) unique values
      filters: logs
        .map((log) => log.user)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      filteredValue: filteredInfo.user || null,
      onFilter: (value, record) => record.user === value,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      render: (value: string) => value.toLocaleLowerCase().replace('_', ' '),
      width: 100,
      filters: Object.values(OperationType).map((value) => ({
        text: value,
        value,
      })),
      filteredValue: filteredInfo.operation || null,
      onFilter: (value, record) => record.operation === value,
    },

    {
      title: 'Table',
      dataIndex: 'model',
      key: 'model',
      hidden: !show_full_log,
      render: (value: string) => value[0] + value.slice(1).toLocaleLowerCase().replace(/_/g, ''),
      width: 100,
      filters: Object.values(DataTableType).map((value) => ({
        text: value,
        value,
      })),
      filteredValue: filteredInfo.model || null,
      onFilter: (value, record) => record.model === value,
    },

    {
      title: 'Record ID',
      dataIndex: 'model_id',
      key: 'model_id',
      hidden: !show_full_log,
      filteredValue: filteredInfo.model_id || null,
      ...getColumnSearchProps('model_id'),
      // filters: logs
      //   .map((log) => log.model_id)
      //   .filter((value, index, self) => self.indexOf(value) === index)
      //   .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.model_id === value,
      width: 100,
      render: (value: string) => {
        return (
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {value.slice(0, 8)}
            <FilterTwoTone
              twoToneColor='lightgray'
              onClick={() => {
                setFilteredInfo((prev) => {
                  return {
                    ...prev,
                    model_id: [value],
                  };
                });
              }}
            />
          </Space>
        );
      },
    },
    {
      title: 'Fields',
      dataIndex: 'fields',
      key: 'fields',
      ellipsis: true,
      render: (value: string, record) => {
        const model_name = record.model;
        if (record.operation === 'LOCK' || record.operation === 'UNLOCK') return '---';
        if (record.operation === 'CREATE' || record.operation === 'DELETE') {
          const json_string = record.operation === 'CREATE' ? record.new_data : record.old_data;
          const data = json_string ? JSON.parse(json_string) : {};
          // const newRecordData = record.data ? JSON.parse(record.data) : {};
          if (record.model === 'JOB') {
            const job_code = data.job_code;
            const customer = data.customer.customer_name;
            return `${job_code} | ${customer}`;
          }
          if (record.model === 'CONTAINER') {
            const container_number = data.container_number;
            const container_type = data.container_type.container_type;
            return `${container_number} | ${container_type}`;
          }
          if (record.model === 'ID_ED') {
            const ided_number = data.id_ed_number;
            const id_ed_type = data.id_ed_type;
            return `${ided_number} (${id_ed_type})`;
          }
          if (record.model === 'CASH_BOOK') {
            const account = data.account?.account_name;
            const amount = (
              data.credit_mmk ||
              data.debit_mmk ||
              data.credit_usd ||
              data.debit_usd
            ).toLocaleString();
            const currency = data.credit_mmk || data.debit_mmk ? 'MMK' : 'USD';
            return `${account} | ${amount} ${currency}`;
          }
          if (record.model === 'CASH_HANDLER') {
            const short_name = data.short_name;
            return `${short_name}`;
          }
          if (record.model === 'INVOICE') {
            const invoice_date = data.invoice_date;
            const invoice_type = data.invoice_type;
            return `${invoice_date} | ${invoice_type}`;
          }
          if (record.model === 'INVOICE_DETAIL') {
            const account = data.description;
            const amount = (
              data.debit_usd ||
              data.debit_mmk ||
              data.amount_usd ||
              data.amount_mmk
            ).toLocaleString();
            const currency = data.debit_mmk || data.amount_mmk ? 'MMK' : 'USD';
            return `${amount} | ${account} ${currency}`;
          }
          if (record.model === 'CUSTOMER') {
            const customer_name = data.customer_name;
            return `${customer_name}`;
          }
        }
        // if (record.operation === 'DELETE') {
        //   if (record.model === 'JOB') return 'job_code + Customer';
        //   if (record.model === 'CONTAINER') return 'container_number + type';
        //   if (record.model === 'ID_ED') return 'ided_number (ID/ED)';
        //   if (record.model === 'CASH_BOOK') return 'Account + Description';
        //   if (record.model === 'INVOICE') return 'Invoice Date + Invoice Type';
        //   if (record.model === 'INVOICE_DETAIL') return 'Account + Amount';
        // }
        if (record.operation === 'UPDATE') {
          // make sure the logs are sorted by changed_at in descending order
          // const relevantlogs = logs.filter(
          //   (log) =>
          //     log.changed_at <= record.changed_at && log.model === record.model && log.model_id === record.model_id
          // );
          // const currentIndex = relevantlogs.indexOf(record);
          // const timeIndex = logs.length - currentIndex - 1;
          // const previousIndex = currentIndex + 1;
          const currentData = record.new_data ? JSON.parse(record.new_data) : {};
          const previousData = record.old_data ? JSON.parse(record.old_data) : {};
          // previousIndex === relevantlogs.length ? {} : JSON.parse(relevantlogs[previousIndex].data || '{}');
          // const changedData = record.data ? JSON.parse(record.data) : {};
          // const previousData = timeIndex === 0 ? {} : JSON.parse(logs[timeIndex - 1].data || '{}');
          const differentFields = findDifferences(currentData, previousData);

          return Object.keys(differentFields)
            .filter((field) => !isHiddenField(field, model_name))
            .map((field) => formatFieldName(field))
            .join(', ');
        }
        return '---';
      },
    },
  ];

  const logComparisonColumns: ColumnsType<logCompareDataType> = [
    {
      title: 'Field Name',
      dataIndex: 'field',
      key: 'field',
      // render: (value: string) => value.toLocaleLowerCase(),
      width: 100,
    },
    {
      title: 'Old Data',
      dataIndex: 'oldData',
      key: 'oldData',
      width: 200,
    },
    {
      title: 'New Data',
      dataIndex: 'newData',
      key: 'newData',
      width: 200,
    },
  ];

  return (
    <Drawer
      title={show_full_log ? 'Complete Change History' : 'Change History'}
      // styles={{ header: { height: 120 } }}
      placement='right'
      closable={true}
      onClose={close_drawer}
      open={open_drawer}
      width={show_full_log ? 1000 : 800}
      extra={
        <Space size={'large'} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button
            type='link'
            size='middle'
            onClick={() => setFilteredInfo({})}
            hidden={!Object.values(filteredInfo).some((value) => value !== null)}
            style={{ margin: 0, padding: 0 }}
          >
            clear all filters
          </Button>
          <Title level={5} style={{ fontWeight: '500', marginRight: 30 }}>
            {logs.length === 0
              ? 'No change history found'
              : show_full_log
              ? `Job Code: ${logs[0].job_code}`
              : `${logs[0].model.replace('_', ' ')} ID: ${logs[0].model_id}`}
          </Title>
        </Space>
      }
    >
      <Table
        dataSource={logs}
        columns={changeLogColumns}
        pagination={false}
        rowKey={'id'}
        size='small'
        onChange={(_, filters, _a) => {
          setFilteredInfo(filters);
        }}
        expandable={{
          defaultExpandedRowKeys: [],
          expandedRowRender: (record) => {
            const model_name = record.model;
            const currentData = record.new_data ? JSON.parse(record.new_data) : {};
            const previousData = record.old_data ? JSON.parse(record.old_data) : {};
            // previousIndex === relevantlogs.length ? {} : JSON.parse(relevantlogs[previousIndex].data || '{}');
            const differentFields = findDifferences(currentData, previousData);
            const logComparisonData: logCompareDataType[] = [];
            Object.keys(record.new_data ? currentData : previousData)
              // .sort()
              .forEach((field) => {
                if (isHiddenField(field, model_name)) return;
                logComparisonData.push({
                  field: formatFieldName(field),
                  oldData: getRelevantData(field, previousData),
                  newData: getRelevantData(field, currentData),
                  changed: differentFields[field] ? true : false,
                });
              });

            return (
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      rowHoverBg: '#c9c9c9',
                    },
                  },
                }}
              >
                <Table
                  dataSource={logComparisonData}
                  columns={logComparisonColumns}
                  rowKey={'field'}
                  size='small'
                  pagination={false}
                  bordered
                  onRow={(record, rowIndex) => {
                    return {
                      style: {
                        whiteSpace: 'pre-wrap',
                        verticalAlign: 'top',
                        backgroundColor: record.changed ? '#ffc069' : '',
                      },
                    };
                  }}
                />
              </ConfigProvider>
            );
          },
          // expandedRowKeys: record => [record.id],
          // rowExpandable: (record) => true,
        }}
      />
    </Drawer>
  );
};

export default LogDrawer;

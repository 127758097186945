// @ts-nocheck
import { GraphQLQuery } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import {
  CreateCustomerInput,
  CreateCustomerMutation,
  Customer,
  CustomerByCodeQuery,
  DeleteCustomerMutation,
  GetCustomerQuery,
  ListCustomersQuery,
  ListCustomersQueryVariables,
  UpdateCustomerInput,
  UpdateCustomerMutation,
} from '../../API';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { compact } from 'lodash';
import ErrorList from 'antd/es/form/ErrorList';

export const getCustomer = async (id: string) => {
  try {
    const customer = await API.graphql<GraphQLQuery<GetCustomerQuery>>({
      query: queries.getCustomer,
      variables: { id },
    });

    if (customer.data?.getCustomer?._deleted) return undefined;
    return customer.data?.getCustomer as Customer | undefined;
  } catch (error: any) {
    if (error.errors) {
      error.errors.forEach((e: any) => console.log(e.message));
    } else {
      console.log('Error in getCustomer: ', error.message ?? '');
    }
  }
};

export const listCustomers = async () => {
  const customers: Customer[] = [];
  const variables: ListCustomersQueryVariables = { limit: 100, filter: { _deleted: { attributeExists: false } } };

  let nextToken: string | undefined | null = null;
  do {
    const result = await API.graphql<GraphQLQuery<ListCustomersQuery>>({
      query: queries.listCustomers,
      variables: { ...variables },
    });
    // console.log(result);
    if (!result.data?.listCustomers?.items) break;

    const { items, nextToken: nt } = result.data.listCustomers;
    customers.push(...compact(items));
    nextToken = nt;
    variables.nextToken = nextToken;
  } while (nextToken);
  return customers;
};

export const getCustomersByCode = async (customer_code: string) => {
  const customers = await API.graphql<GraphQLQuery<CustomerByCodeQuery>>({
    query: queries.customerByCode,
    variables: { customer_code },
  });
  return customers.data?.customerByCode?.items as Customer[];
};

export const createCustomer = async (input: CreateCustomerInput) => {
  const result = await API.graphql<GraphQLQuery<CreateCustomerMutation>>({
    query: mutations.createCustomer,
    variables: { input },
  });
  return result.data?.createCustomer as Customer;
};

export const updateCustomer = async (input: UpdateCustomerInput) => {
  const customer = await API.graphql<GraphQLQuery<UpdateCustomerMutation>>({
    query: mutations.updateCustomer,
    variables: { input },
  });
  return customer.data?.updateCustomer as Customer;
};

export const deleteCustomer = async (id: string, version: number) => {
  const deletedCustomer = await API.graphql<GraphQLQuery<DeleteCustomerMutation>>({
    query: mutations.deleteCustomer,
    variables: { input: { id, _version: version } },
  });
  return deletedCustomer.data?.deleteCustomer as Customer;
};

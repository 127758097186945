// @ts-nocheck
import { API, DataStore } from 'aws-amplify';
import {
  Log,
  LogByJobIdQuery,
  LogByJobIdQueryVariables,
  CashBookByJobQueryVariables,
  CashBookByJobQuery,
  Container,
  CashBook,
  ContainerByJobQueryVariables,
  ContainerByJobQuery,
  IdEd,
  IdEdByJobQueryVariables,
  IdEdByJobQuery,
  GetJobQuery,
  UpdateJobMutation,
  UpdateJobInput,
  Job,
  UpdateLogMutation,
  UpdateLogInput,
  CreateLogInput,
  CreateLogMutation,
  CreateJobInput,
  CreateJobMutation,
  JobByCodeQuery,
  CreateContainerInput,
  CreateContainerMutation,
  UpdateContainerInput,
  UpdateContainerMutation,
  DeleteContainerMutation,
  CreateIdEdInput,
  UpdateIdEdInput,
  DeleteIdEdInput,
  CreateIdEdMutation,
  UpdateIdEdMutation,
  DeleteIdEdMutation,
  CreateCashBookInput,
  CreateCashBookMutation,
  UpdateCashBookInput,
  UpdateCashBookMutation,
  DeleteCashBookMutation,
  GetCashBookQuery,
  GetContainerQuery,
  GetIdEdQuery,
  DataTableType,
  GetInvoiceQuery,
  Invoice,
  InvoiceByJobQuery,
  CreateInvoiceInput,
  CreateInvoiceMutation,
  UpdateInvoiceInput,
  UpdateInvoiceMutation,
  DeleteInvoiceMutation,
  GetInvoiceDetailQuery,
  InvoiceDetail,
  CreateInvoiceDetailInput,
  CreateInvoiceDetailMutation,
  UpdateInvoiceDetailInput,
  UpdateInvoiceDetailMutation,
  DeleteInvoiceDetailMutation,
  InvoiceDetailByInvoiceQueryVariables,
  InvoiceDetailByInvoiceQuery,
  JobByCustomerQuery,
  JobByCustomerQueryVariables,
  JobByDateOfCreationQuery,
  JobByDateOfCreationQueryVariables,
  DeleteJobMutation,
} from '../../API';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { GraphQLQuery } from '@aws-amplify/api';
import { compact } from 'lodash';
import dayjs from 'dayjs';

export const getJob = async (id: string) => {
  const job = await API.graphql<GraphQLQuery<GetJobQuery>>({
    query: queries.getJob,
    variables: { id },
  });
  if (job.data?.getJob?._deleted) return undefined;
  return job.data?.getJob as Job | undefined;
};

export const getJobsByCode = async (job_code: string) => {
  const jobs = await API.graphql<GraphQLQuery<JobByCodeQuery>>({
    query: queries.jobByCode,
    variables: { job_code },
  });
  return jobs.data?.jobByCode?.items as Job[] | undefined;
};

export const getJobsByDate = async (startDate: string, endDate: string) => {
  const jobs: Job[] = [];
  const variables: JobByDateOfCreationQueryVariables = {
    virtual_index: 0,
    date_of_creation: { between: [startDate, endDate] },
    limit: 500,
    filter: { _deleted: { attributeExists: false } },
    nextToken: null,
  };
  do {
    const result = await API.graphql<GraphQLQuery<JobByDateOfCreationQuery>>({
      query: queries.jobByDateOfCreation,
      variables,
    });
    if (!result.data?.jobByDateOfCreation) break;
    const { items, nextToken: nt } = result.data.jobByDateOfCreation;
    jobs.push(...compact(items));
    variables.nextToken = nt;
  } while (variables.nextToken);
  return jobs.sort((a, b) => dayjs(b.date_of_creation).unix() - dayjs(a.date_of_creation).unix());
};

export const getJobsByCustomer = async (
  customer_id: string,
  limit: number | undefined = undefined
) => {
  if (limit) {
    if (limit > 100) throw new Error('limit must be less than 100');
    const jobs = await API.graphql<GraphQLQuery<JobByCustomerQuery>>({
      query: queries.jobByCustomer,
      variables: { customer_id, limit },
    });
    return jobs.data?.jobByCustomer?.items as Job[] | undefined;
  } else {
    const jobs: Job[] = [];
    const variables: JobByCustomerQueryVariables = {
      customer_id,
      limit: 100,
      filter: { _deleted: { attributeExists: false } },
    };
    let nextToken: string | undefined | null = null;
    do {
      const result = await API.graphql<GraphQLQuery<JobByCustomerQuery>>({
        query: queries.jobByCustomer,
        variables,
      });
      if (!result.data?.jobByCustomer) break;
      const { items, nextToken: nt } = result.data?.jobByCustomer;
      jobs.push(...compact(items));
      nextToken = nt;
      variables.nextToken = nextToken;
    } while (nextToken);
    return jobs.sort((a, b) => dayjs(b.date_of_creation).unix() - dayjs(a.date_of_creation).unix());
  }
};

export const createJob = async (input: CreateJobInput) => {
  const job = await API.graphql<GraphQLQuery<CreateJobMutation>>({
    query: mutations.createJob,
    variables: { input },
  });
  return job.data?.createJob as Job;
};

export const updateJob = async (input: UpdateJobInput) => {
  const job = await API.graphql<GraphQLQuery<UpdateJobMutation>>({
    query: mutations.updateJob,
    variables: { input },
  });
  return job.data?.updateJob as Job;
};

export const deleteJob = async (id: string, version: number) => {
  const deletedJob = await API.graphql<GraphQLQuery<DeleteJobMutation>>({
    query: mutations.deleteJob,
    variables: { input: { id, _version: version } },
  });
  return deletedJob.data?.deleteJob as Job;
};

export const listChangeLogs = async (
  job_id: string,
  model: DataTableType | undefined = undefined,
  model_id: string | undefined = undefined
) => {
  if (model && !model_id) throw new Error('model_id is required when model is provided');
  const logs: Log[] = [];
  let variables: LogByJobIdQueryVariables;
  if (model && model_id) {
    variables = {
      job_id,
      modelModel_id: {
        eq: {
          model,
          model_id,
        },
      },
      limit: 100,
      filter: { _deleted: { attributeExists: false } },
    };
  } else {
    variables = { job_id, limit: 100, filter: { _deleted: { attributeExists: false } } };
  }
  // console.log(variables);

  let nextToken: string | undefined | null = null;
  do {
    const changeLogs = await API.graphql<GraphQLQuery<LogByJobIdQuery>>({
      query: queries.logByJobId,
      variables,
    });
    if (!changeLogs.data?.logByJobId) break;

    const { items, nextToken: nt } = changeLogs.data?.logByJobId;
    logs.push(...compact(items));
    nextToken = nt;
    variables.nextToken = nextToken;
  } while (nextToken);
  return logs.sort((a, b) => dayjs(b.changed_at).unix() - dayjs(a.changed_at).unix());
};

export const createChangeLog = async (input: CreateLogInput) => {
  const newLog = await API.graphql<GraphQLQuery<CreateLogMutation>>({
    query: mutations.createLog,
    variables: { input },
  });
  return newLog.data?.createLog as Log;
};

export const getCashBook = async (id: string) => {
  const cashbook = await API.graphql<GraphQLQuery<GetCashBookQuery>>({
    query: queries.getCashBook,
    variables: { id },
  });
  if (cashbook.data?.getCashBook?._deleted) return undefined;
  return cashbook.data?.getCashBook as CashBook | undefined;
};

export const listCashBook = async (job_id: string) => {
  const cashbooks: CashBook[] = [];
  const variables: CashBookByJobQueryVariables = {
    job_id,
    limit: 100,
    filter: { _deleted: { attributeExists: false } },
  };
  let nextToken: string | undefined | null = null;
  do {
    const cashbook = await API.graphql<GraphQLQuery<CashBookByJobQuery>>({
      query: queries.cashBookByJob,
      variables,
    });
    if (!cashbook.data?.cashBookByJob) break;

    const { items, nextToken: nt } = cashbook.data?.cashBookByJob;
    cashbooks.push(...compact(items));
    nextToken = nt;
    variables.nextToken = nextToken;
  } while (nextToken);
  return cashbooks;
};

export const createCashBook = async (input: CreateCashBookInput) => {
  const newCashBook = await API.graphql<GraphQLQuery<CreateCashBookMutation>>({
    query: mutations.createCashBook,
    variables: { input: { ...input, virtual_index: 'Cashbook' } },
  });
  return newCashBook.data?.createCashBook as CashBook;
};

export const updateCashBook = async (input: UpdateCashBookInput) => {
  const updatedCashBook = await API.graphql<GraphQLQuery<UpdateCashBookMutation>>({
    query: mutations.updateCashBook,
    variables: { input },
  });
  return updatedCashBook.data?.updateCashBook as CashBook;
};

export const deleteCashBook = async (id: string, version: number) => {
  const deletedCashBook = await API.graphql<GraphQLQuery<DeleteCashBookMutation>>({
    query: mutations.deleteCashBook,
    variables: { input: { id, _version: version } },
  });
  return deletedCashBook.data?.deleteCashBook as CashBook;
};

export const getContainer = async (id: string) => {
  const container = await API.graphql<GraphQLQuery<GetContainerQuery>>({
    query: queries.getContainer,
    variables: { id },
  });
  if (container.data?.getContainer?._deleted) return undefined;
  return container.data?.getContainer as Container | undefined;
};

export const listContainers = async (job_id: string) => {
  const containers: Container[] = [];
  const variables: ContainerByJobQueryVariables = {
    job_id,
    limit: 100,
    filter: { _deleted: { attributeExists: false } },
  };
  let nextToken: string | undefined | null = null;
  do {
    const container = await API.graphql<GraphQLQuery<ContainerByJobQuery>>({
      query: queries.containerByJob,
      variables,
    });
    if (!container.data?.containerByJob) break;

    const { items, nextToken: nt } = container.data?.containerByJob;
    containers.push(...compact(items));
    nextToken = nt;
    variables.nextToken = nextToken;
  } while (nextToken);
  return containers;
};

export const createContainer = async (input: CreateContainerInput) => {
  const newContainer = await API.graphql<GraphQLQuery<CreateContainerMutation>>({
    query: mutations.createContainer,
    variables: { input },
  });
  return newContainer.data?.createContainer as Container;
};

export const updateContainer = async (input: UpdateContainerInput) => {
  const updatedContainer = await API.graphql<GraphQLQuery<UpdateContainerMutation>>({
    query: mutations.updateContainer,
    variables: { input },
  });
  return updatedContainer.data?.updateContainer as Container;
};

export const deleteContainer = async (id: string, version: number) => {
  const deletedContainer = await API.graphql<GraphQLQuery<DeleteContainerMutation>>({
    query: mutations.deleteContainer,
    variables: { input: { id, _version: version } },
  });
  return deletedContainer.data?.deleteContainer as Container;
};

export const getIdEd = async (id: string) => {
  const idEd = await API.graphql<GraphQLQuery<GetIdEdQuery>>({
    query: queries.getIdEd,
    variables: { id },
  });
  if (idEd.data?.getIdEd?._deleted) return undefined;
  return idEd.data?.getIdEd as IdEd | undefined;
};

export const listIdEds = async (job_id: string) => {
  const idEds: IdEd[] = [];
  const variables: IdEdByJobQueryVariables = {
    job_id,
    limit: 100,
    filter: { _deleted: { attributeExists: false } },
  };
  let nextToken: string | undefined | null = null;
  do {
    const idEd = await API.graphql<GraphQLQuery<IdEdByJobQuery>>({
      query: queries.idEdByJob,
      variables,
    });
    if (!idEd.data?.idEdByJob) break;

    const { items, nextToken: nt } = idEd.data?.idEdByJob;
    idEds.push(...compact(items));
    nextToken = nt;
    variables.nextToken = nextToken;
  } while (nextToken);
  return idEds;
};

export const createIdEd = async (input: CreateIdEdInput) => {
  const newIdEd = await API.graphql<GraphQLQuery<CreateIdEdMutation>>({
    query: mutations.createIdEd,
    variables: { input },
  });
  return newIdEd.data?.createIdEd as IdEd;
};

export const updateIdEd = async (input: UpdateIdEdInput) => {
  const updatedIdEd = await API.graphql<GraphQLQuery<UpdateIdEdMutation>>({
    query: mutations.updateIdEd,
    variables: { input },
  });
  return updatedIdEd.data?.updateIdEd as IdEd;
};

export const deleteIdEd = async (id: string, version: number) => {
  const deletedIdEd = await API.graphql<GraphQLQuery<DeleteIdEdMutation>>({
    query: mutations.deleteIdEd,
    variables: { input: { id, _version: version } },
  });
  return deletedIdEd.data?.deleteIdEd as IdEd;
};

export const getInvoice = async (id: string) => {
  const invoice = await API.graphql<GraphQLQuery<GetInvoiceQuery>>({
    query: queries.getInvoice,
    variables: { id, filter: { _deleted: { attributeExists: false, ne: true } } },
  });
  if (invoice.data?.getInvoice?._deleted) return undefined;
  return invoice.data?.getInvoice as Invoice | undefined;
};

export const getInvoiceByJobId = async (job_id: string) => {
  const invoices = await API.graphql<GraphQLQuery<InvoiceByJobQuery>>({
    query: queries.invoiceByJob,
    variables: { job_id, filter: { _deleted: { attributeExists: false, ne: true } } },
  });
  const invoice = invoices.data?.invoiceByJob?.items?.[0];
  if (invoice?._deleted) return undefined;
  return invoice as Invoice | undefined;
};

export const createInvoice = async (input: CreateInvoiceInput) => {
  const newInvoice = await API.graphql<GraphQLQuery<CreateInvoiceMutation>>({
    query: mutations.createInvoice,
    variables: { input },
  });
  return newInvoice.data?.createInvoice as Invoice;
};

export const updateInvoice = async (input: UpdateInvoiceInput) => {
  const updatedInvoice = await API.graphql<GraphQLQuery<UpdateInvoiceMutation>>({
    query: mutations.updateInvoice,
    variables: { input },
  });
  return updatedInvoice.data?.updateInvoice as Invoice;
};

export const deleteInvoice = async (id: string, version: number) => {
  const deletedInvoice = await API.graphql<GraphQLQuery<DeleteInvoiceMutation>>({
    query: mutations.deleteInvoice,
    variables: { input: { id, _version: version } },
  });
  return deletedInvoice.data?.deleteInvoice as Invoice;
};

export const getInvoiceDetail = async (id: string) => {
  const invoiceDetail = await API.graphql<GraphQLQuery<GetInvoiceDetailQuery>>({
    query: queries.getInvoiceDetail,
    variables: { id },
  });
  if (invoiceDetail.data?.getInvoiceDetail?._deleted) return undefined;
  return invoiceDetail.data?.getInvoiceDetail as InvoiceDetail | undefined;
};

export const listInvoiceDetails = async (invoice_id: string) => {
  const invoiceDetails: InvoiceDetail[] = [];
  const variables: InvoiceDetailByInvoiceQueryVariables = {
    invoice_id,
    limit: 100,
    filter: { _deleted: { attributeExists: false } },
  };
  let nextToken: string | undefined | null = null;
  do {
    const invoiceDetail = await API.graphql<GraphQLQuery<InvoiceDetailByInvoiceQuery>>({
      query: queries.invoiceDetailByInvoice,
      variables,
    });
    if (!invoiceDetail.data?.invoiceDetailByInvoice) break;

    const { items, nextToken: nt } = invoiceDetail.data?.invoiceDetailByInvoice;
    invoiceDetails.push(...compact(items));
    nextToken = nt;
    variables.nextToken = nextToken;
  } while (nextToken);
  return invoiceDetails;
};

export const createInvoiceDetails = async (input: CreateInvoiceDetailInput) => {
  const newInvoiceDetail = await API.graphql<GraphQLQuery<CreateInvoiceDetailMutation>>({
    query: mutations.createInvoiceDetail,
    variables: { input },
  });
  return newInvoiceDetail.data?.createInvoiceDetail as InvoiceDetail;
};

export const updateInvoiceDetails = async (input: UpdateInvoiceDetailInput) => {
  const updatedInvoiceDetail = await API.graphql<GraphQLQuery<UpdateInvoiceDetailMutation>>({
    query: mutations.updateInvoiceDetail,
    variables: { input },
  });
  return updatedInvoiceDetail.data?.updateInvoiceDetail as InvoiceDetail;
};

export const deleteInvoiceDetails = async (id: string, version: number) => {
  const deletedInvoiceDetail = await API.graphql<GraphQLQuery<DeleteInvoiceDetailMutation>>({
    query: mutations.deleteInvoiceDetail,
    variables: { input: { id, _version: version } },
  });
  return deletedInvoiceDetail.data?.deleteInvoiceDetail as InvoiceDetail;
};

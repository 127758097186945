import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Form, Input, Modal, Popconfirm, Select, Space, Table, Tooltip } from 'antd';
import { IdEd, DataTableType, Job, OperationType, ID_ED_TYPE } from '../../API';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { LeftOutlined, LockFilled, ProfileOutlined, UnlockFilled } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import NetworkIndicator from '../../components/NetworkIndicator';
import { createIdEd, deleteIdEd, getIdEd, getJob, listChangeLogs, updateIdEd } from './api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { logActivity } from '../../utilities/logger';
import { useIdEdSubscription } from './subscriptions';
import useLogDrawer from '../../custom_hooks/useLogDrawer';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from '../Users/AuthContext';
import { useGetPermissionInfo } from '../Users/adminHooks';

export const IdEds = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { job_id } = useParams<{ job_id: string }>();

  const [job, setJob] = useState<Job | undefined | null>(null);
  const [modalState, setModalState] = useState<'add' | 'edit' | null>(null);
  const [editingIdEd, setEditingIdEd] = useState<IdEd | null>(null);

  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.ID_ED);

  const { idEds } = useIdEdSubscription(job_id);
  useDocumentTitle(job ? `ID/ED | ${job.job_code}` : 'ID/ED');

  const { openDrawer, LogDrawer } = useLogDrawer();

  useEffect(() => {
    if (!job_id) return;
    const fetchJob = async () => {
      const job = await getJob(job_id);
      setJob(job);
    };
    fetchJob();
  }, [job_id]);

  useEffect(() => {
    if (modalState === 'add') {
      form.resetFields();
    }
    if (modalState === 'edit') {
      form.setFieldsValue(editingIdEd);
    }
  }, [modalState, editingIdEd, form]);

  const hasEditingIdEdChanged = (editingIdEd: IdEd, values: IdEd) => {
    const fieldListToCheck = ['id_ed_number', 'id_ed_type', 'bl_number', 'form_required', 'form_number', 'remark'];
    // @ts-ignore
    return fieldListToCheck.some((field) => editingIdEd[field] !== values[field]);
  };

  const onFormSave = async (values: IdEd) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      if (modalState === 'add') {
        const newIdEd = await createIdEd({
          ...values,
          job_id,
          archived: false,
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.ID_ED,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newIdEd,
        });
      } else if (modalState === 'edit') {
        if (!editingIdEd) return;
        const latestIdEd = await getIdEd(editingIdEd.id);
        if (!latestIdEd) return;
        if (!hasEditingIdEdChanged(latestIdEd, values)) {
          setModalState(null);
          return;
        }
        const updatedIdEd = await updateIdEd({
          ...values,
          id: latestIdEd.id,
          _version: latestIdEd._version,
        });
        // logActivity(user.attributes.email, job, DataTableType.ID_ED, updatedIdEd, OperationType.UPDATE);
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.ID_ED,
          operation_type: OperationType.UPDATE,
          old_data: latestIdEd,
          new_data: updatedIdEd,
        });
      }
    } catch (error) {
      console.log('error saving id_ed', error);
    } finally {
      form.resetFields();
      setModalState(null);
    }
  };

  const onDelete = async (idEd: IdEd) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      const deletedIdEd = await deleteIdEd(idEd.id, idEd._version);
      // logActivity(user.attributes.email, job, DataTableType.ID_ED, deletedIdEd, OperationType.DELETE);
      logActivity({
        user_email: currentUser.email,
        job,
        model_type: DataTableType.ID_ED,
        operation_type: OperationType.DELETE,
        old_data: deletedIdEd,
        new_data: null,
      });
    } catch (error) {
      console.log('error deleting id_ed', error);
    }
  };

  const columns: ColumnsType<IdEd> = [
    {
      title: 'ID/ED',
      dataIndex: 'id_ed_type',
      key: 'id_ed_type',
    },
    {
      title: 'ID/ED No.',
      dataIndex: 'id_ed_number',
      key: 'id_ed_number',
      ellipsis: true,
    },
    {
      title: 'BL No.',
      dataIndex: 'bl_number',
      key: 'bl_number',
      ellipsis: true,
    },
    {
      title: 'Form Required',
      dataIndex: 'form_required',
      key: 'form_required',
      render: (form_required: boolean) => (form_required ? 'Yes' : 'No'),
    },
    {
      title: 'Form No.',
      dataIndex: 'form_number',
      key: 'form_number',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      // width: 150,
      ellipsis: true,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 160,
      render: (_: any, record: IdEd) => (
        <Space>
          <ProfileOutlined
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              if (!job_id) return;
              openDrawer(job_id, DataTableType.ID_ED, record.id, 'model');
            }}
          />
          {/* 
          Button is disabled when
          1. job is archived
          2. user does not have permission to edit ID/ED
          */}
          <Button
            onClick={() => {
              setModalState('edit');
              setEditingIdEd(record);
            }}
            type='link'
            disabled={job && job.archived ? true : permission.UPDATE ? false : true}
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete ID/ED ${record.id_ed_number}?`}
            onConfirm={() => onDelete(record)}
            okText='Yes'
            cancelText='No'
          >
            {/* 
            Button is disabled when
            1. job is archived
            2. user does not have permission to delete ID/ED
            */}
            <Button type='link' danger disabled={job && job.archived ? true : permission.DELETE ? false : true}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Button
              type='primary'
              shape='circle'
              icon={<LeftOutlined />}
              size='middle'
              onClick={() => navigate(`/jobs/${job_id}`)}
            />
            <Title level={4} style={{ color: 'darkred' }}>
              ID/ED for Job {job?.job_code}
            </Title>
          </Space>
        }
        style={{ width: 'max(calc(100vw - 400px), 900px)', height: 'fit-content' }}
        extra={
          <Space>
            <Tooltip
              title={job?.archived ? 'Record is locked.' : 'Record is not locked.'}
              color={job?.archived ? 'red' : 'green'}
            >
              {!job || job?.archived ? (
                <LockFilled style={{ fontSize: '1.5em', color: 'darkred' }} />
              ) : (
                <UnlockFilled style={{ fontSize: '1.5em', color: 'green' }} />
              )}
            </Tooltip>
            <NetworkIndicator />
          </Space>
        }
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5, marginTop: -15 }}>
          {/* Button is disabled when
          1. job is archived
          2. user doesn't have permision to CREATE ID/ED */}
          <Button
            type='default'
            onClick={() => setModalState('add')}
            disabled={job && job.archived ? true : permission.CREATE ? false : true}
          >
            Add ID/ED
          </Button>
        </div>
        <Table
          dataSource={idEds}
          columns={columns}
          size='small'
          rowKey={'id'}
          pagination={{ showTotal: (total, range) => `Total ${total} ID/ED` }}
        />
        <Modal
          title={modalState === 'add' ? `Add ID/ED to Job ${job?.job_code}` : `Edit ID/ED for Job ${job?.job_code}`}
          open={modalState !== null}
          okText='Save'
          onOk={async () => {
            form.submit();
          }}
          onCancel={() => {
            setModalState(null);
          }}
          styles={{ body: { paddingTop: 20 } }}
          destroyOnClose={true}
          maskClosable={false}
        >
          <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFormSave}>
            <Form.Item
              label='ID/ED Type'
              name='id_ed_type'
              rules={[{ required: true, message: 'Please select ID/ED Type.' }]}
            >
              <Select>
                {Object.values(ID_ED_TYPE).map((type) => (
                  <Select.Option value={type} key={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='ID/ED Number'
              name='id_ed_number'
              rules={[{ required: true, message: 'ID/ED Number is required.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label='BL Number' name='bl_number'>
              <Input />
            </Form.Item>
            <Form.Item label='Form Required' name='form_required' valuePropName='checked'>
              <Checkbox />
            </Form.Item>
            <Form.Item label='Form Number' name='form_number'>
              <Input />
            </Form.Item>
            <Form.Item label='Remark' name='remark'>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </Card>
      {LogDrawer}
    </div>
  );
};

import * as APITypes from '../../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const jobByDateOfCreation = /* GraphQL */ `
  query JobByDateOfCreation(
    $virtual_index: Int!
    $date_of_creation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobByDateOfCreation(
      virtual_index: $virtual_index
      date_of_creation: $date_of_creation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        containers {
          items {
            id
            container_number
            container_type_id
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        id_eds {
          items {
            id
            id_ed_number
            id_ed_type
            bl_number
            form_required
            form_number
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        cash_book_entries {
          items {
            id
            date
            cash_handler
            voucher_number
            account_id
            description
            debit_usd
            credit_usd
            debit_mmk
            credit_mmk
            is_deleted
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery

        archived

        virtual_index
        _version
        _deleted
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const jobByCode = /* GraphQL */ `
  query JobByCode(
    $job_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobByCode(
      job_code: $job_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        containers {
          items {
            id
            container_number
            container_type_id
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        id_eds {
          items {
            id
            id_ed_number
            id_ed_type
            bl_number
            form_required
            form_number
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        cash_book_entries {
          items {
            id
            date
            cash_handler
            voucher_number
            account_id
            description
            debit_usd
            credit_usd
            debit_mmk
            credit_mmk
            is_deleted
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery

        archived

        virtual_index
        _version
        _deleted
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const jobsByIndexAndCode = /* GraphQL */ `query JobsByIndexAndCode(
  $virtual_index: Int!
  $job_code: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobsByIndexAndCode(
    virtual_index: $virtual_index
    job_code: $job_code
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        containers {
          items {
            id
            container_number
            container_type_id
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        id_eds {
          items {
            id
            id_ed_number
            id_ed_type
            bl_number
            form_required
            form_number
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        cash_book_entries {
          items {
            id
            date
            cash_handler
            voucher_number
            account_id
            description
            debit_usd
            credit_usd
            debit_mmk
            credit_mmk
            is_deleted
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery

        archived

        virtual_index
        _version
        _deleted
      }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.JobsByIndexAndCodeQueryVariables, APITypes.JobsByIndexAndCodeQuery>;

export const jobByCustomer = /* GraphQL */ `
  query JobByCustomer(
    $customer_id: ID!
    $date_of_creation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobByCustomer(
      customer_id: $customer_id
      date_of_creation: $date_of_creation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        containers {
          items {
            id
            container_number
            container_type_id
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        id_eds {
          items {
            id
            id_ed_number
            id_ed_type
            bl_number
            form_required
            form_number
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        cash_book_entries {
          items {
            id
            date
            cash_handler
            voucher_number
            account_id
            description
            debit_usd
            credit_usd
            debit_mmk
            credit_mmk
            is_deleted
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery

        archived

        virtual_index
        _version
        _deleted
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const jobByCountry = /* GraphQL */ `
  query JobByCountry(
    $country_id: ID!
    $date_of_creation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobByCountry(
      country_id: $country_id
      date_of_creation: $date_of_creation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        containers {
          items {
            id
            container_number
            container_type_id
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        id_eds {
          items {
            id
            id_ed_number
            id_ed_type
            bl_number
            form_required
            form_number
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        cash_book_entries {
          items {
            id
            date
            cash_handler
            voucher_number
            account_id
            description
            debit_usd
            credit_usd
            debit_mmk
            credit_mmk
            is_deleted
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery

        archived

        virtual_index
        _version
        _deleted
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const jobByRoute = /* GraphQL */ `
  query JobByRoute(
    $route: ROUTE_TYPE!
    $date_of_creation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobByRoute(
      route: $route
      date_of_creation: $date_of_creation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        containers {
          items {
            id
            container_number
            container_type_id
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        id_eds {
          items {
            id
            id_ed_number
            id_ed_type
            bl_number
            form_required
            form_number
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        cash_book_entries {
          items {
            id
            date
            cash_handler
            voucher_number
            account_id
            description
            debit_usd
            credit_usd
            debit_mmk
            credit_mmk
            is_deleted
            remark
            _deleted
          }
          nextToken
          startedAt
          __typename
        }
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery

        archived

        virtual_index
        _version
        _deleted
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const idEdByNumber = /* GraphQL */ `
  query IdEdByNumber(
    $id_ed_number: String!
    $sortDirection: ModelSortDirection
    $filter: ModelIdEdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    idEdByNumber(
      id_ed_number: $id_ed_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        id_ed_id
        id_ed_number
        id_ed_type
        bl_number
        form_required
        form_number
        remark
        job_id
        job {
          id
          job_id
          job_code
          date_of_creation
          invoice_id
          customer_id
          country_id
          containers {
            items {
              id
              container_number
              container_type_id
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          id_eds {
            items {
              id
              id_ed_number
              id_ed_type
              bl_number
              form_required
              form_number
              remark
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          cash_book_entries {
            items {
              id
              date
              cash_handler
              voucher_number
              account_id
              description
              debit_usd
              credit_usd
              debit_mmk
              credit_mmk
              is_deleted
              remark
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          route
          comodity
          date_of_arrival
          voyage
          forwarder
          liner
          description
          remark
          date_of_delivery

          archived

          virtual_index
          _version
          _deleted
        }
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const idEdByIndexAndNumber = /* GraphQL */ `query IdEdByIndexAndNumber(
  $virtual_index: Int!
  $id_ed_number: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIdEdFilterInput
  $limit: Int
  $nextToken: String
) {
  idEdByIndexAndNumber(
    virtual_index: $virtual_index
    id_ed_number: $id_ed_number
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
        id
        id_ed_id
        id_ed_number
        id_ed_type
        bl_number
        form_required
        form_number
        remark
        job_id
        job {
          id
          job_id
          job_code
          date_of_creation
          invoice_id
          customer_id
          country_id
          containers {
            items {
              id
              container_number
              container_type_id
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          id_eds {
            items {
              id
              id_ed_number
              id_ed_type
              bl_number
              form_required
              form_number
              remark
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          cash_book_entries {
            items {
              id
              date
              cash_handler
              voucher_number
              account_id
              description
              debit_usd
              credit_usd
              debit_mmk
              credit_mmk
              is_deleted
              remark
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          route
          comodity
          date_of_arrival
          voyage
          forwarder
          liner
          description
          remark
          date_of_delivery

          archived

          virtual_index
          _version
          _deleted
        }
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IdEdByIndexAndNumberQueryVariables,
  APITypes.IdEdByIndexAndNumberQuery
>;

export const containerByNumber = /* GraphQL */ `
  query ContainerByNumber(
    $container_number: String!
    $sortDirection: ModelSortDirection
    $filter: ModelContainerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    containerByNumber(
      container_number: $container_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        job {
          id
          job_id
          job_code
          date_of_creation
          invoice_id
          customer_id
          country_id
          containers {
            items {
              id
              container_number
              container_type_id
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          id_eds {
            items {
              id
              id_ed_number
              id_ed_type
              bl_number
              form_required
              form_number
              remark
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          cash_book_entries {
            items {
              id
              date
              cash_handler
              voucher_number
              account_id
              description
              debit_usd
              credit_usd
              debit_mmk
              credit_mmk
              is_deleted
              remark
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          route
          comodity
          date_of_arrival
          voyage
          forwarder
          liner
          description
          remark
          date_of_delivery

          archived

          virtual_index
          _version
          _deleted
        }
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const containerByIndexAndNumber = /* GraphQL */ `query ContainerByIndexAndNumber(
  $virtual_index: Int!
  $container_number: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelContainerFilterInput
  $limit: Int
  $nextToken: String
) {
  containerByIndexAndNumber(
    virtual_index: $virtual_index
    container_number: $container_number
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
        id
        container_id
        container_number
        container_type_id
        job_id
        job {
          id
          job_id
          job_code
          date_of_creation
          invoice_id
          customer_id
          country_id
          containers {
            items {
              id
              container_number
              container_type_id
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          id_eds {
            items {
              id
              id_ed_number
              id_ed_type
              bl_number
              form_required
              form_number
              remark
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          cash_book_entries {
            items {
              id
              date
              cash_handler
              voucher_number
              account_id
              description
              debit_usd
              credit_usd
              debit_mmk
              credit_mmk
              is_deleted
              remark
              _deleted
            }
            nextToken
            startedAt
            __typename
          }
          route
          comodity
          date_of_arrival
          voyage
          forwarder
          liner
          description
          remark
          date_of_delivery

          archived

          virtual_index
          _version
          _deleted
        }
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContainerByIndexAndNumberQueryVariables,
  APITypes.ContainerByIndexAndNumberQuery
>;

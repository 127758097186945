import React, { useEffect, useMemo, useState } from 'react';
import { DataTableType, Log } from '../API';
import LogDrawerInter from '../components/LogDrawer';
import { listChangeLogs } from '../pages/Jobs/api';

const useLogDrawer = () => {
  const [open, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [job_id, set_job_id] = React.useState<string | undefined>(undefined);
  const [model_type, set_model_type] = React.useState<DataTableType | undefined>(undefined);
  const [model_id, set_model_id] = React.useState<string | undefined>(undefined);
  const [log_type, set_log_type] = React.useState<'job' | 'model'>('job'); // ['job', 'model'

  const [logs, setLogs] = useState<Log[]>([]);

  const openDrawer = (
    job_id: string | undefined,
    model_type: DataTableType | undefined,
    model_id: string | undefined,
    log_type: 'job' | 'model'
  ) => {
    try {
      if (log_type === 'job' && job_id === undefined) throw new Error('job_id must be defined if log_type is job');
      if (log_type === 'model' && (model_type === undefined || model_id === undefined))
        throw new Error('model_type and model_id must be defined if log_type is model');
      set_job_id(job_id || 'no_job_id');
      set_model_type(model_type);
      set_model_id(model_id);
      set_log_type(log_type);
      setOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const onInsufficientInfo = () => {
      setLogs([]);
      console.warn('Insufficient info to fetch logs');
    };
    const fetchFullLog = async (job_id: string) => {
      try {
        const fullChangeLog = await listChangeLogs(job_id);
        setLogs(fullChangeLog);
        setDrawerOpen(true);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchModelLog = async (job_id: string, model_type: DataTableType, model_id: string) => {
      try {
        const modelChangeLog = await listChangeLogs(job_id, model_type, model_id);
        setLogs(modelChangeLog);
        setDrawerOpen(true);
      } catch (error) {
        console.error(error);
      }
    };

    if (open) {
      if (log_type === 'job' && job_id) {
        fetchFullLog(job_id);
      } else if (log_type === 'model' && job_id && model_type && model_id) {
        fetchModelLog(job_id, model_type, model_id);
      } else {
        onInsufficientInfo();
      }
    } else {
      setLogs([]);
    }
  }, [open, job_id, model_type, model_id, log_type]);

  const closeDrawer = () => {
    setDrawerOpen(false);
    setOpen(false);
  };

  const LogDrawer = useMemo(
    () => (
      <LogDrawerInter
        logs={logs}
        show_full_log={log_type === 'job' ? true : false}
        open_drawer={drawerOpen}
        close_drawer={closeDrawer}
      />
    ),
    [logs, drawerOpen, log_type]
  );

  return {
    openDrawer,
    LogDrawer,
  };
};

export default useLogDrawer;

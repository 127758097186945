import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Authenticator } from '@aws-amplify/ui-react';

import awsExports from './aws-exports';
import { Amplify, DataStore, syncExpression } from 'aws-amplify';
import { CashBook, Container, IdEd, Invoice, InvoiceDetail, Job, Log, PageVisit } from './models';

const numberOfDays = 180;

const earliestDate = new Date(Date.now() - numberOfDays * 24 * 60 * 60 * 1000).toISOString();

Amplify.configure(awsExports);
DataStore.configure({
  syncExpressions: [
    syncExpression(CashBook, () => {
      return (cb) => cb.and((cb) => [cb.virtual_index.eq('no_download')]);
    }),
    syncExpression(Job, () => {
      return (j) => j.and((j) => [j.virtual_index.eq(0), j.createdAt.gt(earliestDate)]);
    }),
    syncExpression(Container, () => {
      return (c) => c.and((c) => [c.virtual_index.eq(1)]);
    }),
    syncExpression(IdEd, () => {
      return (idEd) => idEd.and((idEd) => [idEd.virtual_index.eq(1)]);
    }),
    syncExpression(Invoice, () => {
      return (i) => i.and((i) => [i.virtual_index.eq(1)]);
    }),
    syncExpression(InvoiceDetail, () => {
      return (i) => i.and((i) => [i.virtual_index.eq(1)]);
    }),
    syncExpression(Log, () => {
      return (l) => l.and((l) => [l.id.eq('no_download')]);
    }),
    syncExpression(PageVisit, () => {
      return (pv) => pv.and((pv) => [pv.id.eq('no_download')]);
    }),
  ],
  syncPageSize: 1000,
  maxRecordsToSync: 5000000, // five million
  fullSyncInterval: 24 * 60, // minutes
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// create a page where you can see all the containers of a job
// the containers are displayed in a antd table
// the table has three columns: container number and container type
// the last column is a button that allows you to edit / delete the container
// the table has a button to add a new container
// the table has a button to go back to the job page
// start writing your code below

import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Popconfirm, Select, Space, Table, Tooltip } from 'antd';
import { Container, DataTableType, Job, OperationType } from '../../API';
import { ContainerType } from '../../models';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { LeftOutlined, LockFilled, ProfileOutlined, UnlockFilled } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import NetworkIndicator from '../../components/NetworkIndicator';
import { DataStore } from 'aws-amplify';
import { createContainer, deleteContainer, getContainer, getJob, listContainers, updateContainer } from './api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { logActivity } from '../../utilities/logger';
import { useContainerSubscription } from './subscriptions';
import useLogDrawer from '../../custom_hooks/useLogDrawer';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from '../Users/AuthContext';
import { useGetPermissionInfo } from '../Users/adminHooks';

export const Containers = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { job_id } = useParams<{ job_id: string }>();

  const [job, setJob] = useState<Job | undefined | null>(null);
  // const [containers, setContainers] = useState<Container[]>([]);
  const [containerTypes, setContainerTypes] = useState<ContainerType[]>([]);
  const [modalState, setModalState] = useState<'add' | 'edit' | null>(null);
  const [editingContainer, setEditingContainer] = useState<Container | null>(null);

  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.CONTAINER);

  const { containers } = useContainerSubscription(job_id);
  useDocumentTitle(job ? `Containers | ${job.job_code}` : 'Containers');

  const { openDrawer, LogDrawer } = useLogDrawer();

  useEffect(() => {
    if (!job_id) return;
    const fetchJob = async () => {
      const job = await getJob(job_id);
      setJob(job);
    };
    const fetchContainerTypes = async () => {
      const containerTypes = await DataStore.query(ContainerType);
      setContainerTypes(containerTypes);
    };
    fetchContainerTypes();
    fetchJob();
  }, [job_id]);

  useEffect(() => {
    if (modalState === 'add') {
      form.resetFields();
    }
    if (modalState === 'edit') {
      form.setFieldsValue(editingContainer);
    }
  }, [modalState, form, editingContainer]);

  const hasEditingContainerChanged = (editingContainer: Container, values: Container) => {
    return (
      editingContainer.container_number !== values.container_number ||
      editingContainer.container_type_id !== values.container_type_id
    );
  };

  const onSave = async (values: Container) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      if (modalState === 'add') {
        const newContainer = await createContainer({ ...values, job_id, archived: false });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.CONTAINER,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newContainer,
        });
        // await logActivity(user.attributes.email, job, DataTableType.CONTAINER, newContainer, OperationType.CREATE);

        setModalState(null);
      } else if (modalState === 'edit') {
        if (!editingContainer) return;
        const latestContainer = await getContainer(editingContainer.id);
        if (!latestContainer) return;
        if (!hasEditingContainerChanged(latestContainer, values)) {
          setModalState(null);
          return;
        }
        const updatedContainer = await updateContainer({
          ...values,
          id: latestContainer.id,
          _version: latestContainer._version,
        });
        await logActivity({
          user_email: currentUser.email,
          job,
          model_type: DataTableType.CONTAINER,
          operation_type: OperationType.UPDATE,
          old_data: latestContainer,
          new_data: updatedContainer,
        });
        // await logActivity(user.attributes.email, job, DataTableType.CONTAINER, updatedContainer, OperationType.UPDATE);
        setModalState(null);
      }
    } catch (error) {
      console.log('error saving container', error);
    }
  };

  const onDelete = async (container: Container) => {
    if (!job_id || !job) return;
    if (!currentUser) return;
    if (job.archived) return;
    try {
      const deletedContainer = await deleteContainer(container.id, container._version);
      await logActivity({
        user_email: currentUser.email,
        job,
        model_type: DataTableType.CONTAINER,
        operation_type: OperationType.DELETE,
        old_data: deletedContainer,
        new_data: null,
      });
      // await logActivity(user.attributes.email, job, DataTableType.CONTAINER, deletedContainer, OperationType.DELETE);
    } catch (error) {
      console.log('error deleting container', error);
    }
  };

  const columns: ColumnsType<Container> = [
    {
      title: 'Container Number',
      dataIndex: 'container_number',
      key: 'container_number',
    },
    {
      title: 'Container Type',
      dataIndex: 'container_type_id',
      key: 'container_type_id',
      width: 160,
      render: (container_type_id: string) => {
        const containerType = containerTypes.find((ct) => ct.id === container_type_id);
        return containerType ? containerType.container_type : '';
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 160,
      render: (_: any, record: Container) => (
        <Space>
          <ProfileOutlined
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              if (!job_id) return;
              openDrawer(job_id, DataTableType.CONTAINER, record.id, 'model');
            }}
          />
          {/* 
          Button is disabled when
          1. job is locked
          2. user doesn't have permission to edit container */}
          <Button
            onClick={() => {
              setModalState('edit');
              setEditingContainer(record);
            }}
            type='link'
            disabled={job && job.archived ? true : !permission.UPDATE}
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete container ${record.container_number}?`}
            onConfirm={() => onDelete(record)}
            okText='Yes'
            cancelText='No'
          >
            {/* 
          Button is disabled when
          1. job is locked
          2. user doesn't have permission to delete containers */}
            <Button type='link' danger disabled={job && job.archived ? true : !permission.DELETE}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size='middle' align='start'>
            <Button
              type='primary'
              shape='circle'
              icon={<LeftOutlined />}
              size='middle'
              onClick={() => navigate(`/jobs/${job_id}`)}
            />
            <Title level={4} style={{ color: 'darkred' }}>
              Containers for Job {job?.job_code}
            </Title>
          </Space>
        }
        style={{ width: 'max(calc(100vw - 1000px), 600px)', height: 'fit-content' }}
        extra={
          <Space>
            <Tooltip
              title={job?.archived ? 'Record is locked.' : 'Record is not locked.'}
              color={job?.archived ? 'red' : 'green'}
            >
              {!job || job?.archived ? (
                <LockFilled style={{ fontSize: '1.5em', color: 'darkred' }} />
              ) : (
                <UnlockFilled style={{ fontSize: '1.5em', color: 'green' }} />
              )}
            </Tooltip>
            <NetworkIndicator />
          </Space>
        }
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5, marginTop: -15 }}>
          {/* Button is locked when
          1. job is locked
          2. user doesn't have permission to add new container */}
          <Button
            type='default'
            onClick={() => setModalState('add')}
            disabled={job && job.archived ? true : !permission.CREATE}
          >
            Add Container
          </Button>
        </div>
        <Table
          dataSource={containers}
          columns={columns}
          size='small'
          rowKey={'id'}
          pagination={{
            showTotal: (total, range) => `Total ${total} containers`,
          }}
        />
        <Modal
          title={
            modalState === 'add' ? `Add Container for Job ${job?.job_code}` : `Edit Container for Job ${job?.job_code}`
          }
          open={modalState !== null}
          okText={'Save'}
          onOk={async () => {
            form.submit();
          }}
          onCancel={() => {
            setModalState(null);
          }}
          styles={{ body: { paddingTop: 20 } }}
          destroyOnClose={true}
          maskClosable={false}
        >
          <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onSave}>
            <Form.Item
              label='Container Number'
              name='container_number'
              rules={[{ required: true, message: 'Container Number is required.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Container Type'
              name='container_type_id'
              rules={[{ required: true, message: 'Container Type is required.' }]}
            >
              <Select>
                {containerTypes
                  .sort((a, b) => a.container_type.localeCompare(b.container_type))
                  .map((ct) => (
                    <Select.Option value={ct.id} key={ct.id}>
                      {ct.container_type}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
      {LogDrawer}
    </div>
  );
};

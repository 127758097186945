import React from 'react';
import { useNetworkStatus } from '../custom_hooks/useNetworkStatus';
import { Tooltip } from 'antd';

const NetworkIndicator = () => {
  const isOnline = useNetworkStatus();
  return (
    <div
      style={{
        width: 50,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tooltip title={isOnline ? 'Online' : 'Offline'} color={isOnline ? 'green' : 'red'}>
        <div
          style={{
            width: 15,
            height: 15,
            backgroundColor: isOnline ? 'lightgreen' : 'crimson',
            borderRadius: '50%',
          }}
        />
      </Tooltip>
    </div>
  );
};

export default NetworkIndicator;

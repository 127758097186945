/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCountry = /* GraphQL */ `mutation CreateCountry(
  $input: CreateCountryInput!
  $condition: ModelCountryConditionInput
) {
  createCountry(input: $input, condition: $condition) {
    id
    country_id
    country_name
    alpha_2_code
    Jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCountryMutationVariables,
  APITypes.CreateCountryMutation
>;
export const updateCountry = /* GraphQL */ `mutation UpdateCountry(
  $input: UpdateCountryInput!
  $condition: ModelCountryConditionInput
) {
  updateCountry(input: $input, condition: $condition) {
    id
    country_id
    country_name
    alpha_2_code
    Jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCountryMutationVariables,
  APITypes.UpdateCountryMutation
>;
export const deleteCountry = /* GraphQL */ `mutation DeleteCountry(
  $input: DeleteCountryInput!
  $condition: ModelCountryConditionInput
) {
  deleteCountry(input: $input, condition: $condition) {
    id
    country_id
    country_name
    alpha_2_code
    Jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCountryMutationVariables,
  APITypes.DeleteCountryMutation
>;
export const createAccountGroup = /* GraphQL */ `mutation CreateAccountGroup(
  $input: CreateAccountGroupInput!
  $condition: ModelAccountGroupConditionInput
) {
  createAccountGroup(input: $input, condition: $condition) {
    id
    account_group_id
    account_group_code
    account_group_name
    account_heading_code
    account_heading
    is_active
    remark
    accounts {
      items {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountGroupMutationVariables,
  APITypes.CreateAccountGroupMutation
>;
export const updateAccountGroup = /* GraphQL */ `mutation UpdateAccountGroup(
  $input: UpdateAccountGroupInput!
  $condition: ModelAccountGroupConditionInput
) {
  updateAccountGroup(input: $input, condition: $condition) {
    id
    account_group_id
    account_group_code
    account_group_name
    account_heading_code
    account_heading
    is_active
    remark
    accounts {
      items {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountGroupMutationVariables,
  APITypes.UpdateAccountGroupMutation
>;
export const deleteAccountGroup = /* GraphQL */ `mutation DeleteAccountGroup(
  $input: DeleteAccountGroupInput!
  $condition: ModelAccountGroupConditionInput
) {
  deleteAccountGroup(input: $input, condition: $condition) {
    id
    account_group_id
    account_group_code
    account_group_name
    account_heading_code
    account_heading
    is_active
    remark
    accounts {
      items {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountGroupMutationVariables,
  APITypes.DeleteAccountGroupMutation
>;
export const createAccount = /* GraphQL */ `mutation CreateAccount(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccount(input: $input, condition: $condition) {
    id
    account_id
    account_code
    account_code_sr
    account_name
    account_group_id
    account_group {
      id
      account_group_id
      account_group_code
      account_group_name
      account_heading_code
      account_heading
      is_active
      remark
      accounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    opening_amount
    opening_date
    is_active
    remark
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountMutationVariables,
  APITypes.CreateAccountMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    id
    account_id
    account_code
    account_code_sr
    account_name
    account_group_id
    account_group {
      id
      account_group_id
      account_group_code
      account_group_name
      account_heading_code
      account_heading
      is_active
      remark
      accounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    opening_amount
    opening_date
    is_active
    remark
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    id
    account_id
    account_code
    account_code_sr
    account_name
    account_group_id
    account_group {
      id
      account_group_id
      account_group_code
      account_group_name
      account_heading_code
      account_heading
      is_active
      remark
      accounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    opening_amount
    opening_date
    is_active
    remark
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createCustomer = /* GraphQL */ `mutation CreateCustomer(
  $input: CreateCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  createCustomer(input: $input, condition: $condition) {
    id
    customer_id
    customer_code
    customer_name
    opening_amount
    contact_person
    contact_address
    phone
    is_active
    remark
    jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    created_by
    modified_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerMutationVariables,
  APITypes.CreateCustomerMutation
>;
export const updateCustomer = /* GraphQL */ `mutation UpdateCustomer(
  $input: UpdateCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  updateCustomer(input: $input, condition: $condition) {
    id
    customer_id
    customer_code
    customer_name
    opening_amount
    contact_person
    contact_address
    phone
    is_active
    remark
    jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    created_by
    modified_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerMutationVariables,
  APITypes.UpdateCustomerMutation
>;
export const deleteCustomer = /* GraphQL */ `mutation DeleteCustomer(
  $input: DeleteCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  deleteCustomer(input: $input, condition: $condition) {
    id
    customer_id
    customer_code
    customer_name
    opening_amount
    contact_person
    contact_address
    phone
    is_active
    remark
    jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    created_by
    modified_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerMutationVariables,
  APITypes.DeleteCustomerMutation
>;
export const createJob = /* GraphQL */ `mutation CreateJob(
  $input: CreateJobInput!
  $condition: ModelJobConditionInput
) {
  createJob(input: $input, condition: $condition) {
    id
    job_id
    job_code
    date_of_creation
    invoice_id
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    customer_id
    customer {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    country_id
    country {
      id
      country_id
      country_name
      alpha_2_code
      Jobs {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    id_eds {
      items {
        id
        id_ed_id
        id_ed_number
        id_ed_type
        bl_number
        form_required
        form_number
        remark
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    route
    comodity
    date_of_arrival
    voyage
    forwarder
    liner
    description
    remark
    date_of_delivery
    is_active
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobMutationVariables,
  APITypes.CreateJobMutation
>;
export const updateJob = /* GraphQL */ `mutation UpdateJob(
  $input: UpdateJobInput!
  $condition: ModelJobConditionInput
) {
  updateJob(input: $input, condition: $condition) {
    id
    job_id
    job_code
    date_of_creation
    invoice_id
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    customer_id
    customer {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    country_id
    country {
      id
      country_id
      country_name
      alpha_2_code
      Jobs {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    id_eds {
      items {
        id
        id_ed_id
        id_ed_number
        id_ed_type
        bl_number
        form_required
        form_number
        remark
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    route
    comodity
    date_of_arrival
    voyage
    forwarder
    liner
    description
    remark
    date_of_delivery
    is_active
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobMutationVariables,
  APITypes.UpdateJobMutation
>;
export const deleteJob = /* GraphQL */ `mutation DeleteJob(
  $input: DeleteJobInput!
  $condition: ModelJobConditionInput
) {
  deleteJob(input: $input, condition: $condition) {
    id
    job_id
    job_code
    date_of_creation
    invoice_id
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    customer_id
    customer {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    country_id
    country {
      id
      country_id
      country_name
      alpha_2_code
      Jobs {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    id_eds {
      items {
        id
        id_ed_id
        id_ed_number
        id_ed_type
        bl_number
        form_required
        form_number
        remark
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    route
    comodity
    date_of_arrival
    voyage
    forwarder
    liner
    description
    remark
    date_of_delivery
    is_active
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobMutationVariables,
  APITypes.DeleteJobMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_code
    invoice_date
    invoice_type
    customer_id
    customer_name
    comodity
    description
    remark
    id_or_ed_and_count
    id_ed_numbers
    container_types_and_counts
    container_numbers
    bl_list
    invoice_details {
      items {
        id
        invoice_id
        invoice_date
        description
        amount_mmk
        amount_usd
        debit_mmk
        debit_usd
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoice_detail_ids
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_code
    invoice_date
    invoice_type
    customer_id
    customer_name
    comodity
    description
    remark
    id_or_ed_and_count
    id_ed_numbers
    container_types_and_counts
    container_numbers
    bl_list
    invoice_details {
      items {
        id
        invoice_id
        invoice_date
        description
        amount_mmk
        amount_usd
        debit_mmk
        debit_usd
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoice_detail_ids
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_code
    invoice_date
    invoice_type
    customer_id
    customer_name
    comodity
    description
    remark
    id_or_ed_and_count
    id_ed_numbers
    container_types_and_counts
    container_numbers
    bl_list
    invoice_details {
      items {
        id
        invoice_id
        invoice_date
        description
        amount_mmk
        amount_usd
        debit_mmk
        debit_usd
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoice_detail_ids
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createInvoiceDetail = /* GraphQL */ `mutation CreateInvoiceDetail(
  $input: CreateInvoiceDetailInput!
  $condition: ModelInvoiceDetailConditionInput
) {
  createInvoiceDetail(input: $input, condition: $condition) {
    id
    invoice_id
    invoice_date
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    amount_mmk
    amount_usd
    debit_mmk
    debit_usd
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceDetailMutationVariables,
  APITypes.CreateInvoiceDetailMutation
>;
export const updateInvoiceDetail = /* GraphQL */ `mutation UpdateInvoiceDetail(
  $input: UpdateInvoiceDetailInput!
  $condition: ModelInvoiceDetailConditionInput
) {
  updateInvoiceDetail(input: $input, condition: $condition) {
    id
    invoice_id
    invoice_date
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    amount_mmk
    amount_usd
    debit_mmk
    debit_usd
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceDetailMutationVariables,
  APITypes.UpdateInvoiceDetailMutation
>;
export const deleteInvoiceDetail = /* GraphQL */ `mutation DeleteInvoiceDetail(
  $input: DeleteInvoiceDetailInput!
  $condition: ModelInvoiceDetailConditionInput
) {
  deleteInvoiceDetail(input: $input, condition: $condition) {
    id
    invoice_id
    invoice_date
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    amount_mmk
    amount_usd
    debit_mmk
    debit_usd
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceDetailMutationVariables,
  APITypes.DeleteInvoiceDetailMutation
>;
export const createContainerType = /* GraphQL */ `mutation CreateContainerType(
  $input: CreateContainerTypeInput!
  $condition: ModelContainerTypeConditionInput
) {
  createContainerType(input: $input, condition: $condition) {
    id
    container_type_id
    container_type
    description
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContainerTypeMutationVariables,
  APITypes.CreateContainerTypeMutation
>;
export const updateContainerType = /* GraphQL */ `mutation UpdateContainerType(
  $input: UpdateContainerTypeInput!
  $condition: ModelContainerTypeConditionInput
) {
  updateContainerType(input: $input, condition: $condition) {
    id
    container_type_id
    container_type
    description
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContainerTypeMutationVariables,
  APITypes.UpdateContainerTypeMutation
>;
export const deleteContainerType = /* GraphQL */ `mutation DeleteContainerType(
  $input: DeleteContainerTypeInput!
  $condition: ModelContainerTypeConditionInput
) {
  deleteContainerType(input: $input, condition: $condition) {
    id
    container_type_id
    container_type
    description
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContainerTypeMutationVariables,
  APITypes.DeleteContainerTypeMutation
>;
export const createContainer = /* GraphQL */ `mutation CreateContainer(
  $input: CreateContainerInput!
  $condition: ModelContainerConditionInput
) {
  createContainer(input: $input, condition: $condition) {
    id
    container_id
    container_number
    container_type_id
    container_type {
      id
      container_type_id
      container_type
      description
      containers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContainerMutationVariables,
  APITypes.CreateContainerMutation
>;
export const updateContainer = /* GraphQL */ `mutation UpdateContainer(
  $input: UpdateContainerInput!
  $condition: ModelContainerConditionInput
) {
  updateContainer(input: $input, condition: $condition) {
    id
    container_id
    container_number
    container_type_id
    container_type {
      id
      container_type_id
      container_type
      description
      containers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContainerMutationVariables,
  APITypes.UpdateContainerMutation
>;
export const deleteContainer = /* GraphQL */ `mutation DeleteContainer(
  $input: DeleteContainerInput!
  $condition: ModelContainerConditionInput
) {
  deleteContainer(input: $input, condition: $condition) {
    id
    container_id
    container_number
    container_type_id
    container_type {
      id
      container_type_id
      container_type
      description
      containers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContainerMutationVariables,
  APITypes.DeleteContainerMutation
>;
export const createIdEd = /* GraphQL */ `mutation CreateIdEd(
  $input: CreateIdEdInput!
  $condition: ModelIdEdConditionInput
) {
  createIdEd(input: $input, condition: $condition) {
    id
    id_ed_id
    id_ed_number
    id_ed_type
    bl_number
    form_required
    form_number
    remark
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIdEdMutationVariables,
  APITypes.CreateIdEdMutation
>;
export const updateIdEd = /* GraphQL */ `mutation UpdateIdEd(
  $input: UpdateIdEdInput!
  $condition: ModelIdEdConditionInput
) {
  updateIdEd(input: $input, condition: $condition) {
    id
    id_ed_id
    id_ed_number
    id_ed_type
    bl_number
    form_required
    form_number
    remark
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIdEdMutationVariables,
  APITypes.UpdateIdEdMutation
>;
export const deleteIdEd = /* GraphQL */ `mutation DeleteIdEd(
  $input: DeleteIdEdInput!
  $condition: ModelIdEdConditionInput
) {
  deleteIdEd(input: $input, condition: $condition) {
    id
    id_ed_id
    id_ed_number
    id_ed_type
    bl_number
    form_required
    form_number
    remark
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIdEdMutationVariables,
  APITypes.DeleteIdEdMutation
>;
export const createCashBook = /* GraphQL */ `mutation CreateCashBook(
  $input: CreateCashBookInput!
  $condition: ModelCashBookConditionInput
) {
  createCashBook(input: $input, condition: $condition) {
    id
    cashbook_id
    date
    cash_handler
    voucher_number
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_creation_date
    account_id
    account {
      id
      account_id
      account_code
      account_code_sr
      account_name
      account_group_id
      account_group {
        id
        account_group_id
        account_group_code
        account_group_name
        account_heading_code
        account_heading
        is_active
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      opening_amount
      opening_date
      is_active
      remark
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    debit_usd
    credit_usd
    debit_mmk
    credit_mmk
    is_deleted
    remark
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCashBookMutationVariables,
  APITypes.CreateCashBookMutation
>;
export const updateCashBook = /* GraphQL */ `mutation UpdateCashBook(
  $input: UpdateCashBookInput!
  $condition: ModelCashBookConditionInput
) {
  updateCashBook(input: $input, condition: $condition) {
    id
    cashbook_id
    date
    cash_handler
    voucher_number
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_creation_date
    account_id
    account {
      id
      account_id
      account_code
      account_code_sr
      account_name
      account_group_id
      account_group {
        id
        account_group_id
        account_group_code
        account_group_name
        account_heading_code
        account_heading
        is_active
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      opening_amount
      opening_date
      is_active
      remark
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    debit_usd
    credit_usd
    debit_mmk
    credit_mmk
    is_deleted
    remark
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCashBookMutationVariables,
  APITypes.UpdateCashBookMutation
>;
export const deleteCashBook = /* GraphQL */ `mutation DeleteCashBook(
  $input: DeleteCashBookInput!
  $condition: ModelCashBookConditionInput
) {
  deleteCashBook(input: $input, condition: $condition) {
    id
    cashbook_id
    date
    cash_handler
    voucher_number
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_creation_date
    account_id
    account {
      id
      account_id
      account_code
      account_code_sr
      account_name
      account_group_id
      account_group {
        id
        account_group_id
        account_group_code
        account_group_name
        account_heading_code
        account_heading
        is_active
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      opening_amount
      opening_date
      is_active
      remark
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    debit_usd
    credit_usd
    debit_mmk
    credit_mmk
    is_deleted
    remark
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCashBookMutationVariables,
  APITypes.DeleteCashBookMutation
>;
export const createCashHandler = /* GraphQL */ `mutation CreateCashHandler(
  $input: CreateCashHandlerInput!
  $condition: ModelCashHandlerConditionInput
) {
  createCashHandler(input: $input, condition: $condition) {
    id
    short_name
    full_name
    email
    phone
    address
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCashHandlerMutationVariables,
  APITypes.CreateCashHandlerMutation
>;
export const updateCashHandler = /* GraphQL */ `mutation UpdateCashHandler(
  $input: UpdateCashHandlerInput!
  $condition: ModelCashHandlerConditionInput
) {
  updateCashHandler(input: $input, condition: $condition) {
    id
    short_name
    full_name
    email
    phone
    address
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCashHandlerMutationVariables,
  APITypes.UpdateCashHandlerMutation
>;
export const deleteCashHandler = /* GraphQL */ `mutation DeleteCashHandler(
  $input: DeleteCashHandlerInput!
  $condition: ModelCashHandlerConditionInput
) {
  deleteCashHandler(input: $input, condition: $condition) {
    id
    short_name
    full_name
    email
    phone
    address
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCashHandlerMutationVariables,
  APITypes.DeleteCashHandlerMutation
>;
export const createLog = /* GraphQL */ `mutation CreateLog(
  $input: CreateLogInput!
  $condition: ModelLogConditionInput
) {
  createLog(input: $input, condition: $condition) {
    id
    job_id
    job_code
    model
    model_id
    operation
    old_data
    new_data
    user
    changed_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLogMutationVariables,
  APITypes.CreateLogMutation
>;
export const updateLog = /* GraphQL */ `mutation UpdateLog(
  $input: UpdateLogInput!
  $condition: ModelLogConditionInput
) {
  updateLog(input: $input, condition: $condition) {
    id
    job_id
    job_code
    model
    model_id
    operation
    old_data
    new_data
    user
    changed_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLogMutationVariables,
  APITypes.UpdateLogMutation
>;
export const deleteLog = /* GraphQL */ `mutation DeleteLog(
  $input: DeleteLogInput!
  $condition: ModelLogConditionInput
) {
  deleteLog(input: $input, condition: $condition) {
    id
    job_id
    job_code
    model
    model_id
    operation
    old_data
    new_data
    user
    changed_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLogMutationVariables,
  APITypes.DeleteLogMutation
>;
export const createPermission = /* GraphQL */ `mutation CreatePermission(
  $input: CreatePermissionInput!
  $condition: ModelPermissionConditionInput
) {
  createPermission(input: $input, condition: $condition) {
    id
    model
    operation
    required_permission_level
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePermissionMutationVariables,
  APITypes.CreatePermissionMutation
>;
export const updatePermission = /* GraphQL */ `mutation UpdatePermission(
  $input: UpdatePermissionInput!
  $condition: ModelPermissionConditionInput
) {
  updatePermission(input: $input, condition: $condition) {
    id
    model
    operation
    required_permission_level
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePermissionMutationVariables,
  APITypes.UpdatePermissionMutation
>;
export const deletePermission = /* GraphQL */ `mutation DeletePermission(
  $input: DeletePermissionInput!
  $condition: ModelPermissionConditionInput
) {
  deletePermission(input: $input, condition: $condition) {
    id
    model
    operation
    required_permission_level
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePermissionMutationVariables,
  APITypes.DeletePermissionMutation
>;
export const createPageVisit = /* GraphQL */ `mutation CreatePageVisit(
  $input: CreatePageVisitInput!
  $condition: ModelPageVisitConditionInput
) {
  createPageVisit(input: $input, condition: $condition) {
    id
    page
    user
    visited_at
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePageVisitMutationVariables,
  APITypes.CreatePageVisitMutation
>;
export const updatePageVisit = /* GraphQL */ `mutation UpdatePageVisit(
  $input: UpdatePageVisitInput!
  $condition: ModelPageVisitConditionInput
) {
  updatePageVisit(input: $input, condition: $condition) {
    id
    page
    user
    visited_at
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePageVisitMutationVariables,
  APITypes.UpdatePageVisitMutation
>;
export const deletePageVisit = /* GraphQL */ `mutation DeletePageVisit(
  $input: DeletePageVisitInput!
  $condition: ModelPageVisitConditionInput
) {
  deletePageVisit(input: $input, condition: $condition) {
    id
    page
    user
    visited_at
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePageVisitMutationVariables,
  APITypes.DeletePageVisitMutation
>;

import React, { useContext, useEffect, useState } from 'react';
import { API, DataStore } from 'aws-amplify';
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Typography,
  Table,
  Modal,
  InputNumber,
  message,
} from 'antd';
import { Account, CashHandler, Customer } from '../../models';
import NetworkIndicator from '../../components/NetworkIndicator';
import dayjs, { Dayjs } from 'dayjs';

import * as queries from '../../graphql/queries';
import * as filter_queries from './cashbook_filter_queries';
import {
  CashBook as CashBookAPI,
  Job as JobAPI,
  CashBookByAccountQueryVariables,
  CashBookByCashHandlerQueryVariables,
  CashBookByCreatedByQueryVariables,
  CashBookByDateQueryVariables,
  CashBookByJobQueryVariables,
  CashBookByVoucherNumberQueryVariables,
  ListCashBooksQueryVariables,
  ModelSortDirection,
  DataTableType,
  OperationType,
  ListJobsQuery,
} from '../../API';
import { compact } from 'lodash';
import { deleteCashBook, getCashBook, getJobsByCode, updateCashBook } from '../Jobs/api';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table/interface';
import { ProfileOutlined } from '@ant-design/icons';
import useLogDrawer from '../../custom_hooks/useLogDrawer';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { logActivity } from '../../utilities/logger';
import { AuthContext } from '../Users/AuthContext';
import { useGetPermissionInfo } from '../Users/adminHooks';

import * as ExcelJs from 'exceljs';
import { saveAs } from 'file-saver';

const { RangePicker } = DatePicker;

const { Title, Text } = Typography;

type CashBookQueryVariables =
  | ListCashBooksQueryVariables
  | CashBookByDateQueryVariables
  | CashBookByJobQueryVariables
  | CashBookByVoucherNumberQueryVariables
  | CashBookByAccountQueryVariables
  | CashBookByCashHandlerQueryVariables
  | CashBookByCreatedByQueryVariables;

type CashBookQueryName =
  | 'listCashBooks'
  | 'cashBookByDate'
  | 'cashBookByJob'
  | 'cashBookByVoucherNumber'
  | 'cashBookByAccount'
  | 'cashBookByCashHandler'
  | 'cashBookByCreatedBy';

const hardLimit = 100;
const softLimit = 100;

const CashBookFilter = () => {
  const [accounts, setAccounts] = React.useState<Account[]>([]);
  const [cashHandlers, setCashHandlers] = React.useState<CashHandler[]>([]);
  const [cashBooks, setCashBooks] = React.useState<CashBookAPI[]>([]);
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [jobsForExport, setJobsForExport] = React.useState<JobAPI[]>([]);
  // filter states
  const [filtersLoaded, setFiltersLoaded] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<string | undefined>(undefined);
  const [endDate, setEndDate] = React.useState<string | undefined>(undefined);
  const [jobCode, setJobCode] = React.useState<string | undefined>(undefined);
  const [cashHandler, setCashHandler] = React.useState<string | undefined>(undefined);
  const [account, setAccount] = React.useState<string | undefined>(undefined);
  const [voucherNo, setVoucherNo] = React.useState<string | undefined>(undefined);
  const [createdBy, setCreatedBy] = React.useState<string | undefined>(undefined);
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({} as any);
  // query Functions State
  const [queryName, setQueryName] = React.useState<CashBookQueryName>('cashBookByDate');
  const [queryVariables, setQueryVariables] = React.useState<CashBookQueryVariables>({
    limit: 100,
    sortDirection: ModelSortDirection.DESC,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  // modal states
  const [modalState, setModalState] = useState<'add' | 'edit' | null>(null);
  const [editingCashbookItem, setEditingCashbookItem] = useState<CashBookAPI | undefined>(
    undefined
  );
  // const searchInput = useRef<InputRef>(null);
  // const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});

  const { openDrawer, LogDrawer } = useLogDrawer();
  const [messageApi, contextHolder] = message.useMessage();

  useDocumentTitle('Cashbook Filter');

  // const { user } = useAuthenticator((context) => [context.user]);
  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.CASH_BOOK);

  const [filterForm] = Form.useForm();
  const [modalForm] = Form.useForm();

  //////////////////////// Filter Functions ////////////////////////

  const listCashBooksByFilterValues = async (
    queryName: CashBookQueryName,
    variables: CashBookQueryVariables,
    softLimit: number,
    hardLimit: number,
    forDownload: boolean = false
  ) => {
    const filteredCashbooks: CashBookAPI[] = [];
    let nextToken: string | null = variables.nextToken || null;
    let limit = softLimit;
    let count = 0;
    const modified_hard_limit = hardLimit < limit ? limit : hardLimit;
    const queries_for_fetch = forDownload ? filter_queries : queries;
    do {
      const result: GraphQLResult<any> = await API.graphql<GraphQLQuery<any>>({
        // @ts-ignore
        query: queries_for_fetch[queryName],
        variables: {
          ...variables,
          limit: limit,
          nextToken: nextToken,
        },
      });
      if (result) {
        const cashbooks = result.data[queryName]?.items;
        if (cashbooks) {
          filteredCashbooks.push(...cashbooks);
          count += cashbooks.length;
        }
        nextToken = result.data[queryName]?.nextToken;
      } else {
        nextToken = null;
      }
    } while (nextToken && count < modified_hard_limit);
    if (!forDownload) {
      setIsLoading(false);
    }
    return { filteredCashbooks, nextToken };
  };

  const reloadFilter = () => {
    setReload(!reload);
  };

  useEffect(() => {
    // fetch accounts and cash handlers
    const fetchAccounts = async () => {
      const accounts = await DataStore.query(Account);
      setAccounts(accounts.sort((a, b) => a.account_name.localeCompare(b.account_name)));
    };
    const fetchCashHandlers = async () => {
      const cash_handlers = await DataStore.query(CashHandler);
      setCashHandlers(cash_handlers);
    };
    const fetchCustomers = async () => {
      const customers = await DataStore.query(Customer);
      setCustomers(customers);
    };
    fetchAccounts();
    fetchCashHandlers();
    fetchCustomers();
  }, []);

  useEffect(() => {
    // load filter from local storage
    const filterStartDate = localStorage.getItem('cb_filter_start_date');
    const filterEndDate = localStorage.getItem('cb_filter_end_date');
    const filterJobCode = localStorage.getItem('cb_filter_job_code');
    const filterCashHandler = localStorage.getItem('cb_filter_cash_handler');
    const filterAccount = localStorage.getItem('cb_filter_account');
    const filterVoucherNo = localStorage.getItem('cb_filter_voucher_no');
    const filterCreatedBy = localStorage.getItem('cb_filter_created_by');
    const pagination_info = localStorage.getItem('cb_filter_pagination_info');
    if (filterStartDate) {
      setStartDate(filterStartDate);
    }
    if (filterEndDate) {
      setEndDate(filterEndDate);
    }
    if (filterJobCode) {
      setJobCode(filterJobCode);
    }
    if (filterCashHandler) {
      setCashHandler(filterCashHandler);
    }
    if (filterAccount) {
      setAccount(filterAccount);
    }
    if (filterVoucherNo) {
      setVoucherNo(filterVoucherNo);
    }
    if (filterCreatedBy) {
      setCreatedBy(filterCreatedBy);
    }
    if (pagination_info) {
      setPaginationInfo(JSON.parse(pagination_info));
    }
    filterForm.setFieldsValue({
      date:
        filterStartDate && filterEndDate ? [dayjs(filterStartDate), dayjs(filterEndDate)] : null,
      job_code: filterJobCode,
      cash_handler: filterCashHandler,
      account: filterAccount,
      voucher_no: filterVoucherNo,
      created_by: filterCreatedBy,
    });
    setFiltersLoaded(true);
  }, []);

  useEffect(() => {
    // fetch cash books based on filter
    const fetchCashBook = async () => {
      const byDate = !!startDate && !!endDate;
      const priorityArray: string[] = [];
      let job_id: string | undefined = undefined;

      if (jobCode) {
        const jobs = await getJobsByCode(jobCode);
        if (jobs && jobs.length > 0) {
          job_id = jobs[0].id;
        } else {
          job_id = 'job_not_found';
        }
        priorityArray.push('jobCode');
      }
      if (voucherNo) priorityArray.push('voucherNo');
      if (account) priorityArray.push('account');
      if (cashHandler) priorityArray.push('cashHandler');
      if (createdBy) priorityArray.push('createdBy');

      let queryName: CashBookQueryName = 'cashBookByDate';
      let variables: CashBookQueryVariables = {
        limit: softLimit,
        sortDirection: ModelSortDirection.DESC,
        filter: { and: [{ _deleted: { attributeExists: false } }] },
      };

      if (priorityArray.length === 0) {
        queryName = 'cashBookByDate';
        variables = {
          ...variables,
          virtual_index: 'Cashbook',
          date: byDate
            ? {
                between: [startDate, endDate],
              }
            : undefined,
        };
      } else {
        priorityArray.forEach((priority, index) => {
          if (index === 0) {
            if (priority === 'jobCode') {
              queryName = 'cashBookByJob';
              variables = {
                ...variables,
                job_id: job_id!,
                date: byDate
                  ? {
                      between: [startDate, endDate],
                    }
                  : undefined,
              };
            } else if (priority === 'voucherNo') {
              queryName = 'cashBookByVoucherNumber';
              variables = {
                ...variables,
                voucher_number: voucherNo!,
                date: byDate
                  ? {
                      between: [startDate, endDate],
                    }
                  : undefined,
              };
            } else if (priority === 'account') {
              queryName = 'cashBookByAccount';
              variables = {
                ...variables,
                account_id: account!,
                date: byDate
                  ? {
                      between: [startDate, endDate],
                    }
                  : undefined,
              };
            } else if (priority === 'cashHandler') {
              queryName = 'cashBookByCashHandler';
              variables = {
                ...variables,
                cash_handler: cashHandler!,
                date: byDate
                  ? {
                      between: [startDate, endDate],
                    }
                  : undefined,
              };
            } else if (priority === 'createdBy') {
              queryName = 'cashBookByCreatedBy';
              variables = {
                ...variables,
                created_by: createdBy!,
                date: byDate
                  ? {
                      between: [startDate, endDate],
                    }
                  : undefined,
              };
            }
          } else {
            // if (!variables) throw new Error('Variables is undefined');
            if (!variables?.filter) {
              variables.filter = { and: [{ _deleted: { attributeExists: false } }] };
            }
            if (priority === 'voucherNo') {
              variables?.filter?.and?.push({
                voucher_number: {
                  eq: voucherNo!,
                },
              });
            } else if (priority === 'account') {
              variables?.filter?.and?.push({
                account_id: {
                  eq: account!,
                },
              });
            } else if (priority === 'cashHandler') {
              variables?.filter?.and?.push({
                cash_handler: {
                  eq: cashHandler!,
                },
              });
            } else if (priority === 'createdBy') {
              variables?.filter?.and?.push({
                created_by: {
                  eq: createdBy!,
                },
              });
            }
          }
        });
      }
      setQueryName(queryName);

      const { filteredCashbooks, nextToken } = await listCashBooksByFilterValues(
        queryName,
        variables,
        softLimit,
        hardLimit
      );
      setCashBooks(filteredCashbooks);
      setQueryVariables({ ...variables, nextToken });
    };
    if (filtersLoaded) {
      setIsLoading(true);
      fetchCashBook();
    }
  }, [
    startDate,
    endDate,
    jobCode,
    voucherNo,
    account,
    cashHandler,
    createdBy,
    filtersLoaded,
    reload,
  ]);

  const fetchMore = async () => {
    if (!queryVariables.nextToken) return;
    setIsLoading(true);
    const { filteredCashbooks, nextToken } = await listCashBooksByFilterValues(
      queryName,
      queryVariables,
      softLimit,
      hardLimit
    );
    setCashBooks([...cashBooks, ...filteredCashbooks]);
    setQueryVariables({ ...queryVariables, nextToken });
  };

  const fetchForExport = async () => {
    setIsLoading(true);
    setIsDownloading(true);
    const cashbooks: CashBookAPI[] = [];
    const jobs: JobAPI[] = [];
    let nextToken: string | null | undefined = null;
    if (jobsForExport.length === 0) {
      do {
        messageApi.open({
          type: 'loading',
          content: `Preparing for export.`,
          key: 'exporting',
        });
        const job_result: GraphQLResult<ListJobsQuery> = await API.graphql<
          GraphQLQuery<ListJobsQuery>
        >({
          query: filter_queries.listJobs,
          variables: {
            limit: 2000,
            nextToken,
          },
        });
        const { items, nextToken: nt } = job_result.data?.listJobs || {};
        if (items) {
          jobs.push(...(compact(items) as JobAPI[]));
        }
        nextToken = nt;
      } while (nextToken);
      setJobsForExport(jobs);
    } else {
      jobs.push(...jobsForExport);
    }

    nextToken = null;

    let softLimit = 2000;
    let hardLimit = 2000;
    do {
      messageApi.open({
        type: 'loading',
        content: `Downloading cashbook data, ${cashbooks.length} records downloaded.`,
        key: 'exporting',
      });
      const { filteredCashbooks, nextToken: nt } = await listCashBooksByFilterValues(
        queryName,
        { ...queryVariables, nextToken },
        softLimit,
        hardLimit,
        true
      );
      cashbooks.push(...filteredCashbooks);
      nextToken = nt as string | null;
    } while (nextToken);

    messageApi.open({
      type: 'success',
      content: `${cashbooks.length} cashbook records successfully exported.`,
      duration: 5,
      key: 'exporting',
    });
    await exportToExcel({ data: cashbooks, jobs, customers, accounts });
    setIsLoading(false);
    setIsDownloading(false);
  };

  /////////////// Filter Settings ///////////////

  type FilterValues = {
    [P in keyof AllValues]: AllValues[P];
  };

  type AllValues = {
    date: [Dayjs, Dayjs] | null;
    job_code: string | undefined;
    cash_handler: string | undefined;
    account: string | undefined;
    voucher_no: string | undefined;
    created_by: string | undefined;
  };

  let timeoutId: NodeJS.Timeout | null = null;

  const onFilterChange = async (changedValues: FilterValues, allValues: AllValues) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      switch (Object.keys(changedValues)[0]) {
        case 'date':
          if (changedValues['date']) {
            setStartDate((changedValues['date'][0] as Dayjs).format('YYYY-MM-DD'));
            setEndDate((changedValues['date'][1] as Dayjs).format('YYYY-MM-DD'));
            localStorage.setItem(
              'cb_filter_start_date',
              (changedValues['date'][0] as Dayjs).format('YYYY-MM-DD')
            );
            localStorage.setItem(
              'cb_filter_end_date',
              (changedValues['date'][1] as Dayjs).format('YYYY-MM-DD')
            );
          } else {
            setStartDate(undefined);
            setEndDate(undefined);
            localStorage.removeItem('cb_filter_start_date');
            localStorage.removeItem('cb_filter_end_date');
          }
          break;
        case 'created_by':
          const newCreatedBy = changedValues['created_by']
            ? (changedValues['created_by'] as string).trim()
            : undefined;
          setCreatedBy(newCreatedBy?.length ? newCreatedBy : undefined);
          if (newCreatedBy?.length) {
            localStorage.setItem('cb_filter_created_by', newCreatedBy);
          } else {
            localStorage.removeItem('cb_filter_created_by');
          }
          break;
        case 'job_code':
          const newJobCode = changedValues['job_code']
            ? (changedValues['job_code'] as string).trim()
            : undefined;
          setJobCode(newJobCode?.length ? newJobCode : undefined);
          if (newJobCode?.length) {
            localStorage.setItem('cb_filter_job_code', newJobCode);
          } else {
            localStorage.removeItem('cb_filter_job_code');
          }
          break;
        case 'cash_handler':
          setCashHandler(changedValues['cash_handler'] as string | undefined);
          if (changedValues['cash_handler'] as string | undefined) {
            localStorage.setItem('cb_filter_cash_handler', changedValues['cash_handler'] as string);
          } else {
            localStorage.removeItem('cb_filter_cash_handler');
          }
          break;
        case 'account':
          setAccount(changedValues['account'] as string | undefined);
          if (changedValues['account'] as string | undefined) {
            localStorage.setItem('cb_filter_account', changedValues['account'] as string);
          } else {
            localStorage.removeItem('cb_filter_account');
          }
          break;
        case 'voucher_no':
          const newVoucherNo = changedValues['voucher_no']
            ? (changedValues['voucher_no'] as string).trim()
            : undefined;
          setVoucherNo(newVoucherNo?.length ? newVoucherNo : undefined);
          if (newVoucherNo?.length) {
            localStorage.setItem('cb_filter_voucher_no', newVoucherNo);
          } else {
            localStorage.removeItem('cb_filter_voucher_no');
          }
          break;
      }
    }, 500);
  };

  const clearFilter = () => {
    filterForm.resetFields();
    setStartDate(undefined);
    setEndDate(undefined);
    setJobCode(undefined);
    setCashHandler(undefined);
    setAccount(undefined);
    setVoucherNo(undefined);
    setCreatedBy(undefined);
    localStorage.removeItem('cb_filter_start_date');
    localStorage.removeItem('cb_filter_end_date');
    localStorage.removeItem('cb_filter_job_code');
    localStorage.removeItem('cb_filter_cash_handler');
    localStorage.removeItem('cb_filter_account');
    localStorage.removeItem('cb_filter_voucher_no');
    localStorage.removeItem('cb_filter_created_by');
  };

  //////////////////////// Modal Functions ////////////////////////
  type CashBookTypeWithDayJS = Omit<CashBookAPI, 'date'> & { date: Dayjs };

  const hasEditingCashbookItemChanged = (
    editingCashbookItem: CashBookAPI,
    values: CashBookTypeWithDayJS
  ) => {
    const { date, ...rest } = values;
    const cashBookItemWithPotentialChangedValues: CashBookAPI = {
      ...rest,
      date: date.format('YYYY-MM-DD'),
    };
    const fieldListToCheck = [
      'date',
      'cash_handler',
      'voucher_number',
      'account_id',
      'description',
      'debit_usd',
      'credit_usd',
      'debit_mmk',
      'credit_mmk',
    ];
    return fieldListToCheck.some(
      // @ts-ignore
      (field) => editingCashbookItem[field] !== cashBookItemWithPotentialChangedValues[field]
    );
  };

  useEffect(() => {
    if (modalState === 'edit') {
      const { date, ...rest } = editingCashbookItem!;
      const editingCashbookItemWithDate = {
        ...rest,
        date: dayjs(date),
      };
      modalForm.setFieldsValue(editingCashbookItemWithDate);
    } else {
      modalForm.resetFields();
    }
  }, [modalState, modalForm, editingCashbookItem]);

  const validateMultipleDebitCredit = () => {
    const values = modalForm.getFieldsValue() as CashBookTypeWithDayJS;
    const { debit_usd, credit_usd, debit_mmk, credit_mmk } = values;
    if (debit_usd === 0 && credit_usd === 0 && debit_mmk === 0 && credit_mmk === 0) {
      return Promise.reject('At least one of the debit or credit fields must be non-zero.');
    }
    // check if there are more than one non-zero fields
    const nonZeroFields = [debit_usd, credit_usd, debit_mmk, credit_mmk].filter(
      (value) => value !== 0
    );
    if (nonZeroFields.length > 1) {
      return Promise.reject('Only one of the debit or credit fields can be non-zero.');
    }
    return Promise.resolve();
  };

  const onFormSave = async (values: CashBookTypeWithDayJS) => {
    if (!editingCashbookItem) return;
    if (!editingCashbookItem.job) return;
    if (!currentUser) return;
    if (editingCashbookItem.job.archived === true) return;
    try {
      const latestCashbookItem = await getCashBook(editingCashbookItem.id);
      if (!latestCashbookItem) return;
      if (!hasEditingCashbookItemChanged(latestCashbookItem, values)) {
        setModalState(null);
        return;
      }
      const updatedCashbookItem = await updateCashBook({
        date: values.date.format('YYYY-MM-DD'),
        cash_handler: values.cash_handler,
        // voucher number is indexed, it cannot be an empty string
        voucher_number:
          values.voucher_number?.trim().length === 0 ? null : values.voucher_number?.trim(),
        account_id: values.account_id,
        description: values.description,
        debit_usd: values.debit_usd,
        debit_mmk: values.debit_mmk,
        credit_usd: values.credit_usd,
        credit_mmk: values.credit_mmk,
        id: latestCashbookItem.id,
        _version: latestCashbookItem._version,
      });
      await logActivity({
        user_email: currentUser.email,
        job: editingCashbookItem.job,
        model_type: DataTableType.CASH_BOOK,
        operation_type: OperationType.UPDATE,
        old_data: latestCashbookItem,
        new_data: updatedCashbookItem,
      });
      setModalState(null);
      reloadFilter();
    } catch (error) {
      console.log('error saving cashbook', error);
    }
  };

  const onDelete = async (cashbookItem: CashBookAPI) => {
    if (!cashbookItem.job) return;
    if (cashbookItem.job.archived === true) return;
    if (!currentUser) return;
    try {
      const deletedCashbookItem = await deleteCashBook(cashbookItem.id, cashbookItem._version);
      await logActivity({
        user_email: currentUser.email,
        job: cashbookItem.job,
        model_type: DataTableType.CASH_BOOK,
        operation_type: OperationType.DELETE,
        old_data: deletedCashbookItem,
        new_data: null,
      });
      reloadFilter();
    } catch (error) {
      console.log('error deleting cashbook', error);
    }
  };

  /////////////////////////// Table Columns ///////////////////////////

  const columns: ColumnsType<CashBookAPI> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 90,
      // fixed: 'left',
    },
    {
      title: 'Voucher No.',
      dataIndex: 'voucher_number',
      key: 'voucher_number',
      width: 100,
    },
    {
      title: 'Job Code',
      dataIndex: 'job_code',
      key: 'job_code',
      width: 150,
      // fixed: 'left',
      ellipsis: true,
      render: (_, record) => record.job?.job_code,
    },
    {
      title: 'Customer',
      dataIndex: 'job_code',
      key: 'job_code',
      width: 150,
      ellipsis: true,
      render: (_, record) => {
        const customer = customers.find((customer) => customer.id === record.job?.customer_id);
        return customer ? customer.customer_name : '-';
      },
    },
    {
      title: 'Account',
      dataIndex: 'account_id',
      key: 'account_id',
      width: 150,
      ellipsis: true,
      render: (account_id: string) => {
        const account = accounts.find((account) => account.id === account_id);
        return account ? account.account_name : '';
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150,
      ellipsis: true,
    },
    // {
    //   title: 'Job Creation Date',
    //   dataIndex: 'job_creation_date',
    //   key: 'job_creation_date',
    //   render: (_, record) => jobs.find((job) => job.id === record.job_id)?.date_of_creation || '-',
    // },
    {
      title: 'Debit',
      children: [
        {
          title: 'USD',
          dataIndex: 'debit_usd',
          key: 'debit_usd',
          width: 80,
          align: 'right',
          render: (text) => (text ? text.toLocaleString() : '0'),
        },
        {
          title: 'MMK',
          dataIndex: 'debit_mmk',
          key: 'debit_mmk',
          width: 120,
          align: 'right',
          render: (text) => (text ? text.toLocaleString() : '0'),
        },
      ],
    },
    {
      title: 'Credit',
      children: [
        {
          title: 'USD',
          dataIndex: 'credit_usd',
          key: 'credit_usd',
          align: 'right',
          width: 80,
          render: (text) => (text ? text.toLocaleString() : '0'),
        },
        {
          title: 'MMK',
          dataIndex: 'credit_mmk',
          key: 'credit_mmk',
          align: 'right',
          width: 120,
          render: (text) => (text ? text.toLocaleString() : '0'),
        },
      ],
    },
    {
      title: 'Cash Handler',
      dataIndex: 'cash_handler',
      key: 'cash_handler',
      width: 100,
    },
    {
      title: 'Data Entry',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 160,
      fixed: 'right',
      render: (_: any, record) => {
        return (
          <Space>
            <ProfileOutlined
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                if (!record.job) return;
                openDrawer(record.job.id, DataTableType.CASH_BOOK, record.id, 'model');
              }}
            />
            <Button
              style={{ padding: 4 }}
              onClick={() => {
                setEditingCashbookItem(record);
                setModalState('edit');
              }}
              type='link'
              disabled={!record.job || record.job.archived ? true : !permission.UPDATE}
              size='small'
            >
              Edit
            </Button>
            <Popconfirm
              title={`Are you sure you want to delete this record?`}
              onConfirm={() => onDelete(record)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                style={{ padding: 4 }}
                type='link'
                danger
                disabled={!record.job || record.job.archived ? true : !permission.DELETE}
                size='small'
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      {contextHolder}
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Title level={4} style={{ color: 'darkred' }}>
              Cashbook Filter
            </Title>
          </Space>
        }
        style={{ width: '100%', height: 'fit-content', marginTop: -10 }}
        extra={
          <Space>
            <Button type='link' onClick={fetchMore} disabled={queryVariables.nextToken === null}>
              fetch more
            </Button>
            <Button onClick={clearFilter} disabled={isLoading}>
              Clear filter
            </Button>
            {/* <Dropdown menu={{ items: export_items }} placement='bottomRight'> */}
            <Button type='primary' onClick={fetchForExport} disabled={isLoading}>
              Export
            </Button>
            {/* </Dropdown> */}
            <NetworkIndicator />
          </Space>
        }
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Form
            form={filterForm}
            layout='inline'
            onValuesChange={onFilterChange}
            disabled={isDownloading}
          >
            <Form.Item
              label='Date'
              name='date'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              style={{ width: 280, marginBottom: 10 }}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item
              label='Job Code'
              name='job_code'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ width: 300, marginBottom: 10 }}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label='Data Entry'
              name='created_by'
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              style={{ width: 250, marginBottom: 10 }}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label='Cash Handler'
              name='cash_handler'
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              style={{ width: 250, marginBottom: 10 }}
            >
              <Select allowClear>
                {cashHandlers.map((cashHandler) => (
                  <Select.Option key={cashHandler.short_name} value={cashHandler.short_name}>
                    {cashHandler.short_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Account'
              name='account'
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              style={{ width: 400, marginBottom: 10 }}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={accounts
                  .filter((account) => account.is_active === true)
                  .map((account) => ({
                    value: account.id,
                    label: account.account_name,
                  }))}
              />
            </Form.Item>
            <Form.Item
              label='Voucher No.'
              name='voucher_no'
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              style={{ width: 250, marginBottom: 10 }}
            >
              <Input allowClear />
            </Form.Item>
          </Form>
        </div>
        <Table
          columns={columns}
          dataSource={cashBooks}
          size='small'
          rowKey={'id'}
          scroll={{ x: 1300 }}
          bordered
          pagination={{
            current: paginationInfo.current,
            pageSize: 10,
            showTotal: (total, range) => `Total ${total} records downloaded.`,
          }}
          loading={isLoading}
          onChange={(pagination) => {
            localStorage.setItem('cb_filter_pagination_info', JSON.stringify(pagination));
            setPaginationInfo(pagination);
          }}
          summary={(data) => {
            const debit_usd_page_total = data.reduce((sum, record) => sum + record.debit_usd, 0);
            const debit_mmk_page_total = data.reduce((sum, record) => sum + record.debit_mmk, 0);
            const credit_usd_page_total = data.reduce((sum, record) => sum + record.credit_usd, 0);
            const credit_mmk_page_total = data.reduce((sum, record) => sum + record.credit_mmk, 0);

            const debit_usd_total = cashBooks.reduce((sum, record) => sum + record.debit_usd, 0);
            const debit_mmk_total = cashBooks.reduce((sum, record) => sum + record.debit_mmk, 0);
            const credit_usd_total = cashBooks.reduce((sum, record) => sum + record.credit_usd, 0);
            const credit_mmk_total = cashBooks.reduce((sum, record) => sum + record.credit_mmk, 0);

            return (
              <>
                <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
                  <Table.Summary.Cell index={0} colSpan={6} align='center'>
                    <Text strong>Page Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align='right'>
                    <Text strong>{debit_usd_page_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align='right'>
                    <Text strong>{Math.floor(debit_mmk_page_total).toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align='right'>
                    <Text strong>{credit_usd_page_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align='right'>
                    <Text strong>{Math.floor(credit_mmk_page_total).toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11} colSpan={13} />
                </Table.Summary.Row>
                <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
                  <Table.Summary.Cell index={0} colSpan={6} align='center'>
                    <Text strong>Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align='right'>
                    <Text strong>{debit_usd_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align='right'>
                    <Text strong>{Math.floor(debit_mmk_total).toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align='right'>
                    <Text strong>{credit_usd_total.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align='right'>
                    <Text strong>{Math.floor(credit_mmk_total).toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11} colSpan={13} />
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Card>
      <Modal
        title={modalState === 'add' ? 'Add new record' : 'Edit record'}
        open={modalState !== null}
        onOk={() => modalForm.submit()}
        onCancel={() => setModalState(null)}
        okText={modalState === 'add' ? 'Add' : 'Save'}
        cancelText='Cancel'
        styles={{ body: { paddingTop: 20 } }}
        destroyOnClose
        maskClosable={false}
        width={580}
      >
        <Form
          form={modalForm}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFormSave}
        >
          <Form.Item name='date' label='Date' rules={[{ required: true }]}>
            <DatePicker minDate={dayjs().subtract(1, 'year')} maxDate={dayjs()} />
          </Form.Item>
          <Form.Item name='cash_handler' label='Cash Handler' rules={[{ required: true }]}>
            <Select>
              {cashHandlers.map((ch) => (
                <Select.Option key={ch.short_name} value={ch.short_name}>
                  {ch.short_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='voucher_number'
            label='Voucher No.'
            rules={[{ whitespace: true, message: 'Voucher number cannot be white spaces.' }]}
          >
            <Input type='text' />
          </Form.Item>
          <Form.Item name='account_id' label='Account' rules={[{ required: true }]}>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.children ?? '').toString().toLowerCase().includes(input.toLowerCase())
              }
            >
              {accounts.map((account) => (
                <Select.Option key={account.id} value={account.id}>
                  {account.account_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='description' label='Description' rules={[{ required: true }]}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
          </Form.Item>
          <Form.Item
            name='debit_usd'
            label='Debit (USD)'
            rules={[{ required: true }, { validator: validateMultipleDebitCredit }]}
            initialValue={0}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={1}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            name='debit_mmk'
            label='Debit (MMK)'
            rules={[{ required: true }, { validator: validateMultipleDebitCredit }]}
            initialValue={0}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={100}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            name='credit_usd'
            label='Credit (USD)'
            rules={[{ required: true }, { validator: validateMultipleDebitCredit }]}
            initialValue={0}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={1}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            name='credit_mmk'
            label='Credit (MMK)'
            rules={[{ required: true }, { validator: validateMultipleDebitCredit }]}
            initialValue={0}
          >
            <InputNumber
              style={{ width: 200 }}
              min={0}
              step={100}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              //@ts-ignore
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </Form>
      </Modal>
      {LogDrawer}
    </div>
  );
};

export default CashBookFilter;

const exportToExcel = async ({
  data,
  jobs,
  customers,
  accounts,
}: {
  data: CashBookAPI[];
  jobs: JobAPI[];
  customers: Customer[];
  accounts: Account[];
}) => {
  try {
    const jobMap = new Map(jobs.map((job) => [job.id, job]));
    const customerMap = new Map(customers.map((customer) => [customer.id, customer]));
    const accountMap = new Map(accounts.map((account) => [account.id, account]));
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Cashbook');
    worksheet.columns = [
      { header: 'Date', key: 'date', width: 12 },
      { header: 'Job Code', key: 'job_code', width: 24 },
      { header: 'Debit (USD)', key: 'debit_usd', width: 16 },
      { header: 'Debit (MMK)', key: 'debit_mmk', width: 16 },
      { header: 'Credit (USD)', key: 'credit_usd', width: 16 },
      { header: 'Credit (MMK)', key: 'credit_mmk', width: 16 },
      { header: 'Account', key: 'account', width: 36 },
      { header: 'Voucher No.', key: 'voucher_no', width: 15 },
      { header: 'Customer', key: 'customer', width: 36 },
      { header: 'Cash Handler', key: 'cash_handler', width: 15 },
      { header: 'Data Entry', key: 'created_by', width: 15 },
      { header: 'Description', key: 'description', width: 36 },
    ];
    worksheet.getColumn('debit_usd').numFmt = '#,##0';
    worksheet.getColumn('debit_mmk').numFmt = '#,##0';
    worksheet.getColumn('credit_usd').numFmt = '#,##0';
    worksheet.getColumn('credit_mmk').numFmt = '#,##0';

    let debit_usd_total = 0;
    let debit_mmk_total = 0;
    let credit_usd_total = 0;
    let credit_mmk_total = 0;
    data.forEach((cashbook) => {
      const job = jobMap.get(cashbook.job_id);
      const customer = customerMap.get(job?.customer_id || '');
      const account = accountMap.get(cashbook.account_id);
      worksheet.addRow({
        date: cashbook.date,
        job_code: job?.job_code || '-',
        debit_usd: cashbook.debit_usd,
        debit_mmk: cashbook.debit_mmk,
        credit_usd: cashbook.credit_usd,
        credit_mmk: cashbook.credit_mmk,
        account: account?.account_name || '-',
        voucher_no: cashbook.voucher_number,
        customer: customer?.customer_name || '-',
        cash_handler: cashbook.cash_handler,
        created_by: cashbook.created_by,
        description: cashbook.description,
      });
      debit_usd_total += cashbook.debit_usd;
      debit_mmk_total += cashbook.debit_mmk;
      credit_usd_total += cashbook.credit_usd;
      credit_mmk_total += cashbook.credit_mmk;
    });
    worksheet.addRow({
      date: 'Total',
      job_code: data.length,
      debit_usd: debit_usd_total,
      debit_mmk: debit_mmk_total,
      credit_usd: credit_usd_total,
      credit_mmk: credit_mmk_total,
      account: '',
      voucher_no: '',
      customer: '',
      cash_handler: '',
      created_by: '',
      description: '',
    });
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
      };
    });
    worksheet.getRow(data.length + 2).eachCell((cell) => {
      cell.font = { bold: true };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
      };
    });

    worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];
    worksheet.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: data.length,
        column: 12,
      },
    };
    // https://github.com/exceljs/exceljs/issues/2041
    // [BUG] Slow generation performance #2041
    const excelGenerator = new ExcelGenerator();
    excelGenerator.finalizeWorkbook(workbook);
    //////////////////////////
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer]),
      `Cashbook Filter Export ${dayjs().format('YYYY-MM-DD h-mm a')}.xlsx`
    );
  } catch (error) {
    console.log('error exporting cashbook', error);
  } finally {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
};

// https://github.com/exceljs/exceljs/issues/2041
// [BUG] Slow generation performance #2041
class ExcelGenerator {
  private internStyle<T extends Partial<ExcelJs.Style>>(
    internedStyles: Map<string, T>,
    style: T,
    type: number
  ) {
    let buf = `${JSON.stringify(style)}${type}`;

    const internedStyle = internedStyles.get(buf);
    if (internedStyle) {
      return internedStyle;
    }

    const newInternedStyle = Object.freeze(Object.assign({}, style));
    internedStyles.set(buf, newInternedStyle);
    return newInternedStyle;
  }

  public finalizeWorkbook(workbook: ExcelJs.Workbook) {
    const internedStyles = new Map<string, Partial<ExcelJs.Style>>();
    workbook.worksheets.forEach((worksheet) => {
      (worksheet as any)._rows.forEach((row: ExcelJs.Row) => {
        (row as any)._cells.forEach((cell: ExcelJs.Cell) => {
          cell.style = this.internStyle(internedStyles, cell.style, cell.type);
        });
      });
    });
  }
}

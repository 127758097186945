import React, { useContext, useEffect } from 'react';
import { CustomUserType, convertUsers, createUser, listUsers } from './adminFunctions';
import { Button, Card, Modal, Space, Typography, Form, Input } from 'antd';
import NetworkIndicator from '../../components/NetworkIndicator';
import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Table, { ColumnsType } from 'antd/es/table';
import { useGetPermissionInfo, useIsAdmin } from './adminHooks';
import { compact } from 'lodash';
import { useAuthenticator } from '@aws-amplify/ui-react';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from './AuthContext';
import { DataTableType } from '../../API';
// import { } from 'aws-amplify/auth'

const { Title } = Typography;

const Users = () => {
  const navigate = useNavigate();
  useDocumentTitle('Users');
  const [form] = Form.useForm();
  const [users, setUsers] = React.useState<CustomUserType[]>([]);
  const [loading, setLoading] = React.useState(true);

  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.USER);
  // const { user: authUser } = useAuthenticator((context) => [context.user]);
  // const { isAdmin: isAuthUserAdmin } = useIsAdmin(authUser?.username);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [reload, setReload] = React.useState(false);

  useEffect(() => {
    const getExistingUsers = async () => {
      const users = await listUsers(25);
      const usersWithCustomType = (await convertUsers(users)).sort((a, b) =>
        a.email.localeCompare(b.email)
      );
      setLoading(false);
      setUsers(compact(usersWithCustomType));
    };
    getExistingUsers();
  }, [reload]);

  const reloadData = () => {
    setReload(!reload);
  };

  const validateEmailDuplicate = async (rule: any, value: string) => {
    const existingUser = users.find((user) => user.email.toLowerCase() === value.toLowerCase());
    if (existingUser) {
      throw new Error('Email already exists in the system');
    }
  };

  const columns: ColumnsType<CustomUserType> = [
    {
      title: 'Email',
      key: 'email',
      render: (text, record) => record.email || 'Error',
    },
    {
      title: 'Name',
      key: 'name',
      width: 200,
      render: (text, record) => record.name || '',
    },
    {
      title: 'Permission Level',
      key: 'permission_level',
      width: 120,
      align: 'center',
      render: (text, record) => record.permission_level,
    },
    // {
    //   title: 'Email Verified',
    //   key: 'email_verified',
    //   width: 80,
    //   render: (text, record) => (record.email_verified ? <CheckOutlined /> : ''),
    // },
    {
      title: 'Admin',
      key: 'admin',
      width: 80,
      render: (text, record) => (record.admin ? <CheckOutlined /> : ''),
    },
    {
      title: 'Can Access Database',
      key: 'data_entry',
      width: 120,
      render: (text, record) => (record.data_entry ? <CheckOutlined /> : ''),
    },
    // {
    //   title: 'Handle Cash',
    //   key: 'cash_handler',
    //   width: 80,
    //   render: (text, record) => (record.cash_handler ? <CheckOutlined /> : ''),
    // },
    // {
    //   title: 'Staff',
    //   key: 'staff',
    //   width: 80,
    //   render: (text, record) => (record.staff ? <CheckOutlined /> : ''),
    // },
    // {
    //   title: 'Agent',
    //   key: 'agent',
    //   width: 80,
    //   render: (text, record) => (record.agent ? <CheckOutlined /> : ''),
    // },
    {
      title: 'Active',
      key: 'active',
      width: 80,
      render: (text, record) => (record.active ? <CheckOutlined /> : ''),
    },
    {
      title: 'User Status',
      key: 'user_status',
      render: (text, record) => record.user_status || '',
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <Space size='middle'>
          <Button
            type='link'
            size='middle'
            onClick={() => {
              navigate(record.username);
            }}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const onFormSave = async (values: any) => {
    if (!(currentUser?.admin && permission.CREATE)) return;
    const { email, name, temporaryPassword } = values;
    try {
      const newUser = await createUser(email, name, temporaryPassword, 'SUPPRESS');
      closeModal();
      reloadData();
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size={'middle'} align='start'>
            {/* <Button type='primary' shape='circle' icon={<LeftOutlined />} size='middle' onClick={() => navigate(-1)} /> */}
            <Title level={4} style={{ color: 'darkred' }}>
              Users
            </Title>
          </Space>
        }
        style={{ width: 'max(calc(100vw - 400px), 1000px)', height: 'fit-content' }}
        extra={
          <Space>
            <Button
              type='primary'
              onClick={openModal}
              hidden={!(currentUser?.admin && permission.CREATE)}
              disabled={!(currentUser?.admin && permission.CREATE)}
            >
              Add User
            </Button>
            <NetworkIndicator />
          </Space>
        }
      >
        <Table
          dataSource={users}
          columns={columns}
          rowKey={'username'}
          size='small'
          loading={loading}
        />
      </Card>
      <Modal
        title='Add User'
        open={modalOpen}
        onCancel={closeModal}
        onOk={form.submit}
        okText='Add'
        cancelText='Cancel'
        destroyOnClose={true}
        maskClosable={false}
        forceRender={true}
      >
        <Form form={form} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} onFinish={onFormSave}>
          <Form.Item
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Please enter an email' },
              { type: 'email', message: 'Enter a valid email message' },
              { validator: validateEmailDuplicate },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Please enter a name' }, { whitespace: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='TemporaryPassword'
            name='temporaryPassword'
            rules={[{ required: true, message: 'Please enter a temporary password' }, { min: 8 }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Users;

import React from 'react';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
// import { Button } from 'antd';
// import ExcelJS from 'exceljs';
// import { DataStore } from 'aws-amplify';
// import { CashHandler, Invoice, Permission, Job } from '../../models';

const About = () => {
  useDocumentTitle('About');

  // const uploadCsvFile = async (e: any) => {
  //   const wb = new ExcelJS.Workbook();
  //   // const worksheet = await wb.csv.readFile('./permissions.csv');

  //   const xlsx_file = await fetch('/files/cash_handlers.xlsx');
  //   const xlsx_file_buffer = await xlsx_file.arrayBuffer();
  //   await wb.xlsx.load(xlsx_file_buffer);

  //   const permissions = wb.worksheets[0].getSheetValues();
  //   let count = 0;

  // for (let i = 0; i < permissions.length; i++) {
  //   const row = permissions[i];
  //   console.log(row);
  //   if (i <= 1) continue;
  //   // @ts-ignore
  //   console.log(row[8], row[9]);
  //   await DataStore.save(
  //     new CashHandler({
  //       // @ts-ignore
  //       full_name: row[8],
  //       // @ts-ignore
  //       short_name: row[9],
  //     })
  //   );
  //   count += 1;
  // }

  // const updateInvoices = async () => {
  //   const invoices = await DataStore.query(Invoice);
  //   const jobs = await DataStore.query(Job);

  //   console.log(invoices.length);
  //   console.log(jobs.length);
  //   let counter = 0;
  //   invoices.forEach(async (invoice) => {
  //     //@ts-ignore
  //     const job = jobs.find((j) => j.id === invoice.job_id);
  //     if (!job) {
  //       console.log('no job for this invoice', invoice.id);
  //     }  else {
  //       console.log('job found for this invoice', invoice.id);
  //       await DataStore.save(
  //         Invoice.copyOf(invoice, (updated) => {
  //           updated.job_code = job.job_code;
  //           //@ts-ignore
  //           updated.customer_id = job.customer_id;
  //         })
  //       );
  //       counter += 1;
  //     }
  //   });
  //   console.log('done', counter);
  // };

  //   console.log({ count });
  // };

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>STL Myanmar Company Ltd.</p>
      {/* <Button type='primary' onClick={updateInvoices}>
        Click Me
      </Button> */}
    </div>
  );
};

export default About;

import React, { useContext, useEffect } from 'react';
import { Form, Typography, Input, Button, Card, Space, Divider, Checkbox } from 'antd';
import NetworkIndicator from '../../components/NetworkIndicator';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { logActivity } from '../../utilities/logger';

import { createCustomer, deleteCustomer, getCustomer, getCustomersByCode, updateCustomer } from './api';
import { Customer, OperationType, DataTableType } from '../../API';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from '../Users/AuthContext';

const { Title } = Typography;

const CustomerForm = () => {
  const { customer_id } = useParams<{ customer_id: string }>();
  const { currentUser } = useContext(AuthContext);
  const [customer, setCustomer] = React.useState<Customer | undefined>(undefined);
  // const [permission, setPermission] = React.useState<PermissionType>(defaultPermission);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  useDocumentTitle(customer ? `Editing ${customer.customer_name}` : `New Customer`);

  useEffect(() => {
    if (customer_id) {
      const getEditingCustomer = async () => {
        const editingCustomer = await getCustomer(customer_id);
        if (editingCustomer) {
          setCustomer(editingCustomer);
          form.setFieldsValue(editingCustomer);
        }
      };
      getEditingCustomer();
    } else {
      form.resetFields();
      setCustomer(undefined);
    }
  }, [customer_id, form]);

  const validateCustomerCode = async (_: any, value: string) => {
    if (!value) return;
    if (customer_id && customer) {
      const editingCustomer = customer;
      if (editingCustomer.customer_code === value) return;
    }

    const customersByCode = await getCustomersByCode(value);
    if (!customersByCode) {
      return Promise.reject('Validation failed.');
    }
    if (customersByCode.length > 0) {
      return Promise.reject('Customer code already exists.');
    }
    return Promise.resolve();
  };

  const hasEditingCustomerChanged = async (editingCustomer: Customer, formValues: Customer) => {
    // const { customer_code, customer_name, contact_person, contact_address, phone, is_active } = formValues;
    const fieldsListToCheck = [
      'customer_code',
      'customer_name',
      'contact_person',
      'contact_address',
      'phone',
      'is_active',
    ];
    // @ts-ignore
    return fieldsListToCheck.some((field) => editingCustomer[field] !== formValues[field]);
  };

  const onFromSave = async (values: Customer) => {
    if (!currentUser) return;
    try {
      if (customer_id) {
        if (!hasEditingCustomerChanged) {
          navigate(`/customers/${customer_id}`);
          return;
        }
        if (!customer) return;
        const updatedCustomer = await updateCustomer({
          ...values,
          id: customer_id,
          _version: customer._version,
        });
        await logActivity({
          user_email: currentUser.email,
          job: null,
          model_type: DataTableType.CUSTOMER,
          operation_type: OperationType.UPDATE,
          old_data: customer,
          new_data: updatedCustomer,
        });
        navigate(`/customers/${customer_id}`);
      } else {
        const newCustomer = await createCustomer({
          ...values,
          is_active: values.is_active || false,
        });
        await logActivity({
          user_email: currentUser.email,
          job: null,
          model_type: DataTableType.CUSTOMER,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newCustomer,
        });
        navigate(`/customers/${newCustomer.id}`);
      }
    } catch (error) {
      console.error('error saving customer', error);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size='large' align='start'>
            <Button
              type='primary'
              shape='circle'
              icon={<LeftOutlined />}
              size='middle'
              onClick={() => navigate(customer_id ? `/customers/${customer_id}` : '/customers')}
            />
            <Title level={4} style={{ color: 'darkred' }}>
              {customer ? customer.customer_name : 'Customer'}
            </Title>
          </Space>
        }
        style={{
          width: 'max(calc(100vw - 900px), 800px)',
          height: 'fit-content',
        }}
        extra={
          <Space>
            <NetworkIndicator />
          </Space>
        }
      >
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} form={form} onFinish={onFromSave}>
          <Form.Item
            label='Customer Code'
            name='customer_code'
            rules={[
              {
                required: true,
                message: 'Please input customer code!',
              },
              {
                validator: validateCustomerCode,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Customer Name'
            name='customer_name'
            rules={[
              {
                required: true,
                message: 'Please input customer name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Contact Person' name='contact_person'>
            <Input />
          </Form.Item>
          <Form.Item label='Contact Address' name='contact_address'>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          <Form.Item label='Contact Phone' name='phone'>
            <Input />
          </Form.Item>
          <Form.Item label='Active' name='is_active' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Divider />
          <Form.Item wrapperCol={{ offset: 7 }}>
            <Space>
              <Button onClick={() => navigate(customer_id ? `/customers/${customer_id}` : '/customers')}>Cancel</Button>
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CustomerForm;

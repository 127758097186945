import { API } from 'aws-amplify';
import { GraphQLSubscription, GraphQLQuery } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import * as subscriptions from '../../graphql/subscriptions';
import {
  OnCreateContainerSubscription,
  OnUpdateContainerSubscription,
  OnDeleteContainerSubscription,
  Container,
  IdEd,
  OnCreateIdEdSubscription,
  OnUpdateIdEdSubscription,
  CashBook,
  OnCreateCashBookSubscription,
  OnDeleteIdEdSubscription,
  OnUpdateCashBookSubscription,
  OnDeleteCashBookSubscription,
} from '../../API';
import { useEffect, useState } from 'react';
import { listCashBook, listContainers, listIdEds } from './api';

export const useContainerSubscription = (job_id: string | undefined) => {
  const [containers, setContainers] = useState<Container[]>([]);

  const fetchContainers = async (job_id: string) => {
    const containers = await listContainers(job_id);
    setContainers(containers.sort((a, b) => a.createdAt.localeCompare(b.createdAt)));
  };

  useEffect(() => {
    if (!job_id) return;
    fetchContainers(job_id);
  }, [job_id]);

  useEffect(() => {
    if (!job_id) return;
    const variables = {
      filter: {
        job_id: {
          eq: job_id,
        },
      },
    };
    const createSub = API.graphql<GraphQLSubscription<OnCreateContainerSubscription>>({
      query: subscriptions.onCreateContainer,
      variables,
    }).subscribe({
      next: ({ provider, value }) => {
        fetchContainers(job_id);
      },
      error: (error) => console.warn(error),
    });

    const updateSub = API.graphql<GraphQLSubscription<OnUpdateContainerSubscription>>({
      query: subscriptions.onUpdateContainer,
      variables,
    }).subscribe({
      next: ({ provider, value }) => {
        fetchContainers(job_id);
      },
      error: (error) => console.warn(error),
    });

    const deleteSub = API.graphql<GraphQLSubscription<OnDeleteContainerSubscription>>({
      query: subscriptions.onDeleteContainer,
      variables,
    }).subscribe({
      next: () => {
        fetchContainers(job_id);
      },
      error: (error) => console.warn(error),
    });

    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      deleteSub.unsubscribe();
    };
  }, [job_id]);

  return { containers };
};

export const useIdEdSubscription = (job_id: string | undefined) => {
  const [idEds, setIdEds] = useState<IdEd[]>([]);

  const fetchIdEds = async (job_id: string) => {
    const idEds = await listIdEds(job_id);
    setIdEds(idEds.sort((a, b) => a.createdAt.localeCompare(b.createdAt)));
  };

  useEffect(() => {
    if (!job_id) return;
    fetchIdEds(job_id);
  }, [job_id]);

  useEffect(() => {
    if (!job_id) return;
    const variables = {
      filter: {
        job_id: {
          eq: job_id,
        },
      },
    };
    const createSub = API.graphql<GraphQLSubscription<OnCreateIdEdSubscription>>({
      query: subscriptions.onCreateIdEd,
      variables,
    }).subscribe({
      next: () => fetchIdEds(job_id),
      error: (error) => console.warn(error),
    });

    const updateSub = API.graphql<GraphQLSubscription<OnUpdateIdEdSubscription>>({
      query: subscriptions.onUpdateIdEd,
      variables,
    }).subscribe({
      next: () => fetchIdEds(job_id),
      error: (error) => console.warn(error),
    });

    const deleteSub = API.graphql<GraphQLSubscription<OnDeleteIdEdSubscription>>({
      query: subscriptions.onDeleteIdEd,
      variables,
    }).subscribe({
      next: () => fetchIdEds(job_id),
      error: (error) => console.warn(error),
    });

    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      deleteSub.unsubscribe();
    };
  }, [job_id]);

  return { idEds };
};

export const useCashBookSubscription = (job_id: string | undefined) => {
  const [cashBook, setCashBook] = useState<CashBook[]>([]);

  const fetchCashBook = async (job_id: string) => {
    const cashBook = await listCashBook(job_id);
    setCashBook(cashBook.sort((a, b) => b.date.localeCompare(a.date)));
  };

  useEffect(() => {
    if (!job_id) return;
    fetchCashBook(job_id);
  }, [job_id]);

  useEffect(() => {
    if (!job_id) return;
    const variables = {
      filter: {
        job_id: {
          eq: job_id,
        },
      },
    };
    const createSub = API.graphql<GraphQLSubscription<OnCreateCashBookSubscription>>({
      query: subscriptions.onCreateCashBook,
      variables,
    }).subscribe({
      next: () => fetchCashBook(job_id),
      error: (error) => console.warn(error),
    });

    const updateSub = API.graphql<GraphQLSubscription<OnUpdateCashBookSubscription>>({
      query: subscriptions.onUpdateCashBook,
      variables,
    }).subscribe({
      next: () => fetchCashBook(job_id),
      error: (error) => console.warn(error),
    });

    const deleteSub = API.graphql<GraphQLSubscription<OnDeleteCashBookSubscription>>({
      query: subscriptions.onDeleteCashBook,
      variables,
    }).subscribe({
      next: () => fetchCashBook(job_id),
      error: (error) => console.warn(error),
    });

    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      deleteSub.unsubscribe();
    };
  }, [job_id]);

  return { cashBook };
};

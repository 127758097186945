/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCountryInput = {
  id?: string | null,
  country_id?: number | null,
  country_name: string,
  alpha_2_code: string,
  _version?: number | null,
};

export type ModelCountryConditionInput = {
  country_id?: ModelIntInput | null,
  country_name?: ModelStringInput | null,
  alpha_2_code?: ModelStringInput | null,
  and?: Array< ModelCountryConditionInput | null > | null,
  or?: Array< ModelCountryConditionInput | null > | null,
  not?: ModelCountryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Country = {
  __typename: "Country",
  id: string,
  country_id?: number | null,
  country_name: string,
  alpha_2_code: string,
  Jobs?: ModelJobConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelJobConnection = {
  __typename: "ModelJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Job = {
  __typename: "Job",
  id: string,
  job_id?: number | null,
  job_code: string,
  date_of_creation: string,
  invoice_id?: string | null,
  invoice?: Invoice | null,
  customer_id: string,
  customer?: Customer | null,
  country_id: string,
  country?: Country | null,
  containers?: ModelContainerConnection | null,
  id_eds?: ModelIdEdConnection | null,
  cash_book_entries?: ModelCashBookConnection | null,
  route?: ROUTE_TYPE | null,
  comodity?: string | null,
  date_of_arrival?: string | null,
  voyage?: string | null,
  forwarder?: string | null,
  liner?: string | null,
  description?: string | null,
  remark?: string | null,
  date_of_delivery?: string | null,
  is_active?: boolean | null,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt: string,
  updatedAt: string,
  virtual_index?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  job_id: string,
  job?: Job | null,
  job_code?: string | null,
  invoice_date: string,
  invoice_type: INVOICE_TYPE,
  customer_id?: string | null,
  customer_name: string,
  comodity?: string | null,
  description?: string | null,
  remark?: string | null,
  id_or_ed_and_count?: string | null,
  id_ed_numbers?: string | null,
  container_types_and_counts?: string | null,
  container_numbers?: string | null,
  bl_list?: string | null,
  invoice_details?: ModelInvoiceDetailConnection | null,
  invoice_detail_ids: Array< string >,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt: string,
  updatedAt: string,
  virtual_index?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum INVOICE_TYPE {
  IMPORT = "IMPORT",
  EXPORT = "EXPORT",
  AIR = "AIR",
  LCL = "LCL",
  IMPORT_SEA = "IMPORT_SEA",
  IMPORT_AIR = "IMPORT_AIR",
  EXPORT_SEA = "EXPORT_SEA",
  EXPORT_AIR = "EXPORT_AIR",
  EXPORT_HNM_SEA = "EXPORT_HNM_SEA",
  EXPORT_HNM_AIR = "EXPORT_HNM_AIR",
}


export type ModelInvoiceDetailConnection = {
  __typename: "ModelInvoiceDetailConnection",
  items:  Array<InvoiceDetail | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type InvoiceDetail = {
  __typename: "InvoiceDetail",
  id: string,
  invoice_id: string,
  invoice_date: string,
  invoice?: Invoice | null,
  description?: string | null,
  amount_mmk: number,
  amount_usd: number,
  debit_mmk: number,
  debit_usd: number,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt: string,
  updatedAt: string,
  virtual_index?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Customer = {
  __typename: "Customer",
  id: string,
  customer_id?: number | null,
  customer_code: string,
  customer_name: string,
  opening_amount?: number | null,
  contact_person?: string | null,
  contact_address?: string | null,
  phone?: string | null,
  is_active?: boolean | null,
  remark?: string | null,
  jobs?: ModelJobConnection | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelContainerConnection = {
  __typename: "ModelContainerConnection",
  items:  Array<Container | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Container = {
  __typename: "Container",
  id: string,
  container_id?: number | null,
  container_number: string,
  container_type_id: string,
  container_type?: ContainerType | null,
  job_id: string,
  job?: Job | null,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt: string,
  updatedAt: string,
  virtual_index?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ContainerType = {
  __typename: "ContainerType",
  id: string,
  container_type_id?: number | null,
  container_type: string,
  description?: string | null,
  containers?: ModelContainerConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelIdEdConnection = {
  __typename: "ModelIdEdConnection",
  items:  Array<IdEd | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type IdEd = {
  __typename: "IdEd",
  id: string,
  id_ed_id?: number | null,
  id_ed_number: string,
  id_ed_type: ID_ED_TYPE,
  bl_number?: string | null,
  form_required?: boolean | null,
  form_number?: string | null,
  remark?: string | null,
  job_id: string,
  job?: Job | null,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt: string,
  updatedAt: string,
  virtual_index?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum ID_ED_TYPE {
  ID = "ID",
  ED = "ED",
}


export type ModelCashBookConnection = {
  __typename: "ModelCashBookConnection",
  items:  Array<CashBook | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type CashBook = {
  __typename: "CashBook",
  id: string,
  cashbook_id?: number | null,
  date: string,
  cash_handler: string,
  voucher_number?: string | null,
  job_id: string,
  job?: Job | null,
  job_creation_date: string,
  account_id: string,
  account?: Account | null,
  description?: string | null,
  debit_usd: number,
  credit_usd: number,
  debit_mmk: number,
  credit_mmk: number,
  is_deleted?: boolean | null,
  remark?: string | null,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt: string,
  updatedAt: string,
  virtual_index: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Account = {
  __typename: "Account",
  id: string,
  account_id?: number | null,
  account_code: string,
  account_code_sr: string,
  account_name: string,
  account_group_id: string,
  account_group?: AccountGroup | null,
  cash_book_entries?: ModelCashBookConnection | null,
  opening_amount?: number | null,
  opening_date?: string | null,
  is_active?: boolean | null,
  remark?: string | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt: string,
  updatedAt: string,
  virtual_index: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type AccountGroup = {
  __typename: "AccountGroup",
  id: string,
  account_group_id?: number | null,
  account_group_code: string,
  account_group_name: string,
  account_heading_code?: string | null,
  account_heading?: string | null,
  is_active?: boolean | null,
  remark?: string | null,
  accounts?: ModelAccountConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum ROUTE_TYPE {
  SEA = "SEA",
  AIR = "AIR",
  SEA_AIR = "SEA_AIR",
  ROAD = "ROAD",
}


export type UpdateCountryInput = {
  id: string,
  country_id?: number | null,
  country_name?: string | null,
  alpha_2_code?: string | null,
  _version?: number | null,
};

export type DeleteCountryInput = {
  id: string,
  _version?: number | null,
};

export type CreateAccountGroupInput = {
  id?: string | null,
  account_group_id?: number | null,
  account_group_code: string,
  account_group_name: string,
  account_heading_code?: string | null,
  account_heading?: string | null,
  is_active?: boolean | null,
  remark?: string | null,
  _version?: number | null,
};

export type ModelAccountGroupConditionInput = {
  account_group_id?: ModelIntInput | null,
  account_group_code?: ModelStringInput | null,
  account_group_name?: ModelStringInput | null,
  account_heading_code?: ModelStringInput | null,
  account_heading?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelAccountGroupConditionInput | null > | null,
  or?: Array< ModelAccountGroupConditionInput | null > | null,
  not?: ModelAccountGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateAccountGroupInput = {
  id: string,
  account_group_id?: number | null,
  account_group_code?: string | null,
  account_group_name?: string | null,
  account_heading_code?: string | null,
  account_heading?: string | null,
  is_active?: boolean | null,
  remark?: string | null,
  _version?: number | null,
};

export type DeleteAccountGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateAccountInput = {
  id?: string | null,
  account_id?: number | null,
  account_code: string,
  account_code_sr: string,
  account_name: string,
  account_group_id: string,
  opening_amount?: number | null,
  opening_date?: string | null,
  is_active?: boolean | null,
  remark?: string | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index: string,
  _version?: number | null,
};

export type ModelAccountConditionInput = {
  account_id?: ModelIntInput | null,
  account_code?: ModelStringInput | null,
  account_code_sr?: ModelStringInput | null,
  account_name?: ModelStringInput | null,
  account_group_id?: ModelIDInput | null,
  opening_amount?: ModelFloatInput | null,
  opening_date?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  remark?: ModelStringInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateAccountInput = {
  id: string,
  account_id?: number | null,
  account_code?: string | null,
  account_code_sr?: string | null,
  account_name?: string | null,
  account_group_id?: string | null,
  opening_amount?: number | null,
  opening_date?: string | null,
  is_active?: boolean | null,
  remark?: string | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: string | null,
  _version?: number | null,
};

export type DeleteAccountInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomerInput = {
  id?: string | null,
  customer_id?: number | null,
  customer_code: string,
  customer_name: string,
  opening_amount?: number | null,
  contact_person?: string | null,
  contact_address?: string | null,
  phone?: string | null,
  is_active?: boolean | null,
  remark?: string | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelCustomerConditionInput = {
  customer_id?: ModelIntInput | null,
  customer_code?: ModelStringInput | null,
  customer_name?: ModelStringInput | null,
  opening_amount?: ModelFloatInput | null,
  contact_person?: ModelStringInput | null,
  contact_address?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  remark?: ModelStringInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCustomerConditionInput | null > | null,
  or?: Array< ModelCustomerConditionInput | null > | null,
  not?: ModelCustomerConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateCustomerInput = {
  id: string,
  customer_id?: number | null,
  customer_code?: string | null,
  customer_name?: string | null,
  opening_amount?: number | null,
  contact_person?: string | null,
  contact_address?: string | null,
  phone?: string | null,
  is_active?: boolean | null,
  remark?: string | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteCustomerInput = {
  id: string,
  _version?: number | null,
};

export type CreateJobInput = {
  id?: string | null,
  job_id?: number | null,
  job_code: string,
  date_of_creation: string,
  invoice_id?: string | null,
  customer_id: string,
  country_id: string,
  route?: ROUTE_TYPE | null,
  comodity?: string | null,
  date_of_arrival?: string | null,
  voyage?: string | null,
  forwarder?: string | null,
  liner?: string | null,
  description?: string | null,
  remark?: string | null,
  date_of_delivery?: string | null,
  is_active?: boolean | null,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type ModelJobConditionInput = {
  job_id?: ModelIntInput | null,
  job_code?: ModelStringInput | null,
  date_of_creation?: ModelStringInput | null,
  invoice_id?: ModelIDInput | null,
  customer_id?: ModelIDInput | null,
  country_id?: ModelIDInput | null,
  route?: ModelROUTE_TYPEInput | null,
  comodity?: ModelStringInput | null,
  date_of_arrival?: ModelStringInput | null,
  voyage?: ModelStringInput | null,
  forwarder?: ModelStringInput | null,
  liner?: ModelStringInput | null,
  description?: ModelStringInput | null,
  remark?: ModelStringInput | null,
  date_of_delivery?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelJobConditionInput | null > | null,
  or?: Array< ModelJobConditionInput | null > | null,
  not?: ModelJobConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelROUTE_TYPEInput = {
  eq?: ROUTE_TYPE | null,
  ne?: ROUTE_TYPE | null,
};

export type UpdateJobInput = {
  id: string,
  job_id?: number | null,
  job_code?: string | null,
  date_of_creation?: string | null,
  invoice_id?: string | null,
  customer_id?: string | null,
  country_id?: string | null,
  route?: ROUTE_TYPE | null,
  comodity?: string | null,
  date_of_arrival?: string | null,
  voyage?: string | null,
  forwarder?: string | null,
  liner?: string | null,
  description?: string | null,
  remark?: string | null,
  date_of_delivery?: string | null,
  is_active?: boolean | null,
  archived?: boolean | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type DeleteJobInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvoiceInput = {
  id?: string | null,
  job_id: string,
  job_code?: string | null,
  invoice_date: string,
  invoice_type: INVOICE_TYPE,
  customer_id?: string | null,
  customer_name: string,
  comodity?: string | null,
  description?: string | null,
  remark?: string | null,
  id_or_ed_and_count?: string | null,
  id_ed_numbers?: string | null,
  container_types_and_counts?: string | null,
  container_numbers?: string | null,
  bl_list?: string | null,
  invoice_detail_ids: Array< string >,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type ModelInvoiceConditionInput = {
  job_id?: ModelIDInput | null,
  job_code?: ModelStringInput | null,
  invoice_date?: ModelStringInput | null,
  invoice_type?: ModelINVOICE_TYPEInput | null,
  customer_id?: ModelStringInput | null,
  customer_name?: ModelStringInput | null,
  comodity?: ModelStringInput | null,
  description?: ModelStringInput | null,
  remark?: ModelStringInput | null,
  id_or_ed_and_count?: ModelStringInput | null,
  id_ed_numbers?: ModelStringInput | null,
  container_types_and_counts?: ModelStringInput | null,
  container_numbers?: ModelStringInput | null,
  bl_list?: ModelStringInput | null,
  invoice_detail_ids?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelInvoiceConditionInput | null > | null,
  or?: Array< ModelInvoiceConditionInput | null > | null,
  not?: ModelInvoiceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelINVOICE_TYPEInput = {
  eq?: INVOICE_TYPE | null,
  ne?: INVOICE_TYPE | null,
};

export type UpdateInvoiceInput = {
  id: string,
  job_id?: string | null,
  job_code?: string | null,
  invoice_date?: string | null,
  invoice_type?: INVOICE_TYPE | null,
  customer_id?: string | null,
  customer_name?: string | null,
  comodity?: string | null,
  description?: string | null,
  remark?: string | null,
  id_or_ed_and_count?: string | null,
  id_ed_numbers?: string | null,
  container_types_and_counts?: string | null,
  container_numbers?: string | null,
  bl_list?: string | null,
  invoice_detail_ids?: Array< string > | null,
  archived?: boolean | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type DeleteInvoiceInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvoiceDetailInput = {
  id?: string | null,
  invoice_id: string,
  invoice_date: string,
  description?: string | null,
  amount_mmk: number,
  amount_usd: number,
  debit_mmk: number,
  debit_usd: number,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type ModelInvoiceDetailConditionInput = {
  invoice_id?: ModelIDInput | null,
  invoice_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  amount_mmk?: ModelFloatInput | null,
  amount_usd?: ModelFloatInput | null,
  debit_mmk?: ModelFloatInput | null,
  debit_usd?: ModelFloatInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelInvoiceDetailConditionInput | null > | null,
  or?: Array< ModelInvoiceDetailConditionInput | null > | null,
  not?: ModelInvoiceDetailConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateInvoiceDetailInput = {
  id: string,
  invoice_id?: string | null,
  invoice_date?: string | null,
  description?: string | null,
  amount_mmk?: number | null,
  amount_usd?: number | null,
  debit_mmk?: number | null,
  debit_usd?: number | null,
  archived?: boolean | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type DeleteInvoiceDetailInput = {
  id: string,
  _version?: number | null,
};

export type CreateContainerTypeInput = {
  id?: string | null,
  container_type_id?: number | null,
  container_type: string,
  description?: string | null,
  _version?: number | null,
};

export type ModelContainerTypeConditionInput = {
  container_type_id?: ModelIntInput | null,
  container_type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelContainerTypeConditionInput | null > | null,
  or?: Array< ModelContainerTypeConditionInput | null > | null,
  not?: ModelContainerTypeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateContainerTypeInput = {
  id: string,
  container_type_id?: number | null,
  container_type?: string | null,
  description?: string | null,
  _version?: number | null,
};

export type DeleteContainerTypeInput = {
  id: string,
  _version?: number | null,
};

export type CreateContainerInput = {
  id?: string | null,
  container_id?: number | null,
  container_number: string,
  container_type_id: string,
  job_id: string,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type ModelContainerConditionInput = {
  container_id?: ModelIntInput | null,
  container_number?: ModelStringInput | null,
  container_type_id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelContainerConditionInput | null > | null,
  or?: Array< ModelContainerConditionInput | null > | null,
  not?: ModelContainerConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateContainerInput = {
  id: string,
  container_id?: number | null,
  container_number?: string | null,
  container_type_id?: string | null,
  job_id?: string | null,
  archived?: boolean | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type DeleteContainerInput = {
  id: string,
  _version?: number | null,
};

export type CreateIdEdInput = {
  id?: string | null,
  id_ed_id?: number | null,
  id_ed_number: string,
  id_ed_type: ID_ED_TYPE,
  bl_number?: string | null,
  form_required?: boolean | null,
  form_number?: string | null,
  remark?: string | null,
  job_id: string,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type ModelIdEdConditionInput = {
  id_ed_id?: ModelIntInput | null,
  id_ed_number?: ModelStringInput | null,
  id_ed_type?: ModelID_ED_TYPEInput | null,
  bl_number?: ModelStringInput | null,
  form_required?: ModelBooleanInput | null,
  form_number?: ModelStringInput | null,
  remark?: ModelStringInput | null,
  job_id?: ModelIDInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelIdEdConditionInput | null > | null,
  or?: Array< ModelIdEdConditionInput | null > | null,
  not?: ModelIdEdConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelID_ED_TYPEInput = {
  eq?: ID_ED_TYPE | null,
  ne?: ID_ED_TYPE | null,
};

export type UpdateIdEdInput = {
  id: string,
  id_ed_id?: number | null,
  id_ed_number?: string | null,
  id_ed_type?: ID_ED_TYPE | null,
  bl_number?: string | null,
  form_required?: boolean | null,
  form_number?: string | null,
  remark?: string | null,
  job_id?: string | null,
  archived?: boolean | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: number | null,
  _version?: number | null,
};

export type DeleteIdEdInput = {
  id: string,
  _version?: number | null,
};

export type CreateCashBookInput = {
  id?: string | null,
  cashbook_id?: number | null,
  date: string,
  cash_handler: string,
  voucher_number?: string | null,
  job_id: string,
  job_creation_date: string,
  account_id: string,
  description?: string | null,
  debit_usd: number,
  credit_usd: number,
  debit_mmk: number,
  credit_mmk: number,
  is_deleted?: boolean | null,
  remark?: string | null,
  archived: boolean,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index: string,
  _version?: number | null,
};

export type ModelCashBookConditionInput = {
  cashbook_id?: ModelIntInput | null,
  date?: ModelStringInput | null,
  cash_handler?: ModelStringInput | null,
  voucher_number?: ModelStringInput | null,
  job_id?: ModelIDInput | null,
  job_creation_date?: ModelStringInput | null,
  account_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  debit_usd?: ModelFloatInput | null,
  credit_usd?: ModelFloatInput | null,
  debit_mmk?: ModelFloatInput | null,
  credit_mmk?: ModelFloatInput | null,
  is_deleted?: ModelBooleanInput | null,
  remark?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelStringInput | null,
  and?: Array< ModelCashBookConditionInput | null > | null,
  or?: Array< ModelCashBookConditionInput | null > | null,
  not?: ModelCashBookConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateCashBookInput = {
  id: string,
  cashbook_id?: number | null,
  date?: string | null,
  cash_handler?: string | null,
  voucher_number?: string | null,
  job_id?: string | null,
  job_creation_date?: string | null,
  account_id?: string | null,
  description?: string | null,
  debit_usd?: number | null,
  credit_usd?: number | null,
  debit_mmk?: number | null,
  credit_mmk?: number | null,
  is_deleted?: boolean | null,
  remark?: string | null,
  archived?: boolean | null,
  created_by?: string | null,
  modified_by?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  virtual_index?: string | null,
  _version?: number | null,
};

export type DeleteCashBookInput = {
  id: string,
  _version?: number | null,
};

export type CreateCashHandlerInput = {
  id?: string | null,
  short_name: string,
  full_name: string,
  email?: string | null,
  phone?: string | null,
  address?: string | null,
  _version?: number | null,
};

export type ModelCashHandlerConditionInput = {
  short_name?: ModelStringInput | null,
  full_name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  address?: ModelStringInput | null,
  and?: Array< ModelCashHandlerConditionInput | null > | null,
  or?: Array< ModelCashHandlerConditionInput | null > | null,
  not?: ModelCashHandlerConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type CashHandler = {
  __typename: "CashHandler",
  id: string,
  short_name: string,
  full_name: string,
  email?: string | null,
  phone?: string | null,
  address?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCashHandlerInput = {
  id: string,
  short_name?: string | null,
  full_name?: string | null,
  email?: string | null,
  phone?: string | null,
  address?: string | null,
  _version?: number | null,
};

export type DeleteCashHandlerInput = {
  id: string,
  _version?: number | null,
};

export type CreateLogInput = {
  id?: string | null,
  job_id: string,
  job_code: string,
  model: DataTableType,
  model_id: string,
  operation: OperationType,
  old_data?: string | null,
  new_data?: string | null,
  user: string,
  changed_at: string,
  _version?: number | null,
};

export enum DataTableType {
  JOB = "JOB",
  CONTAINER = "CONTAINER",
  ID_ED = "ID_ED",
  CASH_BOOK = "CASH_BOOK",
  CASH_HANDLER = "CASH_HANDLER",
  INVOICE = "INVOICE",
  INVOICE_DETAIL = "INVOICE_DETAIL",
  CUSTOMER = "CUSTOMER",
  ACCOUNT = "ACCOUNT",
  ACCOUNT_GROUP = "ACCOUNT_GROUP",
  USER = "USER",
}


export enum OperationType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  LOCK = "LOCK",
  UNLOCK = "UNLOCK",
  PRINT_INVOICE = "PRINT_INVOICE",
}


export type ModelLogConditionInput = {
  job_id?: ModelIDInput | null,
  job_code?: ModelStringInput | null,
  model?: ModelDataTableTypeInput | null,
  model_id?: ModelIDInput | null,
  operation?: ModelOperationTypeInput | null,
  old_data?: ModelStringInput | null,
  new_data?: ModelStringInput | null,
  user?: ModelStringInput | null,
  changed_at?: ModelStringInput | null,
  and?: Array< ModelLogConditionInput | null > | null,
  or?: Array< ModelLogConditionInput | null > | null,
  not?: ModelLogConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDataTableTypeInput = {
  eq?: DataTableType | null,
  ne?: DataTableType | null,
};

export type ModelOperationTypeInput = {
  eq?: OperationType | null,
  ne?: OperationType | null,
};

export type Log = {
  __typename: "Log",
  id: string,
  job_id: string,
  job_code: string,
  model: DataTableType,
  model_id: string,
  operation: OperationType,
  old_data?: string | null,
  new_data?: string | null,
  user: string,
  changed_at: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateLogInput = {
  id: string,
  job_id?: string | null,
  job_code?: string | null,
  model?: DataTableType | null,
  model_id?: string | null,
  operation?: OperationType | null,
  old_data?: string | null,
  new_data?: string | null,
  user?: string | null,
  changed_at?: string | null,
  _version?: number | null,
};

export type DeleteLogInput = {
  id: string,
  _version?: number | null,
};

export type CreatePermissionInput = {
  id?: string | null,
  model: DataTableType,
  operation: OperationType,
  required_permission_level: number,
  _version?: number | null,
};

export type ModelPermissionConditionInput = {
  model?: ModelDataTableTypeInput | null,
  operation?: ModelOperationTypeInput | null,
  required_permission_level?: ModelIntInput | null,
  and?: Array< ModelPermissionConditionInput | null > | null,
  or?: Array< ModelPermissionConditionInput | null > | null,
  not?: ModelPermissionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Permission = {
  __typename: "Permission",
  id: string,
  model: DataTableType,
  operation: OperationType,
  required_permission_level: number,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePermissionInput = {
  id: string,
  model?: DataTableType | null,
  operation?: OperationType | null,
  required_permission_level?: number | null,
  _version?: number | null,
};

export type DeletePermissionInput = {
  id: string,
  _version?: number | null,
};

export type CreatePageVisitInput = {
  id?: string | null,
  page: string,
  user: string,
  visited_at: string,
  type: string,
  _version?: number | null,
};

export type ModelPageVisitConditionInput = {
  page?: ModelStringInput | null,
  user?: ModelStringInput | null,
  visited_at?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPageVisitConditionInput | null > | null,
  or?: Array< ModelPageVisitConditionInput | null > | null,
  not?: ModelPageVisitConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type PageVisit = {
  __typename: "PageVisit",
  id: string,
  page: string,
  user: string,
  visited_at: string,
  type: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePageVisitInput = {
  id: string,
  page?: string | null,
  user?: string | null,
  visited_at?: string | null,
  type?: string | null,
  _version?: number | null,
};

export type DeletePageVisitInput = {
  id: string,
  _version?: number | null,
};

export type ModelCountryFilterInput = {
  id?: ModelIDInput | null,
  country_id?: ModelIntInput | null,
  country_name?: ModelStringInput | null,
  alpha_2_code?: ModelStringInput | null,
  and?: Array< ModelCountryFilterInput | null > | null,
  or?: Array< ModelCountryFilterInput | null > | null,
  not?: ModelCountryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCountryConnection = {
  __typename: "ModelCountryConnection",
  items:  Array<Country | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAccountGroupFilterInput = {
  id?: ModelIDInput | null,
  account_group_id?: ModelIntInput | null,
  account_group_code?: ModelStringInput | null,
  account_group_name?: ModelStringInput | null,
  account_heading_code?: ModelStringInput | null,
  account_heading?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelAccountGroupFilterInput | null > | null,
  or?: Array< ModelAccountGroupFilterInput | null > | null,
  not?: ModelAccountGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAccountGroupConnection = {
  __typename: "ModelAccountGroupConnection",
  items:  Array<AccountGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  account_id?: ModelIntInput | null,
  account_code?: ModelStringInput | null,
  account_code_sr?: ModelStringInput | null,
  account_name?: ModelStringInput | null,
  account_group_id?: ModelIDInput | null,
  opening_amount?: ModelFloatInput | null,
  opening_date?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  remark?: ModelStringInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null,
  customer_id?: ModelIntInput | null,
  customer_code?: ModelStringInput | null,
  customer_name?: ModelStringInput | null,
  opening_amount?: ModelFloatInput | null,
  contact_person?: ModelStringInput | null,
  contact_address?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  remark?: ModelStringInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCustomerFilterInput | null > | null,
  or?: Array< ModelCustomerFilterInput | null > | null,
  not?: ModelCustomerFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelJobFilterInput = {
  id?: ModelIDInput | null,
  job_id?: ModelIntInput | null,
  job_code?: ModelStringInput | null,
  date_of_creation?: ModelStringInput | null,
  invoice_id?: ModelIDInput | null,
  customer_id?: ModelIDInput | null,
  country_id?: ModelIDInput | null,
  route?: ModelROUTE_TYPEInput | null,
  comodity?: ModelStringInput | null,
  date_of_arrival?: ModelStringInput | null,
  voyage?: ModelStringInput | null,
  forwarder?: ModelStringInput | null,
  liner?: ModelStringInput | null,
  description?: ModelStringInput | null,
  remark?: ModelStringInput | null,
  date_of_delivery?: ModelStringInput | null,
  is_active?: ModelBooleanInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelJobFilterInput | null > | null,
  or?: Array< ModelJobFilterInput | null > | null,
  not?: ModelJobFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  job_code?: ModelStringInput | null,
  invoice_date?: ModelStringInput | null,
  invoice_type?: ModelINVOICE_TYPEInput | null,
  customer_id?: ModelStringInput | null,
  customer_name?: ModelStringInput | null,
  comodity?: ModelStringInput | null,
  description?: ModelStringInput | null,
  remark?: ModelStringInput | null,
  id_or_ed_and_count?: ModelStringInput | null,
  id_ed_numbers?: ModelStringInput | null,
  container_types_and_counts?: ModelStringInput | null,
  container_numbers?: ModelStringInput | null,
  bl_list?: ModelStringInput | null,
  invoice_detail_ids?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelInvoiceFilterInput | null > | null,
  or?: Array< ModelInvoiceFilterInput | null > | null,
  not?: ModelInvoiceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInvoiceDetailFilterInput = {
  id?: ModelIDInput | null,
  invoice_id?: ModelIDInput | null,
  invoice_date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  amount_mmk?: ModelFloatInput | null,
  amount_usd?: ModelFloatInput | null,
  debit_mmk?: ModelFloatInput | null,
  debit_usd?: ModelFloatInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelInvoiceDetailFilterInput | null > | null,
  or?: Array< ModelInvoiceDetailFilterInput | null > | null,
  not?: ModelInvoiceDetailFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelContainerTypeFilterInput = {
  id?: ModelIDInput | null,
  container_type_id?: ModelIntInput | null,
  container_type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelContainerTypeFilterInput | null > | null,
  or?: Array< ModelContainerTypeFilterInput | null > | null,
  not?: ModelContainerTypeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelContainerTypeConnection = {
  __typename: "ModelContainerTypeConnection",
  items:  Array<ContainerType | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelContainerFilterInput = {
  id?: ModelIDInput | null,
  container_id?: ModelIntInput | null,
  container_number?: ModelStringInput | null,
  container_type_id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelContainerFilterInput | null > | null,
  or?: Array< ModelContainerFilterInput | null > | null,
  not?: ModelContainerFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIdEdFilterInput = {
  id?: ModelIDInput | null,
  id_ed_id?: ModelIntInput | null,
  id_ed_number?: ModelStringInput | null,
  id_ed_type?: ModelID_ED_TYPEInput | null,
  bl_number?: ModelStringInput | null,
  form_required?: ModelBooleanInput | null,
  form_number?: ModelStringInput | null,
  remark?: ModelStringInput | null,
  job_id?: ModelIDInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelIntInput | null,
  and?: Array< ModelIdEdFilterInput | null > | null,
  or?: Array< ModelIdEdFilterInput | null > | null,
  not?: ModelIdEdFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCashBookFilterInput = {
  id?: ModelIDInput | null,
  cashbook_id?: ModelIntInput | null,
  date?: ModelStringInput | null,
  cash_handler?: ModelStringInput | null,
  voucher_number?: ModelStringInput | null,
  job_id?: ModelIDInput | null,
  job_creation_date?: ModelStringInput | null,
  account_id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  debit_usd?: ModelFloatInput | null,
  credit_usd?: ModelFloatInput | null,
  debit_mmk?: ModelFloatInput | null,
  credit_mmk?: ModelFloatInput | null,
  is_deleted?: ModelBooleanInput | null,
  remark?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  created_by?: ModelStringInput | null,
  modified_by?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  virtual_index?: ModelStringInput | null,
  and?: Array< ModelCashBookFilterInput | null > | null,
  or?: Array< ModelCashBookFilterInput | null > | null,
  not?: ModelCashBookFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCashHandlerFilterInput = {
  id?: ModelIDInput | null,
  short_name?: ModelStringInput | null,
  full_name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  address?: ModelStringInput | null,
  and?: Array< ModelCashHandlerFilterInput | null > | null,
  or?: Array< ModelCashHandlerFilterInput | null > | null,
  not?: ModelCashHandlerFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCashHandlerConnection = {
  __typename: "ModelCashHandlerConnection",
  items:  Array<CashHandler | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLogFilterInput = {
  id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  job_code?: ModelStringInput | null,
  model?: ModelDataTableTypeInput | null,
  model_id?: ModelIDInput | null,
  operation?: ModelOperationTypeInput | null,
  old_data?: ModelStringInput | null,
  new_data?: ModelStringInput | null,
  user?: ModelStringInput | null,
  changed_at?: ModelStringInput | null,
  and?: Array< ModelLogFilterInput | null > | null,
  or?: Array< ModelLogFilterInput | null > | null,
  not?: ModelLogFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLogConnection = {
  __typename: "ModelLogConnection",
  items:  Array<Log | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPermissionFilterInput = {
  id?: ModelIDInput | null,
  model?: ModelDataTableTypeInput | null,
  operation?: ModelOperationTypeInput | null,
  required_permission_level?: ModelIntInput | null,
  and?: Array< ModelPermissionFilterInput | null > | null,
  or?: Array< ModelPermissionFilterInput | null > | null,
  not?: ModelPermissionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPermissionConnection = {
  __typename: "ModelPermissionConnection",
  items:  Array<Permission | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPageVisitFilterInput = {
  id?: ModelIDInput | null,
  page?: ModelStringInput | null,
  user?: ModelStringInput | null,
  visited_at?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPageVisitFilterInput | null > | null,
  or?: Array< ModelPageVisitFilterInput | null > | null,
  not?: ModelPageVisitFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPageVisitConnection = {
  __typename: "ModelPageVisitConnection",
  items:  Array<PageVisit | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelLogLogByJobIdCompositeKeyConditionInput = {
  eq?: ModelLogLogByJobIdCompositeKeyInput | null,
  le?: ModelLogLogByJobIdCompositeKeyInput | null,
  lt?: ModelLogLogByJobIdCompositeKeyInput | null,
  ge?: ModelLogLogByJobIdCompositeKeyInput | null,
  gt?: ModelLogLogByJobIdCompositeKeyInput | null,
  between?: Array< ModelLogLogByJobIdCompositeKeyInput | null > | null,
  beginsWith?: ModelLogLogByJobIdCompositeKeyInput | null,
};

export type ModelLogLogByJobIdCompositeKeyInput = {
  model?: DataTableType | null,
  model_id?: string | null,
};

export type ModelLogLogByJobCodeCompositeKeyConditionInput = {
  eq?: ModelLogLogByJobCodeCompositeKeyInput | null,
  le?: ModelLogLogByJobCodeCompositeKeyInput | null,
  lt?: ModelLogLogByJobCodeCompositeKeyInput | null,
  ge?: ModelLogLogByJobCodeCompositeKeyInput | null,
  gt?: ModelLogLogByJobCodeCompositeKeyInput | null,
  between?: Array< ModelLogLogByJobCodeCompositeKeyInput | null > | null,
  beginsWith?: ModelLogLogByJobCodeCompositeKeyInput | null,
};

export type ModelLogLogByJobCodeCompositeKeyInput = {
  model?: DataTableType | null,
  model_id?: string | null,
};

export type ModelSubscriptionCountryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  country_id?: ModelSubscriptionIntInput | null,
  country_name?: ModelSubscriptionStringInput | null,
  alpha_2_code?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCountryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCountryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionAccountGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  account_group_id?: ModelSubscriptionIntInput | null,
  account_group_code?: ModelSubscriptionStringInput | null,
  account_group_name?: ModelSubscriptionStringInput | null,
  account_heading_code?: ModelSubscriptionStringInput | null,
  account_heading?: ModelSubscriptionStringInput | null,
  is_active?: ModelSubscriptionBooleanInput | null,
  remark?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  account_id?: ModelSubscriptionIntInput | null,
  account_code?: ModelSubscriptionStringInput | null,
  account_code_sr?: ModelSubscriptionStringInput | null,
  account_name?: ModelSubscriptionStringInput | null,
  account_group_id?: ModelSubscriptionIDInput | null,
  opening_amount?: ModelSubscriptionFloatInput | null,
  opening_date?: ModelSubscriptionStringInput | null,
  is_active?: ModelSubscriptionBooleanInput | null,
  remark?: ModelSubscriptionStringInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  modified_by?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  virtual_index?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionCustomerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  customer_id?: ModelSubscriptionIntInput | null,
  customer_code?: ModelSubscriptionStringInput | null,
  customer_name?: ModelSubscriptionStringInput | null,
  opening_amount?: ModelSubscriptionFloatInput | null,
  contact_person?: ModelSubscriptionStringInput | null,
  contact_address?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  is_active?: ModelSubscriptionBooleanInput | null,
  remark?: ModelSubscriptionStringInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  modified_by?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionJobFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  job_id?: ModelSubscriptionIntInput | null,
  job_code?: ModelSubscriptionStringInput | null,
  date_of_creation?: ModelSubscriptionStringInput | null,
  invoice_id?: ModelSubscriptionIDInput | null,
  customer_id?: ModelSubscriptionIDInput | null,
  country_id?: ModelSubscriptionIDInput | null,
  route?: ModelSubscriptionStringInput | null,
  comodity?: ModelSubscriptionStringInput | null,
  date_of_arrival?: ModelSubscriptionStringInput | null,
  voyage?: ModelSubscriptionStringInput | null,
  forwarder?: ModelSubscriptionStringInput | null,
  liner?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  remark?: ModelSubscriptionStringInput | null,
  date_of_delivery?: ModelSubscriptionStringInput | null,
  is_active?: ModelSubscriptionBooleanInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  modified_by?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  virtual_index?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionJobFilterInput | null > | null,
  or?: Array< ModelSubscriptionJobFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInvoiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  job_id?: ModelSubscriptionIDInput | null,
  job_code?: ModelSubscriptionStringInput | null,
  invoice_date?: ModelSubscriptionStringInput | null,
  invoice_type?: ModelSubscriptionStringInput | null,
  customer_id?: ModelSubscriptionStringInput | null,
  customer_name?: ModelSubscriptionStringInput | null,
  comodity?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  remark?: ModelSubscriptionStringInput | null,
  id_or_ed_and_count?: ModelSubscriptionStringInput | null,
  id_ed_numbers?: ModelSubscriptionStringInput | null,
  container_types_and_counts?: ModelSubscriptionStringInput | null,
  container_numbers?: ModelSubscriptionStringInput | null,
  bl_list?: ModelSubscriptionStringInput | null,
  invoice_detail_ids?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  modified_by?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  virtual_index?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInvoiceDetailFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  invoice_id?: ModelSubscriptionIDInput | null,
  invoice_date?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  amount_mmk?: ModelSubscriptionFloatInput | null,
  amount_usd?: ModelSubscriptionFloatInput | null,
  debit_mmk?: ModelSubscriptionFloatInput | null,
  debit_usd?: ModelSubscriptionFloatInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  modified_by?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  virtual_index?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionInvoiceDetailFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvoiceDetailFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionContainerTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  container_type_id?: ModelSubscriptionIntInput | null,
  container_type?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContainerTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionContainerTypeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionContainerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  container_id?: ModelSubscriptionIntInput | null,
  container_number?: ModelSubscriptionStringInput | null,
  container_type_id?: ModelSubscriptionIDInput | null,
  job_id?: ModelSubscriptionIDInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  modified_by?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  virtual_index?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionContainerFilterInput | null > | null,
  or?: Array< ModelSubscriptionContainerFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIdEdFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  id_ed_id?: ModelSubscriptionIntInput | null,
  id_ed_number?: ModelSubscriptionStringInput | null,
  id_ed_type?: ModelSubscriptionStringInput | null,
  bl_number?: ModelSubscriptionStringInput | null,
  form_required?: ModelSubscriptionBooleanInput | null,
  form_number?: ModelSubscriptionStringInput | null,
  remark?: ModelSubscriptionStringInput | null,
  job_id?: ModelSubscriptionIDInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  modified_by?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  virtual_index?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionIdEdFilterInput | null > | null,
  or?: Array< ModelSubscriptionIdEdFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCashBookFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cashbook_id?: ModelSubscriptionIntInput | null,
  date?: ModelSubscriptionStringInput | null,
  cash_handler?: ModelSubscriptionStringInput | null,
  voucher_number?: ModelSubscriptionStringInput | null,
  job_id?: ModelSubscriptionIDInput | null,
  job_creation_date?: ModelSubscriptionStringInput | null,
  account_id?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  debit_usd?: ModelSubscriptionFloatInput | null,
  credit_usd?: ModelSubscriptionFloatInput | null,
  debit_mmk?: ModelSubscriptionFloatInput | null,
  credit_mmk?: ModelSubscriptionFloatInput | null,
  is_deleted?: ModelSubscriptionBooleanInput | null,
  remark?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  created_by?: ModelSubscriptionStringInput | null,
  modified_by?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  virtual_index?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCashBookFilterInput | null > | null,
  or?: Array< ModelSubscriptionCashBookFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCashHandlerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  short_name?: ModelSubscriptionStringInput | null,
  full_name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCashHandlerFilterInput | null > | null,
  or?: Array< ModelSubscriptionCashHandlerFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  job_id?: ModelSubscriptionIDInput | null,
  job_code?: ModelSubscriptionStringInput | null,
  model?: ModelSubscriptionStringInput | null,
  model_id?: ModelSubscriptionIDInput | null,
  operation?: ModelSubscriptionStringInput | null,
  old_data?: ModelSubscriptionStringInput | null,
  new_data?: ModelSubscriptionStringInput | null,
  user?: ModelSubscriptionStringInput | null,
  changed_at?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionLogFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPermissionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  model?: ModelSubscriptionStringInput | null,
  operation?: ModelSubscriptionStringInput | null,
  required_permission_level?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionPermissionFilterInput | null > | null,
  or?: Array< ModelSubscriptionPermissionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPageVisitFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  page?: ModelSubscriptionStringInput | null,
  user?: ModelSubscriptionStringInput | null,
  visited_at?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPageVisitFilterInput | null > | null,
  or?: Array< ModelSubscriptionPageVisitFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateCountryMutationVariables = {
  input: CreateCountryInput,
  condition?: ModelCountryConditionInput | null,
};

export type CreateCountryMutation = {
  createCountry?:  {
    __typename: "Country",
    id: string,
    country_id?: number | null,
    country_name: string,
    alpha_2_code: string,
    Jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCountryMutationVariables = {
  input: UpdateCountryInput,
  condition?: ModelCountryConditionInput | null,
};

export type UpdateCountryMutation = {
  updateCountry?:  {
    __typename: "Country",
    id: string,
    country_id?: number | null,
    country_name: string,
    alpha_2_code: string,
    Jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCountryMutationVariables = {
  input: DeleteCountryInput,
  condition?: ModelCountryConditionInput | null,
};

export type DeleteCountryMutation = {
  deleteCountry?:  {
    __typename: "Country",
    id: string,
    country_id?: number | null,
    country_name: string,
    alpha_2_code: string,
    Jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAccountGroupMutationVariables = {
  input: CreateAccountGroupInput,
  condition?: ModelAccountGroupConditionInput | null,
};

export type CreateAccountGroupMutation = {
  createAccountGroup?:  {
    __typename: "AccountGroup",
    id: string,
    account_group_id?: number | null,
    account_group_code: string,
    account_group_name: string,
    account_heading_code?: string | null,
    account_heading?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    accounts?:  {
      __typename: "ModelAccountConnection",
      items:  Array< {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAccountGroupMutationVariables = {
  input: UpdateAccountGroupInput,
  condition?: ModelAccountGroupConditionInput | null,
};

export type UpdateAccountGroupMutation = {
  updateAccountGroup?:  {
    __typename: "AccountGroup",
    id: string,
    account_group_id?: number | null,
    account_group_code: string,
    account_group_name: string,
    account_heading_code?: string | null,
    account_heading?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    accounts?:  {
      __typename: "ModelAccountConnection",
      items:  Array< {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAccountGroupMutationVariables = {
  input: DeleteAccountGroupInput,
  condition?: ModelAccountGroupConditionInput | null,
};

export type DeleteAccountGroupMutation = {
  deleteAccountGroup?:  {
    __typename: "AccountGroup",
    id: string,
    account_group_id?: number | null,
    account_group_code: string,
    account_group_name: string,
    account_heading_code?: string | null,
    account_heading?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    accounts?:  {
      __typename: "ModelAccountConnection",
      items:  Array< {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    account_id?: number | null,
    account_code: string,
    account_code_sr: string,
    account_name: string,
    account_group_id: string,
    account_group?:  {
      __typename: "AccountGroup",
      id: string,
      account_group_id?: number | null,
      account_group_code: string,
      account_group_name: string,
      account_heading_code?: string | null,
      account_heading?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      accounts?:  {
        __typename: "ModelAccountConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    opening_amount?: number | null,
    opening_date?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    account_id?: number | null,
    account_code: string,
    account_code_sr: string,
    account_name: string,
    account_group_id: string,
    account_group?:  {
      __typename: "AccountGroup",
      id: string,
      account_group_id?: number | null,
      account_group_code: string,
      account_group_name: string,
      account_heading_code?: string | null,
      account_heading?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      accounts?:  {
        __typename: "ModelAccountConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    opening_amount?: number | null,
    opening_date?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    account_id?: number | null,
    account_code: string,
    account_code_sr: string,
    account_name: string,
    account_group_id: string,
    account_group?:  {
      __typename: "AccountGroup",
      id: string,
      account_group_id?: number | null,
      account_group_code: string,
      account_group_name: string,
      account_heading_code?: string | null,
      account_heading?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      accounts?:  {
        __typename: "ModelAccountConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    opening_amount?: number | null,
    opening_date?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id: string,
    customer_id?: number | null,
    customer_code: string,
    customer_name: string,
    opening_amount?: number | null,
    contact_person?: string | null,
    contact_address?: string | null,
    phone?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    id: string,
    customer_id?: number | null,
    customer_code: string,
    customer_name: string,
    opening_amount?: number | null,
    contact_person?: string | null,
    contact_address?: string | null,
    phone?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    id: string,
    customer_id?: number | null,
    customer_code: string,
    customer_name: string,
    opening_amount?: number | null,
    contact_person?: string | null,
    contact_address?: string | null,
    phone?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateJobMutationVariables = {
  input: CreateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type CreateJobMutation = {
  createJob?:  {
    __typename: "Job",
    id: string,
    job_id?: number | null,
    job_code: string,
    date_of_creation: string,
    invoice_id?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customer_id: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    country_id: string,
    country?:  {
      __typename: "Country",
      id: string,
      country_id?: number | null,
      country_name: string,
      alpha_2_code: string,
      Jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    id_eds?:  {
      __typename: "ModelIdEdConnection",
      items:  Array< {
        __typename: "IdEd",
        id: string,
        id_ed_id?: number | null,
        id_ed_number: string,
        id_ed_type: ID_ED_TYPE,
        bl_number?: string | null,
        form_required?: boolean | null,
        form_number?: string | null,
        remark?: string | null,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    route?: ROUTE_TYPE | null,
    comodity?: string | null,
    date_of_arrival?: string | null,
    voyage?: string | null,
    forwarder?: string | null,
    liner?: string | null,
    description?: string | null,
    remark?: string | null,
    date_of_delivery?: string | null,
    is_active?: boolean | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateJobMutationVariables = {
  input: UpdateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type UpdateJobMutation = {
  updateJob?:  {
    __typename: "Job",
    id: string,
    job_id?: number | null,
    job_code: string,
    date_of_creation: string,
    invoice_id?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customer_id: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    country_id: string,
    country?:  {
      __typename: "Country",
      id: string,
      country_id?: number | null,
      country_name: string,
      alpha_2_code: string,
      Jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    id_eds?:  {
      __typename: "ModelIdEdConnection",
      items:  Array< {
        __typename: "IdEd",
        id: string,
        id_ed_id?: number | null,
        id_ed_number: string,
        id_ed_type: ID_ED_TYPE,
        bl_number?: string | null,
        form_required?: boolean | null,
        form_number?: string | null,
        remark?: string | null,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    route?: ROUTE_TYPE | null,
    comodity?: string | null,
    date_of_arrival?: string | null,
    voyage?: string | null,
    forwarder?: string | null,
    liner?: string | null,
    description?: string | null,
    remark?: string | null,
    date_of_delivery?: string | null,
    is_active?: boolean | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteJobMutationVariables = {
  input: DeleteJobInput,
  condition?: ModelJobConditionInput | null,
};

export type DeleteJobMutation = {
  deleteJob?:  {
    __typename: "Job",
    id: string,
    job_id?: number | null,
    job_code: string,
    date_of_creation: string,
    invoice_id?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customer_id: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    country_id: string,
    country?:  {
      __typename: "Country",
      id: string,
      country_id?: number | null,
      country_name: string,
      alpha_2_code: string,
      Jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    id_eds?:  {
      __typename: "ModelIdEdConnection",
      items:  Array< {
        __typename: "IdEd",
        id: string,
        id_ed_id?: number | null,
        id_ed_number: string,
        id_ed_type: ID_ED_TYPE,
        bl_number?: string | null,
        form_required?: boolean | null,
        form_number?: string | null,
        remark?: string | null,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    route?: ROUTE_TYPE | null,
    comodity?: string | null,
    date_of_arrival?: string | null,
    voyage?: string | null,
    forwarder?: string | null,
    liner?: string | null,
    description?: string | null,
    remark?: string | null,
    date_of_delivery?: string | null,
    is_active?: boolean | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInvoiceMutationVariables = {
  input: CreateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type CreateInvoiceMutation = {
  createInvoice?:  {
    __typename: "Invoice",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_code?: string | null,
    invoice_date: string,
    invoice_type: INVOICE_TYPE,
    customer_id?: string | null,
    customer_name: string,
    comodity?: string | null,
    description?: string | null,
    remark?: string | null,
    id_or_ed_and_count?: string | null,
    id_ed_numbers?: string | null,
    container_types_and_counts?: string | null,
    container_numbers?: string | null,
    bl_list?: string | null,
    invoice_details?:  {
      __typename: "ModelInvoiceDetailConnection",
      items:  Array< {
        __typename: "InvoiceDetail",
        id: string,
        invoice_id: string,
        invoice_date: string,
        description?: string | null,
        amount_mmk: number,
        amount_usd: number,
        debit_mmk: number,
        debit_usd: number,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice_detail_ids: Array< string >,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInvoiceMutationVariables = {
  input: UpdateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type UpdateInvoiceMutation = {
  updateInvoice?:  {
    __typename: "Invoice",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_code?: string | null,
    invoice_date: string,
    invoice_type: INVOICE_TYPE,
    customer_id?: string | null,
    customer_name: string,
    comodity?: string | null,
    description?: string | null,
    remark?: string | null,
    id_or_ed_and_count?: string | null,
    id_ed_numbers?: string | null,
    container_types_and_counts?: string | null,
    container_numbers?: string | null,
    bl_list?: string | null,
    invoice_details?:  {
      __typename: "ModelInvoiceDetailConnection",
      items:  Array< {
        __typename: "InvoiceDetail",
        id: string,
        invoice_id: string,
        invoice_date: string,
        description?: string | null,
        amount_mmk: number,
        amount_usd: number,
        debit_mmk: number,
        debit_usd: number,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice_detail_ids: Array< string >,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInvoiceMutationVariables = {
  input: DeleteInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type DeleteInvoiceMutation = {
  deleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_code?: string | null,
    invoice_date: string,
    invoice_type: INVOICE_TYPE,
    customer_id?: string | null,
    customer_name: string,
    comodity?: string | null,
    description?: string | null,
    remark?: string | null,
    id_or_ed_and_count?: string | null,
    id_ed_numbers?: string | null,
    container_types_and_counts?: string | null,
    container_numbers?: string | null,
    bl_list?: string | null,
    invoice_details?:  {
      __typename: "ModelInvoiceDetailConnection",
      items:  Array< {
        __typename: "InvoiceDetail",
        id: string,
        invoice_id: string,
        invoice_date: string,
        description?: string | null,
        amount_mmk: number,
        amount_usd: number,
        debit_mmk: number,
        debit_usd: number,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice_detail_ids: Array< string >,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInvoiceDetailMutationVariables = {
  input: CreateInvoiceDetailInput,
  condition?: ModelInvoiceDetailConditionInput | null,
};

export type CreateInvoiceDetailMutation = {
  createInvoiceDetail?:  {
    __typename: "InvoiceDetail",
    id: string,
    invoice_id: string,
    invoice_date: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    amount_mmk: number,
    amount_usd: number,
    debit_mmk: number,
    debit_usd: number,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInvoiceDetailMutationVariables = {
  input: UpdateInvoiceDetailInput,
  condition?: ModelInvoiceDetailConditionInput | null,
};

export type UpdateInvoiceDetailMutation = {
  updateInvoiceDetail?:  {
    __typename: "InvoiceDetail",
    id: string,
    invoice_id: string,
    invoice_date: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    amount_mmk: number,
    amount_usd: number,
    debit_mmk: number,
    debit_usd: number,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInvoiceDetailMutationVariables = {
  input: DeleteInvoiceDetailInput,
  condition?: ModelInvoiceDetailConditionInput | null,
};

export type DeleteInvoiceDetailMutation = {
  deleteInvoiceDetail?:  {
    __typename: "InvoiceDetail",
    id: string,
    invoice_id: string,
    invoice_date: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    amount_mmk: number,
    amount_usd: number,
    debit_mmk: number,
    debit_usd: number,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContainerTypeMutationVariables = {
  input: CreateContainerTypeInput,
  condition?: ModelContainerTypeConditionInput | null,
};

export type CreateContainerTypeMutation = {
  createContainerType?:  {
    __typename: "ContainerType",
    id: string,
    container_type_id?: number | null,
    container_type: string,
    description?: string | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContainerTypeMutationVariables = {
  input: UpdateContainerTypeInput,
  condition?: ModelContainerTypeConditionInput | null,
};

export type UpdateContainerTypeMutation = {
  updateContainerType?:  {
    __typename: "ContainerType",
    id: string,
    container_type_id?: number | null,
    container_type: string,
    description?: string | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContainerTypeMutationVariables = {
  input: DeleteContainerTypeInput,
  condition?: ModelContainerTypeConditionInput | null,
};

export type DeleteContainerTypeMutation = {
  deleteContainerType?:  {
    __typename: "ContainerType",
    id: string,
    container_type_id?: number | null,
    container_type: string,
    description?: string | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContainerMutationVariables = {
  input: CreateContainerInput,
  condition?: ModelContainerConditionInput | null,
};

export type CreateContainerMutation = {
  createContainer?:  {
    __typename: "Container",
    id: string,
    container_id?: number | null,
    container_number: string,
    container_type_id: string,
    container_type?:  {
      __typename: "ContainerType",
      id: string,
      container_type_id?: number | null,
      container_type: string,
      description?: string | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContainerMutationVariables = {
  input: UpdateContainerInput,
  condition?: ModelContainerConditionInput | null,
};

export type UpdateContainerMutation = {
  updateContainer?:  {
    __typename: "Container",
    id: string,
    container_id?: number | null,
    container_number: string,
    container_type_id: string,
    container_type?:  {
      __typename: "ContainerType",
      id: string,
      container_type_id?: number | null,
      container_type: string,
      description?: string | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContainerMutationVariables = {
  input: DeleteContainerInput,
  condition?: ModelContainerConditionInput | null,
};

export type DeleteContainerMutation = {
  deleteContainer?:  {
    __typename: "Container",
    id: string,
    container_id?: number | null,
    container_number: string,
    container_type_id: string,
    container_type?:  {
      __typename: "ContainerType",
      id: string,
      container_type_id?: number | null,
      container_type: string,
      description?: string | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateIdEdMutationVariables = {
  input: CreateIdEdInput,
  condition?: ModelIdEdConditionInput | null,
};

export type CreateIdEdMutation = {
  createIdEd?:  {
    __typename: "IdEd",
    id: string,
    id_ed_id?: number | null,
    id_ed_number: string,
    id_ed_type: ID_ED_TYPE,
    bl_number?: string | null,
    form_required?: boolean | null,
    form_number?: string | null,
    remark?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateIdEdMutationVariables = {
  input: UpdateIdEdInput,
  condition?: ModelIdEdConditionInput | null,
};

export type UpdateIdEdMutation = {
  updateIdEd?:  {
    __typename: "IdEd",
    id: string,
    id_ed_id?: number | null,
    id_ed_number: string,
    id_ed_type: ID_ED_TYPE,
    bl_number?: string | null,
    form_required?: boolean | null,
    form_number?: string | null,
    remark?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteIdEdMutationVariables = {
  input: DeleteIdEdInput,
  condition?: ModelIdEdConditionInput | null,
};

export type DeleteIdEdMutation = {
  deleteIdEd?:  {
    __typename: "IdEd",
    id: string,
    id_ed_id?: number | null,
    id_ed_number: string,
    id_ed_type: ID_ED_TYPE,
    bl_number?: string | null,
    form_required?: boolean | null,
    form_number?: string | null,
    remark?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCashBookMutationVariables = {
  input: CreateCashBookInput,
  condition?: ModelCashBookConditionInput | null,
};

export type CreateCashBookMutation = {
  createCashBook?:  {
    __typename: "CashBook",
    id: string,
    cashbook_id?: number | null,
    date: string,
    cash_handler: string,
    voucher_number?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_creation_date: string,
    account_id: string,
    account?:  {
      __typename: "Account",
      id: string,
      account_id?: number | null,
      account_code: string,
      account_code_sr: string,
      account_name: string,
      account_group_id: string,
      account_group?:  {
        __typename: "AccountGroup",
        id: string,
        account_group_id?: number | null,
        account_group_code: string,
        account_group_name: string,
        account_heading_code?: string | null,
        account_heading?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      opening_amount?: number | null,
      opening_date?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    debit_usd: number,
    credit_usd: number,
    debit_mmk: number,
    credit_mmk: number,
    is_deleted?: boolean | null,
    remark?: string | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCashBookMutationVariables = {
  input: UpdateCashBookInput,
  condition?: ModelCashBookConditionInput | null,
};

export type UpdateCashBookMutation = {
  updateCashBook?:  {
    __typename: "CashBook",
    id: string,
    cashbook_id?: number | null,
    date: string,
    cash_handler: string,
    voucher_number?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_creation_date: string,
    account_id: string,
    account?:  {
      __typename: "Account",
      id: string,
      account_id?: number | null,
      account_code: string,
      account_code_sr: string,
      account_name: string,
      account_group_id: string,
      account_group?:  {
        __typename: "AccountGroup",
        id: string,
        account_group_id?: number | null,
        account_group_code: string,
        account_group_name: string,
        account_heading_code?: string | null,
        account_heading?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      opening_amount?: number | null,
      opening_date?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    debit_usd: number,
    credit_usd: number,
    debit_mmk: number,
    credit_mmk: number,
    is_deleted?: boolean | null,
    remark?: string | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCashBookMutationVariables = {
  input: DeleteCashBookInput,
  condition?: ModelCashBookConditionInput | null,
};

export type DeleteCashBookMutation = {
  deleteCashBook?:  {
    __typename: "CashBook",
    id: string,
    cashbook_id?: number | null,
    date: string,
    cash_handler: string,
    voucher_number?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_creation_date: string,
    account_id: string,
    account?:  {
      __typename: "Account",
      id: string,
      account_id?: number | null,
      account_code: string,
      account_code_sr: string,
      account_name: string,
      account_group_id: string,
      account_group?:  {
        __typename: "AccountGroup",
        id: string,
        account_group_id?: number | null,
        account_group_code: string,
        account_group_name: string,
        account_heading_code?: string | null,
        account_heading?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      opening_amount?: number | null,
      opening_date?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    debit_usd: number,
    credit_usd: number,
    debit_mmk: number,
    credit_mmk: number,
    is_deleted?: boolean | null,
    remark?: string | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCashHandlerMutationVariables = {
  input: CreateCashHandlerInput,
  condition?: ModelCashHandlerConditionInput | null,
};

export type CreateCashHandlerMutation = {
  createCashHandler?:  {
    __typename: "CashHandler",
    id: string,
    short_name: string,
    full_name: string,
    email?: string | null,
    phone?: string | null,
    address?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCashHandlerMutationVariables = {
  input: UpdateCashHandlerInput,
  condition?: ModelCashHandlerConditionInput | null,
};

export type UpdateCashHandlerMutation = {
  updateCashHandler?:  {
    __typename: "CashHandler",
    id: string,
    short_name: string,
    full_name: string,
    email?: string | null,
    phone?: string | null,
    address?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCashHandlerMutationVariables = {
  input: DeleteCashHandlerInput,
  condition?: ModelCashHandlerConditionInput | null,
};

export type DeleteCashHandlerMutation = {
  deleteCashHandler?:  {
    __typename: "CashHandler",
    id: string,
    short_name: string,
    full_name: string,
    email?: string | null,
    phone?: string | null,
    address?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLogMutationVariables = {
  input: CreateLogInput,
  condition?: ModelLogConditionInput | null,
};

export type CreateLogMutation = {
  createLog?:  {
    __typename: "Log",
    id: string,
    job_id: string,
    job_code: string,
    model: DataTableType,
    model_id: string,
    operation: OperationType,
    old_data?: string | null,
    new_data?: string | null,
    user: string,
    changed_at: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLogMutationVariables = {
  input: UpdateLogInput,
  condition?: ModelLogConditionInput | null,
};

export type UpdateLogMutation = {
  updateLog?:  {
    __typename: "Log",
    id: string,
    job_id: string,
    job_code: string,
    model: DataTableType,
    model_id: string,
    operation: OperationType,
    old_data?: string | null,
    new_data?: string | null,
    user: string,
    changed_at: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLogMutationVariables = {
  input: DeleteLogInput,
  condition?: ModelLogConditionInput | null,
};

export type DeleteLogMutation = {
  deleteLog?:  {
    __typename: "Log",
    id: string,
    job_id: string,
    job_code: string,
    model: DataTableType,
    model_id: string,
    operation: OperationType,
    old_data?: string | null,
    new_data?: string | null,
    user: string,
    changed_at: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePermissionMutationVariables = {
  input: CreatePermissionInput,
  condition?: ModelPermissionConditionInput | null,
};

export type CreatePermissionMutation = {
  createPermission?:  {
    __typename: "Permission",
    id: string,
    model: DataTableType,
    operation: OperationType,
    required_permission_level: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePermissionMutationVariables = {
  input: UpdatePermissionInput,
  condition?: ModelPermissionConditionInput | null,
};

export type UpdatePermissionMutation = {
  updatePermission?:  {
    __typename: "Permission",
    id: string,
    model: DataTableType,
    operation: OperationType,
    required_permission_level: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePermissionMutationVariables = {
  input: DeletePermissionInput,
  condition?: ModelPermissionConditionInput | null,
};

export type DeletePermissionMutation = {
  deletePermission?:  {
    __typename: "Permission",
    id: string,
    model: DataTableType,
    operation: OperationType,
    required_permission_level: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePageVisitMutationVariables = {
  input: CreatePageVisitInput,
  condition?: ModelPageVisitConditionInput | null,
};

export type CreatePageVisitMutation = {
  createPageVisit?:  {
    __typename: "PageVisit",
    id: string,
    page: string,
    user: string,
    visited_at: string,
    type: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePageVisitMutationVariables = {
  input: UpdatePageVisitInput,
  condition?: ModelPageVisitConditionInput | null,
};

export type UpdatePageVisitMutation = {
  updatePageVisit?:  {
    __typename: "PageVisit",
    id: string,
    page: string,
    user: string,
    visited_at: string,
    type: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePageVisitMutationVariables = {
  input: DeletePageVisitInput,
  condition?: ModelPageVisitConditionInput | null,
};

export type DeletePageVisitMutation = {
  deletePageVisit?:  {
    __typename: "PageVisit",
    id: string,
    page: string,
    user: string,
    visited_at: string,
    type: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetCountryQueryVariables = {
  id: string,
};

export type GetCountryQuery = {
  getCountry?:  {
    __typename: "Country",
    id: string,
    country_id?: number | null,
    country_name: string,
    alpha_2_code: string,
    Jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCountriesQueryVariables = {
  id?: string | null,
  filter?: ModelCountryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCountriesQuery = {
  listCountries?:  {
    __typename: "ModelCountryConnection",
    items:  Array< {
      __typename: "Country",
      id: string,
      country_id?: number | null,
      country_name: string,
      alpha_2_code: string,
      Jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCountriesQueryVariables = {
  filter?: ModelCountryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCountriesQuery = {
  syncCountries?:  {
    __typename: "ModelCountryConnection",
    items:  Array< {
      __typename: "Country",
      id: string,
      country_id?: number | null,
      country_name: string,
      alpha_2_code: string,
      Jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAccountGroupQueryVariables = {
  id: string,
};

export type GetAccountGroupQuery = {
  getAccountGroup?:  {
    __typename: "AccountGroup",
    id: string,
    account_group_id?: number | null,
    account_group_code: string,
    account_group_name: string,
    account_heading_code?: string | null,
    account_heading?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    accounts?:  {
      __typename: "ModelAccountConnection",
      items:  Array< {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAccountGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelAccountGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAccountGroupsQuery = {
  listAccountGroups?:  {
    __typename: "ModelAccountGroupConnection",
    items:  Array< {
      __typename: "AccountGroup",
      id: string,
      account_group_id?: number | null,
      account_group_code: string,
      account_group_name: string,
      account_heading_code?: string | null,
      account_heading?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      accounts?:  {
        __typename: "ModelAccountConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAccountGroupsQueryVariables = {
  filter?: ModelAccountGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAccountGroupsQuery = {
  syncAccountGroups?:  {
    __typename: "ModelAccountGroupConnection",
    items:  Array< {
      __typename: "AccountGroup",
      id: string,
      account_group_id?: number | null,
      account_group_code: string,
      account_group_name: string,
      account_heading_code?: string | null,
      account_heading?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      accounts?:  {
        __typename: "ModelAccountConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    account_id?: number | null,
    account_code: string,
    account_code_sr: string,
    account_name: string,
    account_group_id: string,
    account_group?:  {
      __typename: "AccountGroup",
      id: string,
      account_group_id?: number | null,
      account_group_code: string,
      account_group_name: string,
      account_heading_code?: string | null,
      account_heading?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      accounts?:  {
        __typename: "ModelAccountConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    opening_amount?: number | null,
    opening_date?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAccountsQueryVariables = {
  id?: string | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      account_id?: number | null,
      account_code: string,
      account_code_sr: string,
      account_name: string,
      account_group_id: string,
      account_group?:  {
        __typename: "AccountGroup",
        id: string,
        account_group_id?: number | null,
        account_group_code: string,
        account_group_name: string,
        account_heading_code?: string | null,
        account_heading?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      opening_amount?: number | null,
      opening_date?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAccountsQuery = {
  syncAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      account_id?: number | null,
      account_code: string,
      account_code_sr: string,
      account_name: string,
      account_group_id: string,
      account_group?:  {
        __typename: "AccountGroup",
        id: string,
        account_group_id?: number | null,
        account_group_code: string,
        account_group_name: string,
        account_heading_code?: string | null,
        account_heading?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      opening_amount?: number | null,
      opening_date?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    id: string,
    customer_id?: number | null,
    customer_code: string,
    customer_name: string,
    opening_amount?: number | null,
    contact_person?: string | null,
    contact_address?: string | null,
    phone?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomersQueryVariables = {
  id?: string | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomersQuery = {
  syncCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetJobQueryVariables = {
  id: string,
};

export type GetJobQuery = {
  getJob?:  {
    __typename: "Job",
    id: string,
    job_id?: number | null,
    job_code: string,
    date_of_creation: string,
    invoice_id?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customer_id: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    country_id: string,
    country?:  {
      __typename: "Country",
      id: string,
      country_id?: number | null,
      country_name: string,
      alpha_2_code: string,
      Jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    id_eds?:  {
      __typename: "ModelIdEdConnection",
      items:  Array< {
        __typename: "IdEd",
        id: string,
        id_ed_id?: number | null,
        id_ed_number: string,
        id_ed_type: ID_ED_TYPE,
        bl_number?: string | null,
        form_required?: boolean | null,
        form_number?: string | null,
        remark?: string | null,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    route?: ROUTE_TYPE | null,
    comodity?: string | null,
    date_of_arrival?: string | null,
    voyage?: string | null,
    forwarder?: string | null,
    liner?: string | null,
    description?: string | null,
    remark?: string | null,
    date_of_delivery?: string | null,
    is_active?: boolean | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListJobsQueryVariables = {
  id?: string | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListJobsQuery = {
  listJobs?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncJobsQueryVariables = {
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncJobsQuery = {
  syncJobs?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvoiceQueryVariables = {
  id: string,
};

export type GetInvoiceQuery = {
  getInvoice?:  {
    __typename: "Invoice",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_code?: string | null,
    invoice_date: string,
    invoice_type: INVOICE_TYPE,
    customer_id?: string | null,
    customer_name: string,
    comodity?: string | null,
    description?: string | null,
    remark?: string | null,
    id_or_ed_and_count?: string | null,
    id_ed_numbers?: string | null,
    container_types_and_counts?: string | null,
    container_numbers?: string | null,
    bl_list?: string | null,
    invoice_details?:  {
      __typename: "ModelInvoiceDetailConnection",
      items:  Array< {
        __typename: "InvoiceDetail",
        id: string,
        invoice_id: string,
        invoice_date: string,
        description?: string | null,
        amount_mmk: number,
        amount_usd: number,
        debit_mmk: number,
        debit_usd: number,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice_detail_ids: Array< string >,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInvoicesQueryVariables = {
  id?: string | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvoicesQuery = {
  syncInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvoiceDetailQueryVariables = {
  id: string,
};

export type GetInvoiceDetailQuery = {
  getInvoiceDetail?:  {
    __typename: "InvoiceDetail",
    id: string,
    invoice_id: string,
    invoice_date: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    amount_mmk: number,
    amount_usd: number,
    debit_mmk: number,
    debit_usd: number,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInvoiceDetailsQueryVariables = {
  id?: string | null,
  filter?: ModelInvoiceDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInvoiceDetailsQuery = {
  listInvoiceDetails?:  {
    __typename: "ModelInvoiceDetailConnection",
    items:  Array< {
      __typename: "InvoiceDetail",
      id: string,
      invoice_id: string,
      invoice_date: string,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      amount_mmk: number,
      amount_usd: number,
      debit_mmk: number,
      debit_usd: number,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvoiceDetailsQueryVariables = {
  filter?: ModelInvoiceDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvoiceDetailsQuery = {
  syncInvoiceDetails?:  {
    __typename: "ModelInvoiceDetailConnection",
    items:  Array< {
      __typename: "InvoiceDetail",
      id: string,
      invoice_id: string,
      invoice_date: string,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      amount_mmk: number,
      amount_usd: number,
      debit_mmk: number,
      debit_usd: number,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContainerTypeQueryVariables = {
  id: string,
};

export type GetContainerTypeQuery = {
  getContainerType?:  {
    __typename: "ContainerType",
    id: string,
    container_type_id?: number | null,
    container_type: string,
    description?: string | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListContainerTypesQueryVariables = {
  id?: string | null,
  filter?: ModelContainerTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListContainerTypesQuery = {
  listContainerTypes?:  {
    __typename: "ModelContainerTypeConnection",
    items:  Array< {
      __typename: "ContainerType",
      id: string,
      container_type_id?: number | null,
      container_type: string,
      description?: string | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContainerTypesQueryVariables = {
  filter?: ModelContainerTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContainerTypesQuery = {
  syncContainerTypes?:  {
    __typename: "ModelContainerTypeConnection",
    items:  Array< {
      __typename: "ContainerType",
      id: string,
      container_type_id?: number | null,
      container_type: string,
      description?: string | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContainerQueryVariables = {
  id: string,
};

export type GetContainerQuery = {
  getContainer?:  {
    __typename: "Container",
    id: string,
    container_id?: number | null,
    container_number: string,
    container_type_id: string,
    container_type?:  {
      __typename: "ContainerType",
      id: string,
      container_type_id?: number | null,
      container_type: string,
      description?: string | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListContainersQueryVariables = {
  id?: string | null,
  filter?: ModelContainerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListContainersQuery = {
  listContainers?:  {
    __typename: "ModelContainerConnection",
    items:  Array< {
      __typename: "Container",
      id: string,
      container_id?: number | null,
      container_number: string,
      container_type_id: string,
      container_type?:  {
        __typename: "ContainerType",
        id: string,
        container_type_id?: number | null,
        container_type: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContainersQueryVariables = {
  filter?: ModelContainerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContainersQuery = {
  syncContainers?:  {
    __typename: "ModelContainerConnection",
    items:  Array< {
      __typename: "Container",
      id: string,
      container_id?: number | null,
      container_number: string,
      container_type_id: string,
      container_type?:  {
        __typename: "ContainerType",
        id: string,
        container_type_id?: number | null,
        container_type: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetIdEdQueryVariables = {
  id: string,
};

export type GetIdEdQuery = {
  getIdEd?:  {
    __typename: "IdEd",
    id: string,
    id_ed_id?: number | null,
    id_ed_number: string,
    id_ed_type: ID_ED_TYPE,
    bl_number?: string | null,
    form_required?: boolean | null,
    form_number?: string | null,
    remark?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListIdEdsQueryVariables = {
  id?: string | null,
  filter?: ModelIdEdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIdEdsQuery = {
  listIdEds?:  {
    __typename: "ModelIdEdConnection",
    items:  Array< {
      __typename: "IdEd",
      id: string,
      id_ed_id?: number | null,
      id_ed_number: string,
      id_ed_type: ID_ED_TYPE,
      bl_number?: string | null,
      form_required?: boolean | null,
      form_number?: string | null,
      remark?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncIdEdsQueryVariables = {
  filter?: ModelIdEdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncIdEdsQuery = {
  syncIdEds?:  {
    __typename: "ModelIdEdConnection",
    items:  Array< {
      __typename: "IdEd",
      id: string,
      id_ed_id?: number | null,
      id_ed_number: string,
      id_ed_type: ID_ED_TYPE,
      bl_number?: string | null,
      form_required?: boolean | null,
      form_number?: string | null,
      remark?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCashBookQueryVariables = {
  id: string,
};

export type GetCashBookQuery = {
  getCashBook?:  {
    __typename: "CashBook",
    id: string,
    cashbook_id?: number | null,
    date: string,
    cash_handler: string,
    voucher_number?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_creation_date: string,
    account_id: string,
    account?:  {
      __typename: "Account",
      id: string,
      account_id?: number | null,
      account_code: string,
      account_code_sr: string,
      account_name: string,
      account_group_id: string,
      account_group?:  {
        __typename: "AccountGroup",
        id: string,
        account_group_id?: number | null,
        account_group_code: string,
        account_group_name: string,
        account_heading_code?: string | null,
        account_heading?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      opening_amount?: number | null,
      opening_date?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    debit_usd: number,
    credit_usd: number,
    debit_mmk: number,
    credit_mmk: number,
    is_deleted?: boolean | null,
    remark?: string | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCashBooksQueryVariables = {
  id?: string | null,
  filter?: ModelCashBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCashBooksQuery = {
  listCashBooks?:  {
    __typename: "ModelCashBookConnection",
    items:  Array< {
      __typename: "CashBook",
      id: string,
      cashbook_id?: number | null,
      date: string,
      cash_handler: string,
      voucher_number?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_creation_date: string,
      account_id: string,
      account?:  {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      debit_usd: number,
      credit_usd: number,
      debit_mmk: number,
      credit_mmk: number,
      is_deleted?: boolean | null,
      remark?: string | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCashBooksQueryVariables = {
  filter?: ModelCashBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCashBooksQuery = {
  syncCashBooks?:  {
    __typename: "ModelCashBookConnection",
    items:  Array< {
      __typename: "CashBook",
      id: string,
      cashbook_id?: number | null,
      date: string,
      cash_handler: string,
      voucher_number?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_creation_date: string,
      account_id: string,
      account?:  {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      debit_usd: number,
      credit_usd: number,
      debit_mmk: number,
      credit_mmk: number,
      is_deleted?: boolean | null,
      remark?: string | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCashHandlerQueryVariables = {
  id: string,
};

export type GetCashHandlerQuery = {
  getCashHandler?:  {
    __typename: "CashHandler",
    id: string,
    short_name: string,
    full_name: string,
    email?: string | null,
    phone?: string | null,
    address?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCashHandlersQueryVariables = {
  id?: string | null,
  filter?: ModelCashHandlerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCashHandlersQuery = {
  listCashHandlers?:  {
    __typename: "ModelCashHandlerConnection",
    items:  Array< {
      __typename: "CashHandler",
      id: string,
      short_name: string,
      full_name: string,
      email?: string | null,
      phone?: string | null,
      address?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCashHandlersQueryVariables = {
  filter?: ModelCashHandlerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCashHandlersQuery = {
  syncCashHandlers?:  {
    __typename: "ModelCashHandlerConnection",
    items:  Array< {
      __typename: "CashHandler",
      id: string,
      short_name: string,
      full_name: string,
      email?: string | null,
      phone?: string | null,
      address?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLogQueryVariables = {
  id: string,
};

export type GetLogQuery = {
  getLog?:  {
    __typename: "Log",
    id: string,
    job_id: string,
    job_code: string,
    model: DataTableType,
    model_id: string,
    operation: OperationType,
    old_data?: string | null,
    new_data?: string | null,
    user: string,
    changed_at: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLogsQueryVariables = {
  id?: string | null,
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLogsQuery = {
  listLogs?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      job_id: string,
      job_code: string,
      model: DataTableType,
      model_id: string,
      operation: OperationType,
      old_data?: string | null,
      new_data?: string | null,
      user: string,
      changed_at: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLogsQueryVariables = {
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLogsQuery = {
  syncLogs?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      job_id: string,
      job_code: string,
      model: DataTableType,
      model_id: string,
      operation: OperationType,
      old_data?: string | null,
      new_data?: string | null,
      user: string,
      changed_at: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPermissionQueryVariables = {
  id: string,
};

export type GetPermissionQuery = {
  getPermission?:  {
    __typename: "Permission",
    id: string,
    model: DataTableType,
    operation: OperationType,
    required_permission_level: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPermissionsQueryVariables = {
  id?: string | null,
  filter?: ModelPermissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPermissionsQuery = {
  listPermissions?:  {
    __typename: "ModelPermissionConnection",
    items:  Array< {
      __typename: "Permission",
      id: string,
      model: DataTableType,
      operation: OperationType,
      required_permission_level: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPermissionsQueryVariables = {
  filter?: ModelPermissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPermissionsQuery = {
  syncPermissions?:  {
    __typename: "ModelPermissionConnection",
    items:  Array< {
      __typename: "Permission",
      id: string,
      model: DataTableType,
      operation: OperationType,
      required_permission_level: number,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPageVisitQueryVariables = {
  id: string,
};

export type GetPageVisitQuery = {
  getPageVisit?:  {
    __typename: "PageVisit",
    id: string,
    page: string,
    user: string,
    visited_at: string,
    type: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPageVisitsQueryVariables = {
  id?: string | null,
  filter?: ModelPageVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPageVisitsQuery = {
  listPageVisits?:  {
    __typename: "ModelPageVisitConnection",
    items:  Array< {
      __typename: "PageVisit",
      id: string,
      page: string,
      user: string,
      visited_at: string,
      type: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPageVisitsQueryVariables = {
  filter?: ModelPageVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPageVisitsQuery = {
  syncPageVisits?:  {
    __typename: "ModelPageVisitConnection",
    items:  Array< {
      __typename: "PageVisit",
      id: string,
      page: string,
      user: string,
      visited_at: string,
      type: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomerByCodeQueryVariables = {
  customer_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByCodeQuery = {
  customerByCode?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type JobByCodeQueryVariables = {
  job_code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobByCodeQuery = {
  jobByCode?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type JobByInvoiceQueryVariables = {
  invoice_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobByInvoiceQuery = {
  jobByInvoice?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type JobByCustomerQueryVariables = {
  customer_id: string,
  date_of_creation?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobByCustomerQuery = {
  jobByCustomer?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type JobByCountryQueryVariables = {
  country_id: string,
  date_of_creation?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobByCountryQuery = {
  jobByCountry?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type JobByRouteQueryVariables = {
  route: ROUTE_TYPE,
  date_of_creation?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobByRouteQuery = {
  jobByRoute?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type JobByDateOfCreationQueryVariables = {
  virtual_index: number,
  date_of_creation?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobByDateOfCreationQuery = {
  jobByDateOfCreation?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type JobsByIndexAndCodeQueryVariables = {
  virtual_index: number,
  job_code?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobsByIndexAndCodeQuery = {
  jobsByIndexAndCode?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvoiceByJobQueryVariables = {
  job_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoiceByJobQuery = {
  invoiceByJob?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvoiceByTypeQueryVariables = {
  invoice_type: INVOICE_TYPE,
  invoice_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoiceByTypeQuery = {
  invoiceByType?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvoiceByCustomerQueryVariables = {
  customer_id: string,
  invoice_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoiceByCustomerQuery = {
  invoiceByCustomer?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvoiceByInvoiceDateQueryVariables = {
  virtual_index: number,
  invoice_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoiceByInvoiceDateQuery = {
  invoiceByInvoiceDate?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvoiceByJobCodeQueryVariables = {
  virtual_index: number,
  job_code?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoiceByJobCodeQuery = {
  invoiceByJobCode?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvoiceDetailByInvoiceQueryVariables = {
  invoice_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoiceDetailByInvoiceQuery = {
  invoiceDetailByInvoice?:  {
    __typename: "ModelInvoiceDetailConnection",
    items:  Array< {
      __typename: "InvoiceDetail",
      id: string,
      invoice_id: string,
      invoice_date: string,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      amount_mmk: number,
      amount_usd: number,
      debit_mmk: number,
      debit_usd: number,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvoiceDetailByInvoiceDateQueryVariables = {
  invoice_date: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoiceDetailByInvoiceDateQuery = {
  invoiceDetailByInvoiceDate?:  {
    __typename: "ModelInvoiceDetailConnection",
    items:  Array< {
      __typename: "InvoiceDetail",
      id: string,
      invoice_id: string,
      invoice_date: string,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      amount_mmk: number,
      amount_usd: number,
      debit_mmk: number,
      debit_usd: number,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ContainerByNumberQueryVariables = {
  container_number: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContainerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContainerByNumberQuery = {
  containerByNumber?:  {
    __typename: "ModelContainerConnection",
    items:  Array< {
      __typename: "Container",
      id: string,
      container_id?: number | null,
      container_number: string,
      container_type_id: string,
      container_type?:  {
        __typename: "ContainerType",
        id: string,
        container_type_id?: number | null,
        container_type: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ContainerByJobQueryVariables = {
  job_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContainerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContainerByJobQuery = {
  containerByJob?:  {
    __typename: "ModelContainerConnection",
    items:  Array< {
      __typename: "Container",
      id: string,
      container_id?: number | null,
      container_number: string,
      container_type_id: string,
      container_type?:  {
        __typename: "ContainerType",
        id: string,
        container_type_id?: number | null,
        container_type: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ContainerByIndexAndNumberQueryVariables = {
  virtual_index: number,
  container_number?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContainerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContainerByIndexAndNumberQuery = {
  containerByIndexAndNumber?:  {
    __typename: "ModelContainerConnection",
    items:  Array< {
      __typename: "Container",
      id: string,
      container_id?: number | null,
      container_number: string,
      container_type_id: string,
      container_type?:  {
        __typename: "ContainerType",
        id: string,
        container_type_id?: number | null,
        container_type: string,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type IdEdByNumberQueryVariables = {
  id_ed_number: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIdEdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IdEdByNumberQuery = {
  idEdByNumber?:  {
    __typename: "ModelIdEdConnection",
    items:  Array< {
      __typename: "IdEd",
      id: string,
      id_ed_id?: number | null,
      id_ed_number: string,
      id_ed_type: ID_ED_TYPE,
      bl_number?: string | null,
      form_required?: boolean | null,
      form_number?: string | null,
      remark?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type IdEdByJobQueryVariables = {
  job_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIdEdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IdEdByJobQuery = {
  idEdByJob?:  {
    __typename: "ModelIdEdConnection",
    items:  Array< {
      __typename: "IdEd",
      id: string,
      id_ed_id?: number | null,
      id_ed_number: string,
      id_ed_type: ID_ED_TYPE,
      bl_number?: string | null,
      form_required?: boolean | null,
      form_number?: string | null,
      remark?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type IdEdByIndexAndNumberQueryVariables = {
  virtual_index: number,
  id_ed_number?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIdEdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IdEdByIndexAndNumberQuery = {
  idEdByIndexAndNumber?:  {
    __typename: "ModelIdEdConnection",
    items:  Array< {
      __typename: "IdEd",
      id: string,
      id_ed_id?: number | null,
      id_ed_number: string,
      id_ed_type: ID_ED_TYPE,
      bl_number?: string | null,
      form_required?: boolean | null,
      form_number?: string | null,
      remark?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashBookByCashHandlerQueryVariables = {
  cash_handler: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashBookByCashHandlerQuery = {
  cashBookByCashHandler?:  {
    __typename: "ModelCashBookConnection",
    items:  Array< {
      __typename: "CashBook",
      id: string,
      cashbook_id?: number | null,
      date: string,
      cash_handler: string,
      voucher_number?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_creation_date: string,
      account_id: string,
      account?:  {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      debit_usd: number,
      credit_usd: number,
      debit_mmk: number,
      credit_mmk: number,
      is_deleted?: boolean | null,
      remark?: string | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashBookByVoucherNumberQueryVariables = {
  voucher_number: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashBookByVoucherNumberQuery = {
  cashBookByVoucherNumber?:  {
    __typename: "ModelCashBookConnection",
    items:  Array< {
      __typename: "CashBook",
      id: string,
      cashbook_id?: number | null,
      date: string,
      cash_handler: string,
      voucher_number?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_creation_date: string,
      account_id: string,
      account?:  {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      debit_usd: number,
      credit_usd: number,
      debit_mmk: number,
      credit_mmk: number,
      is_deleted?: boolean | null,
      remark?: string | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashBookByJobQueryVariables = {
  job_id: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashBookByJobQuery = {
  cashBookByJob?:  {
    __typename: "ModelCashBookConnection",
    items:  Array< {
      __typename: "CashBook",
      id: string,
      cashbook_id?: number | null,
      date: string,
      cash_handler: string,
      voucher_number?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_creation_date: string,
      account_id: string,
      account?:  {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      debit_usd: number,
      credit_usd: number,
      debit_mmk: number,
      credit_mmk: number,
      is_deleted?: boolean | null,
      remark?: string | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashBookByAccountQueryVariables = {
  account_id: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashBookByAccountQuery = {
  cashBookByAccount?:  {
    __typename: "ModelCashBookConnection",
    items:  Array< {
      __typename: "CashBook",
      id: string,
      cashbook_id?: number | null,
      date: string,
      cash_handler: string,
      voucher_number?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_creation_date: string,
      account_id: string,
      account?:  {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      debit_usd: number,
      credit_usd: number,
      debit_mmk: number,
      credit_mmk: number,
      is_deleted?: boolean | null,
      remark?: string | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashBookByCreatedByQueryVariables = {
  created_by: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashBookByCreatedByQuery = {
  cashBookByCreatedBy?:  {
    __typename: "ModelCashBookConnection",
    items:  Array< {
      __typename: "CashBook",
      id: string,
      cashbook_id?: number | null,
      date: string,
      cash_handler: string,
      voucher_number?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_creation_date: string,
      account_id: string,
      account?:  {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      debit_usd: number,
      credit_usd: number,
      debit_mmk: number,
      credit_mmk: number,
      is_deleted?: boolean | null,
      remark?: string | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashBookByDateQueryVariables = {
  virtual_index: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashBookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashBookByDateQuery = {
  cashBookByDate?:  {
    __typename: "ModelCashBookConnection",
    items:  Array< {
      __typename: "CashBook",
      id: string,
      cashbook_id?: number | null,
      date: string,
      cash_handler: string,
      voucher_number?: string | null,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_creation_date: string,
      account_id: string,
      account?:  {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      description?: string | null,
      debit_usd: number,
      credit_usd: number,
      debit_mmk: number,
      credit_mmk: number,
      is_deleted?: boolean | null,
      remark?: string | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogByJobIdQueryVariables = {
  job_id: string,
  modelModel_id?: ModelLogLogByJobIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogByJobIdQuery = {
  logByJobId?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      job_id: string,
      job_code: string,
      model: DataTableType,
      model_id: string,
      operation: OperationType,
      old_data?: string | null,
      new_data?: string | null,
      user: string,
      changed_at: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogByJobCodeQueryVariables = {
  job_code: string,
  modelModel_id?: ModelLogLogByJobCodeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogByJobCodeQuery = {
  logByJobCode?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      job_id: string,
      job_code: string,
      model: DataTableType,
      model_id: string,
      operation: OperationType,
      old_data?: string | null,
      new_data?: string | null,
      user: string,
      changed_at: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogByModelQueryVariables = {
  model: DataTableType,
  changed_at?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogByModelQuery = {
  logByModel?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      job_id: string,
      job_code: string,
      model: DataTableType,
      model_id: string,
      operation: OperationType,
      old_data?: string | null,
      new_data?: string | null,
      user: string,
      changed_at: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogByOperationQueryVariables = {
  operation: OperationType,
  changed_at?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogByOperationQuery = {
  logByOperation?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      job_id: string,
      job_code: string,
      model: DataTableType,
      model_id: string,
      operation: OperationType,
      old_data?: string | null,
      new_data?: string | null,
      user: string,
      changed_at: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PageVisitByPageQueryVariables = {
  page: string,
  visited_at?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PageVisitByPageQuery = {
  pageVisitByPage?:  {
    __typename: "ModelPageVisitConnection",
    items:  Array< {
      __typename: "PageVisit",
      id: string,
      page: string,
      user: string,
      visited_at: string,
      type: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PageVisitByUserQueryVariables = {
  user: string,
  visited_at?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PageVisitByUserQuery = {
  pageVisitByUser?:  {
    __typename: "ModelPageVisitConnection",
    items:  Array< {
      __typename: "PageVisit",
      id: string,
      page: string,
      user: string,
      visited_at: string,
      type: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PageVisitByDateQueryVariables = {
  type: string,
  visited_at?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PageVisitByDateQuery = {
  pageVisitByDate?:  {
    __typename: "ModelPageVisitConnection",
    items:  Array< {
      __typename: "PageVisit",
      id: string,
      page: string,
      user: string,
      visited_at: string,
      type: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateCountrySubscriptionVariables = {
  filter?: ModelSubscriptionCountryFilterInput | null,
};

export type OnCreateCountrySubscription = {
  onCreateCountry?:  {
    __typename: "Country",
    id: string,
    country_id?: number | null,
    country_name: string,
    alpha_2_code: string,
    Jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCountrySubscriptionVariables = {
  filter?: ModelSubscriptionCountryFilterInput | null,
};

export type OnUpdateCountrySubscription = {
  onUpdateCountry?:  {
    __typename: "Country",
    id: string,
    country_id?: number | null,
    country_name: string,
    alpha_2_code: string,
    Jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCountrySubscriptionVariables = {
  filter?: ModelSubscriptionCountryFilterInput | null,
};

export type OnDeleteCountrySubscription = {
  onDeleteCountry?:  {
    __typename: "Country",
    id: string,
    country_id?: number | null,
    country_name: string,
    alpha_2_code: string,
    Jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAccountGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAccountGroupFilterInput | null,
};

export type OnCreateAccountGroupSubscription = {
  onCreateAccountGroup?:  {
    __typename: "AccountGroup",
    id: string,
    account_group_id?: number | null,
    account_group_code: string,
    account_group_name: string,
    account_heading_code?: string | null,
    account_heading?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    accounts?:  {
      __typename: "ModelAccountConnection",
      items:  Array< {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAccountGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAccountGroupFilterInput | null,
};

export type OnUpdateAccountGroupSubscription = {
  onUpdateAccountGroup?:  {
    __typename: "AccountGroup",
    id: string,
    account_group_id?: number | null,
    account_group_code: string,
    account_group_name: string,
    account_heading_code?: string | null,
    account_heading?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    accounts?:  {
      __typename: "ModelAccountConnection",
      items:  Array< {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAccountGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAccountGroupFilterInput | null,
};

export type OnDeleteAccountGroupSubscription = {
  onDeleteAccountGroup?:  {
    __typename: "AccountGroup",
    id: string,
    account_group_id?: number | null,
    account_group_code: string,
    account_group_name: string,
    account_heading_code?: string | null,
    account_heading?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    accounts?:  {
      __typename: "ModelAccountConnection",
      items:  Array< {
        __typename: "Account",
        id: string,
        account_id?: number | null,
        account_code: string,
        account_code_sr: string,
        account_name: string,
        account_group_id: string,
        opening_amount?: number | null,
        opening_date?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    account_id?: number | null,
    account_code: string,
    account_code_sr: string,
    account_name: string,
    account_group_id: string,
    account_group?:  {
      __typename: "AccountGroup",
      id: string,
      account_group_id?: number | null,
      account_group_code: string,
      account_group_name: string,
      account_heading_code?: string | null,
      account_heading?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      accounts?:  {
        __typename: "ModelAccountConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    opening_amount?: number | null,
    opening_date?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    account_id?: number | null,
    account_code: string,
    account_code_sr: string,
    account_name: string,
    account_group_id: string,
    account_group?:  {
      __typename: "AccountGroup",
      id: string,
      account_group_id?: number | null,
      account_group_code: string,
      account_group_name: string,
      account_heading_code?: string | null,
      account_heading?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      accounts?:  {
        __typename: "ModelAccountConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    opening_amount?: number | null,
    opening_date?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    account_id?: number | null,
    account_code: string,
    account_code_sr: string,
    account_name: string,
    account_group_id: string,
    account_group?:  {
      __typename: "AccountGroup",
      id: string,
      account_group_id?: number | null,
      account_group_code: string,
      account_group_name: string,
      account_heading_code?: string | null,
      account_heading?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      accounts?:  {
        __typename: "ModelAccountConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    opening_amount?: number | null,
    opening_date?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    id: string,
    customer_id?: number | null,
    customer_code: string,
    customer_name: string,
    opening_amount?: number | null,
    contact_person?: string | null,
    contact_address?: string | null,
    phone?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    id: string,
    customer_id?: number | null,
    customer_code: string,
    customer_name: string,
    opening_amount?: number | null,
    contact_person?: string | null,
    contact_address?: string | null,
    phone?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    id: string,
    customer_id?: number | null,
    customer_code: string,
    customer_name: string,
    opening_amount?: number | null,
    contact_person?: string | null,
    contact_address?: string | null,
    phone?: string | null,
    is_active?: boolean | null,
    remark?: string | null,
    jobs?:  {
      __typename: "ModelJobConnection",
      items:  Array< {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnCreateJobSubscription = {
  onCreateJob?:  {
    __typename: "Job",
    id: string,
    job_id?: number | null,
    job_code: string,
    date_of_creation: string,
    invoice_id?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customer_id: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    country_id: string,
    country?:  {
      __typename: "Country",
      id: string,
      country_id?: number | null,
      country_name: string,
      alpha_2_code: string,
      Jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    id_eds?:  {
      __typename: "ModelIdEdConnection",
      items:  Array< {
        __typename: "IdEd",
        id: string,
        id_ed_id?: number | null,
        id_ed_number: string,
        id_ed_type: ID_ED_TYPE,
        bl_number?: string | null,
        form_required?: boolean | null,
        form_number?: string | null,
        remark?: string | null,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    route?: ROUTE_TYPE | null,
    comodity?: string | null,
    date_of_arrival?: string | null,
    voyage?: string | null,
    forwarder?: string | null,
    liner?: string | null,
    description?: string | null,
    remark?: string | null,
    date_of_delivery?: string | null,
    is_active?: boolean | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnUpdateJobSubscription = {
  onUpdateJob?:  {
    __typename: "Job",
    id: string,
    job_id?: number | null,
    job_code: string,
    date_of_creation: string,
    invoice_id?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customer_id: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    country_id: string,
    country?:  {
      __typename: "Country",
      id: string,
      country_id?: number | null,
      country_name: string,
      alpha_2_code: string,
      Jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    id_eds?:  {
      __typename: "ModelIdEdConnection",
      items:  Array< {
        __typename: "IdEd",
        id: string,
        id_ed_id?: number | null,
        id_ed_number: string,
        id_ed_type: ID_ED_TYPE,
        bl_number?: string | null,
        form_required?: boolean | null,
        form_number?: string | null,
        remark?: string | null,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    route?: ROUTE_TYPE | null,
    comodity?: string | null,
    date_of_arrival?: string | null,
    voyage?: string | null,
    forwarder?: string | null,
    liner?: string | null,
    description?: string | null,
    remark?: string | null,
    date_of_delivery?: string | null,
    is_active?: boolean | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnDeleteJobSubscription = {
  onDeleteJob?:  {
    __typename: "Job",
    id: string,
    job_id?: number | null,
    job_code: string,
    date_of_creation: string,
    invoice_id?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customer_id: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      customer_id?: number | null,
      customer_code: string,
      customer_name: string,
      opening_amount?: number | null,
      contact_person?: string | null,
      contact_address?: string | null,
      phone?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    country_id: string,
    country?:  {
      __typename: "Country",
      id: string,
      country_id?: number | null,
      country_name: string,
      alpha_2_code: string,
      Jobs?:  {
        __typename: "ModelJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    id_eds?:  {
      __typename: "ModelIdEdConnection",
      items:  Array< {
        __typename: "IdEd",
        id: string,
        id_ed_id?: number | null,
        id_ed_number: string,
        id_ed_type: ID_ED_TYPE,
        bl_number?: string | null,
        form_required?: boolean | null,
        form_number?: string | null,
        remark?: string | null,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cash_book_entries?:  {
      __typename: "ModelCashBookConnection",
      items:  Array< {
        __typename: "CashBook",
        id: string,
        cashbook_id?: number | null,
        date: string,
        cash_handler: string,
        voucher_number?: string | null,
        job_id: string,
        job_creation_date: string,
        account_id: string,
        description?: string | null,
        debit_usd: number,
        credit_usd: number,
        debit_mmk: number,
        credit_mmk: number,
        is_deleted?: boolean | null,
        remark?: string | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    route?: ROUTE_TYPE | null,
    comodity?: string | null,
    date_of_arrival?: string | null,
    voyage?: string | null,
    forwarder?: string | null,
    liner?: string | null,
    description?: string | null,
    remark?: string | null,
    date_of_delivery?: string | null,
    is_active?: boolean | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnCreateInvoiceSubscription = {
  onCreateInvoice?:  {
    __typename: "Invoice",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_code?: string | null,
    invoice_date: string,
    invoice_type: INVOICE_TYPE,
    customer_id?: string | null,
    customer_name: string,
    comodity?: string | null,
    description?: string | null,
    remark?: string | null,
    id_or_ed_and_count?: string | null,
    id_ed_numbers?: string | null,
    container_types_and_counts?: string | null,
    container_numbers?: string | null,
    bl_list?: string | null,
    invoice_details?:  {
      __typename: "ModelInvoiceDetailConnection",
      items:  Array< {
        __typename: "InvoiceDetail",
        id: string,
        invoice_id: string,
        invoice_date: string,
        description?: string | null,
        amount_mmk: number,
        amount_usd: number,
        debit_mmk: number,
        debit_usd: number,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice_detail_ids: Array< string >,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnUpdateInvoiceSubscription = {
  onUpdateInvoice?:  {
    __typename: "Invoice",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_code?: string | null,
    invoice_date: string,
    invoice_type: INVOICE_TYPE,
    customer_id?: string | null,
    customer_name: string,
    comodity?: string | null,
    description?: string | null,
    remark?: string | null,
    id_or_ed_and_count?: string | null,
    id_ed_numbers?: string | null,
    container_types_and_counts?: string | null,
    container_numbers?: string | null,
    bl_list?: string | null,
    invoice_details?:  {
      __typename: "ModelInvoiceDetailConnection",
      items:  Array< {
        __typename: "InvoiceDetail",
        id: string,
        invoice_id: string,
        invoice_date: string,
        description?: string | null,
        amount_mmk: number,
        amount_usd: number,
        debit_mmk: number,
        debit_usd: number,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice_detail_ids: Array< string >,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnDeleteInvoiceSubscription = {
  onDeleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_code?: string | null,
    invoice_date: string,
    invoice_type: INVOICE_TYPE,
    customer_id?: string | null,
    customer_name: string,
    comodity?: string | null,
    description?: string | null,
    remark?: string | null,
    id_or_ed_and_count?: string | null,
    id_ed_numbers?: string | null,
    container_types_and_counts?: string | null,
    container_numbers?: string | null,
    bl_list?: string | null,
    invoice_details?:  {
      __typename: "ModelInvoiceDetailConnection",
      items:  Array< {
        __typename: "InvoiceDetail",
        id: string,
        invoice_id: string,
        invoice_date: string,
        description?: string | null,
        amount_mmk: number,
        amount_usd: number,
        debit_mmk: number,
        debit_usd: number,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice_detail_ids: Array< string >,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInvoiceDetailSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceDetailFilterInput | null,
};

export type OnCreateInvoiceDetailSubscription = {
  onCreateInvoiceDetail?:  {
    __typename: "InvoiceDetail",
    id: string,
    invoice_id: string,
    invoice_date: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    amount_mmk: number,
    amount_usd: number,
    debit_mmk: number,
    debit_usd: number,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInvoiceDetailSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceDetailFilterInput | null,
};

export type OnUpdateInvoiceDetailSubscription = {
  onUpdateInvoiceDetail?:  {
    __typename: "InvoiceDetail",
    id: string,
    invoice_id: string,
    invoice_date: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    amount_mmk: number,
    amount_usd: number,
    debit_mmk: number,
    debit_usd: number,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInvoiceDetailSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceDetailFilterInput | null,
};

export type OnDeleteInvoiceDetailSubscription = {
  onDeleteInvoiceDetail?:  {
    __typename: "InvoiceDetail",
    id: string,
    invoice_id: string,
    invoice_date: string,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      job_id: string,
      job?:  {
        __typename: "Job",
        id: string,
        job_id?: number | null,
        job_code: string,
        date_of_creation: string,
        invoice_id?: string | null,
        customer_id: string,
        country_id: string,
        route?: ROUTE_TYPE | null,
        comodity?: string | null,
        date_of_arrival?: string | null,
        voyage?: string | null,
        forwarder?: string | null,
        liner?: string | null,
        description?: string | null,
        remark?: string | null,
        date_of_delivery?: string | null,
        is_active?: boolean | null,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      job_code?: string | null,
      invoice_date: string,
      invoice_type: INVOICE_TYPE,
      customer_id?: string | null,
      customer_name: string,
      comodity?: string | null,
      description?: string | null,
      remark?: string | null,
      id_or_ed_and_count?: string | null,
      id_ed_numbers?: string | null,
      container_types_and_counts?: string | null,
      container_numbers?: string | null,
      bl_list?: string | null,
      invoice_details?:  {
        __typename: "ModelInvoiceDetailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice_detail_ids: Array< string >,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    amount_mmk: number,
    amount_usd: number,
    debit_mmk: number,
    debit_usd: number,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContainerTypeSubscriptionVariables = {
  filter?: ModelSubscriptionContainerTypeFilterInput | null,
};

export type OnCreateContainerTypeSubscription = {
  onCreateContainerType?:  {
    __typename: "ContainerType",
    id: string,
    container_type_id?: number | null,
    container_type: string,
    description?: string | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContainerTypeSubscriptionVariables = {
  filter?: ModelSubscriptionContainerTypeFilterInput | null,
};

export type OnUpdateContainerTypeSubscription = {
  onUpdateContainerType?:  {
    __typename: "ContainerType",
    id: string,
    container_type_id?: number | null,
    container_type: string,
    description?: string | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContainerTypeSubscriptionVariables = {
  filter?: ModelSubscriptionContainerTypeFilterInput | null,
};

export type OnDeleteContainerTypeSubscription = {
  onDeleteContainerType?:  {
    __typename: "ContainerType",
    id: string,
    container_type_id?: number | null,
    container_type: string,
    description?: string | null,
    containers?:  {
      __typename: "ModelContainerConnection",
      items:  Array< {
        __typename: "Container",
        id: string,
        container_id?: number | null,
        container_number: string,
        container_type_id: string,
        job_id: string,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContainerSubscriptionVariables = {
  filter?: ModelSubscriptionContainerFilterInput | null,
};

export type OnCreateContainerSubscription = {
  onCreateContainer?:  {
    __typename: "Container",
    id: string,
    container_id?: number | null,
    container_number: string,
    container_type_id: string,
    container_type?:  {
      __typename: "ContainerType",
      id: string,
      container_type_id?: number | null,
      container_type: string,
      description?: string | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContainerSubscriptionVariables = {
  filter?: ModelSubscriptionContainerFilterInput | null,
};

export type OnUpdateContainerSubscription = {
  onUpdateContainer?:  {
    __typename: "Container",
    id: string,
    container_id?: number | null,
    container_number: string,
    container_type_id: string,
    container_type?:  {
      __typename: "ContainerType",
      id: string,
      container_type_id?: number | null,
      container_type: string,
      description?: string | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContainerSubscriptionVariables = {
  filter?: ModelSubscriptionContainerFilterInput | null,
};

export type OnDeleteContainerSubscription = {
  onDeleteContainer?:  {
    __typename: "Container",
    id: string,
    container_id?: number | null,
    container_number: string,
    container_type_id: string,
    container_type?:  {
      __typename: "ContainerType",
      id: string,
      container_type_id?: number | null,
      container_type: string,
      description?: string | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateIdEdSubscriptionVariables = {
  filter?: ModelSubscriptionIdEdFilterInput | null,
};

export type OnCreateIdEdSubscription = {
  onCreateIdEd?:  {
    __typename: "IdEd",
    id: string,
    id_ed_id?: number | null,
    id_ed_number: string,
    id_ed_type: ID_ED_TYPE,
    bl_number?: string | null,
    form_required?: boolean | null,
    form_number?: string | null,
    remark?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateIdEdSubscriptionVariables = {
  filter?: ModelSubscriptionIdEdFilterInput | null,
};

export type OnUpdateIdEdSubscription = {
  onUpdateIdEd?:  {
    __typename: "IdEd",
    id: string,
    id_ed_id?: number | null,
    id_ed_number: string,
    id_ed_type: ID_ED_TYPE,
    bl_number?: string | null,
    form_required?: boolean | null,
    form_number?: string | null,
    remark?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteIdEdSubscriptionVariables = {
  filter?: ModelSubscriptionIdEdFilterInput | null,
};

export type OnDeleteIdEdSubscription = {
  onDeleteIdEd?:  {
    __typename: "IdEd",
    id: string,
    id_ed_id?: number | null,
    id_ed_number: string,
    id_ed_type: ID_ED_TYPE,
    bl_number?: string | null,
    form_required?: boolean | null,
    form_number?: string | null,
    remark?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCashBookSubscriptionVariables = {
  filter?: ModelSubscriptionCashBookFilterInput | null,
};

export type OnCreateCashBookSubscription = {
  onCreateCashBook?:  {
    __typename: "CashBook",
    id: string,
    cashbook_id?: number | null,
    date: string,
    cash_handler: string,
    voucher_number?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_creation_date: string,
    account_id: string,
    account?:  {
      __typename: "Account",
      id: string,
      account_id?: number | null,
      account_code: string,
      account_code_sr: string,
      account_name: string,
      account_group_id: string,
      account_group?:  {
        __typename: "AccountGroup",
        id: string,
        account_group_id?: number | null,
        account_group_code: string,
        account_group_name: string,
        account_heading_code?: string | null,
        account_heading?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      opening_amount?: number | null,
      opening_date?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    debit_usd: number,
    credit_usd: number,
    debit_mmk: number,
    credit_mmk: number,
    is_deleted?: boolean | null,
    remark?: string | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCashBookSubscriptionVariables = {
  filter?: ModelSubscriptionCashBookFilterInput | null,
};

export type OnUpdateCashBookSubscription = {
  onUpdateCashBook?:  {
    __typename: "CashBook",
    id: string,
    cashbook_id?: number | null,
    date: string,
    cash_handler: string,
    voucher_number?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_creation_date: string,
    account_id: string,
    account?:  {
      __typename: "Account",
      id: string,
      account_id?: number | null,
      account_code: string,
      account_code_sr: string,
      account_name: string,
      account_group_id: string,
      account_group?:  {
        __typename: "AccountGroup",
        id: string,
        account_group_id?: number | null,
        account_group_code: string,
        account_group_name: string,
        account_heading_code?: string | null,
        account_heading?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      opening_amount?: number | null,
      opening_date?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    debit_usd: number,
    credit_usd: number,
    debit_mmk: number,
    credit_mmk: number,
    is_deleted?: boolean | null,
    remark?: string | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCashBookSubscriptionVariables = {
  filter?: ModelSubscriptionCashBookFilterInput | null,
};

export type OnDeleteCashBookSubscription = {
  onDeleteCashBook?:  {
    __typename: "CashBook",
    id: string,
    cashbook_id?: number | null,
    date: string,
    cash_handler: string,
    voucher_number?: string | null,
    job_id: string,
    job?:  {
      __typename: "Job",
      id: string,
      job_id?: number | null,
      job_code: string,
      date_of_creation: string,
      invoice_id?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        job_id: string,
        job_code?: string | null,
        invoice_date: string,
        invoice_type: INVOICE_TYPE,
        customer_id?: string | null,
        customer_name: string,
        comodity?: string | null,
        description?: string | null,
        remark?: string | null,
        id_or_ed_and_count?: string | null,
        id_ed_numbers?: string | null,
        container_types_and_counts?: string | null,
        container_numbers?: string | null,
        bl_list?: string | null,
        invoice_detail_ids: Array< string >,
        archived: boolean,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        virtual_index?: number | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customer_id: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        customer_id?: number | null,
        customer_code: string,
        customer_name: string,
        opening_amount?: number | null,
        contact_person?: string | null,
        contact_address?: string | null,
        phone?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        created_by?: string | null,
        modified_by?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      country_id: string,
      country?:  {
        __typename: "Country",
        id: string,
        country_id?: number | null,
        country_name: string,
        alpha_2_code: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      containers?:  {
        __typename: "ModelContainerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      id_eds?:  {
        __typename: "ModelIdEdConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      route?: ROUTE_TYPE | null,
      comodity?: string | null,
      date_of_arrival?: string | null,
      voyage?: string | null,
      forwarder?: string | null,
      liner?: string | null,
      description?: string | null,
      remark?: string | null,
      date_of_delivery?: string | null,
      is_active?: boolean | null,
      archived: boolean,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    job_creation_date: string,
    account_id: string,
    account?:  {
      __typename: "Account",
      id: string,
      account_id?: number | null,
      account_code: string,
      account_code_sr: string,
      account_name: string,
      account_group_id: string,
      account_group?:  {
        __typename: "AccountGroup",
        id: string,
        account_group_id?: number | null,
        account_group_code: string,
        account_group_name: string,
        account_heading_code?: string | null,
        account_heading?: string | null,
        is_active?: boolean | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      cash_book_entries?:  {
        __typename: "ModelCashBookConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      opening_amount?: number | null,
      opening_date?: string | null,
      is_active?: boolean | null,
      remark?: string | null,
      created_by?: string | null,
      modified_by?: string | null,
      createdAt: string,
      updatedAt: string,
      virtual_index: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    description?: string | null,
    debit_usd: number,
    credit_usd: number,
    debit_mmk: number,
    credit_mmk: number,
    is_deleted?: boolean | null,
    remark?: string | null,
    archived: boolean,
    created_by?: string | null,
    modified_by?: string | null,
    createdAt: string,
    updatedAt: string,
    virtual_index: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCashHandlerSubscriptionVariables = {
  filter?: ModelSubscriptionCashHandlerFilterInput | null,
};

export type OnCreateCashHandlerSubscription = {
  onCreateCashHandler?:  {
    __typename: "CashHandler",
    id: string,
    short_name: string,
    full_name: string,
    email?: string | null,
    phone?: string | null,
    address?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCashHandlerSubscriptionVariables = {
  filter?: ModelSubscriptionCashHandlerFilterInput | null,
};

export type OnUpdateCashHandlerSubscription = {
  onUpdateCashHandler?:  {
    __typename: "CashHandler",
    id: string,
    short_name: string,
    full_name: string,
    email?: string | null,
    phone?: string | null,
    address?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCashHandlerSubscriptionVariables = {
  filter?: ModelSubscriptionCashHandlerFilterInput | null,
};

export type OnDeleteCashHandlerSubscription = {
  onDeleteCashHandler?:  {
    __typename: "CashHandler",
    id: string,
    short_name: string,
    full_name: string,
    email?: string | null,
    phone?: string | null,
    address?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLogSubscriptionVariables = {
  filter?: ModelSubscriptionLogFilterInput | null,
};

export type OnCreateLogSubscription = {
  onCreateLog?:  {
    __typename: "Log",
    id: string,
    job_id: string,
    job_code: string,
    model: DataTableType,
    model_id: string,
    operation: OperationType,
    old_data?: string | null,
    new_data?: string | null,
    user: string,
    changed_at: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLogSubscriptionVariables = {
  filter?: ModelSubscriptionLogFilterInput | null,
};

export type OnUpdateLogSubscription = {
  onUpdateLog?:  {
    __typename: "Log",
    id: string,
    job_id: string,
    job_code: string,
    model: DataTableType,
    model_id: string,
    operation: OperationType,
    old_data?: string | null,
    new_data?: string | null,
    user: string,
    changed_at: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLogSubscriptionVariables = {
  filter?: ModelSubscriptionLogFilterInput | null,
};

export type OnDeleteLogSubscription = {
  onDeleteLog?:  {
    __typename: "Log",
    id: string,
    job_id: string,
    job_code: string,
    model: DataTableType,
    model_id: string,
    operation: OperationType,
    old_data?: string | null,
    new_data?: string | null,
    user: string,
    changed_at: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePermissionSubscriptionVariables = {
  filter?: ModelSubscriptionPermissionFilterInput | null,
};

export type OnCreatePermissionSubscription = {
  onCreatePermission?:  {
    __typename: "Permission",
    id: string,
    model: DataTableType,
    operation: OperationType,
    required_permission_level: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePermissionSubscriptionVariables = {
  filter?: ModelSubscriptionPermissionFilterInput | null,
};

export type OnUpdatePermissionSubscription = {
  onUpdatePermission?:  {
    __typename: "Permission",
    id: string,
    model: DataTableType,
    operation: OperationType,
    required_permission_level: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePermissionSubscriptionVariables = {
  filter?: ModelSubscriptionPermissionFilterInput | null,
};

export type OnDeletePermissionSubscription = {
  onDeletePermission?:  {
    __typename: "Permission",
    id: string,
    model: DataTableType,
    operation: OperationType,
    required_permission_level: number,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePageVisitSubscriptionVariables = {
  filter?: ModelSubscriptionPageVisitFilterInput | null,
};

export type OnCreatePageVisitSubscription = {
  onCreatePageVisit?:  {
    __typename: "PageVisit",
    id: string,
    page: string,
    user: string,
    visited_at: string,
    type: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePageVisitSubscriptionVariables = {
  filter?: ModelSubscriptionPageVisitFilterInput | null,
};

export type OnUpdatePageVisitSubscription = {
  onUpdatePageVisit?:  {
    __typename: "PageVisit",
    id: string,
    page: string,
    user: string,
    visited_at: string,
    type: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePageVisitSubscriptionVariables = {
  filter?: ModelSubscriptionPageVisitFilterInput | null,
};

export type OnDeletePageVisitSubscription = {
  onDeletePageVisit?:  {
    __typename: "PageVisit",
    id: string,
    page: string,
    user: string,
    visited_at: string,
    type: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

import React, { ReactNode, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Customer as CustomerAPI, DataTableType, OperationType } from '../../API';
import { Button, Card, Col, List, Popconfirm, Row, Space, Typography, message } from 'antd';
import NetworkIndicator from '../../components/NetworkIndicator';
import { BorderOutlined, CheckSquareTwoTone, LeftOutlined } from '@ant-design/icons';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import useLogDrawer from '../../custom_hooks/useLogDrawer';
import { deleteCustomer, getCustomer } from './api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { logActivity } from '../../utilities/logger';
import { getJobsByCustomer } from '../Jobs/api';
import { AuthContext } from '../Users/AuthContext';
import { useGetPermissionInfo } from '../Users/adminHooks';

const { Title, Text } = Typography;
const ListItem = ({ label, content }: { label: ReactNode; content: ReactNode }) => {
  return (
    <List.Item style={{ padding: '10px 0' }}>
      <Row style={{ width: '100%', minHeight: 22 }}>
        <Col span={8}>
          <Title level={5} style={{ marginBottom: 0 }}>
            {label}
          </Title>
        </Col>
        <Col span={16} style={{ whiteSpace: 'pre-wrap' }}>
          {content}
        </Col>
      </Row>
    </List.Item>
  );
};

const Customer = () => {
  const { customer_id } = useParams<{ customer_id: string }>();
  const navigate = useNavigate();
  const [customer, setCustomer] = React.useState<CustomerAPI | undefined>(undefined);
  useDocumentTitle(customer?.customer_name || 'Customer');
  const { currentUser } = useContext(AuthContext);

  const { openDrawer, LogDrawer } = useLogDrawer();
  const [messageApi, messageContextHolder] = message.useMessage();
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.CUSTOMER);

  React.useEffect(() => {
    const fetchCustomer = async (customerId: string) => {
      const customer = await getCustomer(customerId);
      setCustomer(customer);
    };
    if (customer_id) {
      fetchCustomer(customer_id);
    } else {
      setCustomer(undefined);
    }
  }, [customer_id]);

  const checkIfJobExistForCustomerId = async (customer_id: string) => {
    const jobs = await getJobsByCustomer(customer_id, 1);
    if (!jobs) throw new Error('Error fetching jobs');
    return jobs.length > 0;
  };

  const onDelete = async (customer: CustomerAPI) => {
    if (!currentUser) return;
    try {
      const jobsExistForCusomer = await checkIfJobExistForCustomerId(customer.id);
      if (jobsExistForCusomer) {
        messageApi.open({
          type: 'error',
          content: 'This customer has associated Jobs in database. It cannot be deleted.',
        });
        return;
      }
      const deletedCustomer = await deleteCustomer(customer.id, customer._version);
      await logActivity({
        user_email: currentUser.email,
        job: null,
        model_type: DataTableType.CUSTOMER,
        operation_type: OperationType.DELETE,
        old_data: deletedCustomer,
        new_data: null,
      });
      navigate('/customers');
    } catch (error) {
      console.error('error deleting customer', error);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      {messageContextHolder}
      <Card
        title={
          <Space size='large' align='start'>
            <Button
              type='primary'
              shape='circle'
              icon={<LeftOutlined />}
              size='middle'
              onClick={() => navigate('/customers')}
            />
            <Title level={4} style={{ color: 'darkred' }}>
              {customer ? customer.customer_name : 'Customer'}
            </Title>
          </Space>
        }
        style={{
          width: 'max(calc(100vw - 900px), 800px)',
          height: 'fit-content',
        }}
        extra={
          <Space>
            <NetworkIndicator />
          </Space>
        }
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5, marginTop: -10 }}>
          <Space size='large'>
            <Button
              type='link'
              onClick={() => {
                openDrawer(undefined, DataTableType.CUSTOMER, customer?.id, 'model');
              }}
            >
              change log
            </Button>
            <Button
              type='primary'
              onClick={() => {
                if (customer_id) {
                  navigate('edit');
                }
              }}
              disabled={!permission.UPDATE}
            >
              Edit Customer
            </Button>
            <Popconfirm
              title={
                <div>
                  <span>Are you sure you want to delete this customer?</span>
                  <br />
                  <span>The customer will be deleted only if there is no associated Job.</span>
                  <br />
                  <span>This action cannot be undone.</span>
                </div>
              }
              onConfirm={() => onDelete(customer as CustomerAPI)}
            >
              <Button type='primary' danger disabled={!permission.DELETE}>
                Delete Customer
              </Button>
            </Popconfirm>
          </Space>
        </div>
        <List>
          <ListItem
            label='Customer Name'
            content={<Text copyable={!!customer?.customer_name}>{customer?.customer_name}</Text>}
          />
          <ListItem label='Customer Code' content={customer?.customer_code} />
          <ListItem
            label='Contact Person'
            content={<Text copyable={!!customer?.contact_person}>{customer?.contact_person}</Text>}
          />
          <ListItem
            label='Contact Address'
            content={<Text copyable={!!customer?.contact_address}>{customer?.contact_address}</Text>}
          />
          <ListItem label='Contact Phone' content={<Text copyable={!!customer?.phone}>{customer?.phone}</Text>} />
          <ListItem
            key='active'
            label='Active'
            content={customer?.is_active ? <CheckSquareTwoTone /> : <BorderOutlined />}
          />
        </List>
      </Card>
      {LogDrawer}
    </div>
  );
};

export default Customer;

import * as APITypes from '../../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const invoiceByInvoiceDate = /* GraphQL */ `query InvoiceByInvoiceDate(
  $virtual_index: Int!
  $invoice_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByInvoiceDate(
    virtual_index: $virtual_index
    invoice_date: $invoice_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        items {
          id
          description
          amount_mmk
          amount_usd
          debit_mmk
          debit_usd
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceByInvoiceDateQueryVariables,
  APITypes.InvoiceByInvoiceDateQuery
>;

export const invoiceByType = /* GraphQL */ `query InvoiceByType(
  $invoice_type: INVOICE_TYPE!
  $invoice_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByType(
    invoice_type: $invoice_type
    invoice_date: $invoice_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        items {
          id
          description
          amount_mmk
          amount_usd
          debit_mmk
          debit_usd
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.InvoiceByTypeQueryVariables, APITypes.InvoiceByTypeQuery>;

export const invoiceByCustomer = /* GraphQL */ `query InvoiceByCustomer(
  $customer_id: String!
  $invoice_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByCustomer(
    customer_id: $customer_id
    invoice_date: $invoice_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        items {
          id
          description
          amount_mmk
          amount_usd
          debit_mmk
          debit_usd
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.InvoiceByCustomerQueryVariables, APITypes.InvoiceByCustomerQuery>;

export const invoiceByJobCode = /* GraphQL */ `query InvoiceByJobCode(
  $virtual_index: Int!
  $job_code: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByJobCode(
    virtual_index: $virtual_index
    job_code: $job_code
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      job_id
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        items {
          id
          description
          amount_mmk
          amount_usd
          debit_mmk
          debit_usd
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.InvoiceByJobCodeQueryVariables, APITypes.InvoiceByJobCodeQuery>;

export const jobByCustomer = /* GraphQL */ `query JobByCustomer(
  $customer_id: ID!
  $date_of_creation: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobByCustomer(
    customer_id: $customer_id
    date_of_creation: $date_of_creation
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      invoice {
        id
        job {
          id
          job_code
        }                     
        invoice_date
        invoice_type
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_details {
        items {
          id
          description
          amount_mmk
          amount_usd
          debit_mmk
          debit_usd
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      _deleted
      _lastChangedAt
      __typename
      }
      virtual_index
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.JobByCustomerQueryVariables, APITypes.JobByCustomerQuery>;

export const jobsByIndexAndCode = /* GraphQL */ `query JobsByIndexAndCode(
  $virtual_index: Int!
  $job_code: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  jobsByIndexAndCode(
    virtual_index: $virtual_index
    job_code: $job_code
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      invoice {
        id
        job {
          id
          job_code
        }                     
        invoice_date
        invoice_type
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_details {
        items {
          id
          description
          amount_mmk
          amount_usd
          debit_mmk
          debit_usd
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      _deleted
      _lastChangedAt
      __typename
      }
      virtual_index
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.JobsByIndexAndCodeQueryVariables, APITypes.JobsByIndexAndCodeQuery>;

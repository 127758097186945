/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateCountry = /* GraphQL */ `subscription OnCreateCountry($filter: ModelSubscriptionCountryFilterInput) {
  onCreateCountry(filter: $filter) {
    id
    country_id
    country_name
    alpha_2_code
    Jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCountrySubscriptionVariables,
  APITypes.OnCreateCountrySubscription
>;
export const onUpdateCountry = /* GraphQL */ `subscription OnUpdateCountry($filter: ModelSubscriptionCountryFilterInput) {
  onUpdateCountry(filter: $filter) {
    id
    country_id
    country_name
    alpha_2_code
    Jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCountrySubscriptionVariables,
  APITypes.OnUpdateCountrySubscription
>;
export const onDeleteCountry = /* GraphQL */ `subscription OnDeleteCountry($filter: ModelSubscriptionCountryFilterInput) {
  onDeleteCountry(filter: $filter) {
    id
    country_id
    country_name
    alpha_2_code
    Jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCountrySubscriptionVariables,
  APITypes.OnDeleteCountrySubscription
>;
export const onCreateAccountGroup = /* GraphQL */ `subscription OnCreateAccountGroup(
  $filter: ModelSubscriptionAccountGroupFilterInput
) {
  onCreateAccountGroup(filter: $filter) {
    id
    account_group_id
    account_group_code
    account_group_name
    account_heading_code
    account_heading
    is_active
    remark
    accounts {
      items {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountGroupSubscriptionVariables,
  APITypes.OnCreateAccountGroupSubscription
>;
export const onUpdateAccountGroup = /* GraphQL */ `subscription OnUpdateAccountGroup(
  $filter: ModelSubscriptionAccountGroupFilterInput
) {
  onUpdateAccountGroup(filter: $filter) {
    id
    account_group_id
    account_group_code
    account_group_name
    account_heading_code
    account_heading
    is_active
    remark
    accounts {
      items {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountGroupSubscriptionVariables,
  APITypes.OnUpdateAccountGroupSubscription
>;
export const onDeleteAccountGroup = /* GraphQL */ `subscription OnDeleteAccountGroup(
  $filter: ModelSubscriptionAccountGroupFilterInput
) {
  onDeleteAccountGroup(filter: $filter) {
    id
    account_group_id
    account_group_code
    account_group_name
    account_heading_code
    account_heading
    is_active
    remark
    accounts {
      items {
        id
        account_id
        account_code
        account_code_sr
        account_name
        account_group_id
        opening_amount
        opening_date
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountGroupSubscriptionVariables,
  APITypes.OnDeleteAccountGroupSubscription
>;
export const onCreateAccount = /* GraphQL */ `subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onCreateAccount(filter: $filter) {
    id
    account_id
    account_code
    account_code_sr
    account_name
    account_group_id
    account_group {
      id
      account_group_id
      account_group_code
      account_group_name
      account_heading_code
      account_heading
      is_active
      remark
      accounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    opening_amount
    opening_date
    is_active
    remark
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSubscriptionVariables,
  APITypes.OnCreateAccountSubscription
>;
export const onUpdateAccount = /* GraphQL */ `subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onUpdateAccount(filter: $filter) {
    id
    account_id
    account_code
    account_code_sr
    account_name
    account_group_id
    account_group {
      id
      account_group_id
      account_group_code
      account_group_name
      account_heading_code
      account_heading
      is_active
      remark
      accounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    opening_amount
    opening_date
    is_active
    remark
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSubscriptionVariables,
  APITypes.OnUpdateAccountSubscription
>;
export const onDeleteAccount = /* GraphQL */ `subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
  onDeleteAccount(filter: $filter) {
    id
    account_id
    account_code
    account_code_sr
    account_name
    account_group_id
    account_group {
      id
      account_group_id
      account_group_code
      account_group_name
      account_heading_code
      account_heading
      is_active
      remark
      accounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    opening_amount
    opening_date
    is_active
    remark
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSubscriptionVariables,
  APITypes.OnDeleteAccountSubscription
>;
export const onCreateCustomer = /* GraphQL */ `subscription OnCreateCustomer($filter: ModelSubscriptionCustomerFilterInput) {
  onCreateCustomer(filter: $filter) {
    id
    customer_id
    customer_code
    customer_name
    opening_amount
    contact_person
    contact_address
    phone
    is_active
    remark
    jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    created_by
    modified_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCustomerSubscriptionVariables,
  APITypes.OnCreateCustomerSubscription
>;
export const onUpdateCustomer = /* GraphQL */ `subscription OnUpdateCustomer($filter: ModelSubscriptionCustomerFilterInput) {
  onUpdateCustomer(filter: $filter) {
    id
    customer_id
    customer_code
    customer_name
    opening_amount
    contact_person
    contact_address
    phone
    is_active
    remark
    jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    created_by
    modified_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCustomerSubscriptionVariables,
  APITypes.OnUpdateCustomerSubscription
>;
export const onDeleteCustomer = /* GraphQL */ `subscription OnDeleteCustomer($filter: ModelSubscriptionCustomerFilterInput) {
  onDeleteCustomer(filter: $filter) {
    id
    customer_id
    customer_code
    customer_name
    opening_amount
    contact_person
    contact_address
    phone
    is_active
    remark
    jobs {
      items {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    created_by
    modified_by
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCustomerSubscriptionVariables,
  APITypes.OnDeleteCustomerSubscription
>;
export const onCreateJob = /* GraphQL */ `subscription OnCreateJob($filter: ModelSubscriptionJobFilterInput) {
  onCreateJob(filter: $filter) {
    id
    job_id
    job_code
    date_of_creation
    invoice_id
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    customer_id
    customer {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    country_id
    country {
      id
      country_id
      country_name
      alpha_2_code
      Jobs {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    id_eds {
      items {
        id
        id_ed_id
        id_ed_number
        id_ed_type
        bl_number
        form_required
        form_number
        remark
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    route
    comodity
    date_of_arrival
    voyage
    forwarder
    liner
    description
    remark
    date_of_delivery
    is_active
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateJobSubscriptionVariables,
  APITypes.OnCreateJobSubscription
>;
export const onUpdateJob = /* GraphQL */ `subscription OnUpdateJob($filter: ModelSubscriptionJobFilterInput) {
  onUpdateJob(filter: $filter) {
    id
    job_id
    job_code
    date_of_creation
    invoice_id
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    customer_id
    customer {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    country_id
    country {
      id
      country_id
      country_name
      alpha_2_code
      Jobs {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    id_eds {
      items {
        id
        id_ed_id
        id_ed_number
        id_ed_type
        bl_number
        form_required
        form_number
        remark
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    route
    comodity
    date_of_arrival
    voyage
    forwarder
    liner
    description
    remark
    date_of_delivery
    is_active
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateJobSubscriptionVariables,
  APITypes.OnUpdateJobSubscription
>;
export const onDeleteJob = /* GraphQL */ `subscription OnDeleteJob($filter: ModelSubscriptionJobFilterInput) {
  onDeleteJob(filter: $filter) {
    id
    job_id
    job_code
    date_of_creation
    invoice_id
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    customer_id
    customer {
      id
      customer_id
      customer_code
      customer_name
      opening_amount
      contact_person
      contact_address
      phone
      is_active
      remark
      jobs {
        nextToken
        startedAt
        __typename
      }
      created_by
      modified_by
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    country_id
    country {
      id
      country_id
      country_name
      alpha_2_code
      Jobs {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    id_eds {
      items {
        id
        id_ed_id
        id_ed_number
        id_ed_type
        bl_number
        form_required
        form_number
        remark
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    cash_book_entries {
      items {
        id
        cashbook_id
        date
        cash_handler
        voucher_number
        job_id
        job_creation_date
        account_id
        description
        debit_usd
        credit_usd
        debit_mmk
        credit_mmk
        is_deleted
        remark
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    route
    comodity
    date_of_arrival
    voyage
    forwarder
    liner
    description
    remark
    date_of_delivery
    is_active
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteJobSubscriptionVariables,
  APITypes.OnDeleteJobSubscription
>;
export const onCreateInvoice = /* GraphQL */ `subscription OnCreateInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
  onCreateInvoice(filter: $filter) {
    id
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_code
    invoice_date
    invoice_type
    customer_id
    customer_name
    comodity
    description
    remark
    id_or_ed_and_count
    id_ed_numbers
    container_types_and_counts
    container_numbers
    bl_list
    invoice_details {
      items {
        id
        invoice_id
        invoice_date
        description
        amount_mmk
        amount_usd
        debit_mmk
        debit_usd
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoice_detail_ids
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInvoiceSubscriptionVariables,
  APITypes.OnCreateInvoiceSubscription
>;
export const onUpdateInvoice = /* GraphQL */ `subscription OnUpdateInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
  onUpdateInvoice(filter: $filter) {
    id
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_code
    invoice_date
    invoice_type
    customer_id
    customer_name
    comodity
    description
    remark
    id_or_ed_and_count
    id_ed_numbers
    container_types_and_counts
    container_numbers
    bl_list
    invoice_details {
      items {
        id
        invoice_id
        invoice_date
        description
        amount_mmk
        amount_usd
        debit_mmk
        debit_usd
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoice_detail_ids
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInvoiceSubscriptionVariables,
  APITypes.OnUpdateInvoiceSubscription
>;
export const onDeleteInvoice = /* GraphQL */ `subscription OnDeleteInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
  onDeleteInvoice(filter: $filter) {
    id
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_code
    invoice_date
    invoice_type
    customer_id
    customer_name
    comodity
    description
    remark
    id_or_ed_and_count
    id_ed_numbers
    container_types_and_counts
    container_numbers
    bl_list
    invoice_details {
      items {
        id
        invoice_id
        invoice_date
        description
        amount_mmk
        amount_usd
        debit_mmk
        debit_usd
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoice_detail_ids
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInvoiceSubscriptionVariables,
  APITypes.OnDeleteInvoiceSubscription
>;
export const onCreateInvoiceDetail = /* GraphQL */ `subscription OnCreateInvoiceDetail(
  $filter: ModelSubscriptionInvoiceDetailFilterInput
) {
  onCreateInvoiceDetail(filter: $filter) {
    id
    invoice_id
    invoice_date
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    amount_mmk
    amount_usd
    debit_mmk
    debit_usd
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInvoiceDetailSubscriptionVariables,
  APITypes.OnCreateInvoiceDetailSubscription
>;
export const onUpdateInvoiceDetail = /* GraphQL */ `subscription OnUpdateInvoiceDetail(
  $filter: ModelSubscriptionInvoiceDetailFilterInput
) {
  onUpdateInvoiceDetail(filter: $filter) {
    id
    invoice_id
    invoice_date
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    amount_mmk
    amount_usd
    debit_mmk
    debit_usd
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInvoiceDetailSubscriptionVariables,
  APITypes.OnUpdateInvoiceDetailSubscription
>;
export const onDeleteInvoiceDetail = /* GraphQL */ `subscription OnDeleteInvoiceDetail(
  $filter: ModelSubscriptionInvoiceDetailFilterInput
) {
  onDeleteInvoiceDetail(filter: $filter) {
    id
    invoice_id
    invoice_date
    invoice {
      id
      job_id
      job {
        id
        job_id
        job_code
        date_of_creation
        invoice_id
        customer_id
        country_id
        route
        comodity
        date_of_arrival
        voyage
        forwarder
        liner
        description
        remark
        date_of_delivery
        is_active
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      job_code
      invoice_date
      invoice_type
      customer_id
      customer_name
      comodity
      description
      remark
      id_or_ed_and_count
      id_ed_numbers
      container_types_and_counts
      container_numbers
      bl_list
      invoice_details {
        nextToken
        startedAt
        __typename
      }
      invoice_detail_ids
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    amount_mmk
    amount_usd
    debit_mmk
    debit_usd
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInvoiceDetailSubscriptionVariables,
  APITypes.OnDeleteInvoiceDetailSubscription
>;
export const onCreateContainerType = /* GraphQL */ `subscription OnCreateContainerType(
  $filter: ModelSubscriptionContainerTypeFilterInput
) {
  onCreateContainerType(filter: $filter) {
    id
    container_type_id
    container_type
    description
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContainerTypeSubscriptionVariables,
  APITypes.OnCreateContainerTypeSubscription
>;
export const onUpdateContainerType = /* GraphQL */ `subscription OnUpdateContainerType(
  $filter: ModelSubscriptionContainerTypeFilterInput
) {
  onUpdateContainerType(filter: $filter) {
    id
    container_type_id
    container_type
    description
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContainerTypeSubscriptionVariables,
  APITypes.OnUpdateContainerTypeSubscription
>;
export const onDeleteContainerType = /* GraphQL */ `subscription OnDeleteContainerType(
  $filter: ModelSubscriptionContainerTypeFilterInput
) {
  onDeleteContainerType(filter: $filter) {
    id
    container_type_id
    container_type
    description
    containers {
      items {
        id
        container_id
        container_number
        container_type_id
        job_id
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContainerTypeSubscriptionVariables,
  APITypes.OnDeleteContainerTypeSubscription
>;
export const onCreateContainer = /* GraphQL */ `subscription OnCreateContainer($filter: ModelSubscriptionContainerFilterInput) {
  onCreateContainer(filter: $filter) {
    id
    container_id
    container_number
    container_type_id
    container_type {
      id
      container_type_id
      container_type
      description
      containers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContainerSubscriptionVariables,
  APITypes.OnCreateContainerSubscription
>;
export const onUpdateContainer = /* GraphQL */ `subscription OnUpdateContainer($filter: ModelSubscriptionContainerFilterInput) {
  onUpdateContainer(filter: $filter) {
    id
    container_id
    container_number
    container_type_id
    container_type {
      id
      container_type_id
      container_type
      description
      containers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContainerSubscriptionVariables,
  APITypes.OnUpdateContainerSubscription
>;
export const onDeleteContainer = /* GraphQL */ `subscription OnDeleteContainer($filter: ModelSubscriptionContainerFilterInput) {
  onDeleteContainer(filter: $filter) {
    id
    container_id
    container_number
    container_type_id
    container_type {
      id
      container_type_id
      container_type
      description
      containers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContainerSubscriptionVariables,
  APITypes.OnDeleteContainerSubscription
>;
export const onCreateIdEd = /* GraphQL */ `subscription OnCreateIdEd($filter: ModelSubscriptionIdEdFilterInput) {
  onCreateIdEd(filter: $filter) {
    id
    id_ed_id
    id_ed_number
    id_ed_type
    bl_number
    form_required
    form_number
    remark
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateIdEdSubscriptionVariables,
  APITypes.OnCreateIdEdSubscription
>;
export const onUpdateIdEd = /* GraphQL */ `subscription OnUpdateIdEd($filter: ModelSubscriptionIdEdFilterInput) {
  onUpdateIdEd(filter: $filter) {
    id
    id_ed_id
    id_ed_number
    id_ed_type
    bl_number
    form_required
    form_number
    remark
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateIdEdSubscriptionVariables,
  APITypes.OnUpdateIdEdSubscription
>;
export const onDeleteIdEd = /* GraphQL */ `subscription OnDeleteIdEd($filter: ModelSubscriptionIdEdFilterInput) {
  onDeleteIdEd(filter: $filter) {
    id
    id_ed_id
    id_ed_number
    id_ed_type
    bl_number
    form_required
    form_number
    remark
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteIdEdSubscriptionVariables,
  APITypes.OnDeleteIdEdSubscription
>;
export const onCreateCashBook = /* GraphQL */ `subscription OnCreateCashBook($filter: ModelSubscriptionCashBookFilterInput) {
  onCreateCashBook(filter: $filter) {
    id
    cashbook_id
    date
    cash_handler
    voucher_number
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_creation_date
    account_id
    account {
      id
      account_id
      account_code
      account_code_sr
      account_name
      account_group_id
      account_group {
        id
        account_group_id
        account_group_code
        account_group_name
        account_heading_code
        account_heading
        is_active
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      opening_amount
      opening_date
      is_active
      remark
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    debit_usd
    credit_usd
    debit_mmk
    credit_mmk
    is_deleted
    remark
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCashBookSubscriptionVariables,
  APITypes.OnCreateCashBookSubscription
>;
export const onUpdateCashBook = /* GraphQL */ `subscription OnUpdateCashBook($filter: ModelSubscriptionCashBookFilterInput) {
  onUpdateCashBook(filter: $filter) {
    id
    cashbook_id
    date
    cash_handler
    voucher_number
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_creation_date
    account_id
    account {
      id
      account_id
      account_code
      account_code_sr
      account_name
      account_group_id
      account_group {
        id
        account_group_id
        account_group_code
        account_group_name
        account_heading_code
        account_heading
        is_active
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      opening_amount
      opening_date
      is_active
      remark
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    debit_usd
    credit_usd
    debit_mmk
    credit_mmk
    is_deleted
    remark
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCashBookSubscriptionVariables,
  APITypes.OnUpdateCashBookSubscription
>;
export const onDeleteCashBook = /* GraphQL */ `subscription OnDeleteCashBook($filter: ModelSubscriptionCashBookFilterInput) {
  onDeleteCashBook(filter: $filter) {
    id
    cashbook_id
    date
    cash_handler
    voucher_number
    job_id
    job {
      id
      job_id
      job_code
      date_of_creation
      invoice_id
      invoice {
        id
        job_id
        job_code
        invoice_date
        invoice_type
        customer_id
        customer_name
        comodity
        description
        remark
        id_or_ed_and_count
        id_ed_numbers
        container_types_and_counts
        container_numbers
        bl_list
        invoice_detail_ids
        archived
        created_by
        modified_by
        createdAt
        updatedAt
        virtual_index
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      customer_id
      customer {
        id
        customer_id
        customer_code
        customer_name
        opening_amount
        contact_person
        contact_address
        phone
        is_active
        remark
        created_by
        modified_by
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      country_id
      country {
        id
        country_id
        country_name
        alpha_2_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      containers {
        nextToken
        startedAt
        __typename
      }
      id_eds {
        nextToken
        startedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      route
      comodity
      date_of_arrival
      voyage
      forwarder
      liner
      description
      remark
      date_of_delivery
      is_active
      archived
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    job_creation_date
    account_id
    account {
      id
      account_id
      account_code
      account_code_sr
      account_name
      account_group_id
      account_group {
        id
        account_group_id
        account_group_code
        account_group_name
        account_heading_code
        account_heading
        is_active
        remark
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cash_book_entries {
        nextToken
        startedAt
        __typename
      }
      opening_amount
      opening_date
      is_active
      remark
      created_by
      modified_by
      createdAt
      updatedAt
      virtual_index
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    description
    debit_usd
    credit_usd
    debit_mmk
    credit_mmk
    is_deleted
    remark
    archived
    created_by
    modified_by
    createdAt
    updatedAt
    virtual_index
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCashBookSubscriptionVariables,
  APITypes.OnDeleteCashBookSubscription
>;
export const onCreateCashHandler = /* GraphQL */ `subscription OnCreateCashHandler(
  $filter: ModelSubscriptionCashHandlerFilterInput
) {
  onCreateCashHandler(filter: $filter) {
    id
    short_name
    full_name
    email
    phone
    address
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCashHandlerSubscriptionVariables,
  APITypes.OnCreateCashHandlerSubscription
>;
export const onUpdateCashHandler = /* GraphQL */ `subscription OnUpdateCashHandler(
  $filter: ModelSubscriptionCashHandlerFilterInput
) {
  onUpdateCashHandler(filter: $filter) {
    id
    short_name
    full_name
    email
    phone
    address
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCashHandlerSubscriptionVariables,
  APITypes.OnUpdateCashHandlerSubscription
>;
export const onDeleteCashHandler = /* GraphQL */ `subscription OnDeleteCashHandler(
  $filter: ModelSubscriptionCashHandlerFilterInput
) {
  onDeleteCashHandler(filter: $filter) {
    id
    short_name
    full_name
    email
    phone
    address
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCashHandlerSubscriptionVariables,
  APITypes.OnDeleteCashHandlerSubscription
>;
export const onCreateLog = /* GraphQL */ `subscription OnCreateLog($filter: ModelSubscriptionLogFilterInput) {
  onCreateLog(filter: $filter) {
    id
    job_id
    job_code
    model
    model_id
    operation
    old_data
    new_data
    user
    changed_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLogSubscriptionVariables,
  APITypes.OnCreateLogSubscription
>;
export const onUpdateLog = /* GraphQL */ `subscription OnUpdateLog($filter: ModelSubscriptionLogFilterInput) {
  onUpdateLog(filter: $filter) {
    id
    job_id
    job_code
    model
    model_id
    operation
    old_data
    new_data
    user
    changed_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLogSubscriptionVariables,
  APITypes.OnUpdateLogSubscription
>;
export const onDeleteLog = /* GraphQL */ `subscription OnDeleteLog($filter: ModelSubscriptionLogFilterInput) {
  onDeleteLog(filter: $filter) {
    id
    job_id
    job_code
    model
    model_id
    operation
    old_data
    new_data
    user
    changed_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLogSubscriptionVariables,
  APITypes.OnDeleteLogSubscription
>;
export const onCreatePermission = /* GraphQL */ `subscription OnCreatePermission(
  $filter: ModelSubscriptionPermissionFilterInput
) {
  onCreatePermission(filter: $filter) {
    id
    model
    operation
    required_permission_level
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePermissionSubscriptionVariables,
  APITypes.OnCreatePermissionSubscription
>;
export const onUpdatePermission = /* GraphQL */ `subscription OnUpdatePermission(
  $filter: ModelSubscriptionPermissionFilterInput
) {
  onUpdatePermission(filter: $filter) {
    id
    model
    operation
    required_permission_level
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePermissionSubscriptionVariables,
  APITypes.OnUpdatePermissionSubscription
>;
export const onDeletePermission = /* GraphQL */ `subscription OnDeletePermission(
  $filter: ModelSubscriptionPermissionFilterInput
) {
  onDeletePermission(filter: $filter) {
    id
    model
    operation
    required_permission_level
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePermissionSubscriptionVariables,
  APITypes.OnDeletePermissionSubscription
>;
export const onCreatePageVisit = /* GraphQL */ `subscription OnCreatePageVisit($filter: ModelSubscriptionPageVisitFilterInput) {
  onCreatePageVisit(filter: $filter) {
    id
    page
    user
    visited_at
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePageVisitSubscriptionVariables,
  APITypes.OnCreatePageVisitSubscription
>;
export const onUpdatePageVisit = /* GraphQL */ `subscription OnUpdatePageVisit($filter: ModelSubscriptionPageVisitFilterInput) {
  onUpdatePageVisit(filter: $filter) {
    id
    page
    user
    visited_at
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePageVisitSubscriptionVariables,
  APITypes.OnUpdatePageVisitSubscription
>;
export const onDeletePageVisit = /* GraphQL */ `subscription OnDeletePageVisit($filter: ModelSubscriptionPageVisitFilterInput) {
  onDeletePageVisit(filter: $filter) {
    id
    page
    user
    visited_at
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePageVisitSubscriptionVariables,
  APITypes.OnDeletePageVisitSubscription
>;

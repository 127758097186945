import {
  Button,
  Card,
  Form,
  Space,
  Typography,
  DatePicker,
  Select,
  Input,
  Table,
  message,
} from 'antd';
import React, { useCallback, useEffect } from 'react';
import NetworkIndicator from '../../components/NetworkIndicator';
import {
  INVOICE_TYPE,
  Invoice,
  InvoiceByCustomerQueryVariables,
  InvoiceByInvoiceDateQueryVariables,
  InvoiceByJobCodeQueryVariables,
  InvoiceByTypeQueryVariables,
  ModelSortDirection,
} from '../../API';
import { Customer } from '../../models';
import { API, DataStore } from 'aws-amplify';
import dayjs, { Dayjs } from 'dayjs';
import { invoiceTypeMap } from '../Invoice/Invoice';

import { GraphQLQuery } from '@aws-amplify/api';

import * as queries from './invoice_filter_queries';
import { compact, uniqBy } from 'lodash';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { SettingOutlined } from '@ant-design/icons';

import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const softLimit = 100;
const hardLimit = 100;

type InvoiceQueryName =
  | 'invoiceByInvoiceDate'
  | 'invoiceByType'
  | 'invoiceByCustomer'
  | 'invoiceByJobCode';
type InvoiceQueryVariables =
  | InvoiceByInvoiceDateQueryVariables
  | InvoiceByTypeQueryVariables
  | InvoiceByCustomerQueryVariables
  | InvoiceByJobCodeQueryVariables;

const InvoiceFilter = () => {
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  // filter states
  const [filtersLoaded, setFiltersLoaded] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<[string, string] | null>(null);
  const [customerId, setCustomerId] = React.useState<string | undefined>();
  const [invoiceType, setInvoiceType] = React.useState<INVOICE_TYPE | undefined>();
  const [job_code, setJobCode] = React.useState<string | undefined>();
  const [paginationInfo, setPaginationInfo] = React.useState<TablePaginationConfig>({} as any);
  // query Functions State
  const [queryName, setQueryName] = React.useState<InvoiceQueryName>('invoiceByInvoiceDate');
  const [queryVariables, setQueryVariables] = React.useState<InvoiceQueryVariables>({
    limit: 100,
    filter: { and: [{ _deleted: { attributeExists: false } }] },
  } as InvoiceByInvoiceDateQueryVariables);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const [descriptionVisible, setDescriptionVisible] = React.useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const [filterForm] = Form.useForm();

  useEffect(() => {
    const fetchCustomers = async () => {
      const customers = await DataStore.query(Customer);
      setCustomers(customers);
    };
    fetchCustomers();
  }, []);

  /// filter Calculations

  const listInvoiceByFilterValues = async (
    queryName: InvoiceQueryName,
    queryVariables: InvoiceQueryVariables,
    softLimit: number,
    hardLimit: number,
    forDownload: boolean
  ) => {
    const filteredInvoices: Invoice[] = [];
    let nextToken: string | null = queryVariables.nextToken || null;
    let limit = softLimit;
    let count = 0;
    let modified_hard_limit = Math.max(hardLimit, limit);

    do {
      const result = await API.graphql<GraphQLQuery<any>>({
        query: queries[queryName],
        variables: { ...queryVariables, limit, nextToken },
      });

      if (result && result.data) {
        let invoices: Invoice[] = result.data[queryName]?.items || [];
        invoices = compact(invoices).filter((i) => !i?._deleted === true);

        filteredInvoices.push(...invoices);
        count += invoices.length;
        nextToken = result.data[queryName]?.nextToken || null;
      } else {
        nextToken = null;
      }
    } while (nextToken && count < modified_hard_limit);

    if (!forDownload) {
      setIsLoading(false);
    }
    return { filteredInvoices, nextToken };
  };

  const pushIntoVairableFilter = (
    variables: InvoiceQueryVariables,
    filter: {
      date?: [string, string] | null;
      customerId?: string | undefined;
      invoiceType?: INVOICE_TYPE | undefined;
      // job_code: string | undefined; // not required since if there is job_code, it is already in the queryVariables
    }
  ) => {
    if (!variables.filter) {
      variables.filter = { and: [] };
    } else if (!variables.filter.and) {
      variables.filter.and = [];
    }

    if (filter.date) {
      variables.filter.and!.push({
        invoice_date: { between: filter.date },
      });
    }
    if (filter.customerId) {
      variables.filter.and!.push({ customer_id: { eq: filter.customerId } });
    }
    if (filter.invoiceType) {
      variables.filter.and!.push({ invoice_type: { eq: filter.invoiceType } });
    }
    return variables;
  };

  const buildQueryNameAndVariables = useCallback(
    (
      date: [string, string] | null,
      customerId: string | undefined,
      invoiceType: INVOICE_TYPE | undefined,
      job_code: string | undefined
    ) => {
      let query_name: InvoiceQueryName;
      let query_variables: InvoiceQueryVariables;
      if (job_code) {
        query_name = 'invoiceByJobCode';
        query_variables = {
          limit: 100,
          job_code: { beginsWith: job_code },
          virtual_index: 0,
          sortDirection: ModelSortDirection.ASC,
        } satisfies InvoiceByJobCodeQueryVariables;
        pushIntoVairableFilter(query_variables, { date, customerId, invoiceType });
      } else if (customerId) {
        query_name = 'invoiceByCustomer';
        query_variables = {
          limit: 100,
          customer_id: customerId,
          invoice_date: date ? { between: date } : undefined,
          sortDirection: ModelSortDirection.DESC,
        } satisfies InvoiceByCustomerQueryVariables;
        pushIntoVairableFilter(query_variables, { invoiceType });
      } else if (invoiceType) {
        query_name = 'invoiceByType';
        query_variables = {
          limit: 100,
          invoice_type: invoiceType,
          invoice_date: date ? { between: date } : undefined,
          sortDirection: ModelSortDirection.DESC,
        } satisfies InvoiceByTypeQueryVariables;
      } else {
        // when only date filter is selected or no filter is selected
        query_name = 'invoiceByInvoiceDate';
        query_variables = {
          limit: 100,
          virtual_index: 0,
          invoice_date: date ? { between: date } : undefined,
          sortDirection: ModelSortDirection.DESC,
        } satisfies InvoiceByInvoiceDateQueryVariables;
      }
      return { queryName: query_name, queryVariables: query_variables };
    },
    []
  );

  useEffect(() => {
    // called when page loaded and whenever query variables change
    const buildQueryAndFetchFirstTime = async () => {
      const { queryName, queryVariables } = buildQueryNameAndVariables(
        date,
        customerId,
        invoiceType,
        job_code
      );

      setQueryName(queryName);
      // first time run the fetch (at page load and when filters
      setIsLoading(true);
      const { filteredInvoices, nextToken } = await listInvoiceByFilterValues(
        queryName,
        queryVariables,
        softLimit,
        hardLimit,
        false
      );
      setInvoices(uniqBy(filteredInvoices, 'id'));
      setQueryVariables({ ...queryVariables, nextToken });
    };
    if (filtersLoaded) {
      buildQueryAndFetchFirstTime();
    }
  }, [date, customerId, invoiceType, job_code, filtersLoaded, buildQueryNameAndVariables]);

  useEffect(() => {
    // load filter values from local storage on page load
    const filter_date = localStorage.getItem('invoice_filter_date');
    const filter_customerId = localStorage.getItem('invoice_filter_customerId');
    const filter_invoiceType = localStorage.getItem('invoice_filter_invoiceType');
    const filter_job_code = localStorage.getItem('invoice_filter_job_code');
    const filter_pagination_info = localStorage.getItem('invoice_filter_pagination_info');
    if (filter_date) {
      setDate(JSON.parse(filter_date));
    }
    if (filter_customerId) {
      setCustomerId(filter_customerId);
    }
    if (filter_invoiceType) {
      setInvoiceType(filter_invoiceType as INVOICE_TYPE);
    }
    if (filter_job_code) {
      setJobCode(filter_job_code);
    }
    if (filter_pagination_info) {
      setPaginationInfo(JSON.parse(filter_pagination_info));
    }
    const parsed_date = filter_date ? JSON.parse(filter_date) : null;
    filterForm.setFieldsValue({
      date: parsed_date ? [dayjs(parsed_date[0]), dayjs(parsed_date[1])] : null,
      customerId: filter_customerId,
      invoiceType: filter_invoiceType,
      job_code: filter_job_code,
    });
    setFiltersLoaded(true);
  }, [filterForm]);

  type AllFilterValues = {
    date: [Dayjs, Dayjs] | null;
    customerId: string | undefined;
    invoiceType: INVOICE_TYPE | undefined;
    job_code: string | undefined;
  };

  let timeoutId: NodeJS.Timeout | null = null;
  const onFilterChange = (changedValues: Partial<AllFilterValues>, allValues: AllFilterValues) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if (allValues.date) {
        setDate([allValues.date[0].format('YYYY-MM-DD'), allValues.date[1].format('YYYY-MM-DD')]);
        localStorage.setItem(
          'invoice_filter_date',
          JSON.stringify([
            allValues.date[0].format('YYYY-MM-DD'),
            allValues.date[1].format('YYYY-MM-DD'),
          ])
        );
      } else {
        setDate(null);
        localStorage.removeItem('invoice_filter_date');
      }
      if (allValues.customerId) {
        setCustomerId(allValues.customerId);
        localStorage.setItem('invoice_filter_customerId', allValues.customerId);
      } else {
        setCustomerId(undefined);
        localStorage.removeItem('invoice_filter_customerId');
      }
      if (allValues.invoiceType) {
        setInvoiceType(allValues.invoiceType);
        localStorage.setItem('invoice_filter_invoiceType', allValues.invoiceType);
      } else {
        setInvoiceType(undefined);
        localStorage.removeItem('invoice_filter_invoiceType');
      }
      const job_code = allValues.job_code ? allValues.job_code.trim() : undefined;
      if (job_code) {
        setJobCode(job_code);
        localStorage.setItem('invoice_filter_job_code', job_code);
      } else {
        setJobCode(undefined);
        localStorage.removeItem('invoice_filter_job_code');
      }
    }, 500);
  };

  const clearFilter = () => {
    filterForm.resetFields();
    setDate(null);
    setCustomerId(undefined);
    setInvoiceType(undefined);
    setJobCode(undefined);
    localStorage.removeItem('invoice_filter_date');
    localStorage.removeItem('invoice_filter_customerId');
    localStorage.removeItem('invoice_filter_invoiceType');
    localStorage.removeItem('invoice_filter_job_code');
  };

  const fetchMore = async () => {
    if (!queryVariables.nextToken) return;
    setIsLoading(true);
    const { filteredInvoices, nextToken } = await listInvoiceByFilterValues(
      queryName,
      queryVariables,
      softLimit,
      hardLimit,
      false
    );
    setInvoices(uniqBy([...invoices, ...filteredInvoices], 'id'));
    setQueryVariables({ ...queryVariables, nextToken });
  };

  const fetchForExport = async () => {
    setIsLoading(true);
    setIsDownloading(true);
    const all_invoices: Invoice[] = [];
    let nextToken: string | undefined | null;
    let softLimit = 500;
    let hardLimit = 500;

    do {
      messageApi.open({
        type: 'loading',
        content: `Downloading invoice data, ${all_invoices.length} records downloaded.`,
        key: 'exporting',
      });
      const { filteredInvoices, nextToken: nt } = await listInvoiceByFilterValues(
        queryName,
        { ...queryVariables, nextToken },
        softLimit,
        hardLimit,
        true
      );
      all_invoices.push(...filteredInvoices);
      nextToken = nt;
    } while (nextToken);

    messageApi.open({
      type: 'success',
      content: `${all_invoices.length} invoice records successfully exported.`,
      duration: 5,
      key: 'exporting',
    });

    await exportToExcel({ invoices: uniqBy(all_invoices, 'id') });

    setIsLoading(false);
    setIsDownloading(false);
  };

  const columns: ColumnsType<Invoice> = [
    {
      title: 'Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Job Code',
      dataIndex: 'job_code',
      key: 'job_code',
      width: 150,
      fixed: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'invoice_type',
      key: 'invoice_type',
      width: 100,
      render: (type: INVOICE_TYPE) => invoiceTypeMap[type],
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      ellipsis: true,
      width: 200,
    },
    {
      title: 'Dr. USD',
      dataIndex: 'debit_usd',
      key: 'debit_usd',
      width: 80,
      align: 'right',
      render: (text, record) => {
        const invoice_details = compact(record.invoice_details?.items || []).filter(
          (id) => id._deleted !== true
        );
        const debit_total_usd = invoice_details.reduce((acc, i) => acc + (i?.debit_usd || 0), 0);
        return debit_total_usd.toLocaleString();
      },
    },
    {
      title: 'Dr. MMK',
      dataIndex: 'debit_mmk',
      key: 'debit_mmk',
      width: 100,
      align: 'right',
      render: (text, record) => {
        const invoice_details = compact(record.invoice_details?.items || []).filter(
          (id) => id._deleted !== true
        );
        const debit_total_mmk = invoice_details.reduce((acc, i) => acc + (i?.debit_mmk || 0), 0);
        return debit_total_mmk.toLocaleString();
      },
    },
    {
      title: 'Cr. USD',
      dataIndex: 'credit_usd',
      key: 'credit_usd',
      width: 80,
      align: 'right',
      render: (text, record) => {
        const invoice_details = compact(record.invoice_details?.items || []).filter(
          (id) => id._deleted !== true
        );
        const credit_total_usd = invoice_details.reduce((acc, i) => acc + (i?.amount_usd || 0), 0);
        return credit_total_usd.toLocaleString();
      },
    },
    {
      title: 'Cr. MMK',
      dataIndex: 'credit_mmk',
      key: 'credit_mmk',
      width: 100,
      align: 'right',
      render: (text, record) => {
        const invoice_details = compact(record.invoice_details?.items || []).filter(
          (id) => id._deleted !== true
        );
        const credit_total_mmk = invoice_details.reduce((acc, i) => acc + (i?.amount_mmk || 0), 0);
        return credit_total_mmk.toLocaleString();
      },
    },
    {
      title: 'Comodity',
      dataIndex: 'comodity',
      key: 'comodity',
      ellipsis: true,
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      hidden: !descriptionVisible,
      width: 200,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      ellipsis: true,
      width: 200,
    },
    {
      title: 'ID/ED',
      dataIndex: 'id_or_ed_and_count',
      key: 'id_or_ed_and_count',
      ellipsis: true,
      width: 150,
    },
    {
      title: 'ID/ED No.',
      dataIndex: 'id_ed_numbers',
      key: 'id_ed_numbers',
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Container Types',
      dataIndex: 'container_types_and_counts',
      key: 'container_types_and_counts',
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Container Numbers',
      dataIndex: 'container_numbers',
      key: 'container_numbers',
      ellipsis: true,
      width: 150,
    },
    {
      title: 'BL List',
      dataIndex: 'bl_list',
      key: 'bl_list',
      ellipsis: true,
      width: 150,
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      {contextHolder}
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Title level={4} style={{ color: 'darkred' }}>
              Invoice Filter
            </Title>
          </Space>
        }
        style={{ width: '100%', height: 'fit-content', marginTop: -10 }}
        extra={
          <Space size='large'>
            <Button type='link' onClick={fetchMore} disabled={queryVariables.nextToken === null}>
              fetch more
            </Button>
            {/* <SettingOutlined
              style={{ cursor: 'pointer', color: '#5D5D5D', marginTop: 5 }}
              onClick={() => setDescriptionVisible(!descriptionVisible)}
            /> */}
            <Button onClick={clearFilter} disabled={isLoading}>
              Clear filter
            </Button>
            <Button type='primary' onClick={fetchForExport} disabled={isLoading}>
              Export
            </Button>
            <NetworkIndicator />
          </Space>
        }
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Form
            form={filterForm}
            layout='inline'
            onValuesChange={onFilterChange}
            disabled={isDownloading}
          >
            <Form.Item
              label='Date'
              name='date'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              style={{ width: 280, marginBottom: 10 }}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item
              label='Customer'
              name='customerId'
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              style={{ width: 350, marginBottom: 10 }}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.label || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={customers
                  .filter((c) => c.is_active)
                  .map((c) => ({ value: c.id, label: c.customer_name }))
                  .sort((a, b) => (a.label > b.label ? 1 : -1))}
              />
            </Form.Item>
            <Form.Item
              label='Job Code'
              name='job_code'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ width: 300, marginBottom: 10 }}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label='Invoice Type'
              name='invoiceType'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ width: 300, marginBottom: 10 }}
            >
              <Select listHeight={500} allowClear>
                {Object.values(INVOICE_TYPE)
                  // .filter((it) => !['IMPORT', 'EXPORT', 'AIR', 'LCL'].includes(it))
                  .map((type) => (
                    <Select.Option value={type} key={type}>
                      {invoiceTypeMap[type]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <Table
          columns={columns}
          dataSource={invoices}
          size='small'
          bordered
          rowKey='id'
          scroll={{ x: 1800 }}
          loading={isLoading}
          pagination={{
            current: paginationInfo.current,
            pageSize: 10,
            showTotal: (total, range) => `Total ${total} records downloaded.`,
          }}
          onChange={(pagination, filters, sorter, extra) => {
            setPaginationInfo(pagination);
            localStorage.setItem('invoice_filter_pagination_info', JSON.stringify(pagination));
          }}
        />
      </Card>
    </div>
  );
};

export default InvoiceFilter;

const exportToExcel = async ({ invoices }: { invoices: Invoice[] }) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const invoice_sheet = workbook.addWorksheet('Invoices');
    invoice_sheet.columns = [
      { header: 'Date', key: 'invoice_date', width: 15 },
      { header: 'Job Code', key: 'job_code', width: 15 },
      { header: 'Type', key: 'invoice_type', width: 15 },
      { header: 'Customer', key: 'customer_name', width: 20 },
      { header: 'Dr. USD', key: 'debit_usd', width: 15 },
      { header: 'Dr. MMK', key: 'debit_mmk', width: 15 },
      { header: 'Cr. USD', key: 'credit_usd', width: 15 },
      { header: 'Cr. MMK', key: 'credit_mmk', width: 15 },
      { header: 'Comodity', key: 'comodity', width: 20 },
      { header: 'Description', key: 'description', width: 20 },
      { header: 'Remark', key: 'remark', width: 20 },
      { header: 'ID/ED', key: 'id_or_ed_and_count', width: 15 },
      { header: 'ID/ED No.', key: 'id_ed_numbers', width: 15 },
      { header: 'Container Types', key: 'container_types_and_counts', width: 15 },
      { header: 'Container Numbers', key: 'container_numbers', width: 15 },
      { header: 'BL List', key: 'bl_list', width: 15 },
    ];

    invoice_sheet.getColumn('debit_usd').numFmt = '#,##0';
    invoice_sheet.getColumn('debit_mmk').numFmt = '#,##0';
    invoice_sheet.getColumn('credit_usd').numFmt = '#,##0';
    invoice_sheet.getColumn('credit_mmk').numFmt = '#,##0';
    invoices.forEach((invoice) => {
      const invoice_details = compact(invoice.invoice_details?.items || []).filter(
        (id) => id._deleted !== true
      );
      const debit_total_usd = invoice_details.reduce((acc, i) => acc + (i?.debit_usd || 0), 0);
      const debit_total_mmk = invoice_details.reduce((acc, i) => acc + (i?.debit_mmk || 0), 0);
      const credit_total_usd = invoice_details.reduce((acc, i) => acc + (i?.amount_usd || 0), 0);
      const credit_total_mmk = invoice_details.reduce((acc, i) => acc + (i?.amount_mmk || 0), 0);
      invoice_sheet.addRow({
        invoice_date: invoice.invoice_date,
        job_code: invoice.job_code,
        invoice_type: invoiceTypeMap[invoice.invoice_type],
        customer_name: invoice.customer_name,
        debit_usd: debit_total_usd,
        debit_mmk: debit_total_mmk,
        credit_usd: credit_total_usd,
        credit_mmk: credit_total_mmk,
        comodity: invoice.comodity,
        description: invoice.description,
        remark: invoice.remark,
        id_or_ed_and_count: invoice.id_or_ed_and_count,
        id_ed_numbers: invoice.id_ed_numbers,
        container_types_and_counts: invoice.container_types_and_counts,
        container_numbers: invoice.container_numbers,
        bl_list: invoice.bl_list,
      });
    });

    invoice_sheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
      };
    });

    invoice_sheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];
    invoice_sheet.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: invoices.length,
        column: 16,
      },
    };

    const invoice_detail_sheet = workbook.addWorksheet(`Invoice Details`);
    invoice_detail_sheet.columns = [
      { header: 'Jobe Code', key: 'job_code', width: 20 },
      { header: 'Invoice Date', key: 'invoice_date', width: 20 },
      { header: 'Customer', key: 'customer_name', width: 20 },
      { header: 'Description', key: 'description', width: 20 },
      { header: 'Debit USD', key: 'debit_usd', width: 20 },
      { header: 'Debit MMK', key: 'debit_mmk', width: 20 },
      { header: 'Credit USD', key: 'credit_usd', width: 20 },
      { header: 'Credit MMK', key: 'credit_mmk', width: 20 },
    ];
    invoice_detail_sheet.getColumn('debit_usd').numFmt = '#,##0';
    invoice_detail_sheet.getColumn('debit_mmk').numFmt = '#,##0';
    invoice_detail_sheet.getColumn('credit_usd').numFmt = '#,##0';
    invoice_detail_sheet.getColumn('credit_mmk').numFmt = '#,##0';

    let invoice_detail_length = 0;

    invoices.forEach((invoice) => {
      const invoice_details = compact(invoice.invoice_details?.items || []).filter(
        (id) => id._deleted !== true
      );
      invoice_details.forEach((detail) => {
        invoice_detail_sheet.addRow({
          job_code: invoice.job_code,
          invoice_date: invoice.invoice_date,
          customer_name: invoice.customer_name,
          description: detail?.description || '',
          debit_usd: detail?.debit_usd || 0,
          debit_mmk: detail?.debit_mmk || 0,
          amount_credit_usdusd: detail?.amount_usd || 0,
          credit_mmk: detail?.amount_mmk || 0,
        });
        invoice_detail_length++;
      });
    });

    invoice_detail_sheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
      };
    });

    invoice_detail_sheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];
    invoice_detail_sheet.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: invoice_detail_length,
        column: 8,
      },
    };
    // https://github.com/exceljs/exceljs/issues/2041
    // [BUG] Slow generation performance #2041
    const excelGenerator = new ExcelGenerator();
    excelGenerator.finalizeWorkbook(workbook);
    //////////////////////////
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `Invoice Filter Export ${dayjs().format('YYYY-MM-DD h-mm a')}.xlsx`);
  } catch (error) {
    console.log('error exporting invoice', error);
  } finally {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
};

// https://github.com/exceljs/exceljs/issues/2041
// [BUG] Slow generation performance #2041
class ExcelGenerator {
  private internStyle<T extends Partial<ExcelJS.Style>>(
    internedStyles: Map<string, T>,
    style: T,
    type: number
  ) {
    let buf = `${JSON.stringify(style)}${type}`;

    const internedStyle = internedStyles.get(buf);
    if (internedStyle) {
      return internedStyle;
    }

    const newInternedStyle = Object.freeze(Object.assign({}, style));
    internedStyles.set(buf, newInternedStyle);
    return newInternedStyle;
  }

  public finalizeWorkbook(workbook: ExcelJS.Workbook) {
    const internedStyles = new Map<string, Partial<ExcelJS.Style>>();
    workbook.worksheets.forEach((worksheet) => {
      (worksheet as any)._rows.forEach((row: ExcelJS.Row) => {
        (row as any)._cells.forEach((cell: ExcelJS.Cell) => {
          cell.style = this.internStyle(internedStyles, cell.style, cell.type);
        });
      });
    });
  }
}

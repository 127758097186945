import React from 'react';
import Styles from './Home.module.css';
const Home = () => {
  return (
    <div className={Styles.hero}>
      <img src='/letter_head/letter_head@2x.png' alt='S.T.L Company Limited' />
      <h1>Job Management Information System</h1>
    </div>
  );
};

export default Home;

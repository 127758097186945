import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import 'antd/dist/reset.css';

import { ConfigProvider, Button, theme } from 'antd';
import Layout from './components/layout';
import Home from './pages/Home/Home';
import Users from './pages/Users/Users';
import Accounts from './pages/Accounts/Accounts';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Customers from './pages/Customers/Customers';
import Jobs from './pages/Jobs/Jobs';
import Job from './pages/Jobs/Job';
import { CashBook } from './pages/Jobs/CashBook';
import CashbookFilter from './pages/Filters/CashBookFilter';
import About from './pages/About/About';
import Login from './pages/Login/Login';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DataStore } from 'aws-amplify';
import JobForm from './pages/Jobs/JobForm';
import { Containers } from './pages/Jobs/Containers';
import { IdEds } from './pages/Jobs/IdEds';
import Invoice from './pages/Invoice/Invoice';
import User from './pages/Users/User';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Customer from './pages/Customers/Customer';
import CustomerForm from './pages/Customers/CustomerForm';
import CashHandler from './pages/CashHandler/CashHandler';
import InvoiceFilter from './pages/Filters/InvoiceFilter';
import JobFilter from './pages/Filters/JobFilter';
import { CustomUserType, convertUser, getUser } from './pages/Users/adminFunctions';
import { AuthContext } from './pages/Users/AuthContext';
import AccountGroups from './pages/Accounts/AccountGroups';
import Pagevisits from './pages/Pagevisits/Pagevisits';

const queryClient = new QueryClient();

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { route, user } = useAuthenticator((context) => [context.route, context.user]);
  const [currentUser, setCurrentUser] = useState<CustomUserType | undefined>(undefined);
  const isAuhenticated = route === 'authenticated';

  const mqListener = useCallback((e: MediaQueryListEvent) => {
    //changing app theme
    setDarkMode(e.matches);
    // changing app logo
    let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement | null;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (e.matches) {
      link.href = './logos/white_logo.png';
    } else {
      link.href = './logos/green_logo.png';
    }
  }, []);

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    darkThemeMq.addEventListener('change', mqListener);
    return () => darkThemeMq.removeEventListener('change', mqListener);
  }, []);

  useEffect(() => {
    const fetchUser = async (username: string) => {
      const user = await getUser(username);
      const converted_user = await convertUser(user);
      setCurrentUser(converted_user);
    };
    if (route === 'authenticated' && user.username) {
      fetchUser(user.username);
    } else {
      setCurrentUser(undefined);
    }
  }, [route, user]);

  const router = createBrowserRouter([
    {
      path: '/',
      loader: () => import('./pages/Home/Home'),
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: '/home',
          element: <Home />,
        },
        {
          path: '/users',
          element: isAuhenticated ? <Users /> : <Login />,
        },
        {
          path: '/users/:username',
          element: isAuhenticated ? <User /> : <Login />,
        },
        {
          path: '/cashhandlers',
          element: isAuhenticated ? <CashHandler /> : <Login />,
        },
        {
          path: '/accounts/accounts',
          element: isAuhenticated ? <Accounts /> : <Login />,
        },
        {
          path: '/accounts/accountgroups',
          element: isAuhenticated ? <AccountGroups /> : <Login />,
        },
        {
          path: '/customers',
          element: isAuhenticated ? <Customers /> : <Login />,
        },
        {
          path: '/customers/:customer_id',
          element: isAuhenticated ? <Customer /> : <Login />,
        },
        {
          path: '/customers/:customer_id/edit',
          element: isAuhenticated ? <CustomerForm /> : <Login />,
        },
        {
          path: '/customers/new',
          element: isAuhenticated ? <CustomerForm /> : <Login />,
        },
        {
          path: '/jobs',
          element: isAuhenticated ? <Jobs /> : <Login />,
        },
        {
          path: '/jobs/new',
          element: isAuhenticated ? <JobForm /> : <Login />,
        },
        {
          path: '/jobs/:job_id',
          element: isAuhenticated ? <Job /> : <Login />,
        },
        {
          path: '/jobs/:job_id/edit',
          element: isAuhenticated ? <JobForm /> : <Login />,
        },
        {
          path: '/jobs/:job_id/invoice/:invoice_id',
          element: isAuhenticated ? <Invoice /> : <Login />,
        },
        {
          path: '/jobs/:job_id/cashbook',
          element: isAuhenticated ? <CashBook /> : <Login />,
        },
        {
          path: '/jobs/:job_id/containers',
          element: isAuhenticated ? <Containers /> : <Login />,
        },
        {
          path: '/jobs/:job_id/ideds',
          element: isAuhenticated ? <IdEds /> : <Login />,
        },
        {
          path: '/filters/cashbook',
          element: isAuhenticated ? <CashbookFilter /> : <Login />,
        },
        {
          path: '/filters/invoice',
          element: isAuhenticated ? <InvoiceFilter /> : <Login />,
        },
        {
          path: '/filters/jobs',
          element: isAuhenticated ? <JobFilter /> : <Login />,
        },
        {
          path: '/about',
          element: <About />,
          errorElement: <div>Oops! There was an error.</div>,
        },
        {
          path: '/pagevisits',
          element: <Pagevisits />,
        },
        { path: '/login', element: <Login /> },
      ],
    },
    // {
    //   path: '/contacts/:contactId',
    //   element: <Contact />,
    // },
  ]);

  return (
    <ConfigProvider
      theme={{
        token: {
          wireframe: false,
          colorPrimary: '#176B87',
          borderRadius: 6,
        },
        components: {
          List: {
            fontSize: 18,
          },
          Table: {
            fontSize: 14,
          },
        },
        algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={{ currentUser }}>
          <RouterProvider router={router} />
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        </AuthContext.Provider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, Card, Form, Input, Modal, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import {
  createCashHandler,
  deleteCashHandler,
  getCashHandler,
  updateCashHandler,
  useCashHandlerSubscription,
} from './api';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import useLogDrawer from '../../custom_hooks/useLogDrawer';
import { logActivity } from '../../utilities/logger';
import { DataTableType, OperationType, CashHandler as CashHandlerAPI } from '../../API';
import { ColumnsType } from 'antd/es/table';
import { LeftOutlined, ProfileOutlined } from '@ant-design/icons';
import { useGetPermissionInfo, useIsAdmin } from '../Users/adminHooks';
import NetworkIndicator from '../../components/NetworkIndicator';
import { AuthContext } from '../Users/AuthContext';

const { Title } = Typography;

const CashHandler = () => {
  const [form] = Form.useForm();
  const [modalState, setModalState] = React.useState<'add' | 'edit' | null>(null);
  const [editingCashHandler, setEditingCashHandler] = React.useState<CashHandlerAPI | null>(null);

  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.CASH_HANDLER);

  const { cash_handlers } = useCashHandlerSubscription();

  useDocumentTitle('Cash Handlers');

  const { openDrawer, LogDrawer } = useLogDrawer();

  useEffect(() => {
    if (modalState === 'add') {
      form.resetFields();
    }
    if (modalState === 'edit') {
      form.setFieldsValue(editingCashHandler);
    }
  }, [modalState, editingCashHandler, form]);

  const validateShortName = async (_: any, value: string) => {
    if (!value) return;
    if (modalState === 'edit' && editingCashHandler?.short_name === value) return;
    const cash_handlers_with_same_short_name = cash_handlers?.filter(
      (cash_handler) => cash_handler.short_name === value
    );
    if (cash_handlers_with_same_short_name?.length) {
      return Promise.reject('Short Name already used.');
    }
    return Promise.resolve();
  };

  const hasEditingCashHandlerChanged = (
    editingCashHandler: CashHandlerAPI,
    values: CashHandlerAPI
  ) => {
    const fieldListToCheck = ['short_name', 'full_name', 'email', 'phone', 'address'];
    return fieldListToCheck.some(
      (field) =>
        editingCashHandler[field as keyof CashHandlerAPI] !== values[field as keyof CashHandlerAPI]
    );
  };

  const onFormSave = async (values: CashHandlerAPI) => {
    if (!currentUser) return;
    try {
      if (modalState === 'add') {
        const newCashHandler = await createCashHandler({
          ...values,
        });
        await logActivity({
          user_email: currentUser.email,
          job: null,
          model_type: DataTableType.CASH_HANDLER,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newCashHandler || null,
        });
      } else if (modalState === 'edit') {
        if (!editingCashHandler) return;
        const oldCashHandler = await getCashHandler(editingCashHandler.id);
        if (!oldCashHandler) return;
        if (!hasEditingCashHandlerChanged(oldCashHandler, values)) {
          setModalState(null);
          return;
        }
        const updatedCashHandler = await updateCashHandler({
          ...values,
          id: editingCashHandler.id,
          _version: oldCashHandler._version,
        });
        await logActivity({
          user_email: currentUser.email,
          job: null,
          model_type: DataTableType.CASH_HANDLER,
          operation_type: OperationType.UPDATE,
          old_data: oldCashHandler,
          new_data: updatedCashHandler || null,
        });
      }
    } catch (error) {
      console.log('error saving cash handler', error);
    } finally {
      form.resetFields();
      setModalState(null);
    }
  };

  const onDelete = async (cashHandler: CashHandlerAPI) => {
    if (!currentUser) return;
    try {
      const deletedCashHandler = await deleteCashHandler(cashHandler.id, cashHandler._version);
      await logActivity({
        user_email: currentUser.email,
        job: null,
        model_type: DataTableType.CASH_HANDLER,
        operation_type: OperationType.DELETE,
        old_data: deletedCashHandler || null,
        new_data: null,
      });
    } catch (error) {
      console.log('error deleting cash handler', error);
    }
  };

  const columns: ColumnsType<CashHandlerAPI> = [
    {
      title: 'Short Name',
      dataIndex: 'short_name',
      key: 'short_name',
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 160,
      render: (text, record) => (
        <Space>
          <ProfileOutlined
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              openDrawer(undefined, DataTableType.CASH_HANDLER, record.id, 'model');
            }}
          />
          {/* Button is disabled when
          1. User is not admin
          2. User does not have permission to update cash handler
          */}
          <Button
            onClick={() => {
              setModalState('edit');
              setEditingCashHandler(record);
            }}
            type='link'
            size='middle'
            disabled={!(currentUser?.admin && permission.UPDATE)}
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete Cash Handler ${record.short_name}?`}
            onConfirm={() => onDelete(record)}
            okText='Yes'
            cancelText='No'
          >
            {/* Button is disabled when
            1. User is not admin
            2. User does not have permission to delete cash handler
            */}
            <Button
              type='link'
              size='middle'
              danger
              disabled={!(currentUser?.admin && permission.DELETE)}
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size={'middle'} align='start'>
            <Title level={4} style={{ color: 'darkred' }}>
              Cash Handlers
            </Title>
          </Space>
        }
        style={{ width: 'max(calc(100vw - 400px), 900px)', height: 'fit-content' }}
        extra={
          <Space>
            <NetworkIndicator />
          </Space>
        }
      >
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5, marginTop: -15 }}
        >
          <Button
            type='default'
            onClick={() => setModalState('add')}
            disabled={!(currentUser?.admin && permission.CREATE)}
          >
            Add Cash Handler
          </Button>
        </div>
        <Table dataSource={cash_handlers} columns={columns} size='small' rowKey={'id'} />
        <Modal
          title={
            modalState === 'add'
              ? 'Add New Cash Handler'
              : `Edit Cash Handler ${editingCashHandler?.short_name}`
          }
          open={modalState !== null}
          okText='Save'
          onOk={async () => {
            form.submit();
          }}
          onCancel={() => {
            setModalState(null);
          }}
          styles={{ body: { paddingTop: 20 } }}
          destroyOnClose={true}
          maskClosable={false}
        >
          <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFormSave}>
            <Form.Item
              label='Short Name'
              name='short_name'
              rules={[
                { required: true, message: 'Please input a short name.' },
                { whitespace: true, message: 'Please input a short name.' },
                { validator: validateShortName },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Full Name'
              name='full_name'
              rules={[
                { required: true, message: 'Please input a full name.' },
                { whitespace: true, message: 'Please input a full name.' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Email'
              name='email'
              rules={[{ type: 'email', message: 'Please input a valid email.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label='Phone' name='phone'>
              <Input />
            </Form.Item>
            <Form.Item label='Address' name='address'>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
          </Form>
        </Modal>
      </Card>
      {LogDrawer}
    </div>
  );
};

export default CashHandler;

import React, { ReactNode, useEffect } from 'react';
import Styles from './main_navbar.module.css';
import { NavLink, useLocation } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { DataStore } from 'aws-amplify';
import { AccountGroup } from '../models';
import { ConfigProvider, Menu, MenuProps, theme } from 'antd';

const MainNavBar = () => {
  const { user, route, signOut } = useAuthenticator((context) => [
    context.user,
    context.route,
    context.signOut,
  ]);
  const [isFilterPage, setIsFilterPage] = React.useState(false);
  const [isAccountPage, setIsAccountPage] = React.useState(false);

  let location = useLocation();

  type MenuItemType = {
    path?: string;
    label: string;
    authenticated?: boolean;
    children?: MenuItemType[];
  };

  const menuItems: MenuItemType[] = [
    { path: '/home', label: 'Home' },
    { path: '/users', label: 'Users', authenticated: true },
    { path: '/cashhandlers', label: 'Cash Handlers', authenticated: true },
    {
      label: 'Accounts',
      authenticated: true,
      children: [
        { path: '/accounts/accounts', label: 'Accounts' },
        { path: '/accounts/accountgroups', label: 'Account Groups' },
      ],
    },
    { path: '/customers', label: 'Customers', authenticated: true },
    { path: '/jobs', label: 'Jobs', authenticated: true },
    {
      label: 'Filters',
      authenticated: true,
      children: [
        { path: '/filters/cashbook', label: 'Cashbook' },
        { path: '/filters/jobs', label: 'Jobs' },
        { path: '/filters/invoice', label: 'Invoice' },
      ],
    },
    // { path: '/cashbook', label: 'Cashbook', authenticated: true },
    { path: '/about', label: 'About' },
  ];

  const isAuthenticated = route === 'authenticated';

  return (
    <nav>
      <ul className={Styles.nav_container}>
        <img src='./logos/green_logo.png' />
        {menuItems.map((item) => {
          const { path, label, authenticated } = item;
          const showItem = !authenticated || route === 'authenticated';
          if (!showItem) return null;

          let menuItem: ReactNode | null = null;
          if (path) {
            menuItem = (
              <NavLink
                key={path}
                to={path}
                className={({ isActive }) => (isActive ? Styles.active : '')}
              >
                {label}
              </NavLink>
            );
          } else if (item.children) {
            menuItem = (
              <div className={Styles.dropdown} key={label}>
                <span
                  key={label}
                  className={
                    location.pathname.split('/')[1] === label.toLowerCase() ? Styles.active : ''
                  }
                >
                  {label}
                  <div className={Styles.dropdownContent}>
                    {item.children.map((child) => {
                      const { path, label } = child;
                      if (!path) return null;
                      return (
                        <NavLink
                          key={path}
                          to={path}
                          className={({ isActive }) => (isActive ? Styles.active : '')}
                        >
                          {label}
                        </NavLink>
                      );
                    })}
                  </div>
                </span>
              </div>
            );
          }

          return menuItem;
        })}
        <div className={Styles.right_items}>
          {isAuthenticated ? (
            <>
              <a>{user?.attributes?.email}</a>
              <NavLink
                className={({ isActive }) => (isActive ? Styles.active : '')}
                to='/home'
                onClick={async () => {
                  signOut();
                  DataStore.clear();
                }}
              >
                Logout
              </NavLink>
            </>
          ) : (
            <NavLink className={({ isActive }) => (isActive ? Styles.active : '')} to='/login'>
              Login
            </NavLink>
          )}
        </div>
      </ul>
    </nav>
  );
};

export default MainNavBar;

import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Space,
  Typography,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Divider,
  Switch,
} from 'antd';
import { useNetworkStatus } from '../../custom_hooks/useNetworkStatus';
import NetworkIndicator from '../../components/NetworkIndicator';
import { LeftOutlined } from '@ant-design/icons';
import { Country, Customer, DataTableType, OperationType } from '../../models';
import { DataStore, API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import * as queries from '../../graphql/queries';
import { JobByCodeQuery, ROUTE_TYPE, Job } from '../../API';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { logActivity } from '../../utilities/logger';
import { createJob, getJob, getJobsByCode, updateJob } from './api';

import { Auth } from 'aws-amplify';
import useDocumentTitle from '../../custom_hooks/useDocumentTitle';
import { AuthContext } from '../Users/AuthContext';
import { useGetPermissionInfo } from '../Users/adminHooks';

const { Title } = Typography;

const JobForm = () => {
  const { job_id } = useParams<{ job_id: string }>();
  const isOnline = useNetworkStatus();
  const [form] = Form.useForm();
  const [editingJob, setEditingJob] = useState<Job | undefined>(undefined);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  const { currentUser } = useContext(AuthContext);
  const { permission } = useGetPermissionInfo(currentUser, DataTableType.JOB);
  const navigate = useNavigate();
  useDocumentTitle(editingJob ? `Editing ${editingJob.job_code}` : 'New Job');

  // const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    const getCustomers = async () => {
      const customers = (await DataStore.query(Customer))
        .filter((customer) => customer.is_active === true)
        .sort((a, b) => a.customer_name.localeCompare(b.customer_name));
      setCustomers(customers);
    };
    const getCountries = async () => {
      const countries = (await DataStore.query(Country)).sort((a, b) =>
        a.country_name.localeCompare(b.country_name)
      );
      setCountries(countries);
    };
    getCustomers();
    getCountries();
  }, []);

  type JobCopy = Omit<Job, 'date_of_arrival' | 'date_of_delivery'> & {
    date_of_arrival: Dayjs | null;
  } & {
    date_of_delivery: Dayjs | null;
  };

  useEffect(() => {
    if (job_id !== undefined) {
      const getEditingJob = async () => {
        const job = await getJob(job_id);
        if (job) {
          setEditingJob(job);

          let jobCopy = {} as JobCopy;
          for (let key in job) {
            if (key === 'date_of_arrival' || key === 'date_of_delivery') {
              jobCopy[key] = job[key] ? dayjs(job[key]) : null;
            } else {
              // @ts-ignore
              jobCopy[key] = job[key];
            }
          }
          // correct the above error
          form.setFieldsValue(jobCopy);
        }
      };
      getEditingJob();
    } else {
      setEditingJob(undefined);
      form.resetFields();
    }
  }, [job_id, form]);

  const validateJobCode = async (_: any, value: string) => {
    if (!value) return;
    if (job_id) {
      const editingJob = await getJob(job_id);
      if (editingJob?.job_code === value) {
        // console.log('same job code');
        return Promise.resolve();
      }
    }
    // const returnJobs = await API.graphql<GraphQLQuery<JobByCodeQuery>>({
    //   query: queries.jobByCode,
    //   variables: { job_code: value },
    // });
    const jobByCode = await getJobsByCode(value.trim());
    if (!jobByCode) {
      return Promise.reject('Validation failed');
    }
    if (jobByCode.filter((j) => !(j._deleted === true)).length > 0) {
      return Promise.reject('Job code already used.');
    }
    return Promise.resolve();
  };

  const hasEditingJobChanged = (editingJob: Job, values: JobCopy) => {
    const { date_of_arrival, date_of_delivery, ...rest } = values;
    const job: Job = {
      ...rest,
      date_of_arrival: date_of_arrival ? date_of_arrival.format('YYYY-MM-DD') : null,
      date_of_delivery: date_of_delivery ? date_of_delivery.format('YYYY-MM-DD') : null,
    };
    const fieldListToCheck = [
      'job_code',
      'customer_id',
      'country_id',
      'route',
      'comodity',
      'voyage',
      'forwarder',
      'liner',
      'description',
      'remark',
      'is_active',
      'date_of_arrival',
      'date_of_delivery',
    ];
    // @ts-ignore
    return fieldListToCheck.some((field) => editingJob[field] !== job[field]);
  };

  const onFormSave = async (values: JobCopy) => {
    if (!currentUser) return;
    // dirty: true will validate only if the field is touched
    // if not set this, isFieldsChange will be triggered when validateFields is called
    // this is to prevent logging activity when the form is not dirty
    // const values: JobCopy = await form.validateFields({ dirty: true });
    try {
      const { date_of_arrival, date_of_delivery, ...rest } = values;
      const job: Job = {
        ...rest,
        date_of_arrival: date_of_arrival ? date_of_arrival.format('YYYY-MM-DD') : null,
        date_of_delivery: date_of_delivery ? date_of_delivery.format('YYYY-MM-DD') : null,
      };
      if (job_id) {
        if (!permission.UPDATE) return;
        if (!hasEditingJobChanged(editingJob!, values)) {
          navigate(`/jobs/${job_id}`);
          return;
        }
        // const editingJob = await DataStore.query(Job, job_id);
        if (!editingJob || editingJob.archived) return;
        const updatedJob = await updateJob({
          id: job_id,
          job_code: values.job_code.trim(),
          customer_id: values.customer_id,
          country_id: values.country_id,
          route: values.route,
          comodity: values.comodity,
          date_of_arrival: values.date_of_arrival
            ? values.date_of_arrival.format('YYYY-MM-DD')
            : null,
          voyage: values.voyage,
          forwarder: values.forwarder,
          liner: values.liner,
          description: values.description,
          remark: values.remark,
          date_of_delivery: values.date_of_delivery
            ? values.date_of_delivery.format('YYYY-MM-DD')
            : null,
          is_active: values.is_active || false,
          _version: editingJob._version,
        });
        await logActivity({
          user_email: currentUser.email,
          job: updatedJob,
          model_type: DataTableType.JOB,
          operation_type: OperationType.UPDATE,
          old_data: editingJob,
          new_data: updatedJob,
        });
        navigate(`/jobs/${job_id}`);
      } else {
        if (!permission.CREATE) return;
        const newJob = await createJob({
          job_code: values.job_code,
          date_of_creation: dayjs().format('YYYY-MM-DD'),
          customer_id: values.customer_id,
          country_id: values.country_id,
          route: values.route,
          comodity: values.comodity,
          date_of_arrival: values.date_of_arrival
            ? values.date_of_arrival.format('YYYY-MM-DD')
            : null,
          voyage: values.voyage,
          forwarder: values.forwarder,
          liner: values.liner,
          description: values.description,
          remark: values.remark,
          date_of_delivery: values.date_of_delivery
            ? values.date_of_delivery.format('YYYY-MM-DD')
            : null,
          is_active: values.is_active || false,
          archived: false,
        });

        await logActivity({
          user_email: currentUser.email,
          job: newJob,
          model_type: DataTableType.JOB,
          operation_type: OperationType.CREATE,
          old_data: null,
          new_data: newJob,
        });
        navigate(`/jobs/${newJob.id}`);
      }
    } catch (error) {
      console.error('error saving job', error);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        // overflow: 'auto',
        padding: 20,
      }}
    >
      <Card
        title={
          <Space size='large' align='start'>
            <Button
              type='primary'
              shape='circle'
              icon={<LeftOutlined />}
              size='middle'
              onClick={() => navigate(-1)}
            />
            <Title level={4} style={{ color: 'darkred' }}>
              {job_id === undefined
                ? 'New Job'
                : `Edit Job : ${
                    editingJob
                      ? editingJob.job_code + `${editingJob.archived ? ' (locked)' : ''}`
                      : ''
                  }`}
            </Title>
            {/* <span>{`Form ${isFormDirty ? 'is dirty.' : 'is not dirty'}`}</span> */}
          </Space>
        }
        style={{ width: 'max(calc(100vw - 500px), 900px)', height: 'calc(100vh - 10em)' }}
        extra={<NetworkIndicator />}
      >
        <div style={{ height: 'calc(100vh - 20em)', overflow: 'auto' }}>
          <Form
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 14 }}
            layout='horizontal'
            form={form}
            disabled={
              (!job_id && permission.CREATE) || (job_id && permission.UPDATE)
                ? job_id && editingJob?.archived
                  ? true
                  : false
                : true
            }
            // onFieldsChange={() => {
            //   setIsFormDirty(true);
            // }}
            onFinish={onFormSave}
          >
            <Form.Item
              label='Job Code'
              name='job_code'
              validateDebounce={1000}
              rules={[
                { required: true, message: 'Please enter Job Code.' },
                { validator: validateJobCode },
              ]}
            >
              <Input />
            </Form.Item>
            {/* Add two new field to the form customer_id and country_id, both are select types */}
            <Form.Item
              label='Customer'
              name='customer_id'
              rules={[{ required: true, message: 'Please select Customer.' }]}
              validateDebounce={1000}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.children ?? '').toString().toLowerCase().includes(input.toLowerCase())
                }
              >
                {customers.map((customer) => (
                  <Select.Option key={customer.id} value={customer.id}>
                    {customer.customer_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Country'
              name='country_id'
              rules={[{ required: true, message: 'Please select Country.' }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.children ?? '').toString().toLowerCase().includes(input.toLowerCase())
                }
              >
                {countries.map((country) => (
                  <Select.Option key={country.id} value={country.id}>
                    {country.country_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Route' name='route'>
              <Select>
                {Object.values(ROUTE_TYPE).map((route) => (
                  <Select.Option key={route} value={route}>
                    {route}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Comodity' name='comodity'>
              <Input />
            </Form.Item>
            <Form.Item label='Date of Arrival / Date of Departure' name='date_of_arrival'>
              <DatePicker />
            </Form.Item>
            <Form.Item label='Voyage / Flight' name='voyage'>
              <Input />
            </Form.Item>
            <Form.Item label='Forwarder' name='forwarder'>
              <Input />
            </Form.Item>
            <Form.Item label='Liner' name='liner'>
              <Input />
            </Form.Item>
            <Form.Item label='Description' name='description'>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
            <Form.Item label='Remark' name='remark'>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
            <Form.Item label='Date of Delivery' name='date_of_delivery'>
              <DatePicker />
            </Form.Item>
            <Form.Item label='Active' name='is_active' valuePropName='checked'>
              <Checkbox />
            </Form.Item>
            <Divider />
            {/* Add a cancel button and save button */}
            <Form.Item wrapperCol={{ offset: 7 }}>
              <Space>
                <Button onClick={() => navigate(job_id ? `/jobs/${job_id}` : '/jobs')}>
                  Cancel
                </Button>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={
                    (!job_id && permission.CREATE) || (job_id && permission.UPDATE)
                      ? job_id && editingJob?.archived
                        ? true
                        : false
                      : true
                  } // disable save button if user doesn't have permission to create or update
                >
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default JobForm;
